import Container from "react-bootstrap/Container";
import "./home.scss";
import HomeSlider from "./homeslider";
import HomeAuctions from "./homeauctions";
import HomeBanner from "./homebanner";

type Props = {
  direction: string;
};

const Home = (props: Props) => {
  return (
    <Container>
      <HomeBanner/>
      <HomeSlider direction={props.direction} />
      <HomeAuctions direction={props.direction} />
    </Container>
  );
};

export default Home;
