/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  GoogleMap,
  InfoWindow,
  OverlayView,
  MarkerClusterer,
  Marker,
} from "@react-google-maps/api";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./auctionbidding.scss";
import { numberFormat } from "../../utils/functions";
import CommonBidModal from "./commonbidmodal";
import Stopwatch from "../stopwatch/stopwatch";
import redWatchlistIcon from "../../assets/Icons/details-red.svg";
import watchlistIcon from "../../assets/Icons/details.svg";
import CarPlate from "../../assets/Icons/Car Plate.svg";
import TaxiPlate from "../../assets/Icons/taxi_plate.svg";
import Hammer from "../../assets/Icons/hammer.svg";
import { t } from "i18next";
import Location from "../../assets/Icons/location.svg";
import CalendarImg from "../../assets/Icons/calander-timing.svg";
import { AssetTag } from "../../components/tags/AssetTag";

type Props = {
  data?: any;
  showSignIn?: any;
  setShowSignIn?: any;
  show?: any;
  assetItem?: any;
  setShow?: any;
  filteredAssetData?: any;
  is_auto_bidding?: any;
  loader?: any;
  setLoader?: any;
  fullscreen?: any;
  setAssetItem?: any;
  assetTitle?: any;
  assetId?: any;
  assetLoader?: any;
  handleDetailPage?: any;
  handleWatchlist?: any;
  handleShow?: any;
  closeModal?: any;
  openModal?: any;
  override?: any;
  categoryData?: any;
  setHighestStart?: any;
  setHighestFalseShow?: any;
  highestStart?: any;
  highestFalseShow?: any;
  makeBidId?: any;
  setMakeBidId?: any;
  setFilteredAssetDataFunc?: any;
};
export const MapView = (props: Props): JSX.Element => {
  const assetData = props?.filteredAssetData;
  const [mapRef, setMapRef] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [clickedMarkerIndex, setClickedMarkerIndex] = useState(-1);
  const mapOptions = {
    disableDefaultUI: true,
    clickable: false,
  };
  const size = "small"; // Dynamic property name
  const model_year = "Model year";
  const designData = assetData.map((asset: any, index: number) => {
    // eslint-disable-next-line no-lone-blocks
    {
      const ImagePlate =
        asset?.category_title.split(" ")[0] === "Taxi" ? TaxiPlate : CarPlate;

      const dummyImg_1_new = (
        <div className="taxi_parent_container">
          <div className="parent_taxi">
            <div className="box_img_new1">
              <h1 className="font_text">{asset.asset_title}</h1>
            </div>
            <img src={ImagePlate} alt="bahrain" />
          </div>
        </div>
      );
      const dummyImg_2_new =
        asset?.asset_images && asset?.asset_images.length > 0 ? (
          <img
            src={asset?.asset_images[0]?.url[size]}
            alt={asset.asset_title}
            className="cartImage"
          />
        ) : (
          <div className="cartImageMAP customBg"> </div>
        );

      const boxImg =
        asset?.category_title.split(" ")[0] === "Taxi" ||
        asset?.category_title.split(" ")[0] === "Car"
          ? dummyImg_1_new
          : dummyImg_2_new;
      const assetTitle =
        asset?.category_title.split(" ")[0] === "Taxi" ||
        asset?.category_title.split(" ")[0] === "Car"
          ? ""
          : asset.asset_title;
      const imgIcon =
        asset?.category_title === "Vehicles" ? CalendarImg : Location;
      return (
        <Col key={asset.asset_id} lg={12} md={12} sm={12} xs={12}>
          <div className="bidcardsection map">
            <div className="ongoingcard">
              <div
                className="imgessectionicons"
                onClick={() => props.handleDetailPage(asset, index)}
              >
                {asset.tag && (
                  <AssetTag
                    tag={asset.tag}
                    discountPercentage={asset.discount_percentage}
                  />
                )}
                <div className="container_font custom_image">{boxImg}</div>
                <div className="d-flex justify-content-between iconssection ArabicBottomColors">
                  <div
                    className="bidiconsMap"
                    onClick={(event: any) =>
                      props.handleWatchlist(event, asset)
                    }
                  >
                    <img
                      src={
                        asset?.is_added_to_watchlist
                          ? redWatchlistIcon
                          : watchlistIcon
                      }
                      alt="bids"
                    />
                  </div>
                  <div
                    className="bidiconsMap"
                    onClick={(event: any) =>
                      props.handleShow("sm-down", asset, event, index)
                    }
                  >
                    <img src={Hammer} alt="bids" />
                  </div>
                </div>
              </div>
              <h2 className="cardbidtitle">
                {" "}
                <span className="threedots"> {assetTitle} </span>
              </h2>
              {t("en") === "en" ? (
                <span className="cardbidsecondtitle">
                  {t("bhd")} {numberFormat(asset.highest_price)}
                </span>
              ) : (
                <h2 className="cardbidsecondtitle">
                  {numberFormat(asset.highest_price)} {t("bhd")}
                </h2>
              )}

              {asset && asset?.asset_details && asset?.asset_location && (
                <p className="AssetCartTextMap">
                  <img src={imgIcon} alt="cube" className="AssetCartIcons" />
                  {asset && asset?.category_title === "Vehicles"
                    ? asset?.asset_details[model_year]
                    : asset?.asset_location}
                </p>
              )}
            </div>

            <div
              className={
                asset?.is_highest_bidder === true || props?.highestStart
                  ? "d-flex justify-content-between bidcardbottomblueMap"
                  : asset?.is_highest_bidder === false
                  ? "d-flex justify-content-between bidcardbottompinkMap"
                  : "d-flex justify-content-between bidcardbottomsaleMap"
              }
            >
              <div className="d-flex align-items-center ArabicBottomColors">
                <img src={Hammer} alt="bidcount" />
                <span className="bidcount">{asset.total_bids || 0} </span>
              </div>
              <Stopwatch
                compName={"mapList"}
                endTime={asset?.end_time}
                setFilteredAssetDataFunc={props?.setFilteredAssetDataFunc}
                asset_id={asset?.asset_id}
              />
            </div>
          </div>
        </Col>
      );
    }
  });

  const handleInfoWindowOpen = (markerIndex: number) => {
    if (clickedMarkerIndex === markerIndex) {
      // If the same marker is clicked again, close the InfoWindow
      setClickedMarkerIndex(-1);
      setInfoWindowOpen(false);
    } else {
      // If a different marker is clicked, open the InfoWindow
      setClickedMarkerIndex(markerIndex);
      setInfoWindowOpen(true);
    }
  };

  const onMapLoad = (map: any) => {
    setMapRef(map);
    // Add event listener to track when the zoom is changed
    map.addListener("zoom_changed", () => {
      const updatedZoomLevel = map.getZoom();
      setZoom(updatedZoomLevel);
    });
    // assigned the bounds as the approximate bounds of bahrain
    const bounds = new window.google.maps.LatLngBounds(
      new google.maps.LatLng(25.803694, 50.363175)
    );
    bounds.extend(new google.maps.LatLng(26.333516, 50.755249));
    // checks if location exists and is not included in bounds of bahrain then extends the bounds accordingly
    assetData?.forEach(({ latitude: lat, longitude: lng }: any) => {
      return (
        lat !== null &&
        lng !== null &&
        !bounds.contains({ lat, lng }) &&
        bounds.extend({ lat, lng })
      );
    });
    map.fitBounds(bounds);
  };
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const handleInfoWindowClose = () => {
    setInfoWindowOpen(false);
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    // Prevent click event propagation to default markers
    setIsOpen(false);
    event.stop();
  };
  const divStyle = {
    background: "white",
    border: "1px solid #ccc",
    padding: "13px 26px 13px 26px",
    top: "812.01px",
    left: "611px",
    width: "191.7px",
    height: "48.25px",
    borderRadius: "13039.24px",
    fontWeight: 700,
    fontSize: "18.26px",
    display: "flex",
    justifyContent: "center",
  };
  const divStyleSelected = {
    background: "#403E3A",
    border: "1px solid #ccc",
    padding: "13px 26px 13px 26px",
    top: "812.01px",
    left: "611px",
    width: "191.7px",
    height: "48.25px",
    borderRadius: "13039.24px",
    fontWeight: 700,
    fontSize: "18.26px",
    display: "flex",
    justifyContent: "center",
    color: "#FFFFFF",
  };
  const mapstyle = {
    height: "952px",
  };

  // adding zoom state
  const [zoom, setZoom] = useState(0);

  return (
    <>
      <GoogleMap
        zoom={12}
        // onLoad={(map) => setMapRef(map)} // Set the map reference
        mapContainerClassName="map-container"
        onLoad={onMapLoad}
        onClick={handleMapClick}
        mapContainerStyle={mapstyle}
        options={mapOptions}
      >
        {/* Render clusters when zoomed out */}
        {zoom < 16 ? (
          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={60}
            maxZoom={16}
            minimumClusterSize={1}
            clusterClass="custom-cluster-icon"
            styles={[
              {
                url: "https://cluster-map.s3.me-south-1.amazonaws.com/blue-circle.png",
                height: 50, // Icon height
                width: 50, // Icon width
                textColor: "#ffffff", // Text color
              },
              // Add more style objects for different cluster sizes
            ]}
          >
            {(clusterer) =>
              assetData.map(
                (
                  { highest_price, latitude: lat, longitude: lng }: any,
                  ind: any
                ) =>
                  lat !== null &&
                  lng !== null && (
                    <Marker
                      key={ind}
                      position={{ lat, lng }}
                      clusterer={clusterer}
                      onClick={() => handleInfoWindowOpen(ind)}
                    />
                  )
              )
            }
          </MarkerClusterer>
        ) : (
          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={60}
            maxZoom={16}
            minimumClusterSize={1}
            styles={[
              {
                url: "https://cluster-map.s3.me-south-1.amazonaws.com/blue-circle.png",
                height: 50, // Icon height
                width: 50, // Icon width
                textColor: "#ffffff", // Text color
              },
              // Add more style objects for different cluster sizes
            ]}
          >
            {(clusterer) => (
              <div>
                {assetData.map(
                  (
                    { highest_price, latitude: lat, longitude: lng }: any,
                    ind: any
                  ) =>
                    lat !== null &&
                    lng !== null && (
                      // <OverlayView
                      <OverlayView
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        position={{ lat, lng }}
                      >
                        <>
                          <div
                            style={
                              infoWindowOpen && clickedMarkerIndex === ind
                                ? divStyleSelected
                                : divStyle
                            }
                            onClick={() => handleInfoWindowOpen(ind)}
                          >
                            <span>
                              {t("en") === "en"
                                ? t("bhd") + " " + numberFormat(highest_price)
                                : numberFormat(highest_price) + t("bhd")}
                            </span>
                          </div>

                          {infoWindowOpen && clickedMarkerIndex === ind && (
                            <InfoWindow
                              position={{ lat, lng }}
                              onCloseClick={handleInfoWindowClose}
                              options={{
                                //disableAutoPan: true,
                                pixelOffset: new window.google.maps.Size(
                                  0,
                                  -30
                                ),
                              }}
                            >
                              {designData[ind]}
                            </InfoWindow>
                          )}
                        </>
                      </OverlayView>
                    )
                )}
              </div>
            )}
          </MarkerClusterer>
        )}
      </GoogleMap>

      {props.show && (
        <CommonBidModal
          categoryData={props.categoryData}
          filterData={props.data}
          fullscreen={props.fullscreen}
          setShow={props.setShow}
          show={props.show}
          assetItem={props.assetItem}
          setAssetItem={props.setAssetItem}
          loader={props.loader}
          assetId={props.assetId}
          assetTitle={props.assetTitle}
          is_auto_bidding={props.is_auto_bidding}
          setHighestStart={props?.setHighestStart}
          setHighestFalseShow={props?.setHighestFalseShow}
          highestStart={props?.highestStart}
          highestFalseShow={props?.highestFalseShow}
          setMakeBidId={props?.setMakeBidId}
        />
      )}
    </>
  );
};
