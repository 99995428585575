import "./buttons.scss";

type Props = {
  imgUrl: string;
  className: string;
  google_store_link?: string;
};

const handleClick = (google_store_link: any) => {
  if (google_store_link?.value) window.open(google_store_link?.value, "_blank");
};

const AndroidButton = (props: Props) => {
  return (
    <button
      className={` ${props.className}`}
      onClick={() => handleClick(props?.google_store_link)}
    >
      <img src={props.imgUrl} alt="" />
      <span className="iostext">Android</span>
    </button>
  );
};

export default AndroidButton;
