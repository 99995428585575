import { CSSProperties, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { apiWithToken } from "../../feature/envs/api";
import TransectionCards from "./transectioncards";
import Pages from "../../assets/Icons/pages.svg";
import { t } from "i18next";
import MyBidsEmpty from "../mybids/mybidsempty";
import { ClipLoader } from "react-spinners";
import MoneyImg from "../../assets/Icons/EmptyStateImages/Transactions.png";
import { trackPageView } from "../../utils/analytics";

type Props = {};

const TransactionHistory = (props: Props) => {
  const [showDetail, setShowDetail] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const override: CSSProperties = {
    display: "block",
    margin: "20% auto",
    borderColor: "black",
    overflow: "-moz-hidden-unscrollable",
    opacity: 0.1,
  };
  const handleTransaction = () => {
    setLoader(true);
    apiWithToken
      .get("api/v1/transaction", {})
      .then((res: any) => {
        if (res.data.status === 200) {
          const details = res?.data?.data;
          setShowDetail(details);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  useEffect(() => {
    trackPageView((window.location.pathname + window.location.search), "Transaction History");
    handleTransaction();
  }, []);

  const statusClassMap: any = {
    PENDING: "pending_for_registration_approval",
    DONE: "successfully_approved",
    REFUNDED: "successfully_approved",
  };

  const statusNameMap: any = {
    PENDING: t("pending_approval"),
    DONE: t("successfully_deposited"),
    REFUNDED: t("successfully_refunded"),
  };
  return (
    <>
      {loader ? (
        <ClipLoader
          color={"#fff"}
          loading={loader}
          cssOverride={override}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <Row className="TransectionHistoryRight">
          {showDetail?.map((detail: any) => {
            const classVal = statusClassMap[detail.status] || "payment_cancel";
            const nameVal = detail?.transaction_type === "REFUND" ? detail?.status === 'PENDING' ?statusNameMap[detail?.status] : t("successfully_refunded") : statusNameMap[detail?.status];
            return (
              <Col sm={5} className="BiddingCardsBottom" key={detail.id}>
                <TransectionCards
                  imgUrl={Pages}
                  className={classVal}
                  name={nameVal}
                  amount={detail.deposit_amount}
                  date={detail?.transaction_date || detail?.deposit_date}
                  detail={detail}
                  used_deposited_amount={detail?.used_deposited_amount}
                  payment_method={detail?.payment_method}
                />
              </Col>
            );
          })}
          {!loader && showDetail.length == 0 && (
            <MyBidsEmpty imgUrl={MoneyImg} title={"transaction_history"} />
          )}
        </Row>
      )}
    </>
  );
};

export default TransactionHistory;
