import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import MyBidsEmpty from "../mybids/mybidsempty";
import "./auctionbidding.scss";
import { isWithin5Seconds, numberFormat } from "../../utils/functions";
import CommonBidModal from "./commonbidmodal";
import SignInPhoneModal from "../../components/modals/signinphonemodal";
import Stopwatch from "../stopwatch/stopwatch";
import redWatchlistIcon from "../../assets/Icons/details-red.svg";
import watchlistIcon from "../../assets/Icons/details.svg";
import CarPlate from "../../assets/Icons/Car Plate.svg";
import TaxiPlate from "../../assets/Icons/taxi_plate.svg";
import Hammer from "../../assets/Icons/hammer.svg";
import { ClipLoader } from "react-spinners";

import noBids from "../../assets/Icons/noBids.svg";
import { CSSProperties, useContext, useState } from "react";
import { AllDataContext } from "../../contexts/signup";
import { v4 as uuidv4 } from "uuid";
import { AssetTag } from "../../components/tags/AssetTag";

type Props = {
  data?: any;
  showSignIn?: any;
  setShowSignIn?: any;
  show?: any;
  assetItem?: any;
  setShow?: any;
  filteredAssetData?: any;
  is_auto_bidding?: any;
  loader?: any;
  setLoader?: any;
  fullscreen?: any;
  setAssetItem?: any;
  assetTitle?: any;
  assetId?: any;
  assetLoader?: any;
  handleDetailPage?: any;
  handleWatchlist?: any;
  handleShow?: any;
  closeModal?: any;
  openModal?: any;
  override?: any;
  categoryData?: any;
  setFilteredAssetDataFunc?: any;
  setHighestStart?: any;
  setHighestFalseShow?: any;
  highestStart?: any;
  highestFalseShow?: any;
  makeBidId?: any;
  setMakeBidId?: any;
  category_title?: any;
  viewMap?: any;
  pageLoader?: boolean;
};

const CategoryPropertiesGrid = (props: Props) => {
  const { t } = useTranslation();
  const [shouldBlink, setShouldBlink] = useState(false);
  let blinkTimeout: any = null;
  const { allData, setAllData } = useContext<any>(AllDataContext);

  const callAnimationFunc = () => {
    clearTimeout(blinkTimeout); // Clear the previous timeout, if any
    blinkTimeout = setTimeout(() => {
      setShouldBlink(false);
    }, 1500);
    setShouldBlink(true);
  };

  let shouldBlinkClass = " ";
  const size = "small"; // Dynamic property name

  const category_title = props?.category_title || allData?.category_title;
  if (props?.viewMap) {
    return <></>;
  }
  const handleImageError = (e: any) => {
    const img = e.target;
    img.style.display = "none"; // Hide the failed image
  };
  const override: CSSProperties = {
    display: "block",
    margin: "20% auto",
    borderColor: "black",
    overflow: "-moz-hidden-unscrollable",
    opacity: 0.1,
  };

  return (
    <>
      <Row>
        {props?.assetLoader ? (
          <ClipLoader
            loading={props.assetLoader}
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : props?.filteredAssetData && props?.filteredAssetData?.length > 0 ? (
          <>
            {props?.filteredAssetData?.map((asset: any, index: number) => {
              const asset_title =
                t("en") === "en" ? asset?.asset_title : asset?.asset_title_ar;
              if (isWithin5Seconds(asset?.latest_bid_time)) {
                shouldBlinkClass = "bidcardsection blink-animation";
                if (!shouldBlink) {
                  callAnimationFunc();
                }
              } else {
                shouldBlinkClass = "bidcardsection";
              }

              const ImagePlate =
                category_title?.split(" ")[0] === "Taxi" ? TaxiPlate : CarPlate;

              const dummyImg_1_new = (
                <div className="taxi_parent_container">
                  <div className="parent_taxi">
                    <div className="box_img_new1">
                      <h1 className="font_text">{asset?.asset_title}</h1>
                    </div>
                    <img src={ImagePlate} alt={asset?.asset_title} />
                  </div>
                </div>
              );
              const dummyImg_2_new =
                asset?.asset_images && asset?.asset_images.length > 0 ? (
                  <img
                    loading="lazy"
                    src={asset?.asset_images[0]?.url[size]}
                    alt={asset?.asset_title}
                    className="cartImage"
                    onError={handleImageError}
                  />
                ) : (
                  <div className="cartImage customBg"></div>
                );
              const boxImg =
                category_title?.split(" ")[0] === "Taxi" ||
                category_title?.split(" ")[0] === "Car"
                  ? dummyImg_1_new
                  : dummyImg_2_new;
              const assetTitle =
                category_title?.split(" ")[0] === "Taxi" ||
                category_title?.split(" ")[0] === "Car"
                  ? ""
                  : asset_title;

              const brokenImageClass =
                category_title?.split(" ")[0] === "Taxi" ||
                category_title?.split(" ")[0] === "Car"
                  ? ""
                  : "custom_image_broken";

              return (
                <Col key={uuidv4()} lg={2} md={3} sm={4} xs={6}>
                  <div
                    className={
                      shouldBlink ? shouldBlinkClass : "bidcardsection"
                    }
                    onClick={() => props.handleDetailPage(asset, index)}
                  >
                    <div className="ongoingcard">
                      <div className="imgessectionicons">
                        {asset?.tag && (
                          <AssetTag
                            tag={asset?.tag}
                            discountPercentage={asset?.discount_percentage}
                          />
                        )}
                        <div className={`custom_image ${brokenImageClass}`}>
                          {boxImg}
                        </div>
                        <div className="d-flex justify-content-between iconssection ArabicBottomColors">
                          <div
                            className="bidicons"
                            onClick={(event: any) =>
                              props.handleWatchlist(event, asset)
                            }
                          >
                            <img
                              src={
                                asset?.is_added_to_watchlist
                                  ? redWatchlistIcon
                                  : watchlistIcon
                              }
                              alt="bids"
                            />
                          </div>
                          <div
                            className="bidicons"
                            onClick={(event: any) =>
                              props.handleShow("sm-down", asset, event, index)
                            }
                          >
                            <img src={Hammer} alt="bids" />
                          </div>
                        </div>
                      </div>
                      <h2 className="cardbidtitle">
                        {" "}
                        <span className="threedots"> {assetTitle} </span>
                      </h2>
                      {t("en") === "en" ? (
                        <h2 className="cardbidsecondtitle">
                          {t("bhd")} {numberFormat(asset.highest_price)}
                        </h2>
                      ) : (
                        <h2 className="cardbidsecondtitle">
                          {numberFormat(asset.highest_price)} {t("bhd")}
                        </h2>
                      )}
                    </div>

                    <div
                      className={
                        props?.makeBidId === asset?.asset_id &&
                        props?.highestStart
                          ? "d-flex justify-content-between bidcardbottomblue"
                          : asset?.is_highest_bidder === true
                          ? "d-flex justify-content-between bidcardbottomblue"
                          : asset?.is_highest_bidder === false
                          ? "d-flex justify-content-between bidcardbottompink"
                          : "d-flex justify-content-between bidcardbottomsale"
                      }
                    >
                      <div className="d-flex align-items-center ArabicBottomColors">
                        <img src={Hammer} alt="bidcount" />
                        <span className="bidcount">
                          {asset.total_bids || 0}{" "}
                        </span>
                      </div>
                      <Stopwatch
                        compName={"assetList"}
                        endTime={asset?.end_time}
                        setFilteredAssetDataFunc={
                          props?.setFilteredAssetDataFunc
                        }
                        asset_id={asset?.asset_id}
                      />
                    </div>
                  </div>
                </Col>
              );
            })}
          </>
        ) : (
          <>
            {props.assetLoader && (
              <ClipLoader
                color={"#fff"}
                loading={props.assetLoader}
                cssOverride={override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
            {!props.assetLoader && (
              <MyBidsEmpty imgUrl={noBids} title={"auction"} />
            )}
          </>
        )}
      </Row>
      <SignInPhoneModal
        handleCloseModal={props.closeModal}
        showSignIn={props.showSignIn}
        handleOpenModal={props.openModal}
        modalType={t("header_signin")}
      />

      {props.show && (
        <CommonBidModal
          categoryData={props.categoryData}
          filterData={props.data}
          fullscreen={props.fullscreen}
          setShow={props.setShow}
          show={props.show}
          assetItem={props.assetItem}
          setAssetItem={props.setAssetItem}
          loader={props.loader}
          assetId={props.assetId}
          assetTitle={props.assetTitle}
          is_auto_bidding={props.is_auto_bidding}
          auto_bidding_on={props?.assetItem?.auto_bidding_on}
          setHighestStart={props?.setHighestStart}
          setHighestFalseShow={props?.setHighestFalseShow}
          highestStart={props?.highestStart}
          highestFalseShow={props?.highestFalseShow}
          makeBidId={props?.makeBidId}
          setMakeBidId={props?.setMakeBidId}
        />
      )}

      <Row>
        {props?.pageLoader ? (
          <ClipLoader
            color={"#fff"}
            loading={props?.pageLoader}
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          ""
        )}
      </Row>
    </>
  );
};

export default CategoryPropertiesGrid;
