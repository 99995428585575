import { getUserDetails } from "./functions";
import { trackGA4Event } from "./analytics";

/**
 * Class containing data related to the event being logged.
 */
export class EventLoggerData {
  /**
   * The type of device used to log the event. Currently supported:
   * - Mobile: for mobile apps
   * - Web: for web applications
   */
  deviceType: 'Mobile' | 'Web' = 'Mobile';

  /**
   * The UNIX timestamp in milliseconds of when the event was logged.
   */
  deviceTimestamp: number = Date.now();

  /**
   * The user ID.
   */
  userID: string | null = null;

  /**
   * The data related to the event being logged. The type of this property is
   * dependent on the analytics provider being used (and mostly going to be an object).
   */
  eventData: any;
}

  

export class EventLogger {
  static providers = {
    GA4: 'GA4',
    // add other providers here
  };

  /**
   * Logs an event to multiple analytics provider based on the implementation in the module.
   *
   * @param {string} eventName - The name of the event to log.
   * @param {any} params - The parameters for the event.
   * @return {Promise<void>} A promise that resolves when the event is logged.
   */
  static async logEvent(eventName: string, params: any) : Promise<void> {
    try {

      // setup the event data object
      let eventLoggerData = new EventLoggerData();
      eventLoggerData.deviceType = 'Web';
      eventLoggerData.deviceTimestamp = Date.now();
      // make sure that the event data is an object
      eventLoggerData.eventData = typeof params === 'object' ? params : {key: params};
      eventLoggerData.userID = await EventLogger.getCurrentUserID();
  
      Object.values(EventLogger.providers).forEach((provider) => {
        switch (provider) {
          case EventLogger.providers.GA4:
            trackGA4Event(eventName, eventLoggerData);
            break;
          // handle other providers here
          default:
            console.warn(`Provider ${provider} not supported.`);
        }
      });
    } catch (error) {
      console.error(`Failed to log event: ${eventName}. Error: ${error}`);
    }
  }
  

  /**
   * Retrieves the current user's ID.
   *
   * @return {Promise<string | null>} A promise that resolves to the user's ID as a string, or null if the user ID is not found.
   */
  static async getCurrentUserID(): Promise<string | null> {
    try {
      // TODO: add code to retrieve the current user's ID
      let userDetails = getUserDetails();
      if(userDetails) return userDetails?.id;
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  
}


export const EventNames = {
  ADD_TO_WATCHLIST: 'add_to_watchlist',
  REMOVE_FROM_WATCHLIST: 'remove_from_watchlist',
  ADD_TO_CART: 'add_to_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  DEPOSIT: 'deposit',
  REFUND: 'refund',
  BID: 'bid',
  CATEGORY_SELECTION_CHANGE: 'category_selection_change',
  VIEW_ASSET: 'view_asset',
};


type NestedObject = {
  [key: string]: any;
};

/**
 * Flattens a nested object into a single level object.
 * 
 * this is mainly used with firebase provider because firebase analytics only accepts { key : string }
 *
 * @param {NestedObject} obj - The nested object to flatten.
 * @param {string} separator - The separator to use when joining the keys.
 * @param {string} parentKey - The parent key of the current object.
 * @param {NestedObject} result - The result object to store the flattened object.
 */
export function flattenObject(obj: NestedObject, separator = '.', parentKey = '', result: NestedObject = {}): NestedObject {
  for (let key in obj) {
      let newKey = parentKey ? `${parentKey}${separator}${key}` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null && !(obj[key] instanceof Array)) {
          flattenObject(obj[key], separator, newKey, result);
      } else {
          result[newKey] = obj[key];
      }
  }
  return result;
}