type Props = {
  imgUrl: string;
  name: string;
  onClick?: (any: any) => void;
  handleSignUpCloseModal?: (modaltype: any) => void;
};

const DeviceSignInButtons = (props: Props) => {
  return (
    <button
      className="download-apple"
      onClick={props.onClick ? props.onClick : () => {}}
    >
      <img src={props.imgUrl} alt="apple" />
      <span>{props.name}</span>
    </button>
  );
};

export default DeviceSignInButtons;
