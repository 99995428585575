import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ImageViewer from "./ImageViewer";

const renderVideoUrl = (video: any) => {
  return (
    <video controls muted className="image-gallery-image">
      <source src={video.original} />
    </video>
  );
};

type Props = {
  assetImages?: any;
};

const CategoryAssetDetailsTopImages = (props: Props) => {
  const { t } = useTranslation();
  const [imagesArr, setImagesArr] = useState<any>([]);
  const size = "medium"; // Dynamic property name
  const thumbnail = "thumbnail";
  const original = "original";
  const [fullscreen, setFullscreen] = useState<any>(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint: any) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const dataImages = props?.assetImages.map((image: any) => {
    if (image.type === "VIDEO") {
      return {
        original: image.url[original],
        thumbnail: image.url[thumbnail],
        renderItem: renderVideoUrl.bind(image),
      };
    }
    return {
      original: image.url[original],
      thumbnail: image.url[thumbnail],
    };
  });

  useEffect(() => {
    if (props?.assetImages) {
      setImagesArr(props?.assetImages.slice(0, 5) || []);
    }
  }, [props?.assetImages]);

  const getImageClassName = (index: number) => {
    let imgClassName =
      imagesArr.length <= 3 && index === 0 ? "AssetImages_right_border" : "";
    imgClassName =
      imagesArr.length > 3 && index === 1
        ? "AssetImages_right_border"
        : imgClassName;
    imgClassName =
      imagesArr.length === 2
        ? `${imgClassName} AssetImagesButtonSection_custom`
        : imgClassName;
    return `${imgClassName} AssetImage_Gallery_Col`.trim();
  };

  const singleImageClassName =
    imagesArr.length === 1
      ? "AssetImages AssetImages_border"
      : "AssetImages AssetImages_left_border";
  return (
    <Row className="gy-4 ArabicBottomColors">
      {imagesArr?.length > 0 && (
        <>
          <Col
            md={imagesArr.length === 1 ? 12 : 7}
            className="AssetImagesButtonSection AssetImagesButtonSection_custom"
          >
            <img
              src={props?.assetImages[0]?.url[size]}
              alt="assets"
              className={singleImageClassName}
              onClick={() => {
                handleShow("sm-down");
              }}
            />
            <button
              className="AllPhotos"
              onClick={() => {
                handleShow("sm-down");
              }}
            >
              {t("see_all_photos")}
            </button>
          </Col>
          <Col md={imagesArr.length === 1 ? 0 : 5}>
            <Row className="gx-4 gy-4">
              {imagesArr.slice(1, 5).map((data: any, ind: any) => {
                let xs = imagesArr.length === 3 ? 12 : 6;
                xs =
                  imagesArr.length % 2 === 0 && ind === imagesArr.length - 2
                    ? 12
                    : xs;
                return (
                  <Col xs={xs} key={ind} className={getImageClassName(ind)}>
                    <img
                      src={data.url[size]}
                      alt="assets"
                      className="AssetImages"
                      onClick={() => {
                        handleShow("sm-down");
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </>
      )}
      <ImageViewer
        show={show}
        setShow={setShow}
        fullscreen={fullscreen}
        images={dataImages}
      />
    </Row>
  );
};

export default CategoryAssetDetailsTopImages;
