import { useTranslation } from "react-i18next";
import Cube from "../../assets/Icons/directsales-cube.svg";
import { apiWithToken } from "../../feature/envs/api";
import { toast } from "react-toastify";
import { UserContext } from "../../contexts/signup";
import { useContext, useState } from "react";
import SignInPhoneModal from "../modals/signinphonemodal";
import { EventLogger, EventNames } from "../../utils/EventLogger";

type Props = {
  btnImg: string;
  btnValue: string;
  asset_id?: any;
  endTime?: any;
  classNameVal?: any;
  getAsset?: any;
  type?: string;
};

const DirectSaleAddToCart = (props: Props) => {
  const { user } = useContext<any>(UserContext);
  const {type } = props;
  const { t } = useTranslation();
  const [showSignIn, setShowSignIn] = useState(false);
  const [addToCartInProgress, setAddToCartInProgress] = useState(false);
  const [removeFromCartInProgress, setRemoveFromCartInProgress] = useState(false);

  const openModal = () => {
    setShowSignIn(true);
  };
  const closeModal = () => {
    setShowSignIn(false);
  };
  

  const removeFromCart = () => {
    if (removeFromCartInProgress) {
      return;
    }
    // Set the flag to indicate that addToCart is in progress
    setRemoveFromCartInProgress(true);
    apiWithToken
      .put(`/api/v2/cart/remove-from-cart`, { asset_id: props?.asset_id })
      .then((res: any) => {
        if (res.data.status === 200) {
          EventLogger.logEvent(EventNames.REMOVE_FROM_CART, {asset_id: props?.asset_id});
          toast(<p className="CartErrMsg">{res.data.message}</p>, {
            autoClose: 3000,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        } else {
          toast(<p className="CartErrMsg">{res.data.message}</p>, {
            autoClose: 3000,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        }
      }).finally(()=>{
        setTimeout(()=>{
          setRemoveFromCartInProgress(false);
        },1000)
      });
  };

  const addToCart = () => {
     // Check if addToCart is already in progress
     if (addToCartInProgress) {
      return;
    }
    // Set the flag to indicate that addToCart is in progress
    setAddToCartInProgress(true);

    apiWithToken
      .post(`/api/v2/cart/add-to-cart`, { asset_id: props?.asset_id })
      .then((res: any) => {
        if (res.data.status === 200) {
          EventLogger.logEvent(EventNames.ADD_TO_CART, {asset_id: props?.asset_id});
          toast(<p className="CartSuccessMsg">{t("item_added_to_cart")}</p>, {
            autoClose: 3000,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        } else {
          toast(<p className="CartErrMsgLarge">{res.data.message}</p>, {
            autoClose: 2500,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        }
      }).finally(()=>{
        setTimeout(()=>{
          setAddToCartInProgress(false);
        },1000)
      });
  };
  const handleCart = () => {
    if (!user.userLoggedIn) {
      setShowSignIn(true);
    }else {
      if (props?.classNameVal === "remove_from_cart") {
        removeFromCart();
      } else {
        addToCart();
      }
    }
  };

  const customClass = type === 'directsale' ? 'BhdDataDirect' : 'BhdData';

  return (
    <>
      <div className={customClass}>
        {type !== 'directsale' ? <p className="CartText d-flex">
          <img src={Cube} alt="cube" className="AssetCartIcons" />
          {props?.asset_id}
        </p>:null}
        <div className="d-sm-flex justify-content-between"></div>

        <button className={props.classNameVal} onClick={handleCart}>
          <img src={props.btnImg} alt="lock" className="CartSale" />
          {props.btnValue}
        </button>
      </div>
     <SignInPhoneModal
        handleCloseModal={closeModal}
        showSignIn={showSignIn}
        handleOpenModal={openModal}
        modalType={t('header_signin')}
      />
         </>
  );
};

export default DirectSaleAddToCart;
