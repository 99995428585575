import { t } from "i18next";
import { useState } from "react";
import { apiWithToken } from "../../../feature/envs/api";
import { EventLogger, EventNames } from "../../../utils/EventLogger";

type Props = {
  totalAmount: number;
  payment_method: number;
  category_id: number;
  method: string;
  useDepositAmount?: any;
  transactionType?: any;
  typePurchase?: any;
  asset_id?: any;
  cart_id?: any;
  selectedType?: any;
};

const Creditcardtransfer = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const userDetails: any = {
    transaction_date: "",
    channel: "web",
  };

  const [paymentOptions] = useState<any>(userDetails);

  const createTransaction = () => {
    const bankPayment = new FormData();
    const bankPaymentCart = new FormData();
    bankPayment.append("deposit_amount", `${props.totalAmount}`);
    bankPayment.append("payment_method", props.method);
    bankPayment.append("category_id", `${props.category_id}`);
    localStorage.setItem("payment_method", "credit_card");
    if (props?.transactionType === "INVOICE" && props?.cart_id > 0) {
      bankPaymentCart.append("payment_method", props.method);
      bankPaymentCart.append("cart_id", `${props?.cart_id}`);
      bankPaymentCart.append("transaction_type", `${props?.transactionType}`);
      bankPaymentCart.append("channel", paymentOptions.channel.trim());
    } else if (props?.transactionType === "INVOICE") {
      bankPayment.append("use_deposit_amount", `${props?.useDepositAmount}`);
      bankPayment.append("asset_id", `${props?.asset_id}`);
      bankPayment.append("transaction_type", `${props?.transactionType}`);
      bankPayment.append("channel", paymentOptions.channel.trim());
    } else {
      bankPayment.append("transaction_type", "DEPOSIT");
      bankPayment.append("channel", paymentOptions.channel.trim());
    }
    const data =
      props?.transactionType === "INVOICE" && props?.cart_id > 0
        ? bankPaymentCart
        : bankPayment;

    apiWithToken
      .post("api/v1/transaction", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        if (res.data.status === 200) {
          EventLogger.logEvent(EventNames.DEPOSIT, {category_id: props.category_id, payment_type: props.method});
          window.location.href = res.data.data.redirect_url;
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const continueDisabled = props.totalAmount === 0;
  return (
    <div>
      <div className="UploadReciept">
        <p className="CurrentDepositeText">
          {t("credit_message")}
        </p>
      </div>
      <button
        className={`confirm-otp-number ${
          (continueDisabled || loading) ? "disable_btn" : ""
        }`}
        onClick={() => {
          setLoading(true);
          createTransaction();
        }}
        disabled={continueDisabled || loading}
      >
        {" "}
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : props?.selectedType === "directsalecheckout" ? (
          t("continue_to_payment")
        ) : (
          t("continue")
        )}
      </button>
    </div>
  );
};

export default Creditcardtransfer;
