import { t } from "i18next";
import { useEffect, useState } from "react";
import "../internalrouting.scss";

type categoryType = {
  id: number;
  category_title: string;
  category_title_ar?: string;
  icon: string;
};

type Props = {
  category: categoryType;
  getCategories: (e: any) => void;
  options: categoryType[];
};

const Categorydropdown = (props: Props) => {
  const { category, getCategories, options } = props;
  const [isActive, setIsActive] = useState(false);

  const handleClickOutside = (e: any) => {
    const { classList } = e.target;
    if (
      !classList.contains("CatageriesDropdown") &&
      !classList.contains("icon-img") &&
      !classList.contains("CatageriesDropdownFirst")
    ) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const onCategoryChange = (data: any) => {
    if (data.id !== category.id) {
      getCategories(data);
    }
    setIsActive(false);
  };

  return (
    <div className="CatageriesDropdown CategeriesDropdownFeild">
      <div
        onClick={() => setIsActive(!isActive)}
        className="CatageriesDropdownFirst"
        tabIndex={0}
      >
        {category?.icon && (
          <img className="icon-img" src={category?.icon} alt="info" />
        )}
        {t("en") === "en"
          ? category?.category_title
          : category?.category_title_ar ? category?.category_title_ar : t(category?.category_title)}
      </div>
      {isActive && (
        <ul className="CatageriesDropdownList">
          {options?.map((data: any) => (
            <li
              key={data.id}
              onClick={() => onCategoryChange(data)}
              className="CatageriesDropdown"
              tabIndex={0}
            >
              {data?.icon && (
                <img className="icon-img" src={data?.icon} alt="info" />
              )}
              {t("en") === "en" ? data.category_title : data?.category_title_ar ? data?.category_title_ar : t(data.category_title)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Categorydropdown;
