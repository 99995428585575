import { Link } from "react-router-dom";

type Props = {
  value: string;
  url:string;
};

const FooterLinks = (props: Props) => {  
  return (
    <Link to={props.url} className="footerlinks">
      {props.value}
    </Link>
  );
};

export default FooterLinks;
