/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import Card from "react-bootstrap/Card";
import ProfileContact from "../../../components/profile/profilecontact";
import PlusIcon from "../../../assets/Icons/plus.svg";
import MinusIcon from "../../../assets/Icons/minus.svg";
import InfoIcon from "../../../assets/Icons/info.svg";
import { apiWithToken } from "../../../feature/envs/api";
import "../internalrouting.scss";
import Modal from "react-bootstrap/Modal";
import VerifyNumberButton from "../../../components/buttons/verifynumberbutton";
import CurrentDeposite from "../../../assets/Icons/current-deposite.svg";
import TopUpLimit from "../../../assets/Icons/top-up-limit.svg";
import Categorydropdown from "./categorydropdown";
import CrossMark from "../../../assets/Icons/crossmark.svg";

import {
  getInputWidthLarge,
  getVariant,
  numberFormat,
  numberFormatReverse,
} from "../../../utils/functions";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PaymentMethods from "../../../components/paymentmethods";
import { trackPageView } from "../../../utils/analytics";

type paymentType = {
  id: number;
  payment_title: string;
  payment_method: string;
  icon: string;
};

type Props = {};

const Deposits = (props: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [fullscreen, setFullscreen] = useState<any>(true);
  const [depositeShow, setDepositeShow] = useState(false);
  const [topUpShow, setTopUpShow] = useState(false);
  const [selectedOption, updateOption] = useState(-1);
  const [options, setOptions] = useState<any>([]);
  const [paymentoptionData, setPaymentOptionData] = useState<any>(null);
  const [topUpValue, setTopUpValue] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [dataLimit, setDataLimit] = useState<any>([]);
  let increment_by = paymentoptionData?.increment_by;
  const [catagery, setCatagery] = useState<any>({});

  const currentDepositeShow = (breakpoint: any) => {
    setFullscreen(breakpoint);
    setDepositeShow(true);
  };
  const topUpLimitShow = (breakpoint: any) => {
    setFullscreen(breakpoint);
    setTopUpShow(true);
  };

  const getCatageries = (data: any) => {
    updateOption(-1);
    setPaymentOptionData(data);
    setDataLimit(data);
    const optionVal = {
      id: data.id,
      icon: data.icon,
      category_title: data.category_title,
      category_title_ar: data.category_title_ar,
    };
    setCatagery(optionVal);
  };

  const fetchOptions = () => {
    apiWithToken.get("api/v1/categories").then((res: any) => {
      if (res?.data?.status === 200) {
        const data = res?.data?.data;
        const options = data.map((item: any) => ({
          id: item?.id,
          icon: item?.icon,
          category_title: item?.category_title,
          category_title_ar: item?.category_title_ar,

        }));
        options && setOptions(options);
        setCatagery(options[0]);
        handleSetPaymentOptions(res.data.data);
      }
    });
  };

  const handleSetPaymentOptions = (categories = []) => {
    if (location.state === null) {
      setOptions(categories);
      setDataLimit(categories[0]);
      setPaymentOptionData(categories[0]);
    }
    const selectedCategory = categories.find(
      (item: { category_title: any }) =>
        item.category_title === location.state.sharedData.category_title
    ) as any;


    setOptions(categories);
    setCatagery(selectedCategory);
    setDataLimit(selectedCategory);
    setPaymentOptionData(selectedCategory);
    setTopUpValue(0);
  };

  useEffect(() => {
    trackPageView((window.location.pathname + window.location.search),"Deposit and Limit");
    fetchOptions();
    return () => setOptions([]);
  }, []);

  const sortedMethods =
    paymentoptionData !== null
      ? (paymentoptionData?.payment_methods || [])
        .sort((a: paymentType, b: paymentType) => a.id - b.id)
        .filter((val: any) => val.payment_method != "apple_pay")
      : [];

  const calculateTotalTopup = () => {
    const deposit_percentage = paymentoptionData?.deposit_percentage;
    const total =
      topUpValue > 0 && deposit_percentage > 0
        ? (topUpValue * Number(deposit_percentage)) / 100
        : 0;
    setTotalAmount(total);
  };

  const handleTopupValue = (e: any) => {
    const { value } = e.target;
    const val = numberFormatReverse(value);
    if (!isNaN(val)) {
      setTopUpValue(+val);
    }
  };

  useEffect(() => {
    calculateTotalTopup();
  }, [topUpValue, catagery]);

  const category_id = paymentoptionData?.id;
  const method =
    sortedMethods.find((method: paymentType) => method.id === selectedOption)
      ?.payment_method || "";
  return (
    <Row className="CatageriesLeft">
      <Col sm={6} className="Catageries">
        <h2 className="profileheading">{t("category")}</h2>
        <Categorydropdown
          category={catagery}
          getCategories={getCatageries}
          options={options}
        />
        <h2 className="profileheading">{t("consumed_limit")}</h2>
        <div className="ProgressBar">
          <p>{dataLimit?.consumed_limit_percentage}%</p>
          <ProgressBar
            now={dataLimit?.consumed_limit_percentage}
            variant={getVariant(dataLimit?.consumed_limit_percentage)}
          />
        </div>
        <div className="d-flex">
          <h2 className="profileheading">{t("current_deposit")}</h2>
          <img
            src={InfoIcon}
            alt="info"
            className="Info"
            onClick={() => currentDepositeShow("sm-down")}
          />
        </div>

        <Card>
          <Card.Header>
            {t("en") === "en" ? (
              <h2 className="CardCategeryHeading">
                {t("bhd")} {numberFormat(dataLimit?.available_limit || 0)}
              </h2>
            ) : (
              <h2 className="CardCategeryHeading">
                {numberFormat(dataLimit?.available_limit || 0)} {t("bhd")}
              </h2>
            )}

            <p className="CurrentAvailable">{t("available_limit")}</p>
          </Card.Header>

          <Card.Body className="d-flex justify-content-between ArabicBottomColors">
            <div className="text-center">
              {t("en") === "en" ? (
                <h2 className="CurrentDeposite">
                  {t("bhd")} {numberFormat(dataLimit?.current_deposit)}
                </h2>
              ) : (
                <h2 className="CurrentDeposite">
                  {numberFormat(dataLimit?.current_deposit)} {t("bhd")}
                </h2>
              )}

              <p className="CurrentDepositeText">{t("current_deposit")} </p>
            </div>
            <div className="VerticleLine"></div>
            <div className="text-center">
              {t("en") === "en" ? (
                <h2 className="CurrentDeposite">
                  {t("bhd")} {numberFormat(dataLimit?.total_limit)}
                </h2>
              ) : (
                <h2 className="CurrentDeposite">
                  {numberFormat(dataLimit?.total_limit)} {t("bhd")}
                </h2>
              )}

              <p className="CurrentDepositeText">{t("total_limit")}</p>
            </div>
          </Card.Body>
        </Card>
        <div className="d-flex">
          <h2 className="profileheading">{t("top_up_limit")}</h2>
          <img
            src={InfoIcon}
            alt="info"
            className="Info"
            onClick={() => topUpLimitShow("sm-down")}
          />
        </div>
        <Card className="topup-card">
          <Card.Header className="d-flex justify-content-between align-items-center ArabicBottomColors">
            <ProfileContact
              imgUrl={MinusIcon}
              onClick={() =>
                setTopUpValue(topUpValue > 0 ? (topUpValue - increment_by) > 0 ? topUpValue - increment_by : 0 : 0)
              }
            />
            <h2 className="CardCategeryHeading d-flex justify-content-center DepositBidBhdCustom">
              <input
                type="text"
                className="no-outline CardCategeryHeading BidInputCustom"
                value={numberFormat(topUpValue)}
                onChange={handleTopupValue}
                style={getInputWidthLarge(topUpValue)}
                data-hj-allow
              />
            </h2>
            <ProfileContact
              imgUrl={PlusIcon}
              onClick={() => setTopUpValue(topUpValue + increment_by)}
            />
          </Card.Header>

          <Card.Body className="d-flex justify-content-between TopUpLimit">
            <Card.Text>
              {t("en") === "en" ? (
                <p className="CurrentDepositeText">
                  {t("you_pay_a_deposit_of")}{" "}
                  <span className="CurrentDepositCost">
                    {t("bhd")} {numberFormat(totalAmount)}{" "}
                  </span>
                </p>
              ) : (
                <p className="CurrentDepositeText">
                  {t("you_pay_a_deposit_of")}{" "}
                  <span className="CurrentDepositCost">
                    {numberFormat(totalAmount)} {t("bhd")}{" "}
                  </span>
                </p>
              )}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={6}>
        <div className="PaymentSummary">
          <PaymentMethods
            sortedMethods={sortedMethods}
            totalAmount={totalAmount}
            category_id={category_id}
            method={method}
            selectedOption={selectedOption}
            updateOption={updateOption}
          />
        </div>
      </Col>

      <Modal
        show={depositeShow}
        fullscreen={fullscreen}
        onHide={() => setDepositeShow(false)}
        className="NotificationModal"
        centered
      >
        <Modal.Header>
          <div className="CloseButtons">
            <button
              className="bahrainarrow"
              onClick={() => setDepositeShow(false)}
            >
              <img src={CrossMark} alt="crossmark" />
            </button>
          </div>
          <h2 className="confirmnumber me-4">{t("current_deposit")}</h2>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center flex-column">
          <img
            src={CurrentDeposite}
            alt="notficationbell"
            className="NotificationImage"
          />
          <h2 className="profileheading">{t("current_deposit")}</h2>
          <p className="ModalCurrentDeposite">{t("current_deposit_message")}</p>
          <VerifyNumberButton
            name={t("ok")}
            className="confirm-otp-number"
            onClick={() => setDepositeShow(false)}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={topUpShow}
        fullscreen={fullscreen}
        onHide={() => setTopUpShow(false)}
        className="NotificationModal"
      >
        <Modal.Header>
          <div className="CloseButtons">
            <button
              className="bahrainarrow"
              onClick={() => setTopUpShow(false)}
            >
              <img src={CrossMark} alt="crossmark" />
            </button>
          </div>
          <h2 className="confirmnumber me-4">{t("top_up_limit")}</h2>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center flex-column">
          <img
            src={TopUpLimit}
            alt="notficationbell"
            className="NotificationImage"
          />
          <h2 className="profileheading">{t("select_your_limit")}</h2>
          <p className="ModalCurrentDeposite">
            {t("limit_message")}
          </p>
          <VerifyNumberButton
            name="Ok"
            className="confirm-otp-number"
            onClick={() => setTopUpShow(false)}
          />
        </Modal.Body>
      </Modal>
    </Row>
  );
};

export default Deposits;
