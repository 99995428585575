import ProfileContact from "./profilecontact";
import Email from "../../assets/Icons/email-profile.svg";
import Message from "../../assets/Icons/msg-profile.svg";
import Contact from "../../assets/Icons/contact-profile.svg";
import { useTranslation } from "react-i18next";
type Props = {};

const ProfileSocialContact = (props: Props) => {
  const { t } = useTranslation();
  const handleClick = (val: any) => {    
    let email = "info@mazad.bh";
    let phone = "+973 1756 1345";
    if (val === "email") {
      var url = "mailto:" + email;
      window.open(url);
    } else if (val === "call") {
      var url = "tel:" + phone;
      window.open(url);
    }
    else if (val === "chat") {
      (window as any).Intercom('show');
    }
  };
  return (
    <div className="d-flex justify-content-between ContactUs ArabicBottomColors">
      <div className="d-flex flex-column align-items-center">
        <ProfileContact imgUrl={Email} onClick={() => handleClick("email")} />
        <p className="ContactOptions">{t("email_us")}</p>
      </div>
      <div className="d-flex flex-column align-items-center">
        <ProfileContact imgUrl={Message} onClick={() => handleClick("chat")}/>
        <p className="ContactOptions">{t("chat_with_us")}</p>
      </div>
      <div className="d-flex flex-column align-items-center">
        <ProfileContact imgUrl={Contact} onClick={() => handleClick("call")} />
        <p className="ContactOptions">{t("call_us")}</p>
      </div>
    </div>
  );
};

export default ProfileSocialContact;
