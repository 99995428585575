import { CSSProperties, useEffect, useState } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import InfoIcon from "../../assets/Icons/info.svg";
import {
  calulateCeil,
  calulateCeilCode,
  getInputWidth,
  getVariant,
  numberFormat,
  numberFormatReverse,
} from "../../utils/functions";
import "./auctionbidding.scss";
import PlusIcon from "../../assets/Icons/plus.svg";
import MinusIcon from "../../assets/Icons/minus.svg";
import BidTimeUp from "../../assets/Icons/bid-timeup.svg";
import BidNow from "../../assets/Icons/bid-now.svg";
import { useNavigate } from "react-router-dom";
import { apiWithToken } from "../../feature/envs/api";
import CommonInfoModal from "./commoninfomodal";
import { ClipLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import Stopwatch from "../stopwatch/stopwatch";
import CrossMark from "../../assets/Icons/crossmark.svg";
import "./animation.scss";
import HighestBidderWhite from "../../assets/Icons/highestbidder-white.svg";
import { EventLogger, EventNames } from "../../utils/EventLogger";

type Props = {
  fullscreen: any;
  setShow: any;
  show: any;
  assetItem?: any;
  filterData?: any;
  setAssetItem?: any;
  loader?: any;
  assetId?: number;
  is_auto_bidding?: boolean;
  assetTitle?: any;
  buttonSlider?: any;
  categoryData?: any;
  setAssetId?: any;
  auto_bidding_on?: any;
  setHighestStart?: any;
  setHighestFalseShow?: any;
  highestStart?: any;
  highestFalseShow?: any;
  makeBidId?: any;
  setMakeBidId?: any;
};

export default function CommonBidModal(props: Props) {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [autoBidOn, setAutoBidOn] = useState(props.is_auto_bidding);
  const [topUpValue, setTopUpValue] = useState(
    props?.assetItem?.highest_price + props?.assetItem?.minimum_increment
  );
  const [confirmBid, setConfirmBid] = useState(false);
  const [fullscreen, setFullscreen] = useState<any>(true);
  const [autoBidConfirmModal, setAutoBidConfirmModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [sliderOffModal, setSliderOffModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showInfoIconModal, setInfoIconModal] = useState(false);

  const handleSlider = (breakpoint: any) => {
    if (autoBidOn) {
      setAutoBidOn(false);
      setFullscreen(breakpoint);
      setModalType("Turn off Auto bid");
      props.is_auto_bidding === false
        ? setSliderOffModal(false)
        : setSliderOffModal(true);
      setAutoBidConfirmModal(false);
      setShowModal(true);
    } else {
      setAutoBidOn(true);
      setShowModal(false);
      setAutoBidConfirmModal(true);
      setModalType("Turn on Auto bid");
      setFullscreen(breakpoint);
    }
  };
  const handleTopupValue = (e: any) => {
    const { value } = e.target;
    const val = numberFormatReverse(value);
    if (!isNaN(val)) {
      setTopUpValue(+val);
    }
  };
  const handleBlurTopupValue = (e: any) => {
    const { value } = e.target;
    const val = numberFormatReverse(value);
    if (!isNaN(val)) {
      console.log("Value",val, props?.assetItem?.highest_price,
      props?.assetItem?.minimum_increment)
      let newVal = calulateCeilCode(val, props?.assetItem?.highest_price,
        props?.assetItem?.minimum_increment)
      setTopUpValue(+newVal);
      console.log("NewValue",newVal)
    }
  };
  useEffect(() => {
    if (props.loader === false) {
      var top = calulateCeil(
        props?.assetItem?.highest_price,
        props?.assetItem?.minimum_increment
      );

      setTopUpValue(top);
    }
  }, [props.loader]);

  useEffect(() => {
    // Chnaged it from only updating if the value of is_auto_bidding is false to setting the value according to the props
    setAutoBidOn( props.is_auto_bidding )
  }, [props.is_auto_bidding]);
  useEffect(() => {
    if (props.assetItem?.highest_price >= topUpValue) {
      var top = calulateCeil(
        props?.assetItem?.highest_price,
        props?.assetItem?.minimum_increment
      );
      setTopUpValue(top);
    }
  }, [props.assetItem.highest_price]);

  function calculateTopupValue(op?: string) {
    setTopUpValue((prev: any) => {
      let newTopupVal = op
        ? eval(`${prev} ${op} ${props.assetItem?.minimum_increment}`)
        : 0;

      newTopupVal =
        newTopupVal <= 0
          ? props.assetItem?.highest_price <= 0 &&
            props.assetItem?.total_bids <= 0
            ? props.assetItem?.highest_price
            : calulateCeil(
                props.assetItem?.highest_price,
                props.assetItem?.minimum_increment
              )
          : newTopupVal <= props.assetItem?.highest_price &&
            props.assetItem?.total_bids <= 0
          ? props.assetItem?.highest_price
          : newTopupVal <= props.assetItem?.highest_price &&
            props.assetItem?.total_bids >= 1
          ? calulateCeil(
              props.assetItem?.highest_price,
              props.assetItem?.minimum_increment
            )
          : newTopupVal <
            props.assetItem?.highest_price + props.assetItem?.minimum_increment
          ? calulateCeil(
              props.assetItem?.highest_price,
              props.assetItem?.minimum_increment
            )
          : newTopupVal;

      return newTopupVal;
    });
  }
  const continueDisabled =
    topUpValue === 0 ||
    (topUpValue <
      props.assetItem?.highest_price + props.assetItem?.minimum_increment &&
      props.assetItem?.total_bids >= 1) ||
    (topUpValue < props.assetItem?.highest_price &&
      props.assetItem?.total_bids <= 0);

  const topUpNowCheck =
    (props.assetItem?.total_limit <
      props.assetItem?.highest_price + props.assetItem?.minimum_increment &&
      !props.assetItem?.is_highest_bidder) ||
    (topUpValue > props.assetItem?.total_limit &&
      !props.assetItem?.is_highest_bidder) ||
    topUpValue > props.assetItem?.available_limit;

  const autoBidBtnCheck = autoBidOn;

  const bidNowCheck =
    props.assetItem?.total_limit >=
      props.assetItem?.highest_price + props.assetItem?.minimum_increment &&
    props.assetItem?.available_limit >=
      props.assetItem?.highest_price + props.assetItem?.minimum_increment &&
    topUpValue <= props.assetItem?.total_limit &&
    topUpValue <= props.assetItem?.available_limit &&
    !autoBidBtnCheck;

  const highestBidderCheck =
    props.assetItem?.is_highest_bidder && !props.assetItem?.is_auto_bidding;

  const autoBidCheck = props.assetItem?.is_auto_bidding;

  const autoBidLimit =
    props.assetItem?.is_auto_bidding &&
    props.assetItem?.auto_bid_limit >=
      props.assetItem?.highest_price + props.assetItem?.minimum_increment;

  const handleTopUpClick = () => {
    navigate("/profile/deposits", {
      state: { sharedData: props.categoryData, topUpValue: topUpValue },
    });
  };

  useEffect(() => {

    if (props?.highestFalseShow === true) {

      const cond =
        !props.assetItem?.is_highest_bidder &&
        props.assetItem?.auto_bidding_on &&
        !props.assetItem?.is_auto_bidding;
      if (cond) {
        
        props?.setHighestStart(true);
        setTimeout(() => {
          props?.setHighestFalseShow(false);
          props?.setHighestStart(false);
        }, 5000);
      }
    }
  }, [props?.highestFalseShow]);

  function handleMakeBid(type: string) {
    const id = props?.assetId;
    const bidData = {
      amount: topUpValue,
      type: type,
      asset_id: id,
    };
    apiWithToken
      .post(`api/v1/bids/make-bid`, bidData)
      .then((res: any) => {
        if (res.data.status === 200) {
          EventLogger.logEvent(EventNames.BID, {asset_id: id, amoutn: bidData.amount});

          if (
            res?.data?.data?.is_highest_bidder === false &&
            res?.data?.data?.is_auto_bidding === false &&
            res?.data?.data?.auto_bidding_on === true
          ) {
            
            props?.setMakeBidId(res?.data?.data?.asset_id);
            props?.setHighestFalseShow(true);
          } else {
            props?.setHighestFalseShow(false);
            props?.setMakeBidId(null);

          }
          // setting the value of autoBidOn according to the response from the api
          setAutoBidOn(res?.data?.data?.is_auto_bidding)
          const { highest_price, minimum_increment } = res.data.data;
          var top = calulateCeil(highest_price, minimum_increment);
          setTopUpValue(top);
          setConfirmBid(false);
        }
      })
      .catch(() => console.error("Something Went Wrong"));
  }
  const handleBidButton = (breakpoint: any) => {
    setFullscreen(breakpoint);
    setConfirmBid(true);
  };
  const handleAutoBid = (breakpoint: any) => {
    setLoading(true);
    setFullscreen(breakpoint);
    setAutoBidConfirmModal(true);
    setModalType("Auto Bid Button");
  };

  const override: CSSProperties = {
    display: "block",
    margin: "20% auto",
    borderColor: "black",
    overflow: "-moz-hidden-unscrollable",
    opacity: 0.1,
  };
  const handleInfoIcon = (breakpoint: any) => {
    setFullscreen(breakpoint);
    setInfoIconModal(true);
    setModalType("Open Info Icon");
  };
  const handleCancel = () => {
    setConfirmBid(false);
    setLoading(false);
  };

  function setAutoBidOff() {
    const id = props.assetId;
    apiWithToken
      .patch(`api/v1/bids/assets/${id}/turn-off-auto-bid`)
      .then((res: any) => {
        if (res.data.status === 200) {
        }
      })
      .catch(() => console.error("Something Went Wrong"));
  }

  const highestBidderUI = (
    <div className="overlayA">
      <div className="overlay-colorA">
        <img src={HighestBidderWhite} alt="You are the Highest Bidder" />
        {t("you_are_the_highest_bidder")}
      </div>
    </div>
  );

  const outBidderUI = (
    <div className="overlayOut">
      <div className="overlay-colorOut">
        <img src={HighestBidderWhite} alt="You've been outbid" />
        {t("you_been_outbid")}
      </div>
    </div>
  );

   let classVa = (t("en") === 'en' ?  'ConfirmBidBhdCustom customWidthAutoBid': 'ConfirmBidBhdCustom-ar') 

  return (
    <div>
      <Modal
        show={props.show}
        fullscreen={props.fullscreen}
        onHide={() => props.setShow(false)}
        className="NotificationModal"
      >
        {props?.loader ? (
          <ClipLoader
            color={"#fff"}
            loading={props.loader}
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <>
            <Modal.Header>
              <div className="CloseButtons">
                <button
                  className="bahrainarrow"
                  onClick={() => props.setShow(false)}
                >
                  <img src={CrossMark} alt="crossmark" />
                </button>
              </div>
             
              <h2 className="confirmnumber htitle">
                {t("en") === "en"
                  ? props?.assetItem?.asset_title
                  : props?.assetItem?.asset_title_ar}
              </h2>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex justify-content-between align-items-center AuctionBidcheck">
                <h2 className="AutoBidHeading">
                  {t("auto_bid")}{" "}
                  <img
                    src={InfoIcon}
                    alt="info"
                    className="ms-2"
                    onClick={() => handleInfoIcon("sm-down")}
                  />
                </h2>
                <label className="switch">
                  <input
                    type="checkbox"
                    // only determining the value of the slider based on 1 variable not props.is_auto_bidding ||
                    checked={autoBidOn}
                  />
                  <span
                    className="slider round"
                    onClick={
                      !topUpNowCheck
                        ? () => handleSlider('"sm-down"')
                        : () => {}
                    }
                  ></span>
                </label>
              </div>
              <div className="BidTiming">
                <Stopwatch
                  compName={"commonBidModal"}
                  endTime={props?.assetItem?.end_time}
                  setShow={props?.setShow}
                />
                {/* Removed check for if highest price is present to show highest price as 0 if not present */}
                  <div className="BidTimingBottom d-flex justify-content-between">
                    <div className="text-center">
                      <h2 className="ModalBidCount">
                        {t("en") === "en"
                          ? t("bhd") +
                            numberFormat(props?.assetItem?.highest_price ?? 0)
                          : numberFormat(props?.assetItem?.highest_price ?? 0) +
                            " " +
                            t("bhd")}
                      </h2>
                      <p className="ModalBidText">{t("highest_bid")}</p>
                    </div>
                    <div className="VerticleLine"></div>
                    <div className="text-center">
                      <h2 className="ModalBidCount">
                        {props.assetItem?.total_bids}
                      </h2>
                      <p className="ModalBidText">{t("bids_made")}</p>
                    </div>
                  </div>
              </div>

              { autoBidLimit ? (
                <div className="BidTiming SecondBidTiming">
                  <div className="BidTimingAuto text-center">
                    <p className="mb-0 MinumumIncrement text-white">
                      {t("your_auto_bid_limit")}
                    </p>
                  </div>
                  <div className="BidAmountChange d-flex align-items-center">
                    <div className="ModalIncrement">
                      <h2 className={`ConfirmBidBhd ${classVa} `}>
                        {numberFormat(props.assetItem?.auto_bid_limit)}
                      </h2>
                    </div>
                  </div>
                  <div className="BidTimingBottom text-center">
                    <p className="mb-0 MinumumIncrement">
                      {t("minimum_increment") + t("bhd")}
                      {numberFormat(props.assetItem?.minimum_increment)}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="BidTiming SecondBidTiming">
                  {t("en") === "en" ? (
                    <div className="BidAmountChange d-flex justify-content-between align-items-center">
                      <div
                        className="ModalMinusPlus d-flex justify-content-center align-items-center"
                        onClick={() => {
                          calculateTopupValue("-");
                        }}
                      >
                        <img src={MinusIcon} alt="minus" />
                      </div>
                      <div className="ModalIncrement">
                        <h2 className="ConfirmBidBhd ConfirmBidBhdCustom">
                          <input
                            type="text"
                            className=" no-outline BidTiming BidInputCustom"
                            value={numberFormat(topUpValue)}
                            onChange={handleTopupValue}
                            onBlur={handleBlurTopupValue}
                            style={getInputWidth(topUpValue)}
                            data-hj-allow
                          />
                        </h2>
                      </div>
                      <div
                        className="ModalMinusPlus d-flex justify-content-center align-items-center"
                        onClick={() => {
                          calculateTopupValue("+");
                        }}
                      >
                        <img src={PlusIcon} alt="plus" />
                      </div>
                    </div>
                  ) : (
                    <div className="BidAmountChange d-flex justify-content-between align-items-center">
                      <div
                        className="ModalMinusPlus d-flex justify-content-center align-items-center"
                        onClick={() => {
                          calculateTopupValue("+");
                        }}
                      >
                        <img src={PlusIcon} alt="plus" />
                      </div>
                      <div className="ModalIncrement">
                        <h2 className="ConfirmBidBhd ConfirmBidBhdCustom-ar">
                          <input
                            type="text"
                            className=" no-outline BidTiming BidInputCustom"
                            value={numberFormat(topUpValue)}
                            onChange={handleTopupValue}
                            style={getInputWidth(topUpValue)}
                            data-hj-allow
                          />
                        </h2>
                      </div>

                      <div
                        className="ModalMinusPlus d-flex justify-content-center align-items-center"
                        onClick={() => {
                          calculateTopupValue("-");
                        }}
                      >
                        <img src={MinusIcon} alt="minus" />
                      </div>
                    </div>
                  )}
                  <div className="BidTimingBottom text-center">
                    <p className="mb-0 MinumumIncrement">
                      {t("minimum_increment")} {t("bhd")}{" "}
                      {numberFormat(props.assetItem?.minimum_increment)}
                    </p>
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-between align-items-center">
                <h2 className="mb-0 ConsumedLimit">{t("consumed_limit")}</h2>
                <p className="mb-0 MaxLimitBhd">
                  {t("max_limit_of")} {t("bhd")}
                  <span className="ConsumedLimit">
                    {props?.assetItem?.total_limit &&
                      numberFormat(props.assetItem?.total_limit || 0)}
                  </span>
                </p>
              </div>
              <div className="ProgressBar">
                <p>{props.assetItem?.consumed_limit_percentage || 0}%</p>
                <ProgressBar
                  now={props.assetItem?.consumed_limit_percentage}
                  variant={getVariant(
                    props.assetItem?.consumed_limit_percentage
                  )}
                />
              </div>

              {props?.highestStart && (
                <button
                  className={`HigestBidderButton ${
                    continueDisabled ? "disable_btn" : ""
                  }`}
                  onClick={() => handleBidButton("sm-down")}
                  disabled={continueDisabled}
                >
                  <img src={HighestBidderWhite} alt="Highest Bidder" />
                  {" " + t("you_are_the_highest_bidder")}
                </button>
              )}

              {!props?.highestStart &&
                bidNowCheck &&
                !autoBidBtnCheck &&
                props?.is_auto_bidding === false &&
                (highestBidderCheck && !autoBidOn && !topUpNowCheck ? (
                  <button
                    className={`HigestBidderButton ${
                      continueDisabled ? "disable_btn" : ""
                    }`}
                    onClick={() => handleBidButton("sm-down")}
                    disabled={continueDisabled}
                  >
                    <img src={HighestBidderWhite} alt="Highest Bidder" />
                    {" " + t("you_are_the_highest_bidder")}
                  </button>
                ) : (
                  <button
                    className={`signin-phone ${
                      continueDisabled ? "disable_btn" : ""
                    }`}
                    onClick={() => handleBidButton("sm-down")}
                    disabled={continueDisabled}
                  >
                    <img src={BidNow} alt="Bid Now" />
                    {t("bid_now")}
                    {props.assetItem?.is_highest_bidder === false &&
                      outBidderUI}
                  </button>
                ))}

              {autoBidBtnCheck && !topUpNowCheck && !autoBidCheck && (
                <button
                  className={`signin-phone ${
                    continueDisabled ? "disable_btn" : ""
                  }`}
                  onClick={() => handleAutoBid("sm-down")}
                  disabled={continueDisabled}
                >
                  <img src={BidNow} alt="Auto Bid Now" />
                  {t("auto_bid_now")}
                </button>
              )}

              {topUpNowCheck && (
                <button className="TopUpNowButton" onClick={handleTopUpClick}>
                  <img src={BidTimeUp} alt="Top Up Now" />
                  {t("top_up_now")}
                  {props.assetItem?.is_highest_bidder === true &&
                    highestBidderUI}
                  {props.assetItem?.is_highest_bidder === false && outBidderUI}
                </button>
              )}

              {autoBidCheck && (
                <button className="AutoActiveBidButton" onClick={() => {}}>
                  {" "}
                  <img src={BidNow} alt="Auto Bid Now" />
                  {t("you_are_auto_bidding")}
                </button>
              )}
            </Modal.Body>
            <div className="BidTermsConditions">
              <p className="mb-0 newLink">
                {t("by_continuing_agree_our")}{" "}
                <a href="http://www.mazad.app/terms-of-service">
                  {t("terms_conditions")}
                </a>
                {t("and")}{" "}
                <a href="http://www.mazad.app/privacy-policy">
                  {t("lock_privacy_policy")}
                </a>
              </p>
            </div>
          </>
        )}
      </Modal>
      <Modal
        show={confirmBid}
        fullscreen={fullscreen}
        onHide={() => setConfirmBid(false)}
        className="NotificationModal"
      >
        <Modal.Header>
          <div className="CloseButtons">
            <button className="bahrainarrow" onClick={handleCancel}>
              <img src={CrossMark} alt="crossmark" />
            </button>
          </div>
          <h2 className="confirmnumber ConfirmBid">{t("confirm_bid")}</h2>
        </Modal.Header>
        <Modal.Body>
          <div className="ConfirmBidCount">
            <h2 className="ConfirmBidBhd">
              <span>{t("bhd")} </span>
              <span className="BidInputCustom">{numberFormat(topUpValue)}</span>
            </h2>
          </div>
          <h2 className="AgreeConfirmBid">
            {t("you_agree_to_bid_the_amount")}
          </h2>
          <button
            className="confirm-bid"
            onClick={() => {
              handleMakeBid("MANUAL");
            }}
          >
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">{t("Loading")}</span>
              </div>
            ) : (
              <>
                <img src={BidNow} alt="Bid Now" />
                {t("bid_now")}
              </>
            )}
          </button>
        </Modal.Body>
      </Modal>
      {showInfoIconModal && (
        <CommonInfoModal
          setInfoIconModal={setInfoIconModal}
          fullscreen={fullscreen}
          modalType={modalType}
          showInfoIconModal={showInfoIconModal}
          setLoading={setLoading}
        />
      )}
      {showModal && (
        <CommonInfoModal
          showModal={showModal}
          setShowModal={setShowModal}
          modalType={modalType}
          setSliderOffModal={setSliderOffModal}
          sliderOffModal={sliderOffModal}
          setAutoBidConfirmModal={setAutoBidConfirmModal}
          setAutoBidOn={setAutoBidOn}
          setAutoBidOff={setAutoBidOff}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {autoBidConfirmModal && (
        <CommonInfoModal
          modalType={modalType}
          autoBidConfirmModal={autoBidConfirmModal}
          setAutoBidConfirmModal={setAutoBidConfirmModal}
          fullscreen={fullscreen}
          topUpValue={topUpValue}
          setAutoBidOn={setAutoBidOn}
          handleMakeBid={handleMakeBid}
          setLoading={setLoading}
          setAutoBidOff={setAutoBidOff}
          loading={loading}
        />
      )}
    </div>
  );
}
