import { useEffect, useLayoutEffect, useState } from "react";
import { apiWithToken } from "../../../feature/envs/api";
import { useScript } from "usehooks-ts";
// import { ApplePaySession } from "applepayjs-polyfill/dist/ApplePaySession";
import Apple from "../../../assets/Icons/apple-logo.svg";
import { t } from "i18next";

// type Props = {
//   totalAmount: number;
//   payment_method: number;
//   category_id: number;
//   method: string;
//   asset_id?:any;
// };

const Applepay = (props) => {
  const [ApplePaySession, setApplePaySession] = useState(null);
//  const status = useScript(
//     `https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js`,
//     {
//       removeOnUnmount: false,
//     }
//   );
  

  // useEffect(() => {
  //   if (status === 'ready') {
  //     const request = {
  //       countryCode: "US",
  //       currencyCode: "USD",
  //       merchantCapabilities: ["supports3DS"],
  //       supportedNetworks: ["visa", "masterCard", "amex", "discover"],
  //       total: {
  //         label: "Demo (Card is not charged)",
  //         type: "final",
  //         amount: "1.99",
  //       },
  //     };
  //     const session = new ApplePaySession(3, request);
  //     session.begin();
  //     alert(session);

  //   }
  // }, [status])

  const [loading, setLoading] = useState(false);
  const userDetails = {
    transaction_date: "", 
    channel: "web",
  };

  // const [paymentOptions] = useState<any>(userDetails);

  function createTransaction() {
  
  if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
    // Define payment request data
    const version = 3;
    const request = {
      countryCode: 'BH',
      currencyCode: 'BHD',
      merchantCapabilities: ['supports3DS'],
      merchantIdentifier: 'merchant.bh.mazad.app',
      supportedNetworks: ['amex', 'discover', 'masterCard', 'visa'],
      total: {
        label: 'My Item',
        amount: '100'
      }
    }; 
    
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      const session = new window.ApplePaySession(version, request);
      console.log("session", session);

      session.onvalidatemerchant = (event) => {
        setApplePaySession(JSON.stringify(event.validationURL));
        console.log("event", event);
        console.log("event.validationURL", event.validationURL);
  
        // Send a validation URL to the server
        fetch("/apple-pay/validate-merchant", {
          method: "POST",
          body: JSON.stringify(event.validationURL),
        })  
          .then((response) => response.json())
          .then((responseJson) => {
            // Complete the validation
            session.completeMerchantValidation(responseJson);
          })
          .catch((error) => {
            // Handle validation error
            console.log(error);
            session.abort();
          });
      };

      session.begin();
      console.log('ApplePaySession object created:', session);

    } else {
      console.log('Apple Pay is not available');
    }
    setLoading(false);
    // Create a new Apple Pay session
  //  const session = new ApplePaySession(version, request);

//console.log("session", session);
    // Handle payment authorization events
  

    // session.onpaymentauthorized = (event) => {
    //   // Send payment data to the server for processing
    //   fetch("/apple-pay/process-payment", {
    //     method: "POST",
    //     body: JSON.stringify(event.payment),
    //   })
    //     .then((response) => response.json())
    //     .then((responseJson) => {
    //       // Complete the payment
    //       session.completePayment(
    //         window.ApplePaySession.STATUS_SUCCESS
    //       );
    //     })
    //     .catch((error) => {
    //       // Handle payment processing error
    //       console.error(error);
    //       session.completePayment(
    //         window.ApplePaySession.STATUS_FAILURE
    //       );
    //     });
    // };

    // Begin the payment session
   // session.begin();
  } else {
    console.log("Apple Pay is not available on this device or browser");
    //setPaymentError("Apple Pay is not available on this device or browser");
  }
    
  }

  const continueDisabled = props.totalAmount === 0;
  return (
    <div>
      <div className="UploadReciept">
        <p className="CurrentDepositeText">
          {t("credit_message")}
        </p>
        <p>{ApplePaySession}</p>
      </div>
      {/* <button
        className={`confirm-otp-number ${
          continueDisabled ? "disable_btn" : ""
        }`}
        onClick={() => {
          setLoading(true);
          createTransaction();
        }}
        disabled={continueDisabled}
      >
        {" "}
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          "Continue"
        )}
      </button> */}

      <button
        className={`ApplePay ${(continueDisabled || loading) ? "disable_btn" : ""}`}
        onClick={() => {
          setLoading(true);
          createTransaction();
        }}
        disabled={continueDisabled || loading}
      >
        {" "}
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            <img src={Apple} alt="lock" /> Pay
          </>
        )}
      </button>
    </div>
  );
};

export default Applepay;