import { CSSProperties } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import BidsWonCardBahrain from "../../components/bidswoncards/bidswoncardbahrain";
import BidsWonCardBottom from "../../components/bidswoncards/bidswoncardbottom";
import MyBidsEmpty from "../mybids/mybidsempty";
import Page from "../../assets/Icons/pages.svg";
import Arrow from "../../assets/Icons/bahrain-arrow.svg";
import "../../pages/bidswon/bidswon.scss";
import noBids from "../../assets/Icons/noBids.svg";

type Props = {
  type?: string
  filteredAssetData?: any;
  setFilteredAssetData?: any;
  assetLoader?: any;
};

const MyPuruchasesAction = (props: Props) => {
  const { filteredAssetData, assetLoader } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pendingStatuses = [
    "pending_for_payment",
    "pending_for_payment_approval",
    "pending_registration",
    "pending_purchase"
  ];
  
  const override: CSSProperties = {
    display: "block",
    margin: "20% auto",
    borderColor: "black",
    overflow: "-moz-hidden-unscrollable",
    opacity: 0.1,
  };

  return (
    <Row>
      {filteredAssetData?.length > 0 ? (
        <>
          {filteredAssetData.map((asset: any, index: number) => {
            return (
              <Col lg={6} md={6}>
                <div
                  className="biddigwonsection"
                >
                  <BidsWonCardBahrain
                    asset={asset}
                    imgUrl={
                      pendingStatuses.includes(asset.status) ? Arrow : Page
                    }
                  />
                  <BidsWonCardBottom
                    highest_price={asset?.highest_price || asset?.asset_price}
                    end_time={asset?.purchase_end_time || asset?.end_time}
                    className={asset.status || 'successfully_approved'}
                    name={asset.status || 'successfully_approved'}
                  />
                </div>
              </Col>
            );
          })}
        </>
      ) : (
        <>
          {assetLoader && (
            <ClipLoader
              color={"#fff"}
              loading={assetLoader}
              cssOverride={override}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
          {!assetLoader && (
            <MyBidsEmpty imgUrl={noBids} title={"bidpurchases"} />
          )}
        </>
      )}

    </Row>
  );

};

export default MyPuruchasesAction;
