import React, { useContext, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import CrossMark from "../../assets/Icons/crossmark.svg";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/signup";
import UserOtp from "./userotp";
import { api } from "../../feature/envs/api";
import { toast } from "react-toastify";
import LockImage from "../../assets/Icons/lock.svg";

type Props = {
  handCloseOtpModal: (modaltype: any) => void;
  setConfirmation: (e?: any) => void;
  showOtp?: boolean;
  userData?: any;
  UpdateUserDetails: () => void;
  mobileedit?: boolean;
  getUserDetail?: any;

};
const ConfirmUserUpdateOtpModal = (props: Props) => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState<string[]>(new Array(4).fill(""));
  const { user } = useContext<any>(UserContext);
  const [hasError, setHasError] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [counter, setCounter] = useState(30);
  const [resetCounter, setResetCounter] = useState(false);
  const [loading, showLoading] = useState(false);

  const handleOtp = (newOtp: any) => {
    setOtp(newOtp);
    let count = newOtp.reduce((c: any, s: any) => c + Boolean(s), 0);
    if (count === 4) {
      setInputDisabled(true);
      handleUpdateUserOTP(newOtp);
    }
  };
  const resendOtp = () => {
    setInputDisabled(false);
    const requestOptions: any = {
      pinId: user.pinId,
      type: "PHONE_NUMBER"
    };
    api
      .post("api/v1/customer/resend-otp", requestOptions)
      .then((res: any) => {
        toast(<p className="CartSuccessMsg">{res.data.message}</p>, {
          autoClose: 3000,
          hideProgressBar: true,
          toastId: "cartToast",
        });
      })
      .catch(() =>
        toast(<p className="CartErrMsg">{t("cancel_payment")}</p>, {
          autoClose: 2500,
          hideProgressBar: true,
          toastId: "cartToast",
        })
      );
  };
  const onClickReset = () => {
    resendOtp();
    setOtp(["", "", "", ""]);
    setCounter(30);
    setResetCounter(false);
  };

  useEffect(() => {
    let interval: any = null;
    if (counter !== 0) {
      interval = setInterval(function () {
        setCounter(counter - 1);
      }, 1000);
    } else {
      setResetCounter(true);
    }
    return () => clearInterval(interval);
  }, [counter]);

  const handleUpdateUserOTP = (newOtp: any) => {
    const requestOptions: any = {
      type: "PHONE_NUMBER",
      phone_number: props.userData.phone_number,
      pinId: user.pinId,
      otp: newOtp.reduce((acc: any, cur: any) => `${acc}${cur}`, ""),
      update_user: true
    };
    showLoading(true);
    api.post(`api/v1/customer/verify-otp`, requestOptions).then((res: any) => {
      if (res.data.status === 200) {
        showLoading(false);
        props?.UpdateUserDetails();
      } else {
        setHasError(true);
        showLoading(false);
        setInputDisabled(false);
        toast(<p className="CartErrMsg">{t("incorrect_code")}</p>, {
          autoClose: 3000,
          hideProgressBar: true,
          toastId: "cartToast",
        });
      }
    });
  };

  return (
    <div>
      <Modal
        show={props.showOtp}
        onHide={() => props.handCloseOtpModal("otpstop")}
        className="confirmdirection"
      >
        <div className="modal-header confirm-bottom">
          <div className="CloseButtons">
            <button
              className="bahrainarrow"
              onClick={() => props.handCloseOtpModal("otpstop")}
            >
              <img src={CrossMark} alt="crossmark" />
            </button>
          </div>
          <h2 className="confirmnumber">{t("confirm_number")}</h2>
        </div>
        <form>
          <p className="phnnumber">
            {t("code_sent")}:<span> {props.userData.phone_number}</span>
          </p>
          <UserOtp
            hasError={hasError}
            handleOtp={handleOtp}
            setOtp={setOtp}
            otp={otp}
            inputDisabled={inputDisabled}
          />
          {resetCounter ? (
            <p className="time-limit">
              {t("did_not_recieve")}&nbsp;
              <span style={{ cursor: "pointer" }} onClick={onClickReset}>
                {t("resend_again")}
              </span>
            </p>
          ) : (
            <p className="time-limit">
              {t("code_request_again")}&nbsp;
              <span>00:{counter}</span>
            </p>
          )}

          <button
            type="submit"
            className={
              !otp.some((cur) => cur === "") ? "otp_btn_signup" : "disable_btn"
            }
            disabled={otp.some((cur) => cur === "")}
            onClick={(e) => {
              showLoading(true);
              handleUpdateUserOTP(e);
            }}
          >
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <>
                <img src={LockImage} alt="lock" />
                <>{" " + t("verify_number")}</>{" "}
              </>
            )}
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default ConfirmUserUpdateOtpModal;
