/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import BreadCrums from "../../components/breadcrumbs/breadcrums";
import Kycverification from "./Kycverification";
import { useTranslation } from "react-i18next";
import Warningmodal from "../../components/modals/Warningmodal";
import "./completereg.scss";
import { apiWithToken } from "../../feature/envs/api";
import verifyId from "../../assets/Icons/verify-id.png";
import SignScreen from "../../assets/Icons/lock-signscreen.svg";

import passport from "../../assets/Icons/passport-id.svg";
import { encryptData } from "../../utils/functions";

const defaultstate = {
  warningModal: false,
  kycModal: false,
  validationCompleted: false,
  warningText: "",
};

const VerifyUserId = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showModals, updateModals] = useState(defaultstate);
  const [loading, setLoading] = useState(false);

  const updateModalsData = (obj: any) => {
    updateModals((prev) => ({ ...prev, ...obj }));
  };

  const onActionClick = () => {
    if (showModals.validationCompleted) {
      navigate("/");
    } else {
      updateModalsData({ warningModal: false });
    }
  };

  const showCurrentStatus = () => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const path = url.pathname;

    if (path !== "/verify-user") {
      apiWithToken.get(`api/v1/customer/kyc-status`, {}).then((result) => {
        if (result.status === 200) {
          if (result.data.data.doc_review_status === "completed") {
            if (result.data.data.identity_verification_status === true) {
              navigate("/");
            } else if (
              result.data.data.identity_verification_status === false
            ) {
              navigate("/verify-user");
            } else {
              updateModalsData({
                kycModal: false,
                warningModal: true,
                validationCompleted:
                  result.data.data.identity_verification_status,
                warningText: result.data.data.doc_verification_comment,
              });
            }
          } else if (result.data.data.doc_review_status === "pending") {
            navigate("/verify-user");
          }
        }
      });
    }
  };

  const showCurrentStatusKYC = async () => {
    try {
      const response = await apiWithToken.get(`api/v1/customer/kyc-status`, {});
      encryptData("user-details", response?.data?.data);
      if (
        response?.data?.data?.doc_review_status === "completed" &&
        response?.data?.data?.identity_verification_status === true
      ) {
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    showCurrentStatusKYC();
  }, []);

  useEffect(() => {
    return () => updateModals(defaultstate);
  }, []);

  const handleLoading = () => {
    let interval: any = null;
    interval = setInterval(function () {
      setLoading(true);
    }, 1000);
  };

  return (
    <Container className="useridsection">
      <div className="verifyidentification">
        <BreadCrums
          previousPage={t("complete_registration")}
          currentPage={t("identity_verification")}
        />
      </div>
      {showModals.kycModal ? (
        <Kycverification showCurrentStatus={showCurrentStatus} />
      ) : (
        <div className="d-flex justify-content-center">
          <div className="d-flex flex-column align-items-center">
            <h2 className="profileheading">{t("verify_your_id")}</h2>
            <div className="camscaner">
              <img src={verifyId} alt="verify-id" />
            </div>
            <div className="passportform">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleLoading();
                  updateModalsData({ kycModal: true });
                }}
              >
                <div className="updatepassport_1">
                  <img src={passport} alt="apple" />
                  <span>{t("personal_id_passport")}</span>
                </div>
                <div className="upload-text">
                  <h4 className="upload-header-text">{t("find_surface")}</h4>
                  <p className="idtext">{t("place_your_id")}</p>
                </div>
                <div className="d-flex">
                  <span className="signscreenlock">
                    <img src={SignScreen} alt="lockscreen" />
                  </span>
                  <p className="idtext">
                    {t("privacy_step")}{" "}
                    <Link
                      to="/privacy-policy"
                      style={{ color: "rgba(64, 62, 58, 0.75)" }}
                    >
                      {t("lock_privacy_policy")}
                    </Link>
                  </p>
                </div>
                <button
                  className="confirmsubmit"
                  onClick={() => handleLoading()}
                >
                  {loading ? (
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">{t("Loading")}</span>
                    </div>
                  ) : (
                    t("continue")
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
      {showModals.warningModal && (
        <Warningmodal
          showModal={showModals.warningModal}
          validationCompleted={showModals.validationCompleted}
          warningText={showModals.warningText}
          onActionClick={onActionClick}
        />
      )}
    </Container>
  );
};

export default VerifyUserId;
