import "./bidswoncard.scss";
import { useTranslation } from "react-i18next";
import carplateImg from "../../assets/Icons/Car Plate (Long).svg";
import Taxiplatelong from "../../assets/Icons/taxi_long.svg";
import { useNavigate } from "react-router-dom";
import { AssetTag } from "../tags/AssetTag";
import years from "../../assets/Icons/years.svg";
import { toast } from "react-toastify";


type Props = {
  imgUrl: string;
  asset?: any;
  handleDelete?: any;
  type?: string;
  setSelectedAsset?: any;
};

const BidsWonCardBahrain = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const size = "thumbnail"; // Dynamic property name

  const pendingStatuses = [
    "pending_for_payment",
    "pending_for_payment_approval",
    "pending_registration",
    "pending_purchase",
  ];
  const handleReceipt = (asset: any) => {
    props?.setSelectedAsset && props?.setSelectedAsset(asset);
    if (asset?.cart_id > 0) {
      if (pendingStatuses.includes(asset.status)) {
        navigate(`/complete-purchase?cartid=${asset?.cart_id}`);
      } else {
        navigate(
          `/checkout?paymentid=${asset?.transaction?.transaction_id}&status=${
            asset.status || "successfully_approved"
          }`
        );
      }
    } else {
      if (pendingStatuses.includes(asset.status)) {
        navigate(`/complete-purchase?id=${asset.asset_id}`);
      } else {
        // ? check if auto-awarding is true
        if (asset?.asset_automatic_awarding){
        navigate(
          `/checkout?paymentid=${asset?.transaction?.transaction_id}&status=${
            asset.status || "successfully_approved"
          }`
        );
      } else {
        //? show toast message
        toast(<p className="CartSuccessMsg">{t('asset_awaiting_approval')}</p>, {
          autoClose: 3000,
          hideProgressBar: true,
          toastId: "cartToast",
        });
      }
      }
    }
  };

  const assetTitleLocal =
    t("en") === "en" ? props?.asset?.asset_title : props?.asset?.asset_title_ar;

  const assetTitle =
    props?.asset?.category_title?.split(" ")[0] === "Taxi" ||
    props?.asset?.category_title?.split(" ")[0] === "Car"
      ? ""
      : assetTitleLocal;
  const longPlate =
    props?.asset?.category_title?.split(" ")[0] === "Car"
      ? carplateImg
      : Taxiplatelong;
  const classVal =
    props?.asset?.category_title?.split(" ")[0] === "Car"
      ? "box_img33"
      : "box_img44";
  const dummyImg_1_new = (
    <div className="container_font">
      <img src={longPlate} alt="bahrain" />
      <div className={classVal}>
        <span className="carFont">{props?.asset?.asset_title}</span>
      </div>
    </div>
  );
  const dummyImg_2_new = (
    <>
      {props?.asset?.tag && (
        <AssetTag
          tag={props.asset?.tag}
          discountPercentage={props.asset?.discount_percentage}
        />
      )}
      <div className="container_font custom_image">
        {props.asset?.asset_images && props.asset?.asset_images.length > 0 ? (
          <img
            src={props.asset?.asset_images[0]?.url[size]}
            alt="bahrain"
            className="bidswonbahrain"
          />
        ) : (
          <div className="bidswonbahrain customBg"> </div>
        )}
      </div>
      <div className="px-3">
        <h2 className="AuctionCardHeading">{assetTitle}</h2>
        {props?.asset?.asset_id && (
          <p className="calander">
            <img src={years} alt="calander" />
            <span>{props?.asset?.asset_id}</span>
          </p>
        )}
      </div>
    </>
  );

  const boxImg =
    props?.asset?.category_title.split(" ")[0] === "Taxi" ||
    props?.asset?.category_title.split(" ")[0] === "Car"
      ? dummyImg_1_new
      : dummyImg_2_new;

  const handleButton = (event: any) => {
    event.stopPropagation();
    if (props.type === "delete") {
      props?.handleDelete(props?.asset);
    } else {
      handleReceipt(props?.asset);
    }
  };
  return (
    <>
      {props?.asset ? (
        <div className="biddingswoncard d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center tag">{boxImg}</div>
          <button className="bahrainarrow" onClick={handleButton}>
            <img src={props.imgUrl} alt="rightarrow" />
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default BidsWonCardBahrain;
