import { useState } from "react";
import { apiWithToken } from "../../../feature/envs/api";
import { useNavigate } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

type Props = {
  totalAmount: number;
  amount: number;
  category_id: number;
  method: string;
  useDepositAmount?: any;
  transactionType?: any;
  typePurchase?: any;
  asset_id?: any;
  cart_id?: any;
  selectedType?: any;
};


const Walletpayment = (props: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const userDetails: any = {
    transaction_date: "",
    note: "",
  };

  const [paymentOptions, setPaymentOptions] = useState<any>(userDetails);
  const createTransaction = () => {
    const bankPayment = new FormData();
    bankPayment.append("deposit_amount", `${props?.amount}`);
    bankPayment.append("payment_method", props.method);
    bankPayment.append("category_id", `${props.category_id}`);

    if (props?.transactionType === "INVOICE") {
      bankPayment.append("use_deposit_amount", `${props?.useDepositAmount}`);
      bankPayment.append("asset_id", `${props?.asset_id}`);
      bankPayment.append("transaction_type", `${props?.transactionType}`);
    }
    bankPayment.append("note", paymentOptions.note.trim());

    const data = bankPayment;
    apiWithToken
      .post("api/v1/transaction", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        if (res.data.status === 200) {
          navigate(`/checkout?paymentid=${res.data.data.transaction_id}`, {
            state: res.data.data,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <button
        className={`confirm-otp-number ${loading ? "disable_btn" : ""
        }`}
        onClick={() => {
          setLoading(true);
          createTransaction();
        }}
        disabled={loading}
      >
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : props?.selectedType === "directsalecheckout" ? (
          t("continue_to_payment")
        ) : (
          t("continue")
        )}
      </button>
    </div>
  );
};

export default Walletpayment;
