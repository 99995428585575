import Modal from "react-bootstrap/Modal";
import "./modals.scss";
import { useTranslation } from "react-i18next";
import SignUpMailInput from "../inputfeilds/signupmailinput";
import { api, apiWithToken } from "../../feature/envs/api";
import { toast } from "react-toastify";
import { useState, useContext } from "react";
import { UserContext } from "../../contexts/signup";
import CrossMark from "../../assets/Icons/crossmark.svg";
import UpdateEmailOtpModal from "./emailotpmodal";
import { encryptData } from "../../utils/functions";

type Props = {
  handleCloseModal: () => void;
  showSignUp: boolean;
  handleOpenModal: () => void;
  userdata: any;
  getUserDetail?: any;

};

const UpdateEmailModal = (props: Props) => {

  const { t } = useTranslation();
  const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext<any>(UserContext);
  const [emailErr, setEmailErr] = useState<any>(false);
  const openOtpModal = (modaltype: any) => {
    modaltype = "otpstop" ? setShowOtp(true) : setShowOtp(true);
  };
  const otpModalStop = (modaltype: any) => {
    modaltype = "otpstop" ? setShowOtp(false) : setShowOtp(false);
  };
  const handleUpdateApi = () => {
    setLoading(true);
    const newObj = {
      type: "EMAIL",
      email_id: user.email,
    }
    api
      .post(`api/v1/customer/generate-otp`, newObj)
      .then((result) => {
        setUser({ ...user, pinId: result.data.data.pinId });
        props.handleCloseModal();
        openOtpModal("otpstop");
        setLoading(false);
      })
      .catch((error) =>
        toast(<p className="CartErrMsg">{t("cancel_payment")}</p>, {
          autoClose: 2500,
          hideProgressBar: true,
          toastId: "cartToast",
        })
      );

  };

  const UpdateUserDetails = () => {
    const data = new FormData();
    data.append("email_id", user.email);
    apiWithToken
      .put(`api/v1/customer/my-details`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        if (res.data.status === 200) {

          const token = res.data.data.token.response.token.idToken;
          const refreshToken = res.data.data.token.response.token.refreshToken;

          localStorage.removeItem("id-token");
          localStorage.removeItem("refresh-token");
          localStorage.removeItem(
            "user-details"
          );

          localStorage.setItem("id-token", token);
          localStorage.setItem("refresh-token", refreshToken);
          encryptData('user-details', res?.data?.data?.user)

          setUser({
            ...user,
            userLoggedIn: true,
            userDetails: res.data.data.user,
          });
          props?.getUserDetail();
          toast(
            <p className="CartSuccessMsg">{t("profile_updated")}</p>,
            {
              autoClose: 2500,
              hideProgressBar: true,
              toastId: "cartToast",
            }
          );
          otpModalStop("otpstop");
        } else {
          toast(<p className="CartErrMsg">{res.data.message}</p>, {
            autoClose: 2500,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        }
      });

  };

  return (
    <>
      <Modal
        show={props.showSignUp}
        onHide={() => {
          setUser({ type: "EMAIL", email: "", phoneNumber: "" });
          props.handleCloseModal();
        }}
        centered
      >
        <div className="modal-header confirm-bottom">
          <div className="CloseButtons">
            <button
              className="bahrainarrow"
              onClick={() => { props.handleCloseModal() }}
            >
              <img src={CrossMark} alt="crossmark" />
            </button>
          </div>
          <h2 className="confirmnumber">{t("update_email")}</h2>
        </div>
        <div>
          <h2 className="update-number-title">{t("enter_email")}</h2>
          <div className="update-warning">
            <h6>{t("update_warning")}</h6>
          </div>
        </div>
        <form
          className="number-form SignUpModal"
          onSubmit={(e) => e.preventDefault()}
        >

          <SignUpMailInput setEmailErr={setEmailErr} />
          <button
            disabled={emailErr || user?.email?.length == 0}
            onClick={() => {
              handleUpdateApi();
            }}
            className={`mt-5 signin-phone sign-top ${(emailErr || user?.email?.length == 0) ? "disable_btn" : ""
              }`}
          >
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <>
                <>{t("update_email")}</>{" "}
              </>
            )}
          </button>
        </form>


      </Modal>

      {showOtp && (
        <UpdateEmailOtpModal
          setConfirmation={() => {
            otpModalStop("otpstop");
          }}
          showOtp={showOtp}
          mobileedit
          handCloseOtpModal={otpModalStop}
          UpdateUserDetails={UpdateUserDetails}
        />
      )}
    </>
  );
};

export default UpdateEmailModal;
