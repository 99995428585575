import { Col, Container, Row } from "react-bootstrap";
import DirectSaleBottom from "../mywatchlist/directsalebottom";
import { useTranslation } from "react-i18next";
import DirectSaledGridCardTop from "../../components/mybidscards/directsaledgridcardtop";
import CrossButton from "../../components/buttons/crossbutton";
import BreadCrums from "../../components/breadcrumbs/breadcrums";
import HeaderCarousel from "../../components/navbar/headercarousel";
import LeftArrow from "../../assets/Icons/left-arrow.svg";
import {
  useNavigate,
  useParams
} from "react-router-dom";
import noBids from "../../assets/Icons/noBids.svg";

import {
  useState,
  useEffect,
  useContext,
  CSSProperties,
  useCallback,
  useRef,
} from "react";
import "./directsales.scss";
import { apiWithToken } from "../../feature/envs/api";
import DirectSaleListViewCard from "./directsalelistviewcard";
import { CartCount, UserContext, AllDataContext } from "../../contexts/signup";
import SignInPhoneModal from "../../components/modals/signinphonemodal";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { connectSocket, disconnectSocket } from "../../utils/socket";
import MyBidsEmpty from "../mybids/mybidsempty";
import { v4 as uuidv4 } from "uuid";
import { decryptData } from "../../utils/functions";
import { trackPageView } from "../../utils/analytics";
import { Libraries, useLoadScript } from "@react-google-maps/api";
import { MapviewDirectsale } from "../auctionbidding/mapviewDirectsale";
import { EventLogger, EventNames } from "../../utils/EventLogger";

let socket: any = null;
let assetsArr: any = [];

type Props = {
  direction?: any;
  category?: any;
  data?: any;
};

const libraries: Libraries = ["drawing", "places"]; //Added for performance warning

const DirectSaleLanding = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filteredAssetData, setFilteredAssetData] = useState<any>();
  const [viewGrid, setViewGrid] = useState(true);
  const { setCartCount } = useContext<any>(CartCount);
  const [sortObj, setSortObj] = useState<any>();
  const { user } = useContext<any>(UserContext);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [assetLoader, setAssetLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [show, setShow] = useState(false);

  const { allData, setAllData } = useContext<any>(AllDataContext);
  let { id }: any = useParams();
  if (Number.isNaN(+id)) {
    id = 1;
  }
  let isRequestInProgress = false;

  useEffect(() => {
    trackPageView((window.location.pathname + window.location.search), "Directsale");
    window.scrollTo(0, 0); // Scroll to the top of the page
    assetsArr = [];
    socket = connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    addSocketListeners();
    return () => {
      socket.off("add-to-watchlist", addWatchlist);
      socket.off("remove-from-watchlist", removeWatchlist);
      socket.off("category-asset", getCategoryAssetSocketCall);
      socket.off("add-to-cart", handleOnAssetAddedToCart);
      socket.off("add-to-cart", addRemoveCartSocketCall);
      socket.off("remove-from-cart", handleOnAssetRemovedFromCart);
      socket.off("remove-from-cart", addRemoveCartSocketCall);
      socket.off("my-cart-details", getMyCartDetails);
    };
  }, [socket]);

  const addSocketListeners = () => {
    if (socket !== null) {
      socket.on("add-to-watchlist", addWatchlist);
      socket.on("remove-from-watchlist", removeWatchlist);
      socket.on("category-asset", getCategoryAssetSocketCall);
      socket.on("add-to-cart", handleOnAssetAddedToCart);
      socket.on("add-to-cart", addRemoveCartSocketCall);
      socket.on("remove-from-cart", handleOnAssetRemovedFromCart);
      socket.on("remove-from-cart", addRemoveCartSocketCall);
      socket.on("my-cart-details", getMyCartDetails);
    }
  };

  const addWatchlist = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("asset-by-category", {
        asset_id: res.data.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const removeWatchlist = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("asset-by-category", {
        asset_id: res.data.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const addRemoveCartSocketCall = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("my-cart", {
        authorization: authToken,
      });
    }
  }, []);

  const getCategoryAssetSocketCall = useCallback((res: any) => {
    const data = res.data[0];
    assetsArr = assetsArr.map((item: any) => {
      if (item.asset_id === data.asset_id) {
        return {
          ...item,
          is_added_to_watchlist: data.is_added_to_watchlist,
        };
      } else {
        return item;
      }
    })

    setFilteredAssetData([...assetsArr]);
    setAllData({ ...allData, direct_asset: assetsArr });
  }, []);

  const getMyCartDetails = useCallback((res: any) => {
    let count: any = null;
    if (res?.data?.cart && res?.data?.cart?.items?.length) {
      count = res?.data?.cart?.items?.length;
    } else {
      count = null;
    }
    setCartCount(count);
  }, []);

  const handleOnAssetAddedToCart = useCallback((res: any) => {
    const data = res.data;

    const userDetails = decryptData("user-details");
    const userId = userDetails?.id;

    assetsArr = assetsArr.map((item: any) => {
      if (item.asset_id == data?.asset_id && userId == data?.user_id) {
        return {
          ...item,
          is_available_for_cart: false,
          in_my_cart: true,
        };
      } else if (item.asset_id == data?.asset_id ) {
        return {
          ...item,
          is_available_for_cart: false,
          in_my_cart: false,
        };
      }else {
        return item;
      }
    })

    setFilteredAssetData([...assetsArr]);
    setAllData({ ...allData, direct_asset: assetsArr });
  }, []);

  const handleOnAssetRemovedFromCart = useCallback((res: any) => {
    const data = res.data;

    assetsArr = assetsArr.map((item: any) => {
      if (item.asset_id == data?.asset_id ) {
        return {
          ...item,
          is_available_for_cart: true,
          in_my_cart: false,
        };
      }else {
        return item;
      }
    })

    setFilteredAssetData([...assetsArr]);
    setAllData({ ...allData, direct_asset: assetsArr });
  }, []);


  const openModal = (modaltype: any) => {
    modaltype === "signin" ? setShowSignIn(true) : setShowSignUp(true);
  };
  const closeModal = (modaltype: any) => {
    modaltype === "signin" ? setShowSignIn(false) : setShowSignUp(false);
  };

  const fetchOptions = () => {
    setAssetLoader(true);
    const apiUrl = `/api/v2/category/${id}/direct-sale/assets`;
    if (searchValue || (sortObj && sortObj?.priceRange)) {
      let min = null,
        max = null;
      if (sortObj?.priceRange) {
        const { min_price, max_price } = sortObj?.priceRange;
        min = min_price;
        max = max_price;
      }
      let apiEndpoint = sortObj
        ? `${apiUrl}?sort_by=${sortObj.sort_by || "highest_price"}&sort_order=${sortObj.sort_order || "DESC"
        }&search_value=${searchValue}`
        : `${apiUrl}?search_value=${searchValue}`;
      if (max !== null && min !== null) {
        apiEndpoint += `&min_price=${min}&max_price=${max}`;
      }
      apiWithToken.get(apiEndpoint).then((res: any) => {
        if (res.data.status === 200) {
          setFilteredAssetData(res?.data?.data?.data);
          setAllData({ ...allData, direct_asset: res?.data?.data?.data });
          assetsArr = res?.data?.data?.data;
          setAssetLoader(false);
        } else {
          setFilteredAssetData([]);
          setAllData({ ...allData, direct_asset: [] });
          assetsArr = [];
          setAssetLoader(false);
        }
      });
    } else {
      apiWithToken.get(`${apiUrl}`).then((res: any) => {
        if (res.data.status === 200) {
          setFilteredAssetData(res?.data?.data?.data);
          setAllData({ ...allData, direct_asset: res?.data?.data?.data });
          assetsArr = res?.data?.data?.data;
          setAssetLoader(false);
        } else {
          setAssetLoader(false);
        }
      });
    }
  };

  const handleDetail = (data: any) => {
    navigate(`/d/${data?.asset_id}`, {
      state: {
        assetItem: data,
        type: "DirectSaleDetail",
      },
    });
  };

  const handleWatchlist = (event: any, data: any) => {
    event.stopPropagation();
    if (!user.userLoggedIn) {
      setShowSignIn(true);
    } else {
      const userDetails = decryptData("user-details");

      if (
        userDetails?.identity_verification_status !== true &&
        userDetails?.doc_review_status !== "completed" &&
        userDetails?.full_name === null
      ) {
        navigate("/registration");
      } else if (
        userDetails?.identity_verification_status !== true &&
        userDetails?.doc_review_status !== "completed" &&
        userDetails?.full_name !== null
      ) {
        navigate("/verify-user");
      } else {
        if (data?.is_added_to_watchlist) {
          apiWithToken
            .put(`/api/v1/watchlist/remove-from-watchlist`, {
              asset_id: data.asset_id,
            })
            .then((res: any) => {
              if (res.data.status === 200) {
                EventLogger.logEvent(EventNames.REMOVE_FROM_WATCHLIST, {asset_id: data.asset_id});
                toast(
                  <p className="CartErrMsgWatch">
                    {t("removed_from_watchlist")}
                  </p>,
                  {
                    autoClose: 2500,
                    hideProgressBar: true,
                    toastId: "cartToast",
                  }
                );
              }
            });
        } else {
          apiWithToken
            .post(`/api/v1/watchlist/add-to-watchlist`, {
              asset_id: data.asset_id,
            })
            .then((res: any) => {
              EventLogger.logEvent(EventNames.ADD_TO_WATCHLIST, {asset_id: data.asset_id});
              if (res.data.status === 200) {
                toast(
                  <p className="CartSuccessMsg">{t("added_to_watchlist")}</p>,
                  {
                    autoClose: 3000,
                    hideProgressBar: true,
                    toastId: "cartToast",
                  }
                );
              }
            });
        }
      }
    }
  };

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    setPage(0);
  };

  useEffect(() => {
    const savedView: any = localStorage.getItem("GridViewOn");
    setViewGrid(JSON.parse(savedView));
  }, []);
  const override: CSSProperties = {
    display: "block",
    margin: "20% auto",
    borderColor: "black",
    overflow: "-moz-hidden-unscrollable",
    opacity: 0.1,
  };

  //New Code
  const prevIdRef = useRef<string | undefined>(undefined);
  const [highestPrices, setHighestPrices]: any = useState({ min: 0, max: 0 });
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 700 <
      document.documentElement.offsetHeight ||
      isLoading
    ) {
      return;
    }

    totalPage > page && !viewMap && fetchDirectSale();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  useEffect(() => {
    if (prevIdRef.current == undefined || prevIdRef.current !== id) {
      prevIdRef.current = id;
      setFilteredAssetData([]);
      setAllData({ ...allData, direct_asset: [] });
      fetchDirectSale();
    } else if (sortObj && sortObj?.priceRange) {
      fetchDirectSale();
    } else {
      // Update prevIdRef.current with the current id value
      prevIdRef.current = id;
      const timeout = setTimeout(() => {
        fetchDirectSale();
      }, 350);
      // Clear the previous timeout on every input change
      return () => clearTimeout(timeout);
    }
  }, [id, searchValue, sortObj]);

  function fetchDirectSale() {
    if (page == 0) setAssetLoader(true);
    if (page >= 1) setPageLoader(true);
    setIsLoading(true);

    if (isRequestInProgress === true && !searchValue) {
      return; // Exit the function if a request is already in progress
    }
    isRequestInProgress = true;
    const apiUrl = `/api/v2/category/${id}/direct-sale/assets`;

    if (searchValue || (sortObj && sortObj?.priceRange)) {
      let min = 0,
        max = null;
      if (sortObj?.priceRange) {
        const { min_price, max_price } = sortObj?.priceRange;
        min = min_price;
        max = max_price;
      }
      let apiEndpoint = sortObj
        ? `${apiUrl}?sort_by=${sortObj.sort_by || "highest_price"}&sort_order=${sortObj.sort_order || "DESC"
        }&search_value=${searchValue}`
        : `${apiUrl}?search_value=${searchValue}`;
      if (max !== null && min !== null) {
        apiEndpoint += `&min_price=${min}&max_price=${max}`;
      }

      apiWithToken
        .get(`${apiEndpoint}&page=${page + 1}`)
        .then((res: any) => {
          if (res.data.status === 200) {
            const direct_asset = res?.data?.data?.data;
            setTotalPage(res?.data?.data?.total);
            setPage(page + 1);
            const mergeData =
              allData?.direct_asset && page + 1 !== 1
                ? [...allData?.direct_asset, ...direct_asset]
                : direct_asset;
            setFilteredAssetData(mergeData);
            setAllData({ ...allData, direct_asset: mergeData });
            assetsArr = mergeData;
          } else {
            setFilteredAssetData([]);
            assetsArr = [];
          }
        })
        .catch((error) => {
          // Handle any errors
          console.error(error);
        })
        .finally(() => {
          setAssetLoader(false);
          setIsLoading(false);
          setPageLoader(false);
          isRequestInProgress = false; // Reset the flag after the request is completed
        });
    } else {
      apiWithToken
        .get(`${apiUrl}?page=${page + 1}`)
        .then((res: any) => {
          if (res.data.status === 200) {
            const direct_asset = res?.data?.data?.data;
            setTotalPage(res?.data?.data?.total);
            // const mergeData = allData?.direct_asset && page + 1 !== 1
            //   ? [...allData?.direct_asset, ...direct_asset]
            //   : direct_asset;
            const mergeData = allData?.direct_asset && page + 1 !== 1
              ? [...assetsArr, ...direct_asset]
              : direct_asset;
            // const mergeData = [...assetsArr, ...direct_asset];
            setFilteredAssetData(mergeData);
            setAllData({ ...allData, direct_asset: mergeData });
            setPage(page + 1);
            setHighestPrices({
              min: res?.data?.data?.prices?.min_price,
              max: res?.data?.data?.prices?.max_price,
            });
            assetsArr = mergeData;
          } else {
            setFilteredAssetData([]);
            setAllData({ ...allData, direct_asset: [] });
            setHighestPrices({
              min: 0,
              max: 0,
            });
            assetsArr = [];
            setAssetLoader(false);
          }
        })
        .catch((error) => {
          // Handle any errors
          console.error(error);
        })
        .finally(() => {
          setAssetLoader(false);
          setIsLoading(false);
          setPageLoader(false);
          isRequestInProgress = false; // Reset the flag after the request is completed
        });
    }
  }

  const handleFilter = (search: any) => {
    setPage(0);
    setSortObj(search);
  };

  //MAP Code
  const [viewMap, setViewMap] = useState(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCNndAcjTzbIK0Q6Z3v2jWcBf3tOf-GiPA",
    libraries: libraries,
  });
  const [loader, setLoader] = useState(false);

  // calls fetch auctions function when the map view is in focus
  useEffect(() => {
    if (viewMap === true) {
      fetchDirectSale();
    }
  }, [viewMap])

  return (
    <>
      <Container>
        <HeaderCarousel
          direction={props.direction}
          handleSearch={handleSearch}
          setViewGrid={setViewGrid}
          viewGrid={viewGrid}
          assetTitle={filteredAssetData?.length > 0 ?? filteredAssetData[0]?.category_title}
          handleFilter={handleFilter}
          type={"directSale"}
          filterValue={sortObj}
          setAssetLoader={setAssetLoader}
          highestPrices={highestPrices}
          setPage={setPage}
          setViewMap={setViewMap}
          viewMap={viewMap}
        />
      </Container>
      <Container fluid={viewMap}>
        {!viewMap && (<Row>
          <div className="d-flex justify-content-between profilereg biddingswoncard">
            <div className="d-flex align-items-center">
              <CrossButton className="prevarrow" imgUrl={LeftArrow} setPage={setPage} />
              <BreadCrums
                previousPage={t("direct_sale")}
                currentPage={
                  filteredAssetData?.length > 0
                    ? t("en") === "en"
                      ? filteredAssetData[0]?.category_title
                      : filteredAssetData[0]?.category_title_ar
                    : ""
                }
              />
            </div>
          </div>
        </Row>
        )}
        {viewMap === false ? viewGrid ? (
          <Row>
            {assetLoader ? (
              <ClipLoader
                color={"#fff"}
                loading={assetLoader}
                cssOverride={override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : filteredAssetData?.length > 0 ? (
              filteredAssetData?.map((data: any) => (
                <Col lg={2} xs={6} sm={4} md={3} key={uuidv4()}>
                  <div
                    className="bidcardsection DirectSalesBidding"
                    onClick={() => handleDetail(data)}
                  >
                    <DirectSaledGridCardTop
                      data={data}
                      handleWatchlist={handleWatchlist}
                      setShowSignIn={setShowSignIn}
                    />
                    <DirectSaleBottom value={t("bhd")} data={data} />
                  </div>
                </Col>
              ))
            ) : (
              <>
                {assetLoader && (
                  <ClipLoader
                    color={"#fff"}
                    loading={assetLoader}
                    cssOverride={override}
                    size={100}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
                {!assetLoader && <MyBidsEmpty imgUrl={noBids} title={"direct"} />}
              </>
            )}
          </Row>
        ) : (
          <Row>
            {assetLoader ? (
              <ClipLoader
                color={"#fff"}
                loading={assetLoader}
                cssOverride={override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : filteredAssetData?.length > 0 ? (
              filteredAssetData?.map((data: any) => (
                <Col lg={4} md={6} key={uuidv4()}>
                  <div
                    className="bidcardsection DirectSalesBidding"
                    onClick={() => handleDetail(data)}
                  >
                    <DirectSaleListViewCard
                      data={data}
                      handleWatchlist={handleWatchlist}
                      fetchOptions={fetchOptions}
                      setShowSignIn={setShowSignIn}
                    />
                  </div>
                </Col>
              ))
            ) : (
              <>
                {assetLoader && (
                  <ClipLoader
                    color={"#fff"}
                    loading={assetLoader}
                    cssOverride={override}
                    size={100}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
                {!assetLoader && <MyBidsEmpty imgUrl={noBids} title={"direct"} />}
              </>
            )}
          </Row>
        ) : null}
        <Row>
          {pageLoader ? (
            <ClipLoader
              color={"#fff"}
              loading={pageLoader}
              cssOverride={override}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            ""
          )}
        </Row>
        <SignInPhoneModal
          handleCloseModal={closeModal}
          showSignIn={showSignIn}
          handleOpenModal={openModal}
          modalType={t("header_signin")}
        />


        {viewMap && isLoaded ? (
          <MapviewDirectsale
            showSignIn={showSignIn}
            setShowSignIn={setShowSignIn}
            show={show}
            setShow={setShow}
            filteredAssetData={filteredAssetData}
            loader={loader}
            setLoader={setLoader}
            assetLoader={assetLoader}
            handleDetailPage={handleDetail}
            handleWatchlist={handleWatchlist}
          />
        ) : (
          (
            <ClipLoader
              loading={isLoading}
              cssOverride={override}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )
        )}

      </Container>
    </>
  );
};

export default DirectSaleLanding;
