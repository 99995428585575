import { Modal } from "react-bootstrap";
import hammer from "../../assets/Icons/hammer.svg";
import BidNow from "../../assets/Icons/bid-now.svg";
import BetterLuckNextTime from "../../assets/Icons/betterluck.svg";
import Congartulations from "../../assets/Icons/congratulations.svg";
import "./auctionbidding.scss";
import { numberFormat } from "../../utils/functions";
import CrossMark from "../../assets/Icons/crossmark.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

type Props = {
  autoBidConfirmModal?: any;
  setAutoBidConfirmModal?: any;
  fullscreen?: any;
  modalType?: any;
  topUpValue?: any;
  setAutoBidOn?: any;
  sliderOffModal?: any;
  setSliderOffModal?: any;
  showModal?: any;
  setShowModal?: any;
  handleMakeBid?: any;
  autoBidOn?: any;
  setInfoIconModal?: any;
  showInfoIconModal?: any;
  setLoading?: any;
  setAutoBidOff?: any;
  loading?: any;
  asset_id?:any;
};
export default function CommonInfoModal(props: Props) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const cancelAutoBid = () => {
    if (props.modalType === "Auto Bid Button") {
      props.setAutoBidConfirmModal(false);
      props.setLoading(false);
    }
    if (props.modalType === "Turn on Auto bid") {
      props.setAutoBidOn(false);
      props.setLoading(false);
      props.setAutoBidConfirmModal(false);
    }
    if (props.modalType === "Turn off Auto bid") {
      props.setShowModal(false);
      props.setLoading(false);
      props.setAutoBidOn(true);
    }
    if (props.modalType === "Open Info Icon") {
      props.setInfoIconModal(false);
      props.setLoading(false);
    }
    if (props.modalType === "Auction Ended Loss") {
      navigate("/");
    }
    if (
      props.modalType === "Auction Ended Won"
    ) {
      navigate(`/complete-purchase?id=${props?.asset_id}`);
    }
  };
  const turnOffAutobid = () => {
    props.setSliderOffModal(false);
    props.setAutoBidOff();
    props.setLoading(false);
  };

  const triggerAutoBid = () => {
    props.setAutoBidConfirmModal(false);
    props.handleMakeBid("AUTO");
    props.setLoading(false);
  };

  return (
    <div>
      <Modal
        show={
          props.autoBidConfirmModal ||
          props.sliderOffModal ||
          props.showInfoIconModal
        }
        fullscreen={props.fullscreen}
        onHide={() => props.setAutoBidConfirmModal(false)}
        className="NotificationModal"
        centered
      >
        <Modal.Header>
          <div className="CloseButtons">
            <button className="bahrainarrow" onClick={cancelAutoBid}>
              <img src={CrossMark} alt="crossmark" />
            </button>
          </div>
          {props.modalType === "Auto Bid Button" && (
            <h2 className="confirmnumber">{t("auto_bid")}</h2>
          )}
          {props.modalType === "Turn on Auto bid" && (
            <h2 className="confirmnumber">{t("notifications")}</h2>
          )}
          {props.modalType === "Turn off Auto bid" && (
            <h2 className="confirmnumber">{t("auto_bid")}</h2>
          )}
          {(props.modalType === "Auction Ended Loss" ||
            props.modalType === "Auction Ended Won") && (
            <h2 className="confirmnumber">{t("auction_ended")}</h2>
          )}
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center flex-column">
          {props.modalType === "Auto Bid Button" && (
            <>
              <div className="ConfirmBidCount infomodal">
                <h2 className="ConfirmBidBhd">
                  {t("en") === "en"
                    ? `${t("bhd")} ${numberFormat(props?.topUpValue)}`
                    : `${numberFormat(props?.topUpValue)} ${t("bhd")}`}
                </h2>
              </div>
              <h2 className="AgreeConfirmBid auto_bi">{t("by_continue")}</h2>
              <button className={`signin-phone`} onClick={triggerAutoBid}>
                <img src={BidNow} alt="Bid Now" />
                {t("auto_bid_now")}
              </button>
            </>
          )}
          {props.modalType === "Turn on Auto bid" && (
            <>
              <img
                src={hammer}
                alt="notficationbell"
                className="NotificationImage"
              />
              <h2 className="profileheading">{t("first_auto_bid")}</h2>

              <h2 className="AgreeConfirmBid auto_bi">
                {t("auto_bid_message")}
              </h2>
              <button
                className={`signin-phone`}
                onClick={() => props.setAutoBidConfirmModal(false)}
              >
                <img src={BidNow} alt="Bid Now" />
                {t("ok")}
              </button>
            </>
          )}
          {props.modalType === "Turn off Auto bid" && (
            <>
              <h2 className="AgreeConfirmBid auto_bi">
                {t("by_continue_turn_off")}
              </h2>
              <button className={`signin-phone`} onClick={turnOffAutobid}>
                <img src={BidNow} alt="Bid Now" />
                {t("turn_off_auto_bid")}
              </button>
            </>
          )}
          {props.modalType === "Open Info Icon" && (
            <>
              <img
                src={hammer}
                alt="notficationbell"
                className="NotificationImage"
              />
              <h2 className="profileheading">{t("first_auto_bid")}</h2>
              <h2 className="AgreeConfirmBid auto_bi">
                {t("auto_bid_message")}
              </h2>
              <button
                className={`signin-phone`}
                onClick={() => props.setInfoIconModal(false)}
              >
                <img src={BidNow} alt="Bid Now" />
                {t("ok")}
              </button>
            </>
          )}
          {props.modalType === "Auction Ended Loss" && (
            <>
              <img
                src={BetterLuckNextTime}
                alt="notficationbell"
                className="NotificationImage"
              />
              <h2 className="profileheadingCongrats">{t("better_luck_next_time")}</h2>
              <h2 className="AgreeConfirmBidCong auto_bi">
                {t("auction_ended_loss_message")}
              </h2>
              <button
                className={`signin-phone`}
                onClick={() => {
                  navigate("/");
                }}
              >
                {t("continue")}
              </button>
            </>
          )}
          {props.modalType === "Auction Ended Won" && (
            <>
              <img
                src={Congartulations}
                alt="notficationbell"
                className="NotificationImage"
              />
              <h2 className="profileheadingCongrats">{t("congratulations")}</h2>
              <h2 className="AgreeConfirmBidCong auto_bi">
                {t("auction_ended_won_message")}
              </h2>
              <button
                className={`signin-phone`}
                style={{ background: "#14AE5C" }}
                onClick={() => {navigate(`/complete-purchase?id=${props?.asset_id}`)}}
              >
                {t("complete_purchase")}
              </button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  ); 
}
