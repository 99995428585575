import { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import DirectSaleToggle from "../../components/directsales/directsaletoggle";
import PaymentMethods from "../../components/paymentmethods";
import OrderCompleteRegistrationDocument from "./ordercompleteregistrationdocument";
type Props = {
  selectedAsset?: any;
  toggle?: any;
  setToggle?: any;
  selectedType?: any;
  id?: any;
};

type paymentType = {
  id: number;
  payment_title: string;
  payment_method: string;
  icon: string;
};
const RegistrationPaymentMethods = (props: Props) => {
  const { t } = useTranslation();
  const [selectedOption, updateOption] = useState(-1);
  const [toggle, setToggle] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  let { payment_methods } = props?.selectedAsset || {};
  const category_id = props?.selectedAsset?.category_id || {};
  const asset_id = props?.selectedAsset?.asset_id || {};
  const [totalWalletAmount, setTotalWalletAmount] = useState(null);
  const highestPrice = props?.selectedAsset?.total_amount || {};
  const deposit_amount = props?.selectedAsset?.deposit_amount || 0 || {};
  const vat_percentage = props?.selectedAsset?.vat_percentage || {};
  const item_total = props?.selectedAsset?.item_total || 0;
  const total_amount = props?.selectedAsset?.total_amount || 0;
  const vat = props?.selectedAsset?.vat || 0;
  const [classVal, setClassVal] = useState(
    props?.selectedAsset?.transaction
      ? "PaymentSummary DirectSalesPaymentSummary"
      : "PaymentSummary"
  );

  const cart_id =
    props?.selectedAsset?.id || props?.selectedAsset?.cart_id || 0;

  payment_methods = payment_methods?.filter(
    (val: any) => val.payment_method != "apple_pay"
  );

  const method =
    (payment_methods &&
      payment_methods?.find((item: paymentType) => item.id === selectedOption)
        ?.payment_method) ||
    "";

  const calculationAmount = () => {    
    props?.id > 0 && updateOption(-1);
    if (toggle) {
      props?.id > 0 && setTotalAmount(highestPrice - deposit_amount);
      setTotalWalletAmount(highestPrice);
    } else {
      props?.id > 0 && setTotalAmount(highestPrice);
    }
  };

  useEffect(() => {
    cart_id < 1 && calculationAmount();
  }, [highestPrice, toggle]);

  useEffect(() => {
    setClassVal(
      props?.selectedAsset?.transaction
        ? "PaymentSummary DirectSalesPaymentSummary"
        : "PaymentSummary"
    );
  }, [props?.selectedAsset]);

  return (
    <div className={classVal}>
      {props?.selectedAsset?.transaction ? (
        <OrderCompleteRegistrationDocument
          cart_id={cart_id}
          selectedAssetId={props?.selectedAsset?.asset_id}
          toggle={props.toggle}
          setToggle={props.setToggle}
        />
      ) : (
        <>
          {props?.id > 0 && (
            <div className="purchase_container">
              <DirectSaleToggle
                toggle={toggle}
                setToggle={setToggle}
                title={"Deposit"}
              />
            </div>
          )}
          {
            <>
              <PaymentMethods
                sortedMethods={payment_methods}
                category_id={category_id}
                method={method}
                selectedOption={selectedOption}
                updateOption={updateOption}
                toggle={toggle}
                totalAmount={totalAmount || total_amount}
                transactionType={"INVOICE"}
                typePurchase={true}
                asset_id={asset_id}
                vat_percentage={vat_percentage}
                item_total={item_total || total_amount}
                vat={vat}
                cart_id={cart_id}
                selectedType={props?.selectedType}
                totalWalletAmount={totalWalletAmount}
                selectedAsset={props?.selectedAsset}
              />
            </>
          }
        </>
      )}
    </div>
  );
};

export default memo(RegistrationPaymentMethods);
