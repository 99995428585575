import { useNavigate } from "react-router-dom";
import VerifyNumberButton from "../../components/buttons/verifynumberbutton";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { apiWithToken } from "../../feature/envs/api";
import { numberFormat } from "../../utils/functions";
import Categorydropdown from "./deposits/categorydropdown";
import { toast } from "react-toastify";
import { trackPageView } from "../../utils/analytics";
import { EventLogger, EventNames } from "../../utils/EventLogger";

type Props = {};

const Refund = (props: Props) => {
  const { t } = useTranslation();
  const [deposit_amount, setDepositeAmount] = useState();
  const [refund_applicable, setrRefundApplicable] = useState(false);
  const [catagery, setCatagery] = useState<any>({});
  const [options, setOptions] = useState();
  const [reason, setSelectedreason] = useState<any>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getCategories = (data: any) => {
    const optionVal = {
      id: data.id,
      category_title: data.category_title,
      category_title_ar: data.category_title_ar,
    };
    setSelectedreason(data.category_title);
    setCatagery(optionVal);
  };

  useEffect(() => {
    trackPageView(window.location.pathname + window.location.search, "Refund");

    apiWithToken.get(`/api/v1/customer/refund`).then((res: any) => {
      if (res.data.status === 200) {
        const { deposit_amount, refund_applicable } = res?.data?.data;
        setDepositeAmount(deposit_amount);
        setrRefundApplicable(refund_applicable);
      }
    });

    apiWithToken.get(`/api/v1/transaction/refund-reasons`).then((res: any) => {
      if (res.data.status === 200) {
        const data = res?.data?.data;
        const options = data.map((item: any) => ({
          id: item.id,
          category_title: t(`${item.value}`),
          category_title_ar: item?.value_ar,
        }));
        options && setOptions(options);
        setCatagery(options[0]);
        setSelectedreason(options[0].category_title);
      }
    });
  }, []);

  const refundData = new FormData();
  refundData.append("refund_reason", reason);
  refundData.append("transaction_type", "REFUND");

  const hadleRefund = () => {
    setLoading(true);
    apiWithToken
      .post("api/v1/transaction/refund", refundData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        if (res.data.status === 200) {
          EventLogger.logEvent(EventNames.REFUND, { refund_reason: reason });
          setLoading(false);
          navigate(`/checkout?paymentid=${res.data.data.transaction_id}`, {
            state: res.data.data,
          });
        }
        if (res.data.status === 400) {
          setLoading(false);
          toast(<p className="CartErrMsg">{t("refund_requested")}</p>, {
            autoClose: 2500,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <div className="RefundDepositeSection">
      <div className="SubmitRefund">
        <h2 className="profileheading">{t("submitting_a_refund")}</h2>
        <p className="CurrentAvailable">{t("submitting_a_refund_text_1")}</p>
        <p className="CurrentAvailable">{t("submitting_a_refund_text_2")}</p>
        <h2 className="profileheading">{t("deposit_refund_requests")}</h2>
        <p className="CurrentAvailable">{t("deposit_refund_requests_text")}</p>
      </div>
      <ul className="CurrentAvailableList">
        <li className="CurrentAvailable">
          {t("deposit_refund_requests_list_one")}
        </li>
        <li className="CurrentAvailable">
          {t("deposit_refund_requests_list_two")}
        </li>
        <li className="CurrentAvailable">
          {t("deposit_refund_requests_list_three")}
        </li>
      </ul>
      <div className="AmountRefundSection">
        <h2 className="profileheading">{t("amount_refund")}</h2>
        <div className="AmountRefund">
          <p>{deposit_amount && t("bhd") + numberFormat(deposit_amount)}</p>
        </div>
        <h2 className="profileheading">{t("wish_to_refund_amount")}</h2>
        <div className="RefundFormselect">
          {options && catagery && (
            <Categorydropdown
              category={catagery}
              getCategories={getCategories}
              options={options}
            />
          )}
        </div>
        <VerifyNumberButton
          name={t("submit_refund")}
          className={
            refund_applicable === false
              ? "confirm-otp-number disable_btn"
              : "confirm-otp-number"
          }
          onClick={hadleRefund}
          refund_applicable={refund_applicable}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Refund;
