import "./app.scss";
import Header from "./components/navbar/header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/home";
import CompleteRegistration from "./pages/completeregistration/completeregistration";
import VerifyUserId from "./pages/completeregistration/verifyuserid";
import ValidateDocuments from "./pages/completeregistration/validatedocuments";
import Mybids from "./pages/mybids/mybids";
import Camara from "./pages/completeregistration/camara";
import cookies from "js-cookie";
import { useEffect, useState } from "react";
import MyWatchList from "./pages/mywatchlist/mywatchlist";
import DeleteAccount from "./pages/internalrounting/deleteaccount";
import Footer from "./components/footer/footer";
import {
  UserContext,
  CartCount,
  CartWarning,
  AllDataContext,
} from "./contexts/signup";
import ContinueToCheckout from "./pages/internalrounting/continuecheckout";
import InternalRouting from "./pages/internalrounting/internalrounting";
import SecondRouting from "./pages/internalrounting/secondrouting";
import ThirdRouting from "./pages/internalrounting/thirdrouting";
import TransactionHistory from "./pages/internalrounting/transactionhistory";
import ProfilePurchase from "./pages/internalrounting/profilepurchase";
import AuctionBidding from "./pages/auctionbidding/auctionbidding";
import Creditpaymentstatus from "./pages/internalrounting/paymentoptions/paymentstatus";
import RequireAuth from "./privateroute";
import RequireProtectedAuth from "./protectedroute";
import Deposits from "./pages/internalrounting/deposits";
import CompletePurchase from "./pages/ordercompleteregistration/CompletePurchase";
import CompletePurchaseCheckout from "./pages/ordercompleteregistration/completepurchasecheckout";
import DirectSaleLanding from "./pages/directsales/directsalelanding";
import DirectSaleProductdetails from "./pages/directsales/directsaleproductdetails";
import CarPlatesAssetDetails from "./pages/categorycarplates/carplatesassetdetails";
import DirectSaleCheckout from "./pages/directsales/directsalecheckout";
import CategoryAssetDetailsOneData from "./pages/auctionbidding/categoryassetdetailsonedata";
import AllTopicsContact from "./pages/termsandcontact/alltopicscontact";
import MazadTermsAndServices from "./pages/termsandcontact/mazadtermsandservices";
import Refund from "./pages/internalrounting/refund";
import { trackPageView } from "./utils/analytics";

function App() {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // Page is visible, perform necessary actions
        // e.g., refresh data, update state, etc.
        setIsVisible(true);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    
    trackPageView((window.location.pathname + window.location.search), "Mazad");

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const currentLanguageCode = cookies.get("i18next") || "en";
  const [direction, setDirection] = useState(
    currentLanguageCode === "en" ? "" : "rtl"
  );
  let userPreDefined = {
    email: "",
    phoneNumber: "",
    userLoggedIn: localStorage.getItem("id-token") !== null,
    method: "",
    navLinkEnable: false,
  };
  let finalContext = null;

  //Check on refresh if user context is not available but localstorage has data
  // if (localStorage.getItem("user-details")) {
  //   const userLocalStorageData = localStorage.getItem("user-details");
  //   if (userLocalStorageData !== null) {
  //     const userData = JSON.parse(userLocalStorageData);
  //     finalContext = {
  //       ...userPreDefined,
  //       userDetails: userData,
  //     };
  //   }
  // }

  const [user, setUser] = useState<any>(
    finalContext !== null ? finalContext : userPreDefined
  );
  const [count, setCartCount] = useState<any>(0);
  const [status, setCartWarning] = useState<any>(false);

  const [allData, setAllData] = useState<any>();

  const languages = [
    { code: "en", dir: "" },
    { code: "ar", dir: "rtl" },
  ];

  const currentLanguage = languages?.find(
    (l) => l.code === currentLanguageCode
  );

  useEffect(() => {
    document.body.dir = currentLanguage?.dir || "ltr";
  }, [currentLanguage]);

  if (!isVisible) {
    return null; // Render nothing if the page is not visible
  }

  return (
    <AllDataContext.Provider value={{ allData, setAllData }}>
      <UserContext.Provider value={{ user, setUser }}>
        <CartCount.Provider value={{ count, setCartCount }}>
          <CartWarning.Provider value={{ status, setCartWarning }}>
            <BrowserRouter basename="/">
              <Header direction={direction} setDirection={setDirection} />
              <Routes>
                <Route
                  path="/"
                  element={
                    <RequireProtectedAuth>
                      <Home direction={direction} />
                    </RequireProtectedAuth>
                  }
                />
                <Route
                  path="/registration"
                  element={
                    <RequireAuth>
                      <CompleteRegistration />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/verify-user"
                  element={
                    <RequireAuth>
                      <VerifyUserId />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/opencamara"
                  element={
                    <RequireAuth>
                      <Camara />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/uploaddocuments"
                  element={
                    <RequireAuth>
                      <ValidateDocuments />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/bids"
                  element={
                    <RequireAuth>
                      <Mybids />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/watchlist"
                  element={
                    <RequireAuth>
                      <MyWatchList />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <RequireAuth>
                      <InternalRouting />
                    </RequireAuth>
                  }
                >
                  <Route
                    path="/profile/notification"
                    element={
                      <RequireAuth>
                        <SecondRouting />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/profile/setting"
                    element={
                      <RequireAuth>
                        <ThirdRouting />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/profile/deposits"
                    element={
                      <RequireAuth>
                        <Deposits />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/profile/refund"
                    element={
                      <RequireAuth>
                        <Refund />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/profile/transactions"
                    element={
                      <RequireAuth>
                        <TransactionHistory />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/profile/purchases"
                    element={
                      <RequireAuth>
                        <ProfilePurchase />
                      </RequireAuth>
                    }
                  />
                </Route>
                {/* <Route
                  path="/deleteaccount"
                  element={
                    <RequireAuth>
                      <DeleteAccount />
                    </RequireAuth>
                  }
                /> */}
                <Route
                  path="/checkout"
                  element={
                    <RequireAuth>
                      <ContinueToCheckout />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/purchasecheckout"
                  element={
                    <RequireAuth>
                      <CompletePurchaseCheckout />
                    </RequireAuth>
                  }
                />

                <Route
                  path="/complete-purchase"
                  element={
                    <RequireAuth>
                      <CompletePurchase />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/a/:id"
                  element={
                    <RequireProtectedAuth>
                      <CategoryAssetDetailsOneData />
                    </RequireProtectedAuth>
                  }
                />
                <Route
                  path="/assetDetailsPlates"
                  element={<CarPlatesAssetDetails />}
                />
                <Route
                  path="/s/:id"
                  element={
                    <RequireProtectedAuth>
                      <DirectSaleLanding direction={direction} />
                    </RequireProtectedAuth>
                  }
                />
                <Route
                  path="/d/:id"
                  element={
                    <RequireProtectedAuth>
                      <DirectSaleProductdetails />
                    </RequireProtectedAuth>
                  }
                />
                <Route
                  path="/c/:id"
                  element={
                    <RequireProtectedAuth>
                      <AuctionBidding direction={direction} />
                    </RequireProtectedAuth>
                  }
                />
                <Route
                  path="/cart"
                  element={
                    <RequireAuth>
                      <DirectSaleCheckout />
                    </RequireAuth>
                  }
                />

                <Route
                  path="/payment/:status/:transaction_id"
                  element={
                    <RequireAuth>
                      <Creditpaymentstatus />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/contact-us"
                  element={
                    <RequireProtectedAuth>
                      <AllTopicsContact />
                    </RequireProtectedAuth>
                  }
                />
                <Route
                  path="/terms-of-service"
                  element={
                    <RequireProtectedAuth>
                      <MazadTermsAndServices />
                    </RequireProtectedAuth>
                  }
                />
                <Route
                  path="/cookie-policy"
                  element={
                    <RequireProtectedAuth>
                      <MazadTermsAndServices />
                    </RequireProtectedAuth>
                  }
                />
                <Route
                  path="/privacy-policy"
                  element={
                    <RequireProtectedAuth>
                      <MazadTermsAndServices />
                    </RequireProtectedAuth>
                  }
                />
                <Route
                  path="/terms-of-use"
                  element={
                    <RequireProtectedAuth>
                      <MazadTermsAndServices />
                    </RequireProtectedAuth>
                  }
                />
              </Routes>
              <Footer />
            </BrowserRouter>
          </CartWarning.Provider>
        </CartCount.Provider>
      </UserContext.Provider>
    </AllDataContext.Provider>
  );
}

export default App;
