import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Categorydropdown from "../internalrounting/deposits/categorydropdown";
import "../internalrounting/internalrouting.scss";
import MoneyImg from "../../assets/Icons/Money.png";
import MultiRangeSlider from "../../components/multirangeslider";
import Hammer from "../../assets/Icons/hammer.svg";
import CrossMark from "../../assets/Icons/crossmark.svg";
import Clock from "../../assets/Icons/directsales-clock.svg";

type Props = {
  setShow?: any;
  show?: any;
  fullscreen?: any;
  title?: any;
  handleFilter?: any;
  filterValue?: any;
  type?: any;
  range: any;
};

export default function FilterModal(props: Props) {
  const { t } = useTranslation();
  const [priceRange, setPriceRange] = useState<any>();
  const [sort_order, setSortOrder] = useState<any>();
  const [sort_by, setSortBy] = useState<any>();

  const auctionOptions = [
    {
      id: 1,
      icon: MoneyImg,
      category_title: t("price_highest_lowest"),
    },
    {
      id: 2,
      icon: MoneyImg,
      category_title: t("price_lowest_highest"),
    },
    {
      id: 3,
      icon: Hammer,
      category_title: t("bids_highest_lowest"),
    },
    {
      id: 4,
      icon: Hammer,
      category_title: t("bids_lowest_highest"),
    },

    {
      id: 5,
      icon: Clock,
      category_title: t("ending_soon_latest"),
    },
    {
      id: 6,
      icon: Clock,
      category_title: t("ending_latest_soon"),
    },
  ];
  const directsaleOptions = [
    {
      id: 1,
      icon: MoneyImg,
      category_title: t("price_highest_lowest"),
    },
    {
      id: 2,
      icon: MoneyImg,
      category_title: t("price_lowest_highest"),
    },
  ];
  const price = props.type === "auction" ? "highest_price" : "asset_price";
  const defaultSortBy = price;
  const defaultSortOrder = "DESC";
  const sortOrderMap = {
    [t("bids_highest_lowest")]: { order: "DESC", sortBy: "total_bids" },
    [t("bids_lowest_highest")]: { order: "ASC", sortBy: "total_bids" },
    [t("price_highest_lowest")]: { order: "DESC", sortBy: price },
    [t("price_lowest_highest")]: { order: "ASC", sortBy: price },
    [t("ending_soon_latest")]: { order: "ASC", sortBy: "end_time" }, // Switched the sort order to show accurate results (Ending soon first)
    [t("ending_latest_soon")]: { order: "DESC", sortBy: "end_time" }, // Switched the sort order to show accurate results (Ending last first)
    [t("number_highest_lowest")]: { order: "DESC", sortBy: "number" },
    [t("number_lowest_highest")]: { order: "ASC", sortBy: "number" },
  };

  const [catagery, setCatagery] = useState({
    id: props?.filterValue?.selectedSortOption?.id || 1,
    icon: props?.filterValue?.selectedSortOption?.icon || MoneyImg,
    category_title:
      props?.filterValue?.selectedSortOption?.category_title ||
      t("price_highest_lowest"),
  });
  const getCategories = (data: any) => {
    const optionVal = {
      id: data.id,
      icon: data.icon,
      category_title: data.category_title,
    };
    setCatagery(optionVal);
    const sortOrderData = sortOrderMap[data.category_title] || {
      order: defaultSortOrder,
      sortBy: defaultSortBy,
    };
    setSortOrder(sortOrderData.order);
    setSortBy(sortOrderData.sortBy);

  };

  const handlePriceRange = (min: any, max: any) => {
    setPriceRange({
      min_price: min,
      max_price: max,
    });
  };

  const filterChange = () => {    
    const sortOrderData = sortOrderMap[catagery.category_title] || {
      order: defaultSortOrder,
      sortBy: defaultSortBy,
    };
    setSortOrder(sortOrderData.order);
    setSortBy(sortOrderData.sortBy);
    props.handleFilter({
      sort_order: sort_order && sort_order || sortOrderData.order,
      sort_by: sort_by && sort_by || sortOrderData.sortBy,
      priceRange: priceRange && priceRange,
      selectedSortOption: catagery,
    });
    props.setShow(false);
  };  

  const ResetFilter=(props:any)=>{
    setPriceRange({
      min_price: props.range.min,
      max_price: props.range.max,
    });
    setCatagery({id:1,icon:MoneyImg,category_title: t("price_highest_lowest")})
    setSortBy(null)
    setSortOrder(null)
    props.handleFilter({
      priceRange: null,
      sort_order:null,
      sort_by:null,
      selectedSortOption: catagery ? catagery : {id:1,icon:MoneyImg,category_title: t("price_highest_lowest")}
    });
    props.setShow(false);
  }  
 
  const handleModalClose = () => {
    props.setShow(false);
  }
  return (
    <Modal
      show={props.show}
      fullscreen={props.fullscreen}
      onHide={handleModalClose}
      className="NotificationModal"
    >
      <div className="filtercontainer">
        <Modal.Header>
          <div className="CloseButtons">
            <button className="bahrainarrow" onClick={handleModalClose}>
              <img src={CrossMark} alt="crossmark" />
            </button>
            </div>
          <h2 className="confirmnumber htitle me-r">
            {props.title} {t("filter")}
          </h2>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col className="colstyle firstcolstyle" sm={12}>
                <span className="modalText">{t("sort_by")}</span>
                <Categorydropdown
                  category={catagery}
                  getCategories={getCategories}
                  options={
                    props.type === "auction"
                      ? auctionOptions
                      : directsaleOptions
                  }
                />
              </Col>
              <Col className="colstyle" sm={12}>
                <span className="modalText">{t("price")}</span>
                <MultiRangeSlider
                  range={props.range}
                  min={props?.filterValue?.priceRange?.min_price || props.range.min}
                  max={props?.filterValue?.priceRange?.max_price || props.range.max}
                  onChange={({ min, max }) => handlePriceRange(min, max)}
                />
              </Col>
              <Col className="colstyle" sm={12}>
                <Button
                  variant="outline-primary"
                  className="applyfilterbtn"
                  onClick={filterChange}
                >
                  {t("apply_filter")}
                </Button>
                <p
                  className="ResetButton"
                  onClick={()=>{  
                   ResetFilter(props)
                 }}
                >
                  {t("clear_filter")}
                </p>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}
