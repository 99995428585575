import { Modal } from "react-bootstrap";
import "../../pages/auctionbidding/./auctionbidding.scss";
import contactCardPic from "../../assets/Icons/passport-id.svg";
import depositCardPic from "../../assets/Icons/Money.png";
import CrossMark from "../../assets/Icons/crossmark.svg";

type Props = {
  fullscreen?: any;
  showModal?: any;
  setShowModal?: any;
  loading?: any;
  title?: any;
};
export default function CompletePurchaseInfoModal(props: Props) {
  return (
    <div>
      <Modal
        show={props.showModal}
        fullscreen={props.fullscreen}
        onHide={() => props.setShowModal(false)}
        className="NotificationModal"
      >
        <Modal.Header>
          <div className="CloseButtons">
            <button className="bahrainarrow" onClick={() => props.setShowModal(false)}>
              <img src={CrossMark} alt="crossmark" />
            </button>
          </div>
          <h2 className="confirmnumber me-4">Use My {props.title}</h2>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center flex-column">
          <img
            src={props.title == "ID" ? contactCardPic : depositCardPic}
            alt="notficationbell"
            className="purchasecontact"
          />
          <h2 className="purchasehead">Use My {props.title}</h2>

          <h5 className="purchasetext">
            Allows you to use the Personal Identification that was created with
            this account , if you wish to add different ID, leave this off
          </h5>
          <button
            className={`signin-phone`}
            onClick={() => props.setShowModal(false)}
          >
            OK
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
