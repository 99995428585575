import ImageGallery from "react-image-gallery";
import CrossMark from "../../assets/Icons/crossmark.svg";
import "../../components/ImageGallery.scss";

type Props = {
  show: any;
  setShow: any;
  fullscreen: any;
  images: any;
};

const ImageViewer = (props: Props) => {
  const closeGallery = () => {
    props.setShow(false);
  };

  return (
    <>
      {props.show && (
        <div className="custom-image-gallery">
          <div className="image-gallery-close">
            <button
              className="image-gallery-close-button"
              onClick={closeGallery}
            >
              <img src={CrossMark} alt="cancelbutton" />
            </button>
          </div>
          <ImageGallery
            items={props.images}
            showFullscreenButton={false}
            showPlayButton={false}
          />
        </div>
      )}
    </>
  );
};
export default ImageViewer;
