import { t } from "i18next";
import ProfileSocialContact from "../../components/profile/profilesocialcontact";

type Props = {};

const ReachUsOptions = (props: Props) => {
  return (
    <div className="text-center ReachUsSection">
      <p className="ReachUs">
        {t("reach_us")}
      </p>
      <ProfileSocialContact />
    </div>
  );
};

export default ReachUsOptions;
