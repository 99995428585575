import moment from "moment";
import { t } from "i18next";
import CryptoJS from "crypto-js";
import { setUserId } from "./analytics";
const SECRET_KEY = "mazad-customer";
export const unixToDateTime = (dateTime) => {
  let unixTimestamp = +dateTime; // Unix timestamp

  if ( unixTimestamp.toString().length === 10 ) {
    unixTimestamp *= 1000;
  }

  const date = new Date(unixTimestamp); // Remove * 1000 to handle timestamps with milliseconds
  const dateString = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const timeString = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const formattedDate = `${dateString} ${timeString}`;
  return formattedDate;
};

export const unixToEndTime = (dateTime) => {
  const unixTimestamp = dateTime; // example Unix timestamp

  const remainingTime = moment.duration(
    unixTimestamp - moment().valueOf(),
    "milliseconds"
  ); // calculate the remaining time using Moment.js
  const daysRemaining = Math.floor(remainingTime.asDays()); // calculate the number of days remaining
  const timeRemaining = moment.utc(remainingTime.asMilliseconds()).format("HH"); // format the time remaining
  const minutesRemaining = moment
    .utc(remainingTime.asMilliseconds())
    .format("mm"); // format the minutes remaining
  const secondsRemaining = moment
    .utc(remainingTime.asMilliseconds())
    .format("s"); // format the seconds remaining
  if (daysRemaining <= 0 && +timeRemaining > 0) {
    return `${timeRemaining}h ${minutesRemaining}m`;
  } else if (daysRemaining <= 0 && +timeRemaining <= 0) {
    if (minutesRemaining <= 0) {
      return `${secondsRemaining}s`;
    } else {
      return `${minutesRemaining}m ${secondsRemaining}s`;
    }
  } else {
    return `${daysRemaining}d ${timeRemaining}h`;
  }
};

export const unixToEndTimeSeconds = (dateTime) => {
  const unixTimestamp = dateTime; // example Unix timestamp

  const remainingTime = moment.duration(
    unixTimestamp - moment().valueOf(),
    "milliseconds"
  ); // calculate the remaining time using Moment.js
  const daysRemaining = Math.floor(remainingTime.asDays()); // calculate the number of days remaining
  const timeRemaining = moment.utc(remainingTime.asMilliseconds()).format("HH"); // format the time remaining
  const minutesRemaining = moment
    .utc(remainingTime.asMilliseconds())
    .format("mm"); // format the minutes remaining

  const secondsRemaining = moment
    .utc(remainingTime.asMilliseconds())
    .format("ss"); // format the seconds remaining
  const dateTimeObj = {
    days: daysRemaining,
    hours: timeRemaining,
    minutes: minutesRemaining,
    seconds: secondsRemaining,
  };
  return dateTimeObj;
};

//e.g 1000 will be 1,000
export const numberFormat = (number) => {
  return number?.toLocaleString("en-US");
};

//e.g 1,000 (string format) will be back to 1000 (number format)
export const numberFormatReverse = (number) => {
  return Number(number.replace(/,/g, ""));
};

export const isLessTime = (endtime) => {
  if (
    Number(unixToEndTimeSeconds(endtime).days) === 0 &&
    Number(unixToEndTimeSeconds(endtime).hours) === 0 &&
    Number(unixToEndTimeSeconds(endtime).minutes) < 5
  ) {
    return true;
  } else {
    return false;
  }
};

export const dateWithTime = (val) => {
  // Create a new Date object with the desired date and time
  const date = new Date(val);

  // Get the current time in milliseconds and add it to the date object
  date.setHours(new Date().getHours());
  date.setMinutes(new Date().getMinutes());
  date.setSeconds(new Date().getSeconds());

  // Get Unix timestamp (in seconds) for the specified date and time
  return Math.floor(date.getTime());
};

export const dateWithTimeUnix = (val) =>{
  // Parse the date string
const dateString = val;
const date = new Date(dateString);

// Get the Unix timestamp in milliseconds
const unixTimestampMillis = date.getTime();

// Convert to seconds
//const unixTimestampSeconds = unixTimestampMillis / 1000;
return unixTimestampMillis;
}

export const timeAgo = (endTime) => {
  const timestamp = endTime;
  const date = moment.unix(timestamp / 1000);
  // Calculate minutes ago
  const minutesAgo = moment().diff(date, "seconds");
  return minutesAgo;
};


export const timeAtWithDay = (endTime) => {
  const unixTimestamp = Math.floor(endTime / 1000); // Unix timestamp in seconds

  const getDateInArabic = () => {
    t("en") === "en" ? moment.locale("en") : moment.locale("ar"); // Set locale to Arabic
    const date = moment
      .unix(unixTimestamp)
      .format("dddd <b>DD/MM/YYYY</b> [في] <b>h:mm A</b>");
    moment.locale("en");
    return date;
  };

  const date = moment
    .unix(unixTimestamp)
    .format("dddd <b>DD/MM/YYYY</b> [at] <b>h:mm A</b>");

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: t("en") === "en" ? date : getDateInArabic(),
      }}
    ></span>
  );
  //return date; // Output: "Thursday 24/11/2022 at 6:30 PM"
};

export const getVariant = (val) => {
  if (val > 0 && val <= 50) {
    return "success";
  } else if (val > 50 && val <= 90) {
    return "warning";
  } else if (val > 90 && val <= 100) {
    return "danger";
  }
};


export function calulateCeil(highest, minimum) {
  //return Math.ceil(highest / minimum) * minimum + minimum;
  return highest + minimum;
}
export function calulateCeilCode(newAmount, highest, minimum) {
  if ((newAmount / minimum) % 1 === 0) {
    return newAmount;
  } else {
    const diff = newAmount - highest;
    const multiplier = Math.ceil(diff / minimum);
    newAmount = highest + (multiplier * minimum);
    return newAmount;
  }
}
export const getInputWidth = (value) => {
  const minWidth = 20; // minimum width of the input box
  const maxWidth = 40; // minimum width of the input box
  const val = value?.toString().length <= 5 ? minWidth : maxWidth;
  const width = val + +value?.toString().length * 10; // calculate width based on value length
  const finalWidth = width >= 150 ? 150 : width;
  return { width: `${finalWidth}px` };
};

export const getInputWidthLarge = (value) => {
  const minWidth = 40; // minimum width of the input box
  const maxWidth = 60; // minimum width of the input box
  const val = value?.toString().length <= 5 ? minWidth : maxWidth;
  const width = val + +value?.toString().length * 10; // calculate width based on value length
  const finalWidth = width >= 185 ? 185 : width;
  return { width: `${finalWidth}px` };
};

export function isWithin5Seconds(timestamp) {
  const currentTimestamp = Date.now();
  const difference = currentTimestamp - timestamp;

  // Calculate the time difference in milliseconds, seconds, minutes, and hours
  const milliseconds = difference % 1000;
  const seconds = Math.floor((difference / 1000) % 60);
  const minutes = Math.floor((difference / (1000 * 60)) % 60);
  const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  return seconds <= 15 && hours === 0 && minutes === 0;
}

export function addTimeToCart(pausedAt) {
  // Convert the Unix timestamps to Moment objects
  const pausedMoment = moment(parseInt(pausedAt));

  const durationToAdd = moment.duration(10, "minutes");

  // Add the time difference to the reserved moment
  const updatedReservedMoment = pausedMoment.add(durationToAdd);

  // Calculate the new reserved_at value as Unix timestamp
  const updatedReservedAt = updatedReservedMoment.valueOf();
  //console.log("val", updatedReservedAt);
  return updatedReservedAt;
}

export function chatCheck() {
  //var userDetails = decryptData("user-details");
  const encrypted = localStorage.getItem("user-details");

  var user = encrypted ? decryptData("user-details") : "{}";
  if (Object.keys(user).length !== 0) {
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "w9s1r2r7",
      name: user?.full_name, // Full name
      email: user?.email_id, // Email address
      created_at: user?.created_at, // Signup date as a Unix timestamp
      user_id: user?.id,
    };

    //Google Analytics User ID setting by calling the Google Analytics function
    setUserId(user?.id);


    window.hj("identify", user?.id, {
      // Add your own custom attributes here. Some EXAMPLES:
      // 'Signed up': '2019—06-20Z', // Signup date in ISO-8601 format.
      // 'Last purchase category': 'Electronics', // Send strings with quotes around them.
      // 'Total purchases': 15, // Send numbers without quotes.
      // 'Last purchase date': '2019-06-20Z', // Send dates in ISO-8601 format.
      // 'Last refund date': null, // Send null when no value exists for a user.
    });
  } else {
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "w9s1r2r7",
      name: null, // Full name
      email: null, // Email address
      created_at: null, // Signup date as a Unix timestamp
      user_id: null,
    };
    // Call the Intercom 'shutdown' method to end the session
    if (window.Intercom) {
      window.Intercom("shutdown");
    }
    window.hj("identify", null, {});
  }
}

export const encryptData = (name, data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    SECRET_KEY
  ).toString();
  localStorage.setItem(name, encrypted);
};

export const decryptData = (name) => {
  const encrypted = localStorage.getItem(name);
  if (encrypted?.charAt(0) === "{") {
    localStorage.removeItem("user-details");
    localStorage.removeItem("id-token");
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("auction_category");
    localStorage.removeItem("direct_category");
    window.location.href = "/";
  }
  const decrypted = encrypted
    ? CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8)
    : "{}";
  return JSON.parse(decrypted);
};

/**
 * Retrieves the user details by decrypting the encrypted data stored in the local storage.
 *
 * @return {Record<string, any> | null} The user details object containing the user's name and cost.
 */
export const getUserDetails = () => {
  const userDetails = decryptData("user-details");
  return userDetails;
}