import axios from "axios";
import jwtDecode from "jwt-decode";

const headers: any = {
  "Content-Type": "application/json;charset=utf-8",
  "Access-Control-Allow-Origin": "*",
  "ngrok-skip-browser-warning": "true",
};

let baseURL: any = "";
const url = window.location.href;
const hostname = window.location.hostname;

if (hostname.includes("staging")) {
  baseURL = process.env.REACT_APP_API_BASE_URL_STAGE;
} else if (hostname.includes("dev") || hostname.includes("localhost")) {
  baseURL = process.env.REACT_APP_API_BASE_URL_DEV;
} else {
  baseURL = process.env.REACT_APP_API_BASE_URL;
}

const defaultConfiguration: any = {
  baseURL: baseURL,
  headers,
};

export const api = axios.create(defaultConfiguration);

export const apiWithToken = axios.create(defaultConfiguration);

const refreshToken = async () => {
  try {
    const response = await api.post(`api/v1/customer/refresh-token`, {
      refreshToken: localStorage.getItem("refresh-token"),
      idToken: localStorage.getItem("id-token"),
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

apiWithToken.interceptors.request.use(
  async (config: any) => {
    const token = localStorage.getItem("id-token");
    if (token) {
      const decodedToken: any = jwtDecode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        const newToken = await refreshToken();        
        if (newToken?.data?.status === 400) {
          localStorage.removeItem("user-details");
          localStorage.removeItem("id-token");
          localStorage.removeItem("refresh-token");
          localStorage.removeItem("auction_category");
          localStorage.removeItem("direct_category");
          window.location.href ='/';
        }
        localStorage.setItem("id-token", newToken?.data?.data?.id_token);
        localStorage.setItem(
          "refresh-token",
          newToken?.data?.data?.refresh_token
        );
        config.headers["Authorization"] = newToken?.data?.data?.id_token;
      } else {
        config.headers.Authorization = localStorage.getItem("id-token");
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
