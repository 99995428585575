/* eslint-disable react-hooks/exhaustive-deps */
import Modal from "react-bootstrap/Modal";
import UserOtp from "./userotp";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/signup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../feature/envs/api";
import { useNavigate } from "react-router-dom";
import CrossMark from "../../assets/Icons/crossmark.svg";
import { chatCheck, encryptData } from "../../utils/functions";
import LockImage from "../../assets/Icons/lock.svg";
type Props = {
  handCloseOtpModal: (modaltype: any) => void;
  setConfirmation: (e?: any) => void;
  showOtp: boolean;
  setPhoneInvalid: any;
  showSignUp?: any;
  setShowSignUp?: any;
};

const ConfirmOtpModals = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [otp, setOtp] = useState<string[]>(new Array(4).fill(""));
  const [resetCounter, setResetCounter] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [counter, setCounter] = useState(30);
  const { user, setUser } = useContext<any>(UserContext);
  const [loading, showLoading] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);

  const resendOtp = () => {
    const requestOptionsPhone: any = {
      type: "PHONE_NUMBER",
      phone_number: user.phoneNumber,
      pinId: user.pinId,
    };
    api
      .post("api/v1/customer/resend-otp", requestOptionsPhone)
      .then((res: any) => {
        if (res.data.status === 200) {
          toast(<p className="CartSuccessMsg">{res.data.message}</p>, {
            autoClose: 3000,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        }
      })
      .catch(() =>
        toast(<p className="CartErrMsg">{t("cancel_payment")}</p>, {
          autoClose: 2500,
          hideProgressBar: true,
          toastId: "cartToast",
        })
      );
  };

  useEffect(() => {
    let interval: any = null;
    props.setPhoneInvalid(true);
    if (counter !== 0) {
      interval = setInterval(function () {
        setCounter(counter - 1);
      }, 1000);
    } else {
      setResetCounter(true);
    }
    return () => clearInterval(interval);
  }, [counter]);

  const handleOtp = (newOtp: any) => {
    setOtp(newOtp);
    let count = newOtp.reduce((c: any, s: any) => c + Boolean(s), 0);
    if (count === 4) {
      setInputDisabled(true);
      handleSignUpOtp(newOtp);
    }
  };

  const onClickReset = () => {
    resendOtp();
    setOtp(["", "", "", ""]);
    setCounter(30);
    setResetCounter(false);
  };

  const handleSignUpOtp = (newOtp: any) => {
    const requestOptionsPhone: any = {
      type: "PHONE_NUMBER",
      phone_number: user.phoneNumber,
      otp: newOtp.reduce((acc: any, cur: any) => `${acc}${cur}`, ""),
      update_user: false,
    };

    if (user?.pinId) {
      requestOptionsPhone.pinId = user?.pinId;
    }
    showLoading(true);
    api
      .post(`api/v1/customer/verify-otp`, requestOptionsPhone)
      .then((res: any) => {
        if (res.data.status === 200) {
          if (props?.setShowSignUp) {
            props?.setShowSignUp(false);
          }

          const token = res?.data?.data?.token?.response?.token?.idToken;
          const refreshToken =
            res?.data?.data?.token?.response?.token?.refreshToken;
          localStorage.setItem("id-token", token);
          localStorage.setItem("refresh-token", refreshToken);
          showLoading(false);
          encryptData("user-details", res?.data?.data?.user);
          showLoading(false);
          props.setConfirmation();
          setUser({
            ...user,
            userLoggedIn: true,
            userDetails: res.data.data.user,
          });
          navigate("/");
          window.location.reload();
          chatCheck();
        } else {
          showLoading(false);
          setHasError(true);
          setInputDisabled(false);
          toast(<p className="CartErrMsg">{t("incorrect_code")}</p>, {
            autoClose: 3000,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        }
      })
      .catch(() => {
        setHasError(true);
        showLoading(false);
        toast(<p className="CartErrMsg">{t("cancel_payment")}</p>, {
          autoClose: 2500,
          hideProgressBar: true,
          toastId: "cartToast",
        });
      });
  };

  return (
    <Modal
      show={props.showOtp}
      onHide={() => props.handCloseOtpModal("otpstop")}
      className="confirmdirection"
    >
      <div className="modal-header confirm-bottom">
        <div className="CloseButtons">
          <button
            className="bahrainarrow"
            onClick={() => props.handCloseOtpModal("otpstop")}
          >
            <img src={CrossMark} alt="crossmark" />
          </button>
        </div>
        <h2 className="confirmnumber">{t("confirm_number")}</h2>
      </div>
      <form>
        <p className="phnnumber">
          {t("code_sent")}:<span> {user.phoneNumber}</span>
        </p>
        <UserOtp
          hasError={hasError}
          handleOtp={handleOtp}
          setOtp={setOtp}
          otp={otp}
          inputDisabled={inputDisabled}
        />
        {resetCounter ? (
          <p className="time-limit">
            {t("did_not_recieve")}&nbsp;
            <span style={{ cursor: "pointer" }} onClick={onClickReset}>
              {t("resend_again")}
            </span>
          </p>
        ) : (
          <p className="time-limit">
            {t("code_request_again")}&nbsp;
            <span>00:{counter}</span>
          </p>
        )}

        <button
          type="submit"
          className={
            !otp.some((cur) => cur === "") ? "otp_btn_signup" : "disable_btn"
          }
          disabled={otp.some((cur) => cur === "") || loading}
          onClick={(e) => {
            showLoading(true);
            handleSignUpOtp(otp);
          }}
        >
          {loading ? (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <>
              <img src={LockImage} alt="lock" />
              <>{t("verify_number")}</>{" "}
            </>
          )}
        </button>
      </form>
    </Modal>
  );
};

export default ConfirmOtpModals;
