import { useTranslation } from "react-i18next";
import Stopwatch from "../../pages/stopwatch/stopwatch";
import years from "../../assets/Icons/years.svg";
type Props = {
  selectedAsset?: any;
};

const RegistrationRight = (props: Props) => {
  const { t } = useTranslation();
  const { purchase_end_time, asset_title, asset_id } =
    props?.selectedAsset ?? {};
  const size = "thumbnail"; // Dynamic property name

  const dummyImg_1_new = (
    <img
      src={
        props.selectedAsset?.asset_images &&
        props.selectedAsset?.asset_images.length > 0 &&
        props.selectedAsset?.asset_images[0]?.url[size]
      }
      alt="bahrain cartImage"
      className="bidswonbahrain"
    />
  );
  const dummyImg_2_new = (
    // <img src={dummyImg_2} alt="bahrain" className="bidswonbahrain" />
    <div className="bidswonbahrain customBg"></div>
  );
  const boxImg =
    props.selectedAsset?.asset_images &&
    props.selectedAsset?.asset_images.length > 0
      ? dummyImg_1_new
      : dummyImg_2_new;

  return (
    <div className="biddigwonsection">
      <div className="biddingswoncard">
        <div className="d-flex align-items-center custom_image">
          {boxImg}
          <div className="px-3">
            <h2 className="AuctionCardHeading">{asset_title}</h2>
            {asset_id && (
              <p className="calander">
                <img src={years} alt="calander" />
                <span>{asset_id}</span>
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="bidregister">
        <p className="pending_registration text-center">
          {t("time_remaining_to_complete_purchase")}{" "}
          <Stopwatch compName={"bidsWon"} endTime={purchase_end_time} />
        </p>
      </div>
    </div>
  );
};

export default RegistrationRight;
