import { Col, Container, Row } from "react-bootstrap";
import DirectsaleBahrainBottomCenter from "../../components/directsales/directsalebahrainbottomcenter";
import RegistrationPageDirection from "./registrationpagedirection";
import { useTranslation } from "react-i18next";
import BidsWonCardBahrain from "../../components/bidswoncards/bidswoncardbahrain";
import BidsWonCardBottom from "../../components/bidswoncards/bidswoncardbottom";
import RegistrationDocuments from "./registrationdocuments";
import RegistrationPaymentMethods from "./registrationpaymentmethods";
import { apiWithToken } from "../../feature/envs/api";
import { CSSProperties, useEffect, useState } from "react";
import MyBidsEmpty from "../mybids/mybidsempty";
import { ClipLoader } from "react-spinners";
import RegistrationRight from "../../components/directsales/registrationright";
import { useLocation, useNavigate } from "react-router-dom";
import Page from "../../assets/Icons/pages.svg";
import Arrow from "../../assets/Icons/bahrain-arrow.svg";
import noBids from "../../assets/Icons/noBids.svg";
import { v4 as uuidv4 } from "uuid";
import { trackPageView } from "../../utils/analytics";

type Props = {};

const OrderCompleteRegistrationPayment = (props: Props) => {
  const { t } = useTranslation();
  const [assetLoader, setAssetLoader] = useState(false);
  const [filteredAssetData, setFilteredAssetData] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState<any>([]);
  const [toggle, setToggle] = useState(false);

  const navigate = useNavigate();

  const override: CSSProperties = {
    display: "block",
    margin: "20% auto",
    borderColor: "black",
    overflow: "-moz-hidden-unscrollable",
    opacity: 0.1,
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cartid: any = searchParams?.get("cartid") || 0;
  const id = searchParams?.get("id") || selectedAsset?.asset_id;

  const pendingStatuses = [
    "pending_for_payment",
    "pending_for_payment_approval",
    "pending_registration",
    "pending_purchase",
  ];

  const cartgetApi = () => {
    apiWithToken.get(`/api/v1/order-details/${cartid}`).then((res: any) => {
      if (res.data.status === 200) {
        const bidsWon = res.data.data.direct_sale;
        const filteredPendingArray = bidsWon.filter((item: any) =>
          pendingStatuses.includes(item.status)
        );
        if (cartid > 0 && bidsWon[0]?.status === "pending_purchase" && bidsWon[0]?.transaction === null || bidsWon?.length == 0) {
          navigate(`/profile/purchases?val=${t("direct_sale")}`);
        }

        filteredPendingArray && setSelectedAsset(filteredPendingArray[0]);
        filteredPendingArray && setFilteredAssetData(filteredPendingArray);
        filteredPendingArray && setAssetLoader(false);
      }
    });
  };

  const getApi = () => {
    apiWithToken.get(`/api/v1/bids/my-bid`).then((res: any) => {
      if (res.data.status === 200) {
        const pendingStatuses = [
          "pending_for_payment",
          "pending_registration",
          "pending_for_payment_approval",
          "pending_purchase",
        ];
        const bidsWon = res?.data?.data?.bids_won;
        const filteredPendingArray = bidsWon.filter((item: any) =>
          pendingStatuses.includes(item.status)
        );
        let result;
        if (id > 0) {
          result = filteredPendingArray.find(
            (obj: any) => obj.asset_id === +id && id !== 0
          );
        } else {
          result = filteredPendingArray[0];
        }

        result && setSelectedAsset(result);
        result && setFilteredAssetData(filteredPendingArray);
        result && setAssetLoader(false);
      }
    });
  };

  useEffect(() => {
    trackPageView((window.location.pathname + window.location.search), "Complete Registration");
    window.scrollTo(0, 0); // Scroll to the top of the page
    setAssetLoader(true);
    cartid < 1 && getApi();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    cartid > 0 && setAssetLoader(true);
    cartid > 0 && cartgetApi();
  }, [cartid]);


  const setAsset = (asset: any) => {
    setSelectedAsset(asset);
    id > 0 && selectedAsset?.cart_id < 1 && navigate(`/complete-purchase?id=${asset.asset_id}`);
  };

  return (
    <Container>
      <Row>
        {(selectedAsset?.cart_id || selectedAsset?.asset_id) && (
          <RegistrationPageDirection
            current_page={
              cartid > 0
                ? t("order_number") + selectedAsset?.cart_id
                : t("complete_purchase")
            }
            previous_page={cartid > 0 ? t("your_purchases") : t("bids_won")}
          />
        )}
        <>
          <Col md={12} lg={12} className="RegistrationLeftSection">
            {assetLoader && (
              <ClipLoader
                color={"#fff"}
                loading={assetLoader}
                cssOverride={override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
          </Col>
        </>
        <Col md={6} lg={5} className="RegistrationLeftSection" key={uuidv4()}>
          {filteredAssetData?.length > 0 ? (
            <>
              {filteredAssetData?.map((asset: any, index: number) => {
                return (
                  <div
                    className="biddigwonsection"
                    onClick={() => setAsset(asset)}
                    key={uuidv4()}
                  >
                    <BidsWonCardBahrain
                      setSelectedAsset={setSelectedAsset}
                      asset={asset}
                      imgUrl={
                        pendingStatuses.includes(asset.status) ? Arrow : Page
                      }

                    />
                    <BidsWonCardBottom
                      highest_price={asset?.highest_price || asset?.asset_price}
                      end_time={asset?.purchase_end_time}
                      className={asset?.status}
                      name={t(asset?.status)}
                    />
                  </div>
                );
              })}
            </>
          ) : (
            <>{!assetLoader && <MyBidsEmpty imgUrl={noBids} title={"bid"} />}</>
          )}
        </Col>
        <Col md={6} lg={7} className="RegisterPaymentSection">
          {!assetLoader && (
            <div className="RegisterPaymentLeftSection">
              {(selectedAsset &&
                selectedAsset?.purchase_end_time &&
                selectedAsset?.category_title?.split(" ")[0] === "Taxi") ||
                selectedAsset?.category_title?.split(" ")[0] === "Car" ? (
                selectedAsset && (
                  <DirectsaleBahrainBottomCenter
                    selectedAsset={selectedAsset}
                  />
                )
              ) : (
                <RegistrationRight selectedAsset={selectedAsset} />
              )}
              {
                <RegistrationDocuments
                  selectedAsset={selectedAsset}
                  toggle={toggle}
                />
              }

              <RegistrationPaymentMethods
                selectedAsset={selectedAsset}
                toggle={toggle}
                setToggle={setToggle}
                id={id}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default OrderCompleteRegistrationPayment;
