import { Container, Row } from "react-bootstrap";
import BreadCrums from "../../components/breadcrumbs/breadcrums";
import CrossButton from "../../components/buttons/crossbutton";
import LeftArrow from "../../assets/Icons/left-arrow.svg";
import "./termsandcontact.scss";
import { useTranslation } from "react-i18next";
import { Fragment, useContext, useEffect } from "react";
import { UserContext } from "../../contexts/signup";
import { trackPageView } from "../../utils/analytics";

type Props = {};

const AllTopicsContact = (props: Props) => {
  const { t } = useTranslation();
  const { user } = useContext<any>(UserContext);
  const { contactDetails } = user;

  useEffect(() => {
    trackPageView(
      window.location.pathname + window.location.search,
      "Contact us"
    );
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const mapUrl = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3578.466481348634!2d${contactDetails?.longitude?.value}!3d${contactDetails?.latitude?.value}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49a5ef88933a91%3A0xe243933f6e4774e0!2sMazad%20Bahrain!5e0!3m2!1sen!2sus!4v1697179886847!5m2!1sen!2sus`;
  return (
    <Container>
      <Row>
        <div className="d-flex align-items-center profilereg biddingswoncard">
          <CrossButton className="prevarrow" imgUrl={LeftArrow} />
          <BreadCrums previousPage={t("home")} currentPage={t("contact_us")} />
        </div>
      </Row>
      <Row className="contactbox">
        {contactDetails && (
          <Fragment key={contactDetails?.settings_id}>
            <div className="locationbox">
              <iframe
                className="iframe-map"
                title="location"
                src={mapUrl}
                loading="lazy"
              ></iframe>
            </div>
            <div className="ContactTopics">
              <div className="d-flex justify-content-between ContactListItems">
                <p className="ContactName">{t("contact_number")}</p>
                <p
                  className="ContactDetails"
                  style={{ direction: "ltr" }}
                  data-hj-allow
                >
                  {t("en") === "en"
                    ? contactDetails?.contact_number?.value
                    : contactDetails?.contact_number?.value_ar}
                </p>
              </div>
              <div className="d-flex justify-content-between ContactListItems">
                <p className="ContactName">{t("email")}</p>
                <p className="ContactDetails">
                  {t("en") === "en"
                    ? contactDetails?.email?.value
                    : contactDetails?.email?.value_ar}
                </p>
              </div>
              <div className="d-flex justify-content-between ContactListItems">
                <p className="ContactName">{t("location")}</p>
                <p className="ContactName ContactNameArabic">
                  {t("en") === "en"
                    ? contactDetails?.location?.value
                    : contactDetails?.location?.value_ar}
                </p>
              </div>
              <div className="d-flex justify-content-between ContactListItems">
                <p className="ContactName">{t("timings")}</p>
                <p className="ContactName ContactNameArabic">
                  {t("en") === "en"
                    ? contactDetails?.timings?.value
                    : contactDetails?.timings?.value_ar}
                </p>
              </div>
            </div>
          </Fragment>
        )}
      </Row>
    </Container>
  );
};

export default AllTopicsContact;
