import "./inputfields.scss";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UpdateNumberModal from "../modals/updatenumbermodal";

type Props = {
  floatLabel: string;
  value: any;
  onChange?: (e: any) => void;
  edit: string;
  disable:Boolean;
  inputProps?: any;
  editbutton?:Boolean;
  numberAvailable?:Boolean
  EmailAvailable?:Boolean;
  handleModal?: (modaltype: any) => void;

};

const FloatLabelNumberEdit = (props: Props) => {  
  const { inputProps } = props;
  const { t } = useTranslation();
  const [number, setNumber] = useState<any>();
  useEffect(() => {
    let nameValue = localStorage.getItem("mobileno");
    setNumber(nameValue);
    localStorage.removeItem("email");
  }, []);

  return (
    <>
    <FloatingLabel controlId="floatingInput" label={props.floatLabel}>
      <Form.Control
        type="text"
        onChange={props.onChange}
        placeholder="name@example.com"
        value={props.value}
        className="editnumber"
        {...inputProps}
        disabled={props.disable}
        data-hj-allow
      />
      {
        props.editbutton && props.numberAvailable && (
          <h1 className="Edit-button" onClick={props.handleModal}>{t("edit")}</h1>
        )
      }
    
      
    </FloatingLabel>
    </>
  );
};

export default FloatLabelNumberEdit;
