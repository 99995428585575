import { Col, Container, Row } from "react-bootstrap";
import BreadCrums from "../../components/breadcrumbs/breadcrums";
import CrossButton from "../../components/buttons/crossbutton";
import ProfileButton from "../../components/buttons/profilebutton";
import LeftArrow from "../../assets/Icons/left-arrow.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { numberFormat, unixToDateTime } from "../../utils/functions";
import PaymentPending from "../../assets/Icons/payment-pending.svg";
import PaymentSucces from "../../assets/Icons/payment-success.svg";
import PaymentFailed from "../../assets/Icons/payment-failed.svg";
import { useTranslation } from "react-i18next";
import { CSSProperties, useEffect, useState } from "react";
import { apiWithToken } from "../../feature/envs/api";
import ClipLoader from "react-spinners/ClipLoader";
import PaymentRequest from "../internalrounting/paymentrequest";
import AllAccountDetails from "../internalrounting/allaccountdetails";
import ReachUsOptions from "../internalrounting/reachusoptions";
import { trackPageView } from "../../utils/analytics";

type Props = {};

const CompletePurchaseCheckout = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loader, setLoader] = useState(true);
  const [transactionData, setTransactionData] = useState<any>();
  const [counter, setCounter] = useState(6);

  function getTransactionData() {
    apiWithToken
      .put(
        `/api/v1/transaction/verify-payment?transaction_id=${searchParams.get(
          "paymentid"
        )}`
      )
      .then((res: any) => {
        if (res.data.status === 200) {
          setTransactionData(res.data.data);
          setLoader(false);
        }
      });
  }
  useEffect(() => {
    trackPageView((window.location.pathname + window.location.search), "Checkout");
    getTransactionData();
  }, []);

  const override: CSSProperties = {
    display: "block",
    margin: "20% auto",
    borderColor: "black",
    overflow: "-moz-hidden-unscrollable",
    opacity: 0.1,
  };
  const orderDetails = [
    {
      title: t("payment_details"),
      userDetails: [
        {
          name: t("payment_id"),
          cost: `${transactionData?.transaction_id}`,
        },
        {
          name: t("asset_id"),
          cost: transactionData?.id ? transactionData?.id : "0",
        },
        {
          name: t("asset_amount"),
          cost: `${transactionData?.asset_amount || 0}`,
        },
        {
          name: t("use_deposit_amount"),
          cost: transactionData?.use_deposit_amount ? "Yes" : "No",
        },
        {
          name: t("total_amount_paid"),
          cost: t("en") === "en" ? `BHD ${
            " " + numberFormat(transactionData?.used_deposited_amount)
          }` :  `${
            " " + numberFormat(transactionData?.used_deposited_amount) + " " + t("bhd")
          }`,
        },
        {
          name: t("payment_date"),
          cost: `${unixToDateTime(
            transactionData?.transaction_date || transactionData?.deposit_date
          )}`,
        },
        {
          name: t("payment_method"),
          cost: `${
            transactionData?.payment_method === "bank_transfer"
              ? "Bank Transfer"
              : "Cheque Transfer"
          }`,
        },
        {
          name: t("payment_status"),
          cost: `${
            transactionData?.status === "PENDING"
              ? "Processing.."
              : transactionData?.status
          }`,
        },
      ],
    },
  ];
  return (
    <Container>
      <Row>
        <Col>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center profilereg biddingswoncard">
              <CrossButton className="prevarrow" imgUrl={LeftArrow} />
              <BreadCrums previousPage="" currentPage="" />
            </div>
            <div>
              <ProfileButton value="View All Deposit Requests" />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        {loader ? (
          <ClipLoader
            color={"#fff"}
            loading={loader}
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <Col className="CheckoutSection">
            <div className="text-center UserPaymentStatus">
              {transactionData?.status === "PENDING" && (
                <img src={PaymentPending} alt="Pending" />
              )}
              {transactionData?.status === "DONE" && (
                <img src={PaymentSucces} alt="Success" />
              )}
              {transactionData?.status === "CANCELLED" && (
                <img src={PaymentFailed} alt="Cancel" />
              )}
              <h2 className="profileheading">
                {t("thank_you_for_your_payment")}
              </h2>
              <div>
                <span className="profileheading"> {t("bhd")}</span>
                <span className="UserPaidAmount">
                  {" " + numberFormat(transactionData?.used_deposited_amount)}
                </span>
              </div>
            </div>
            <PaymentRequest
              title=""
              text={t("pending_subtitle")}
            />
            {orderDetails.map(({ title, userDetails }) => (
              <div className="AccountDetails">
                <h2 className="PaymentMethodHeading">{title}</h2>
                <AllAccountDetails userDetails={userDetails} />
              </div>
            ))}
            <ReachUsOptions />
            <button
              className="confirm-otp-number"
              onClick={() => {
                navigate("/profile/deposits");
              }}
            >
              {counter >= 3 ? t("continue") : `${"00:" + counter}`}
            </button>
            ;
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default CompletePurchaseCheckout;
