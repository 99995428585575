import { t } from "i18next";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { UserContext } from "../../contexts/signup";
import { apiWithToken } from "../../feature/envs/api";
import SignInPhoneModal from "../modals/signinphonemodal";
import "./directsalescontact.scss";
import { EventLogger, EventNames } from "../../utils/EventLogger";

type Props = {
  imgUrl: string;
  type?: any;
  assetItem?: any;
  asset_id?: any;
  setShowSignIn?: any;
  setShowSupport?:any;
};

const DirectSalesContacts = (props: Props) => {
  const { user } = useContext<any>(UserContext);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);

  const openModal = (modaltype: any) => {
    modaltype === "signin" ? setShowSignIn(true) : setShowSignUp(true);
  };
  const closeModal = (modaltype: any) => {
    modaltype === "signin" ? setShowSignIn(false) : setShowSignUp(false);
  };

  const handleWatchlist = (event: any) => {
    if (props.type === "watchlist") {
      event.stopPropagation();
      if (!user.userLoggedIn) {
        setShowSignIn(true);
      } else {
        if (props?.assetItem?.is_added_to_watchlist) {
          apiWithToken
            .put(`/api/v1/watchlist/remove-from-watchlist`, {
              asset_id: props?.assetItem?.asset_id || +props?.asset_id,
            })
            .then((res: any) => {
              if (res.data.status === 200) {
                EventLogger.logEvent(EventNames.REMOVE_FROM_WATCHLIST, {asset_id: props?.assetItem?.asset_id || +props?.asset_id});
                toast(
                  <p className="CartErrMsgWatch">
                   {t('removed_from_watchlist')}
                  </p>,
                  {
                    autoClose: 2500,
                    hideProgressBar: true,
                    toastId: 'cartToast'
                  }
                );
              }
            });
        } else {
          apiWithToken
            .post(`/api/v1/watchlist/add-to-watchlist`, {
              asset_id: props?.assetItem?.asset_id || +props?.asset_id,
            })
            .then((res: any) => {
              if (res.data.status === 200) {
                EventLogger.logEvent(EventNames.ADD_TO_WATCHLIST, {asset_id: props?.assetItem?.asset_id || +props?.asset_id});
                toast(
                  <p className="CartSuccessMsg">
                   {t('added_to_watchlist')}
                  </p>,
                  {
                    autoClose: 3000,
                    hideProgressBar: true,
                    toastId: 'cartToast'
                  }
                );
              }
            });
        }
      }
    }

    if (props.type === "contact") { 
      props?.setShowSupport(true)
    }

  };
  return (
    <div
      className="DirectsaleContact d-flex justify-content-center align-items-center"
    >
      <img onClick={handleWatchlist} src={props.imgUrl} alt="minus" />
      <SignInPhoneModal
        handleCloseModal={closeModal}
        showSignIn={showSignIn}
        handleOpenModal={openModal}
        modalType={t('header_signin')}

      />
    </div>
  );
};

export default DirectSalesContacts;
