type Props = {
  name: string;
  className: string;
  onClick?: any;
  refund_applicable?: any;
  loading?: any;
};

const VerifyNumberButton = (props: Props) => {
  return (
    <button
      className={`${props.className}`}
      disabled={props?.refund_applicable && !props?.refund_applicable}
      onClick={props?.onClick}
    >
      {props.loading && (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}{" "}
      {props.name}
    </button>
  );
};

export default VerifyNumberButton;
