import { t } from "i18next";
import { numberFormat } from "../../utils/functions";

type Props = {
  value: string;
  data?: any;
};

const DirectSaleBottom = (props: Props) => {
  return (
    <div className="bidcardbottomsale">
      {t("bhd") === "BHD " ? (
        <h2 className="cardbidsecondtitle">
          {props.value} {numberFormat(props?.data?.asset_price)}
        </h2>
      ) : (
        <h2 className="cardbidsecondtitle">
          {numberFormat(props?.data?.asset_price)} {props.value}{" "}
        </h2>
      )}
    </div>
  );
};

export default DirectSaleBottom;
