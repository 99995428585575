import AddCart from "../../assets/Icons/directsales-cart.svg";
import BlurCart from "../../assets/Icons/blur-cart.svg";
import DirectsalesTopIcons from "./directsalestopicons";
import DirectSaleAddToCart from "../../components/directsales/directsaleaddtocart";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import "./directsales.scss";
import "../auctionbidding/auctionbidding.scss";
import { decryptData, numberFormat } from "../../utils/functions";
import carplateImg from "../../assets/Icons/car details.svg";
import { apiWithToken } from "../../feature/envs/api";
import { useCallback, useContext, useEffect, useState } from "react";
import { CartWarning } from "../../contexts/signup";
import GreenCart from "../../assets/Icons/greenCart.svg";
import DocumentsSection from "../../components/directsales/documentssection";
import { connectSocket, disconnectSocket } from "../../utils/socket";
import { CartCount } from "../../contexts/signup";
import { Container, Modal } from "react-bootstrap";
import ProfileSocialContact from "../../components/profile/profilesocialcontact";
import HelpContact from "../../assets/Icons/help-contact.svg";
import LeftArrow from "../../assets/Icons/left-arrow.svg";
import { trackPageView } from "../../utils/analytics";
import CategoryAssetDetailsOneData from "../auctionbidding/categoryassetdetailsonedata";
import CalendarImg from "../../assets/Icons/calander-timing.svg";
import ViewMoreModal from "../auctionbidding/viewmoremodal";
import Taxiplatelong from "../../assets/Icons/taxi_long.svg";
import { EventLogger, EventNames } from "../../utils/EventLogger";

let socket: any = null;
let assetsArr: any = [];

type Props = {
  data?: any;
};

const DirectSaleProductdetails = (props: Props) => {
  const location = useLocation();
  const [assetItem, setAssetItem] = useState(location?.state?.assetItem);
  const [asset_title, setAssetTitle] = useState(
    location?.state?.assetItem?.asset_title
  );
  const { setCartWarning } = useContext<any>(CartWarning);
  const { setCartCount } = useContext<any>(CartCount);
  const [showSupport, setShowSupport] = useState<any>(false);
  const { id }: any = useParams();
  const [assetImages, setAssetImages] = useState<any>([]);
  const { t } = useTranslation();
  const [fullscreenview, setFullscreenView] = useState<any>(true);
  const [showViewMore, setViewMore] = useState(false);
  const stateVal =
    assetItem && assetItem?.description && assetItem?.asset_details
      ? false
      : assetItem?.description && !assetItem?.asset_details
      ? true
      : false;
  const [description, setDescription] = useState(stateVal);
  const longPlate =
    assetItem?.category_title?.split(" ")[0] === "Car"
      ? carplateImg
      : Taxiplatelong;
  const classVal =
    assetItem?.category_title?.split(" ")[0] === "Car"
      ? "box_img66"
      : "box_img77";

  type AnyRecord = Record<string, any>;
  const objDetails: AnyRecord =
    assetItem && t("en") === "en"
      ? assetItem?.asset_details
      : assetItem?.asset_details_ar
      ? assetItem?.asset_details_ar
      : [];

  const myElements =
    assetItem &&
    assetItem?.asset_details &&
    Object.entries(objDetails).map(([key, value], index) => {
      return (
        index < 5 && (
          <div
            className={`${
              !description ? "" : "d-none"
            } d-flex justify-content-between align-items-center PropertyAddress`}
            key={index}
          >
            <p className="AssetType">{key}</p>
            <p className="AssetAddress">{value.toString()}</p>
          </div>
        )
      );
    });

  useEffect(() => {
    trackPageView(
      window.location.pathname + window.location.search,
      "Asset Details"
    );
    window.scrollTo(0, 0); // Scroll to the top of the page
    assetsArr = [];
    socket = connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    addSocketListeners();
    return () => {
      socket.off("add-to-watchlist", addWatchlist);
      socket.off("remove-from-watchlist", removeWatchlist);
      socket.off("category-asset", getCategoryAssetSocketCall);
      socket.off("add-to-cart", handleOnAssetAddedToCart);
      socket.off("add-to-cart", addRemoveCartSocketCall);
      socket.off("remove-from-cart", handleOnAssetRemovedFromCart);
      socket.off("remove-from-cart", addRemoveCartSocketCall);
      socket.off("my-cart-details", getMyCartDetails);
    };
  }, [socket]);

  const addSocketListeners = () => {
    if (socket !== null) {
      socket.on("add-to-watchlist", addWatchlist);
      socket.on("remove-from-watchlist", removeWatchlist);
      socket.on("category-asset", getCategoryAssetSocketCall);
      socket.on("add-to-cart", handleOnAssetAddedToCart);
      socket.on("add-to-cart", addRemoveCartSocketCall);
      socket.on("remove-from-cart", handleOnAssetRemovedFromCart);
      socket.on("remove-from-cart", addRemoveCartSocketCall);
      socket.on("my-cart-details", getMyCartDetails);
    }
  };

  const getMyCartDetails = useCallback((res: any) => {
    let count: any = null;
    if (res?.data?.cart && res?.data?.cart?.items?.length) {
      count = res?.data?.cart?.items?.length;
    } else {
      count = null;
    }
    setCartCount(count);
  }, []);

  const addWatchlist = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("asset-by-category", {
        asset_id: res.data.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const removeWatchlist = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("asset-by-category", {
        asset_id: res.data.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const getCategoryAssetSocketCall = useCallback((res: any) => {
    const data = res.data[0];
    assetsArr =
      assetsArr &&
      assetsArr.map((item: any) => {
        if (item.asset_id === data.asset_id) {
          return {
            ...item,
            is_added_to_watchlist: data.is_added_to_watchlist,
          };
        } else {
          return item;
        }
      });
    setAssetItem(data);
  }, []);

  const addRemoveCartSocketCall = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("my-cart", {
        authorization: authToken,
      });
    }
  }, []);

  const handleOnAssetAddedToCart = useCallback((res: any) => {
    const data = res.data;

    const userDetails = decryptData("user-details");
    const userId = userDetails?.id;
    
    assetsArr = assetsArr && assetsArr.map((item: any) => {
      if (item.asset_id == data.asset_id && userId == data.user_id) {
        return {
          ...item,
          is_available_for_cart: false,
          in_my_cart: true,
        };
      } else if (item.asset_id == data.asset_id ) {
        return {
          ...item,
          is_available_for_cart: false,
          in_my_cart: false,
        };
      }else {
        return item;
      }
    })
    setAssetItem(assetsArr[0]);
  }, []);

  const handleOnAssetRemovedFromCart = useCallback((res: any) => {
    const data = res.data;

    assetsArr = assetsArr && assetsArr.map((item: any) => {
      if (item.asset_id == data.asset_id ) {
        return {
          ...item,
          is_available_for_cart: true,
          in_my_cart: false,
        };
      }else {
        return item;
      }
    })

    setAssetItem(assetsArr[0]);
  }, []);

  const getAsset = () => {
    apiWithToken.get(`/api/v1/asset/${id}`).then((res: any) => {
      if (res.data.status === 200) {
        EventLogger.logEvent(EventNames.VIEW_ASSET, {asset_id: id});
        setAssetTitle(res?.data?.data[0]?.asset_title);
        setAssetItem(res?.data?.data[0]);
        assetsArr = [res?.data?.data[0]];
        setAssetImages(res?.data?.data[0]?.asset_images);
      }
    });
  };

  useEffect(() => {
    getAsset();
  }, [asset_title]);

  useEffect(() => {
    setCartWarning(
      assetItem?.in_my_cart === false &&
        assetItem?.is_available_for_cart === false
    );
  }, [assetItem]);

  const btnText = assetItem?.in_my_cart
    ? t("remove_from_cart")
    : assetItem?.is_available_for_cart
    ? t("add_to_cart")
    : t("unavailable");
  const cartImg = assetItem?.in_my_cart
    ? GreenCart
    : assetItem?.is_available_for_cart
    ? AddCart
    : BlurCart;
  const classNameVal = assetItem?.in_my_cart
    ? "remove_from_cart"
    : assetItem?.is_available_for_cart
    ? "signin-phone"
    : "disable_btn";

  const cat_title =
    assetItem && t("en") === "en"
      ? assetItem?.category_title
      : assetItem?.category_title_ar;

  let content = null;

  const imgIcon =
    assetItem?.category_title === "Vehicles" ? CalendarImg : Location;
  const words =
    assetItem?.description && assetItem?.description.trim().split(" ");
  const wordLimit = 140;

  let descriptions =
    words && words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : assetItem?.description;

  let descriptions_ar =
    words && words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : assetItem?.description_ar;

  const DetailsDescription =
    assetItem && assetItem?.asset_details && assetItem?.description;
  function handleShowView(breakpoint: any) {
    setFullscreenView(breakpoint);
    setViewMore(true);
  }
  const showDescription = () => {
    setDescription(!description);
  };

  const displayCond =
    t("en") === "en"
      ? (assetItem && assetItem?.asset_details) || assetItem?.description
      : (assetItem && assetItem?.asset_details_ar) || assetItem?.description_ar;

  let carTaxiContent = (
    <>
      {" "}
      <DirectsalesTopIcons
        currentPage={cat_title}
        assetItem={assetItem}
        end_title={assetItem?.asset_title}
        setShowSupport={setShowSupport}
      />{" "}
      <div className="DirectSaleProductdetails">
        <div className="container_plate_new">
          <div className="detail_img">
            <img src={longPlate} alt="bahrain" className="w-100" />

            <div className={classVal}>
              <span className="detailfont">{assetItem?.asset_title}</span>
            </div>
          </div>
        </div>

        <div className="BhdData">
          <h2 className="DirectSaleBhd">
            {t("bhd")} <span>{numberFormat(assetItem?.asset_price)}</span>
          </h2>
        </div>
        <DirectSaleAddToCart
          endTime={assetItem?.end_time}
          asset_id={assetItem?.asset_id}
          btnImg={cartImg}
          btnValue={btnText}
          classNameVal={classNameVal}
        />

        {displayCond && (
          <div className="AssetDetailsLeft">
            {((assetItem && assetItem?.asset_details) ||
              assetItem?.description) && (
              <div className="AssetDetailsDescription">
                <div className="AssetDetailsAddress">
                  <div className="d-flex flex-md-row flex-column flex-lg-column flex-xl-row justify-content-between align-items-center DetailsDescriptionButtons">
                    {t("en") === "en"
                      ? assetItem &&
                        assetItem?.asset_details && (
                          <button
                            className={`${
                              !description
                                ? "DetailsButton w-100"
                                : "DescriptionButton"
                            }`}
                            onClick={DetailsDescription && showDescription}
                          >
                            {t("details")}
                          </button>
                        )
                      : assetItem &&
                        assetItem?.asset_details_ar && (
                          <button
                            className={`${
                              !description
                                ? "DetailsButton w-100"
                                : "DescriptionButton"
                            }`}
                            onClick={DetailsDescription && showDescription}
                          >
                            {t("details")}
                          </button>
                        )}

                    {t("en") === "en"
                      ? assetItem &&
                        assetItem?.description &&
                        assetItem?.asset_details && (
                          <button
                            className={`${
                              description
                                ? "DetailsButton w-100"
                                : "DescriptionButton"
                            }`}
                            onClick={showDescription}
                          >
                            {t("description")}
                          </button>
                        )
                      : assetItem &&
                        assetItem?.description_ar &&
                        assetItem?.asset_details_ar && (
                          <button
                            className={`${
                              description
                                ? "DetailsButton w-100"
                                : "DescriptionButton"
                            }`}
                            onClick={showDescription}
                          >
                            {t("description")}
                          </button>
                        )}

                    {t("en") === "en"
                      ? assetItem &&
                        assetItem?.description &&
                        !assetItem?.asset_details && (
                          <button
                            className={`${
                              !description
                                ? "DetailsButton w-100"
                                : "DescriptionButton"
                            }`}
                          >
                            {t("description")}
                          </button>
                        )
                      : assetItem &&
                        assetItem?.description_ar &&
                        !assetItem?.asset_details_ar && (
                          <button
                            className={`${
                              !description
                                ? "DetailsButton w-100"
                                : "DescriptionButton"
                            }`}
                          >
                            {t("description")}
                          </button>
                        )}
                  </div>

                  {assetItem && assetItem?.asset_details && myElements}
                  {assetItem &&
                    assetItem?.description &&
                    assetItem?.asset_details && (
                      <div className={`${description ? "" : "d-none"}`}>
                        <p className="AssetDescriptionText">
                          {t("en") === "en"
                            ? assetItem?.description && descriptions
                            : assetItem?.description && descriptions_ar}
                        </p>
                      </div>
                    )}
                  {assetItem &&
                    assetItem?.description &&
                    !assetItem?.asset_details && (
                      <div className={`${!description ? "" : "d-none"}`}>
                        <p className="AssetDescriptionText">
                          {t("en") === "en"
                            ? assetItem?.description && descriptions
                            : assetItem?.description && descriptions_ar}
                        </p>
                      </div>
                    )}
                </div>

                {t("en") === "en"
                  ? assetItem &&
                    (assetItem?.asset_details || assetItem?.description) && (
                      <div
                        className="ViewMoreButton"
                        onClick={() => handleShowView("sm-down")}
                      >
                        <a href="#">{t("view_more")}</a>
                      </div>
                    )
                  : assetItem &&
                    (assetItem?.asset_details_ar ||
                      assetItem?.description_ar) && (
                      <div
                        className="ViewMoreButton"
                        onClick={() => handleShowView("sm-down")}
                      >
                        <a href="#">{t("view_more")}</a>
                      </div>
                    )}
              </div>
            )}
          </div>
        )}

        {assetItem?.asset_doc ? (
          <DocumentsSection
            heading={t("documents")}
            docList={assetItem?.asset_doc}
          />
        ) : (
          <h1 style={{ marginTop: "10px" }}></h1>
        )}
      </div>
    </>
  );

  if (["Taxi", "Car"].includes(assetItem?.category_title?.split(" ")[0])) {
    content = carTaxiContent;
  } else {
    content = <CategoryAssetDetailsOneData type="directsale" />;
  }

  return (
    <>
      <Container>
        {content}
        <Modal
          show={showSupport}
          onHide={() => setShowSupport(false)}
          className="NotificationModal"
        >
          <Modal.Header className="d-flex align-items-center">
            <div className="CloseButtons">
              <button
                className="bahrainarrow"
                onClick={() => setShowSupport(false)}
              >
                <img src={LeftArrow} alt="LeftArrow" />
              </button>
            </div>

            <div className="HeadingContainer">
              <h2 className="confirmnumber">{t("help_contact")}</h2>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column align-items-center">
              <img src={HelpContact} alt="help" className="HelpAndContactImg" />
              <h2 className="AutoBidHeading">{t("need_help")}</h2>
            </div>
            <ProfileSocialContact />
          </Modal.Body>
        </Modal>

        {assetItem && (
          <ViewMoreModal
            showViewMore={showViewMore}
            setViewMore={setViewMore}
            fullscreenview={fullscreenview}
            assetDetails={assetItem}
            viewName={
              assetItem.description && assetItem.asset_details
                ? !description
                  ? "DetailsButton"
                  : "DescriptionButton"
                : assetItem.description
                ? "DescriptionButton"
                : assetItem.asset_details
                ? "DetailsButton"
                : null
            }
          />
        )}
      </Container>
    </>
  );
};

export default DirectSaleProductdetails;
