import { useTranslation } from "react-i18next";
import Clock from "../../assets/Icons/directsales-clock.svg";
import Cube from "../../assets/Icons/directsales-cube.svg";
import { useNavigate, useParams } from "react-router-dom";
import { timeAgo, timeAtWithDay } from "../../utils/functions";
import SignInPhoneModal from "../../components/modals/signinphonemodal";
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/signup";
import HighestBidderWhite from "../../assets/Icons/highestbidder-white.svg";
import BidTimeUp from "../../assets/Icons/bid-timeup.svg";

type Props = {
  btnImg: string;
  btnValue: string;
  handleShow?: any;
  end_time?: any;
  asset_id?: any;
  is_highest_bidder?: any;
  total_limit?: any;
  consumed_limit_percentage?: any;
  highest_price?: any;
  highestStart?: any;
  makeBidId?: any;
};

const CarPlateBidButton = (props: Props) => {
  const { user, setUser } = useContext<any>(UserContext);
  const [showSignIn, setShowSignIn] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const openModal = () => {
    setShowSignIn(true);
  };
  const closeModal = () => {
    setShowSignIn(false);
  };
  const outBidderUI = (
    <div className="overlayOut">
      <div className="overlay-colorOut">
        <img src={HighestBidderWhite} alt="You've been outbid" />
        {t("you_been_outbid")}
      </div>
    </div>
  );
  const handleTopUpClick = () => {
    navigate("/profile/deposits");
  };

  const topUpNowCheck =
    props?.consumed_limit_percentage === 100 ||
    props?.total_limit === 0 ||
    props?.total_limit <= props?.highest_price;

  const topUPUI = topUpNowCheck && (
    <button className="TopUpNowButton mt-5" onClick={handleTopUpClick}>
      <img src={BidTimeUp} alt="Top Up Now" />
      {t("top_up_now")}
      {props?.is_highest_bidder === false && outBidderUI}
    </button>
  );

  return (
    <div className="BhdData">
      <p className="CartText d-flex">
        <img src={Cube} alt="cube" className="AssetCartIcons" />
        {id}
      </p>
      <div className="d-sm-flex justify-content-between">
        <p className="CartText d-flex">
          <img src={Clock} alt="clock" className="AssetCartIcons" />
          <span> {timeAtWithDay(props?.end_time)}</span>
        </p>
      </div>

      {props?.highestStart ? (
        <button
          className={`HigestBidderButton mt-5
              }`}
          onClick={() => props.handleShow("sm-down")}
        >
          <img src={HighestBidderWhite} alt="Highest Bidder" />
          {" " + t("you_are_the_highest_bidder")}
        </button>
      ) : !props?.highestStart && user.userLoggedIn ? (
        props?.is_highest_bidder === true ? (
          <button
            className={`HigestBidderButton mt-5
              }`}
            onClick={() => props.handleShow("sm-down")}
          >
            <img src={HighestBidderWhite} alt="Highest Bidder" />
            {" " + t("you_are_the_highest_bidder")}
          </button>
        ) : topUpNowCheck ? (
          topUPUI
        ) : (
          <button
            disabled={timeAgo(props?.end_time) >= 0}
            className={
              timeAgo(props?.end_time) >= 0
                ? "signin-phone disable_btn mt-5"
                : "signin-phone mt-5"
            }
            onClick={() => props.handleShow("sm-down")}
          >
            <img src={props.btnImg} alt="lock" />
            {props.btnValue}
            {props?.is_highest_bidder === false && outBidderUI}
          </button>
        )
      ) : (
        <>
          <button
            disabled={timeAgo(props?.end_time) >= 0}
            className={
              timeAgo(props?.end_time) >= 0
                ? "signin-phone disable_btn"
                : "signin-phone"
            }
            onClick={openModal}
          >
            <img src={props.btnImg} alt="lock" />
            {props.btnValue}
          </button>
        </>
      )}
      <SignInPhoneModal
        handleCloseModal={closeModal}
        showSignIn={showSignIn}
        handleOpenModal={openModal}
        modalType={t('header_signin')}
      />
    </div>
  );
};

export default CarPlateBidButton;
