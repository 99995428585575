/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import snsWebSdk from "@sumsub/websdk";
import { apiWithToken } from "../../feature/envs/api";
import "./completereg.scss";
import { useTranslation } from "react-i18next";

type Props = {
  showCurrentStatus: () => void;
};

function Kycverification(props: Props) {
  const [token, updateToken] = useState("");
  const { t } = useTranslation();
  const userlang: any = t("en") == "en" ? "en" : "ar";

  const fetchAccessToken = () => {
    apiWithToken
      .post(`api/v1/customer/generate-sumsub-token`, {})
      .then((result) => {
        updateToken(result.data.data.token);
      })
      .catch(() => { });
  };

  useEffect(() => {
    fetchAccessToken();
    return () => updateToken("");
  }, []);

  useEffect(() => {
    if (token !== "") {
      launchWebSdk(token);
    }
  }, [token]);

  function launchWebSdk(accessToken: string) {
    const documentDefinitions: any = {
      IDENTITY: { idDocType: "ID_CARD", country: "IND" },
    };
    const snsWebSdkInstance = snsWebSdk
      .init(accessToken, async () => getNewAccessToken())
      .withConf({
        lang: `${userlang}`,
        documentDefinitions,
        i18n: {
          document: {
            subTitles: {
              IDENTITY: "Upload a document that proves your identity",
            },
          },
        },
        uiConf: {
          customCssStr:
            ":root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}",
        },
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      .on("idCheck.applicantStatus", (payload: any) => {
        if (payload.reviewStatus === "completed") {
          props.showCurrentStatus();
        }
      })
      .on("idCheck.onError", (error: any) => {
        console.log("onError", error);
      })
      .onMessage((type: any, payload: any) => {
        console.log("onMessage", type, payload);
      })
      .build();
    snsWebSdkInstance.launch("#sumsub-websdk-container");
  }

  function getNewAccessToken() {
    return Promise.resolve(token);
  }

  return (
    <>
      {token !== "" && (
        <div className="sumsub-container" id="sumsub-websdk-container" />
      )}
    </>
  );
}

export default Kycverification;
