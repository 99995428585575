import { createContext } from "react";

export type User = {
  email: string;
  phoneNumber: number;
  method: string;
  navLinkEnable: boolean;
};

export interface UserContextInterface {
  user: any;
  setUser: () => {};
}

export const UserContext = createContext({
  user: {},
  setUser: (value: any) => {
    return value;
  },
});

export const AllDataContext = createContext({
  allData: {},
  setAllData: (value: any) => {
    return value;
  },
});

export const CartCount = createContext({
  count: null,
  setCartCount: (value: any) => {
    return value;
  },
});

export const CartWarning = createContext({
  status: false,
  setCartWarning: (value: any) => {
    return value;
  },
});
