import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { numberFormat, unixToDateTime } from "../../utils/functions";

type Props = {
  imgUrl: string;
  name: string;
  className: string;
  amount: string;
  date?: string;
  detail?: any;
  payment_method?:any;
  used_deposited_amount?:any;
};

const TransectionCards = (props: Props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (props?.detail?.transaction_id) {
      navigate(`/checkout?paymentid=${props?.detail?.transaction_id}`, {
        state: props?.detail,
      });
    }
  };
  return (
    <div className="biddigwonsection">
      <div className="biddingswoncard d-flex justify-content-between align-items-center">
        {t("en") === "en" ? (
          <h2 className="TransectionAmount">
            {t("bhd")} {numberFormat(props?.payment_method === 'wallet' ? props?.used_deposited_amount > 0 ? props?.used_deposited_amount : props?.amount : props?.amount)}
          </h2>
        ) : (
          <h2 className="TransectionAmount">
            {numberFormat(props?.payment_method === 'wallet' ? props?.used_deposited_amount > 0 ? props?.used_deposited_amount : props?.amount : props?.amount)} {t("bhd")}
          </h2>
        )}

        <button className="bahrainarrow" onClick={handleClick}>
          <img src={props.imgUrl} alt="rightarrow" />
        </button>
      </div>
      <div className="d-flex justify-content-between bidregister">
        <div className="d-flex align-items-center TimingSection">
          <img src="../assets/Icons/calander-timing.svg" alt="calander" />
          <p>{unixToDateTime(props?.date)}</p>
        </div>
        <p className={`${props.className}`}>{props.name}</p>
      </div>
    </div>
  );
};

export default TransectionCards;
