import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IosButton from "../../components/buttons/iosbutton";
import AndroidButton from "../../components/buttons/androidbutton";
import "./home.scss";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/signup";
import { useContext } from "react";

const HomeBanner = () => {
  const { t } = useTranslation();
  const { user } = useContext<any>(UserContext);
  const { contactDetails } = user;
  return (
    <Row>
      <Col>
        <div className="home-banner">
          <h2 className="banner-heading">{t("banner_heading")}</h2>
          <p className="banner-text">{t("banner_text")}</p>
          <div className="bannerbuttons">
            <IosButton
              className="iosbutton home-iosbutton"
              imgUrl="./assets/Icons/iphone.svg"
              apple_store_link={contactDetails?.apple_store_link}
            />
            <AndroidButton
              className="iosbutton"
              imgUrl="./assets/Icons/android.svg"
              google_store_link={contactDetails?.google_store_link}
            />
          </div>
          <div className="banner-iphone13">
            <img
              src={
                t("en") === "en"
                  ? "./assets/Images/iPhone13Pro.png"
                  : "./assets/Images/iPhone13Pro.png"
              }
              className="iphone13"
              alt="phone"
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default HomeBanner;
