import redWatchlistIcon from "../../assets/Icons/details-red.svg";
import watchlistIcon from "../../assets/Icons/details.svg";
import { apiWithToken } from "../../feature/envs/api";
import { toast } from "react-toastify";
import Cart from "../../assets/Icons/storage.svg";
import CartDisabled from "../../assets/Icons/CartDisabled.svg";
import CarPlate from "../../assets/Icons/Car Plate.svg";
import GreenCart from "../../assets/Icons/greenCart.svg";
import { decryptData, numberFormat } from "../../utils/functions";
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/signup";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { AssetTag } from "../tags/AssetTag";
import TaxiPlate from "../../assets/Icons/taxi_plate.svg";
import { EventLogger, EventNames } from "../../utils/EventLogger";

type Props = {
  data?: any;
  handleWatchlist?: any;
  type?: any;
  index?: any;
  setShowSignIn?: any;
};

const DirectSaledGridCardTop = (props: Props) => {
  const { user } = useContext<any>(UserContext);
  const navigate = useNavigate();
  const [addToCartInProgress, setAddToCartInProgress] = useState(false);
  const [removeFromCartInProgress, setRemoveFromCartInProgress] =
    useState(false);
  const addToCart = () => {
    // Check if addToCart is already in progress
    if (addToCartInProgress) {
      return;
    }
    // Set the flag to indicate that addToCart is in progress
    setAddToCartInProgress(true);
    apiWithToken
      .post(`/api/v2/cart/add-to-cart`, { asset_id: props?.data?.asset_id })
      .then((res: any) => {
        if (res.data.status === 200) {
          EventLogger.logEvent(EventNames.ADD_TO_CART, {asset_id: props?.data?.asset_id});
          toast(<p className="CartSuccessMsg">{t("item_added_to_cart")}</p>, {
            autoClose: 3000,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        } else {
          toast(<p className="CartErrMsgLarge">{res.data.message}</p>, {
            autoClose: 2500,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        }
      })
      .finally(() => {
        setTimeout(() => {
          setAddToCartInProgress(false);
        }, 1000);
      });
  };

  const removeFromCart = () => {
    if (removeFromCartInProgress) {
      return;
    }
    // Set the flag to indicate that addToCart is in progress
    setRemoveFromCartInProgress(true);
    apiWithToken
      .put(`/api/v2/cart/remove-from-cart`, { asset_id: props?.data?.asset_id })
      .then((res: any) => {
        if (res.data.status === 200) {
          EventLogger.logEvent(EventNames.REMOVE_FROM_CART, {asset_id: props?.data?.asset_id});
          toast(<p className="CartErrMsg">{res.data.message}</p>, {
            autoClose: 2500,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        } else {
          toast(<p className="CartErrMsg">{res.data.message}</p>, {
            autoClose: 2500,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        }
      })
      .finally(() => {
        setTimeout(() => {
          setRemoveFromCartInProgress(false);
        }, 1000);
      });
  };

  const handleCart = (event: any) => {
    event?.stopPropagation();
    if (!user.userLoggedIn) {
      props.setShowSignIn(true);
    } else {
      const userDetails = decryptData("user-details");

      if (
        userDetails?.identity_verification_status !== true &&
        userDetails?.doc_review_status !== "completed" &&
        userDetails?.full_name === null
      ) {
        navigate("/registration");
      } else if (
        userDetails?.identity_verification_status !== true &&
        userDetails?.doc_review_status !== "completed" &&
        userDetails?.full_name !== null
      ) {
        navigate("/verify-user");
      } else {
        if (props?.data?.is_available_for_cart) {
          addToCart();
        } else {
          removeFromCart();
        }
      }
    }
  };

  const handleImageError = (e: any) => {
    const img = e.target;
    img.style.display = "none"; // Hide the failed image
  };

  const size = "small"; // Dynamic property name
  const asset_title =
    t("en") === "en" ? props?.data?.asset_title : props?.data?.asset_title_ar;
  const ImagePlate =
    props?.data?.category_title.split(" ")[0] === "Taxi" ? TaxiPlate : CarPlate;
  const dummyImg_1_new = (
    <div className="taxi_parent_container">
      <div className="parent_taxi">
        <div className="box_img_new1">
          <h1 className="font_text">{props?.data?.asset_title}</h1>
        </div>
        <img src={ImagePlate} alt="bahrain" />
      </div>
    </div>
  );

  const dummyImg_2_new =
    props?.data?.asset_images && props?.data?.asset_images.length > 0 ? (
      <img
        loading="lazy"
        src={props?.data?.asset_images[0]?.url[size]}
        alt={props?.data?.asset_title}
        className="cartImage"
        onError={handleImageError}
      />
    ) : (
      <div className="cartImage customBg"></div>
    );
  const boxImg =
    props?.data?.category_title?.split(" ")[0] === "Taxi" ||
    props?.data?.category_title?.split(" ")[0] === "Car"
      ? dummyImg_1_new
      : dummyImg_2_new;
  const assetTitle =
    props?.data?.category_title?.split(" ")[0] === "Taxi" ||
    props?.data?.category_title?.split(" ")[0] === "Car"
      ? ""
      : asset_title;

  return (
    <div className="ongoingcard">
      <div className="imgessectionicons">
        {props?.data?.tag && (
          <AssetTag
            tag={props.data?.tag}
            discountPercentage={props.data?.discount_percentage}
          />
        )}
        <div className="container_font custom_image">{boxImg}</div>
        <div className="d-flex justify-content-between iconssection ArabicBottomColors">
          <div
            className="bidicons"
            onClick={(event: any) => props.handleWatchlist(event, props?.data)}
          >
            <img
              src={
                props?.data?.is_added_to_watchlist ||
                props?.type === "watchlist"
                  ? redWatchlistIcon
                  : watchlistIcon
              }
              alt="bids"
            />
          </div>

          <div
            className="bidicons"
            onClick={
              props.data?.is_available_for_cart
                ? handleCart
                : props?.data?.in_my_cart
                ? handleCart
                : (e) => e.stopPropagation()
            }
          >
            <img
              src={
                props?.data?.is_available_for_cart
                  ? Cart
                  : props?.data?.in_my_cart
                  ? GreenCart
                  : CartDisabled
              }
              alt={numberFormat(props?.data?.is_available_for_cart)}
            />
          </div>
        </div>
      </div>
      <h2 className="cardbidtitle">
        {" "}
        <span className="threedots"> {assetTitle} </span>
      </h2>
    </div>
  );
};

export default DirectSaledGridCardTop;
