import { useState, useContext, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import "./header.scss";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Figure from "react-bootstrap/Figure";
import SignInButton from "../buttons/signinbutton";
import SignUpButton from "../buttons/signupbutton";
import SignInPhoneModal from "../modals/signinphonemodal";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { UserContext, CartCount, CartWarning } from "../../contexts/signup";
import Cart from "../../assets/Icons/storage.svg";
import Watchlist from "../../assets/Icons/details.svg";
import Mypurchases from "../../assets/Icons/purchases.svg";
import Hammer from "../../assets/Icons/hammer.svg";
import Profile from "../../assets/Icons/profile.svg";
import Logout from "../../assets/Icons/logout-icon.svg";
import { apiWithToken } from "../../feature/envs/api";
import { toast, ToastContainer, Zoom } from "react-toastify";
import { NavDropdown } from "react-bootstrap";
import LogoutModal from "../modals/logoutmodal";
//import Notification from "./notification";
import MazadLogo from "../../assets/Icons/Splash-Logo.png";
import { chatCheck, encryptData } from "../../utils/functions";
import Cookies from "js-cookie";

type Props = {
  setDirection: (langDirection: string) => void;
  direction: string;
};

const Header = (props: Props) => {
  const { user, setUser } = useContext<any>(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showSignIn, setShowSignIn] = useState(false);
  const { count, setCartCount } = useContext<any>(CartCount);
  const { status, setCartWarning } = useContext<any>(CartWarning);
  const [isexpanded, setExpanded] = useState(false);
  const [show, setShow] = useState(false);
  const currentPath = window.location.pathname + window.location.search;
  const [modalType, setModalType] = useState("");
  const [searchParams] = useSearchParams();
  const [langVal, setLangVal] = useState("");
  let location = useLocation();

  let language = searchParams.get("lang");

  function changeDirection(lang: string) {
    document.body.dir = lang === "en" ? "" : "rtl";
    props.setDirection(lang === "en" ? "" : "rtl");
    i18next.changeLanguage(lang);
    if (language !== lang) {
      localStorage.setItem("lang", lang);
      setLangVal(lang);
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const langParam = urlSearchParams.get("lang");

    if (!langVal && !langParam) {
      let lang = localStorage.getItem("lang") || "en";
      searchParams.set("lang", lang);
    } else if (langParam && !langVal && ["en", "ar"].includes(langParam)) {
      localStorage.setItem("lang", langParam);
      searchParams.set("lang", langParam);
      changeDirection(langParam);
    } else {
      searchParams.set("lang", langVal || "en");
    }

    const updatedPath = `${location.pathname}?${searchParams.toString()}${
      location.hash
    }`;

    // Check if the updated path is different from the current path before navigating
    const currentPath = `${location.pathname}${location.search}${location.hash}`;
    if (updatedPath !== currentPath) {
      navigate(updatedPath, { replace: true });
    }
  }, [langVal, navigate, location]);

  const openModal = (modaltype: any) => {
    setExpanded(false);
    setModalType(modaltype);
    modaltype === "signin" ? setShowSignIn(true) : setShowSignIn(true);
  };
  const closeModal = (modaltype: any) => {
    modaltype === "signin" ? setShowSignIn(false) : setShowSignIn(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const handlecloseShow = () => {
    setShow(false);
  };

  const handleLogout = () => {
    setUser({ userLoggedIn: false });
    localStorage.removeItem("id-token");
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("user-details");
    localStorage.removeItem("direct_category");
    localStorage.removeItem("auction_category");
    window.location.reload();
  };

  const getMyCartApi = () => {
    apiWithToken.get(`/api/v2/cart/my-cart`).then((res: any) => {
      if (res.data.status === 200) {
        let count: any = null;
        if (res?.data?.data?.cart && res?.data?.data?.cart?.items?.length) {
          count = res?.data?.data?.cart?.items?.length;
        } else {
          count = null;
        }
        setCartCount(count);
      }
    });
  };

  const getContactdetails = () => {
    apiWithToken.get(`api/v1/settings/contact-details`).then((res: any) => {
      if (res.data.status === 200) {
        setUser({ ...user, contactDetails: res?.data?.data });
      }
    });
  };

  useEffect(() => {
    if (user.userLoggedIn) {
      const urlString = window.location.href;
      const url = new URL(urlString);
      const path = url.pathname;
      if (path !== "/cart") getMyCartApi();
      chatCheck();
    }
    getContactdetails();
  }, []);

  function showToast() {
    if (!toast.isActive("cartToast")) {
      toast(<p className="CartErrMsg">{t("another_user")}</p>, {
        autoClose: 3000,
        hideProgressBar: true,
        toastId: "cartToast",
      });
    }
  }
  useEffect(() => {
    if (status) {
      showToast();
      setCartWarning(false);
    }
  }, [status]);

  const containerStyle = {
    width: "100%",
    maxWidth: "600px", // set a maximum width to avoid it becoming too wide
    zIndex: 9999,
    margin: "0 auto", // center the container horizontally
  };

  const logoClick = () => {
    navigate("/");
  };

  useEffect(() => {
    // SSO login code
    const cookieExists = Cookies.get("idToken") !== undefined;
    if (cookieExists) {
      setUser({
        userLoggedIn: false,
      });
      localStorage.removeItem("user-details");
      localStorage.removeItem("id-token");
      localStorage.removeItem("refresh-token"); // Now try to retrieve the cookie
      const idToken: any = cookieExists ? Cookies.get("idToken") : null;
      const refreshToken: any = cookieExists
        ? Cookies.get("refreshToken")
        : null;
      const user_data: any = cookieExists ? Cookies.get("user") : null;

      localStorage.setItem("id-token", JSON.parse(JSON.stringify(idToken)));
      localStorage.setItem(
        "refresh-token",
        JSON.parse(JSON.stringify(refreshToken))
      );
      encryptData("user-details", JSON.parse(user_data));
      setUser({
        ...user,
        userLoggedIn: true,
        userDetails: JSON.parse(user_data),
      });
      Cookies.remove("idToken", { path: "/" });
      Cookies.remove("idToken", { path: "/", domain: ".mazad.app" });
      window.location.reload();
    }
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        closeButton={false}
        draggable={false}
        toastStyle={{
          backgroundColor: "transparent",
          boxShadow: "none",
          width: "max-content",
        }}
        style={containerStyle}
        transition={Zoom}
      />
      <Navbar
        bg="light"
        expand="lg"
        className="guest-header d-flex flex-column"
        expanded={isexpanded}
        onToggle={(toggleState) => setExpanded(toggleState)}
      >
        <Container fluid>
          <Navbar.Brand style={{ cursor: "pointer" }} onClick={logoClick}>
            <img
              alt="logo"
              src={MazadLogo}
              className="align-self-center mazadlogo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {user.userLoggedIn ? (
              <Nav className="ms-auto d-flex align-items-center">
                <Link
                  to={currentPath}
                  className="navsign headerguesticons nav-link"
                  onClick={() => changeDirection("en")}
                >
                  {t("change_language_english")}
                </Link>
                <Link
                  to={currentPath}
                  className="navsign headerguesticons nav-link"
                  onClick={() => changeDirection("ar")}
                >
                  {t("change_language_arabic")}
                </Link>
                <Link to="/cart" className="headericons nav-link">
                  <Figure.Image alt="storage" src={Cart} />
                  {count > 0 && <span className="cartCount">{count}</span>}
                </Link>
                <Link to="/bids" className="headericons nav-link">
                  <Figure.Image alt="headericons" src={Hammer} />
                </Link>
                <Link to="/watchlist" className="headericons nav-link">
                  <Figure.Image alt="headericons" src={Watchlist} />
                </Link>

                <Link to="/profile/purchases" className="headericons nav-link">
                  <Figure.Image alt="headericons" src={Mypurchases} />
                </Link>
                {/* NOTIFICATION CODE */}
                {/* <NavDropdown
                  id="basic-nav-dropdown"
                  className="profile-icon"
                  title={
                    <div className="d-inline profileIcon">
                      <Figure.Image alt="notifications" src={Profile} />
                    </div>
                  }
                >
                  <Notification />
                </NavDropdown> */}
                {t("en") === "en" ? (
                  <NavDropdown
                    id="basic-nav-dropdown"
                    className="profile-icon"
                    title={
                      <div className="d-inline profileIcon">
                        <Figure.Image alt="headericons" src={Profile} />
                      </div>
                    }
                  >
                    <div className="nav-dropdown ">
                      <Container className="p-5">
                        <NavDropdown.Item
                          className="navbarItem-name"
                          as={Link}
                          to="/profile"
                        >
                          {" "}
                          {t("my_details")}
                        </NavDropdown.Item>
                        <hr />
                        {/* <NavDropdown.Item
                        as={Link}
                        to="/profile/notification"
                        className="navbarItem-name"
                      >
                        {t("notifications")}
                      </NavDropdown.Item>
                      <hr /> */}
                        {/* <NavDropdown.Item className="navbarItem-name">
                        {t("settings_and_privacy")}
                      </NavDropdown.Item>
                      <hr /> */}
                        <NavDropdown.Item
                          as={Link}
                          to="/profile/deposits"
                          className="navbarItem-name"
                        >
                          {t("deposit_and_limit")}
                        </NavDropdown.Item>
                        <hr />
                        <NavDropdown.Item
                          as={Link}
                          to="/profile/purchases"
                          className="navbarItem-name"
                        >
                          {t("my_purchases")}
                        </NavDropdown.Item>
                        <hr />
                        <NavDropdown.Item
                          className="navbarItem-name-logout"
                          onClick={handleShow}
                        >
                          <Figure.Image
                            alt="headericons"
                            className="m-2 logoutIcon"
                            src={Logout}
                          />
                          {t("logout")}
                        </NavDropdown.Item>
                      </Container>
                    </div>
                  </NavDropdown>
                ) : (
                  <NavDropdown
                    id="basic-nav-dropdown"
                    className="profile-icon"
                    title={
                      <div className="d-inline profileIcon">
                        <Figure.Image alt="headericons" src={Profile} />
                      </div>
                    }
                  >
                    <div className="nav-dropdown-ar ">
                      <Container className="p-5">
                        <NavDropdown.Item
                          className="navbarItem-name"
                          as={Link}
                          to="/profile"
                        >
                          {" "}
                          {t("my_details")}
                        </NavDropdown.Item>
                        <hr />
                        {/* <NavDropdown.Item
                        as={Link}
                        to="/profile/notification"
                        className="navbarItem-name"
                      >
                        {t("notifications")}
                      </NavDropdown.Item>
                      <hr /> */}
                        {/* <NavDropdown.Item className="navbarItem-name">
                        {t("settings_and_privacy")}
                      </NavDropdown.Item>
                      <hr /> */}
                        <NavDropdown.Item
                          as={Link}
                          to="/profile/deposits"
                          className="navbarItem-name"
                        >
                          {t("deposit_and_limit")}
                        </NavDropdown.Item>
                        <hr />
                        <NavDropdown.Item
                          as={Link}
                          to="/profile/purchases"
                          className="navbarItem-name"
                        >
                          {t("my_purchases")}
                        </NavDropdown.Item>
                        <hr />
                        <NavDropdown.Item
                          className="navbarItem-name-logout"
                          onClick={handleShow}
                        >
                          <Figure.Image
                            alt="headericons"
                            className="m-2 logoutIcon"
                            src={Logout}
                          />
                          {t("logout")}
                        </NavDropdown.Item>
                      </Container>
                    </div>
                  </NavDropdown>
                )}
              </Nav>
            ) : (
              <Nav className="ms-auto d-flex align-items-center">
                <Link
                  to={currentPath}
                  className="navsign headerguesticons nav-link"
                  onClick={() => changeDirection("en")}
                >
                  {t("change_language_english")}
                </Link>
                <Link
                  to={currentPath}
                  className="navsign headerguesticons nav-link"
                  onClick={() => changeDirection("ar")}
                >
                  {t("change_language_arabic")}
                </Link>
                <Link to="" className="headerguesticons nav-link">
                  <SignInButton
                    value={t("header_signin")}
                    handleClick={openModal}
                  />
                </Link>
                <Link to="" className="headerguesticons nav-link">
                  <SignUpButton
                    value={t("header_signup")}
                    handleSignUpClick={openModal}
                  />
                </Link>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <SignInPhoneModal
        showSignIn={showSignIn}
        handleCloseModal={closeModal}
        handleOpenModal={openModal}
        modalType={modalType}
      />

      <LogoutModal
        show={show}
        openlogoutModal={handleShow}
        closelogoutModal={handlecloseShow}
        logout={handleLogout}
      />
    </>
  );
};

export default Header;
