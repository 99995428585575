import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
//import LiveButton from "../../components/buttons/livebutton";
import "./homeslider.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SliderImages from "./sliderimages";
import { useCallback, useContext, useEffect, useState } from "react";
import { connectSocket, disconnectSocket } from "../../utils/socket";
import { AllDataContext } from "../../contexts/signup";

type Props = {
  direction: string;
};
let socket: any = null;
let featuredArr: any = [];

const HomeSlider = (props: Props) => {
  const { allData, setAllData } = useContext<any>(AllDataContext);
  const [feature, setFeature] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    featuredArr = [];
    socket = connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {        
    allData?.featured_cards &&  setFeature(allData?.featured_cards);
  }, [allData]);

  useEffect(() => {
    addSocketListeners();
    return () => {
      socket.off("home", getMyHomeDetails);
      socket.off("category-home-details", getMyHomeOptions);
    };
  }, [socket]);

  const addSocketListeners = () => {
    if (socket !== null) {
      socket.on("home", getMyHomeDetails);
      socket.on("category-home-details", getMyHomeOptions);
      socket.on("home-featured", getMyHomeFeatured);
      socket.on("featured-home-details", getMyHomeOptions);
    }
  };

  const getMyHomeFeatured = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (authToken !== null && authToken !== undefined) {
      socket.emit("featured-home", {
        authorization: authToken,
      });
    }
  }, []);

  const getMyHomeDetails = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (authToken !== null && authToken !== undefined) {
      socket.emit("category-home", {
        authorization: authToken,
      });
    }
  }, []);

  const getMyHomeOptions = useCallback((res: any) => {
    if (res?.featured_cards?.length > 0) {
      setFeature(res?.featured_cards);
      setAllData((prevData: any) => ({
        ...prevData,
        featured_cards: res?.featured_cards,
      }));
    }
  }, []);

  return (
    <Row className="slidersection">
      <Col>
        <Carousel
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
              partialVisibilityGutter: 30,
            },
            mobile: {
              breakpoint: {
                max: 575,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 20,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 576,
              },
              items: 2,
              partialVisibilityGutter: 20,
            },
          }}
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          partialVisible
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          rewind={false}
          rewindWithAnimation={false}
          rtl={props.direction === "rtl"}
          shouldResetAutoplay
          showDots={false}
          sliderClass={feature?.length < 4 ? "containerStyle" : ""}
          slidesToSlide={1}
          swipeable
        >
          {/* Might be needed in future 
          <div className="sliderimage">
            <img
              src="./assets/Images/homescreencards.png"
              alt="sliderimages"
              className="sliderimges"
            />
            <div className="sliderlivebutton">
              <LiveButton />
            </div>
          </div> */}
          {feature?.map((ele, index) => (
            <SliderImages key={index} featuredata={ele} />
          ))}
        </Carousel>
      </Col>
    </Row>
  );
};

export default HomeSlider;
