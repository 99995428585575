import "./profilecontact.scss";
type Props = {
  imgUrl: string;
  onClick?: () => void;
};

const ProfileContact = (props: Props) => {
  return (
    <div
      className="MinusAndPlus d-flex justify-content-center align-items-center pointer_class"
      onClick={props.onClick}
    >
      <img src={props.imgUrl} alt="minus" />
    </div>
  );
};

export default ProfileContact;
