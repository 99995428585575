import { t } from "i18next";
import { useEffect, useState } from "react";
import {
  isLessTime,
  timeAgo,
  unixToEndTime,
  unixToEndTimeSeconds,
} from "../../utils/functions";
import TimeDivider from "../../assets/Icons/time-divider.svg";
import ClockRed from "../../assets/Icons/Watch-Shape-red.svg";
import Clock from "../../assets/Icons/Watch-Shape.svg";

type Props = {
  compName: string;
  endTime: Number;
  setShow?: any;
  setExpiredAsset?: any;
  setFilteredAssetDataFunc?: any;
  handleDeleteManual?:any;
  asset_id?: any;
  setClassState?:any;
  classState?:any;
};

const Stopwatch = (props: Props) => {
  const [stopTime, setStopTime]:any = useState(props?.endTime);
  const [currentTime, setCurrentTime] = useState(Date.now());
  const handleInterval = () => {
    setStopTime((prev: any) => Math.floor(prev - 0));
  };

  useEffect(() => {
    setStopTime(props?.endTime);
    const interval = setInterval(handleInterval, 1000);
    return () => clearInterval(interval);
  }, [props?.endTime]);

  //To Render Every Second
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  var stopWatch: any = "";
  const days =
    props?.endTime && stopTime && unixToEndTimeSeconds(stopTime)?.days > 0;
  const daysTextClass = isLessTime(stopTime) ? "DaysText  timeRed" : "DaysText";
  const daysHours = days ? t("days") : t("hours");
  const hoursMinutes = days ? t("hours") : t("minutes");
  const minuteSeconds = days ? t("minutes") : t("seconds");

  if (props?.compName === "home") {
    if (timeAgo(stopTime && stopTime) >= -1 && stopTime > 0) {
      stopWatch = <p className={"overtime"}>0s</p>;
    } else {
      stopWatch = (
        <p className={isLessTime(stopTime) ? "overtime" : "time"}>
          {props?.endTime && unixToEndTime(stopTime)}
        </p>
      );
    }
  } else if (props?.compName === "home_banner") {
    if (timeAgo(stopTime && stopTime) >= -1 && stopTime > 0) {
      stopWatch = "0s";
    } else {
      stopWatch = props?.endTime && unixToEndTime(stopTime);
    }
  } else if (props?.compName === "assetList") {
    if (timeAgo(stopTime && stopTime) >= -1 && stopTime > 0) {
      stopWatch = (
        <div className="d-flex align-items-center ArabicBottomColors customWidth">
          <img src={isLessTime(stopTime) ? ClockRed : Clock} alt="bidtime" />
          <span className={"timeduration-red"}>0s</span>
        </div>
      );
      props?.setFilteredAssetDataFunc(props?.asset_id);
    } else {
      stopWatch = (
        <div className="d-flex align-items-center ArabicBottomColors customWidth">
          <img src={isLessTime(stopTime) ? ClockRed : Clock} alt="bidtime" />
          <span
            className={
              isLessTime(stopTime) ? "timeduration-red" : "timeduration"
            }
          >
            {unixToEndTime(stopTime)}
          </span>
        </div>
      );
    }
  } else if (props?.compName === "mapList") {
    if (timeAgo(stopTime && stopTime) >= -1 && stopTime > 0) {
      stopWatch = (
        <div className="d-flex align-items-center ArabicBottomColors">
          <img src={isLessTime(stopTime) ? ClockRed : Clock} alt="bidtime" />
          <span className={"timeduration-red"}>0s</span>
        </div>
      );
      props?.setFilteredAssetDataFunc(props?.asset_id);
    } else {
      stopWatch = (
        <div className="d-flex align-items-center ArabicBottomColors">
          <img src={isLessTime(stopTime) ? ClockRed : Clock} alt="bidtime" />
          <span
            className={
              isLessTime(stopTime) ? "timeduration-red" : "timeduration"
            }
          >
            {unixToEndTime(stopTime)}
          </span>
        </div>
      );
    }
  } else if (props?.compName === "commonBidModal") {
    if (timeAgo(stopTime && stopTime) >= -1 && stopTime > 0) {
      props.setShow(false);
    }

    stopWatch = (
      <div className="d-flex justify-content-between BidTimingTop">
        <div className="d-flex flex-column align-items-center">
          <span
            className={
              isLessTime(stopTime) ? "DaysNumber timeRed" : "DaysNumber"
            }
          >
            {days
              ? props?.endTime &&
                stopTime &&
                unixToEndTimeSeconds(stopTime)?.days
              : props?.endTime &&
                stopTime &&
                unixToEndTimeSeconds(stopTime)?.hours}
          </span>
          <span className={daysTextClass}>{daysHours}</span>
        </div>
        <img src={TimeDivider} alt="time" />
        <div className="d-flex flex-column align-items-center">
          <span
            className={
              isLessTime(stopTime) ? "DaysNumber timeRed" : "DaysNumber"
            }
          >
            {days
              ? props?.endTime &&
                stopTime &&
                unixToEndTimeSeconds(stopTime)?.hours
              : props?.endTime &&
                stopTime &&
                unixToEndTimeSeconds(stopTime)?.minutes}
          </span>
          <span className={daysTextClass}>{hoursMinutes}</span>
        </div>
        <img src={TimeDivider} alt="time" />
        <div className="d-flex flex-column align-items-center">
          <span
            className={
              isLessTime(stopTime) ? "DaysNumber timeRed" : "DaysNumber"
            }
          >
            {days
              ? props?.endTime &&
                stopTime &&
                unixToEndTimeSeconds(stopTime)?.minutes
              : props?.endTime &&
                stopTime &&
                unixToEndTimeSeconds(stopTime)?.seconds}
          </span>
          <span className={daysTextClass}>{minuteSeconds}</span>
        </div>
      </div>
    );
  } else if (props?.compName === "assetDetails") {
    if (timeAgo(stopTime && stopTime) >= -1 && stopTime > 0) {
      stopWatch = (
        <div className="d-flex justify-content-between align-items-baseline  AssetTimingTop ArabicBottomColors">
          <div className="d-flex flex-column align-items-center">
            <span className="DaysNumber timeRed"> 00</span>
            <span className={daysTextClass}>{daysHours}</span>
          </div>
          <img src={TimeDivider} alt="time" />
          <div className="d-flex flex-column align-items-center">
            <span className="DaysNumber timeRed">00</span>
            <span className={daysTextClass}>{hoursMinutes}</span>
          </div>
          <img src={TimeDivider} alt="time" />
          <div className="d-flex flex-column align-items-center">
            <span className="DaysNumber timeRed">00</span>
            <span className={daysTextClass}>{minuteSeconds}</span>
          </div>
        </div>
      );
      if (props?.setExpiredAsset) {
        props?.setExpiredAsset(true);
      }
    } else {
      stopWatch = (
        <div className="d-flex justify-content-between align-items-baseline  AssetTimingTop ArabicBottomColors">
          <div className="d-flex flex-column align-items-center">
            <span
              className={
                isLessTime(stopTime) ? "DaysNumber timeRed" : "DaysNumber"
              }
            >
              {" "}
              {days
                ? props?.endTime && unixToEndTimeSeconds(stopTime)?.days
                : unixToEndTimeSeconds(stopTime)?.hours}
            </span>
            <span className={daysTextClass}>{daysHours}</span>
          </div>
          <img src={TimeDivider} alt="time" />
          <div className="d-flex flex-column align-items-center">
            <span
              className={
                isLessTime(stopTime) ? "DaysNumber timeRed" : "DaysNumber"
              }
            >
              {days
                ? props?.endTime && unixToEndTimeSeconds(stopTime)?.hours
                : unixToEndTimeSeconds(stopTime)?.minutes}
            </span>
            <span className={daysTextClass}>{hoursMinutes}</span>
          </div>
          <img src={TimeDivider} alt="time" />
          <div className="d-flex flex-column align-items-center">
            <span
              className={
                isLessTime(stopTime) ? "DaysNumber timeRed" : "DaysNumber"
              }
            >
              {days
                ? props?.endTime && unixToEndTimeSeconds(stopTime)?.minutes
                : unixToEndTimeSeconds(stopTime)?.seconds}
            </span>
            <span className={daysTextClass}>{minuteSeconds}</span>
          </div>
        </div>
      );
    }
  } else if (props?.compName === "assetListView") {
    if (timeAgo(stopTime && stopTime) >= -1 && stopTime > 0) {
      stopWatch = (
        <div className="d-flex align-items-center ArabicBottomColors customWidthListView">
          <div className="BottomClock">
            <img src={isLessTime(stopTime) ? ClockRed : Clock} alt="bidtime" />
          </div>
          <span className={"timeduration-red"}>0s</span>
        </div>
      );
      props?.setFilteredAssetDataFunc(props?.asset_id);
    } else {
      stopWatch = (
        <div className="d-flex align-items-center ArabicBottomColors customWidthListView">
          <div className="BottomClock">
            <img src={isLessTime(stopTime) ? ClockRed : Clock} alt="bidtime" />
          </div>
          <span
            className={
              isLessTime(stopTime) ? "timeduration-red" : "timeduration"
            }
          >
            {unixToEndTime(stopTime)}
          </span>
        </div>
      );
    }
  } else if (props?.compName === "bidsWon") {
    if (timeAgo(stopTime && stopTime) >= -1 && stopTime > 0) {
      stopWatch = <span className={"timeduration-red"}>0s</span>;
    } else {
      stopWatch = (
        <span
          className={isLessTime(stopTime) ? "timeduration-red" : "timeduration"}
        >
          {unixToEndTime(stopTime)}
        </span>
      );
    }
  } else if (props?.compName === "cartcheckout") {
    if (timeAgo(stopTime && stopTime) >= -1 && stopTime > 0) {
      stopWatch = (
        <span className={"timeduration_chekcout-red"} style={{ width: "auto" }}>
          {t("time_up")}
        </span>
      );
      props?.setClassState('biddigwonsection biddigwonsection_op')
    } else {
      props?.setClassState('biddigwonsection')
      stopWatch = (
        <span
          className={
            isLessTime(stopTime)
              ? "timeduration_chekcout-red"
              : "timeduration_chekcout"
          }
        >
          <img src={isLessTime(stopTime) ? ClockRed : Clock} alt="bidtime" />{" "}
          {unixToEndTime(stopTime)}
        </span>
      );
    }
  }

  return stopWatch && stopWatch;
};

export default Stopwatch;
