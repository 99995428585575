import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

const Camara = () => {
  return (
    <Container className="text-center bg-dark w-50 h-100">
      <p className="text-white text-center ">Scan Document</p>
      <Link to="/uploaddocuments">
        <Button variant="outline-secondary">Camera Opened</Button>
      </Link>
      <p className="text-white text-center">Scan Personal ID</p>
      <p className="text-white text-center">
        Placce your document within the frame until all 4 corners allign, it
        will capture automatically
      </p>
    </Container>
  );
};

export default Camara;
