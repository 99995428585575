import { useTranslation } from "react-i18next";
import HighestBidder from "../../assets/Icons/highest-bidder.svg";
import OutBidder from "../../assets/Icons/outbidder.svg";
import CommonBidModal from "./commonbidmodal";
import { isWithin5Seconds, numberFormat } from "../../utils/functions";
import { ClipLoader } from "react-spinners";
import MyBidsEmpty from "../mybids/mybidsempty";
import locationIcon from "../../assets/Icons/location.svg";
import Stopwatch from "../stopwatch/stopwatch";
import redWatchlistIcon from "../../assets/Icons/details-red.svg";
import watchlistIcon from "../../assets/Icons/details.svg";
import "../auctionbidding/auctionbidding.scss";
import noBids from "../../assets/Icons/noBids.svg";
import years from "../../assets/Icons/years.svg";
import hammer from "../../assets/Icons/hammer.svg";
import React, { useRef, useState } from "react";
import { AssetTag } from "../../components/tags/AssetTag";

type Props = {
  direction?: any;
  data?: any;
  viewGrid?: any;
  showSignIn?: any;
  show?: any;
  assetItem?: any;
  setShow?: any;
  filteredAssetData?: any;
  is_auto_bidding?: any;
  loader?: any;
  setLoader?: any;
  fullscreen?: any;
  setAssetItem?: any;
  assetTitle?: any;
  assetId?: any;
  assetLoader?: any;
  handleDetailPage?: any;
  handleWatchlist?: any;
  handleShow?: any;
  closeModal?: any;
  openModal?: any;
  override?: any;
  categoryData?: any;
  index?: any;
  asset?: any;
  highestStart?: any;
  makeBidId?: any;
  setMakeBidId?: any;
  setFilteredAssetDataFunc?: any;
};

const GeneralListView = (props: Props) => {
  const { t } = useTranslation();
  const [shouldBlink, setShouldBlink] = useState(false);
  const size = "thumbnail"; // Dynamic property name

  const callAnimationFunc = () => {
    console.count();
    setTimeout(() => {
      setShouldBlink(false);
    }, 1500);
    setShouldBlink(true);
  };
  let shouldBlinkClass = " ";

  if (isWithin5Seconds(props?.asset?.latest_bid_time)) {
    shouldBlinkClass = "biddigwonsection blink-animation";
    if (!shouldBlink) {
      callAnimationFunc();
    }
  } else {
    shouldBlinkClass = "biddigwonsection";
  }

  const divRef: any = useRef(null);

  const handleImageError = (e: any) => {
    const img = e.target;
    img.style.display = "none"; // Hide the failed image
    divRef.current.style.display = "block"; // Show the replacement div
  };

  return (
    <>
      {props?.filteredAssetData && props?.filteredAssetData?.length > 0 ? (
        <>
          <div className={shouldBlinkClass}>
            <div
              className="biddingswoncard d-flex justify-content-between"
              onClick={() => props.handleDetailPage(props.asset, props?.index)}
            >
              <div className="d-flex align-items-center">
                <div className="container-general-list">
                  {props?.asset?.tag && (
                    <AssetTag
                      tag={props.asset?.tag}
                      discountPercentage={props.asset?.discount_percentage}
                    />
                  )}
                  {props.asset?.asset_images &&
                  props.asset?.asset_images.length > 0 ? (
                    <>
                      <img
                        src={props.asset?.asset_images[0]?.url[size]}
                        alt={props?.asset?.asset_title}
                        onError={handleImageError}
                      />
                      <div
                        className="img customBg"
                        ref={divRef}
                        style={{ display: "none" }}
                      ></div>
                    </>
                  ) : (
                    <div className="img customBg"></div>
                  )}
                </div>

                <div className="px-3">
                  <h2 className="AuctionCardHeading">
                    {t("en") === "en"
                      ? props?.asset?.asset_title
                      : props?.asset?.asset_title_ar}
                  </h2>

                  {props?.asset?.location_title &&
                    props?.asset?.location_title && (
                      <p className="calander">
                        <img src={locationIcon} alt="calander" />
                        <span>
                          {t("en") === "en"
                            ? props?.asset?.location_title
                            : props?.asset?.location_title_ar}
                        </span>
                      </p>
                    )}

                  {props?.asset?.asset_id && (
                    <p className="calander">
                      <img src={years} alt="calander" />
                      <span>{props?.asset?.asset_id}</span>
                    </p>
                  )}
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <button
                  className="bahrainarrow"
                  onClick={(event: any) =>
                    props.handleWatchlist(event, props?.asset)
                  }
                >
                  <img
                    src={
                      props.asset?.is_added_to_watchlist
                        ? redWatchlistIcon
                        : watchlistIcon
                    }
                    alt="watchlist"
                  />
                </button>

                <button
                  className="bahrainarrow"
                  onClick={(event: any) =>
                    props.handleShow(
                      "sm-down",
                      props?.asset,
                      event,
                      props?.index
                    )
                  }
                >
                  <img src={hammer} alt="rightarrow" />
                </button>
              </div>
            </div>

            <div
              className={
                props?.makeBidId === props?.asset?.asset_id &&
                props?.highestStart
                  ? "d-flex justify-content-between align-items-center CategoryCardBottomBlue"
                  : props?.asset?.is_highest_bidder === true
                  ? "d-flex justify-content-between align-items-center CategoryCardBottomBlue"
                  : props?.asset?.is_highest_bidder === false
                  ? "d-flex justify-content-between align-items-center CategoryCardBottomPink"
                  : "d-flex justify-content-between align-items-center CategoryCardBottomGrey"
              }
            >
              <p className="HighestBidder d-none">
                <img src={HighestBidder} alt="highbidder" />
                You’re the Highest Bidder
              </p>
              <p className="OutBidder d-none">
                <img src={OutBidder} alt="highbidder" />
                You’ve been outbid
              </p>
              {t("en") === "en" ? (
                <p className="bhdwon ">
                  {t("bhd")} {numberFormat(props?.asset?.highest_price)}
                </p>
              ) : (
                <p className="bhdwon ">
                  {numberFormat(props?.asset.highest_price)} {t("bhd")}{" "}
                </p>
              )}

              <div className="d-flex align-items-center ArabicBottomColors">
                <div className="BottomHammer">
                  <img src={hammer} alt="hammer" />
                </div>
                <span className="bidcount">{props?.asset?.total_bids}</span>
              </div>
              <Stopwatch
                compName={"assetListView"}
                endTime={props?.asset?.end_time}
                setFilteredAssetDataFunc={props?.setFilteredAssetDataFunc}
                asset_id={props?.asset?.asset_id}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {props.assetLoader && (
            <ClipLoader
              color={"#fff"}
              loading={props.assetLoader}
              cssOverride={props.override}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
          {!props.assetLoader && (
            <MyBidsEmpty imgUrl={noBids} title={"auction"} />
          )}
        </>
      )}

      {props.show && (
        <CommonBidModal
          filterData={props.data}
          fullscreen={props.fullscreen}
          setShow={props.setShow}
          show={props.show}
          assetItem={props.assetItem}
          setAssetItem={props.setAssetItem}
          loader={props.loader}
          assetId={props.assetId}
          buttonSlider="show"
          assetTitle={props.assetTitle}
          is_auto_bidding={props.is_auto_bidding}
          categoryData={props.categoryData}
        />
      )}
    </>
  );
};

export default React.memo(GeneralListView);
