import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SignInPhoneModal from "../../components/modals/signinphonemodal";
import CommonBidModal from "./commonbidmodal";
import "../auctionbidding/auctionbidding.scss";
import CarPlateListView from "./carplatelistview";
import GeneralListView from "./generallistview";
import { ClipLoader } from "react-spinners";
import MyBidsEmpty from "../mybids/mybidsempty";
import noBids from "../../assets/Icons/noBids.svg";
import { v4 as uuidv4 } from "uuid";

type Props = {
  direction?: any;
  data?: any;
  viewGrid?: any;
  setViewGrid?: any;
  showSignIn?: any;
  show?: any;
  assetItem?: any;
  setShow?: any;
  filteredAssetData?: any;
  is_auto_bidding?: any;
  loader?: any;
  setLoader?: any;
  fullscreen?: any;
  setAssetItem?: any;
  assetTitle?: any;
  assetId?: any;
  assetLoader?: any;
  handleDetailPage?: any;
  handleWatchlist?: any;
  handleShow?: any;
  closeModal?: any;
  openModal?: any;
  override?: any;
  categoryData?: any;
  key?: any;
  setHighestStart?: any;
  setHighestFalseShow?: any;
  highestStart?: any;
  highestFalseShow?: any;
  makeBidId?: any;
  setMakeBidId?: any;
  category_title?: any;
  pageLoader?: boolean;
  setFilteredAssetDataFunc?: any;

};

const CategoryPropertyListNew = (props: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        {props?.assetLoader ? (
          <ClipLoader
            color={"#fff"}
            loading={props.assetLoader}
            cssOverride={props.override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : props?.filteredAssetData && props?.filteredAssetData?.length > 0 ? (
          <>
            {props?.filteredAssetData?.map((asset: any, index: number) => {
              if (
                ["Taxi", "Car"].includes(props?.category_title?.split(" ")[0])
              ) {
                return (
                  <Col key={uuidv4()} lg={4} md={6}>
                    <CarPlateListView
                      asset={asset}
                      direction={props.direction}
                      data={props.data}
                      showSignIn={props.showSignIn}
                      assetItem={props.assetItem}
                      setShow={props.setShow}
                      is_auto_bidding={props.is_auto_bidding}
                      loader={props.loader}
                      fullscreen={props.fullscreen}
                      setAssetItem={props.setAssetItem}
                      assetTitle={props.assetTitle}
                      assetId={props.assetId}
                      assetLoader={props.assetLoader}
                      handleDetailPage={props.handleDetailPage}
                      handleWatchlist={props.handleWatchlist}
                      handleShow={props.handleShow}
                      closeModal={props.closeModal}
                      openModal={props.openModal}
                      override={props.override}
                      categoryData={props.categoryData}
                      index={index}
                      highestStart={props?.highestStart}
                      makeBidId={props?.makeBidId}
                      setMakeBidId={props?.setMakeBidId}
                      setFilteredAssetDataFunc={
                        props?.setFilteredAssetDataFunc
                      }
                    />
                  </Col>
                );
              } else {
                return (
                  <Col key={uuidv4()} lg={4} md={6}>
                    <GeneralListView
                      asset={asset}
                      filteredAssetData={props?.filteredAssetData}
                      direction={props.direction}
                      data={props.data}
                      viewGrid={props.viewGrid}
                      showSignIn={props.showSignIn}
                      assetItem={props.assetItem}
                      setShow={props.setShow}
                      is_auto_bidding={props.is_auto_bidding}
                      loader={props.loader}
                      setLoader={props.setLoader}
                      fullscreen={props.fullscreen}
                      setAssetItem={props.setAssetItem}
                      assetTitle={props.assetTitle}
                      assetId={props.assetId}
                      assetLoader={props.assetLoader}
                      handleDetailPage={props.handleDetailPage}
                      handleWatchlist={props.handleWatchlist}
                      handleShow={props.handleShow}
                      closeModal={props.closeModal}
                      openModal={props.openModal}
                      override={props.override}
                      categoryData={props.categoryData}
                      index={index}
                      highestStart={props?.highestStart}
                      makeBidId={props?.makeBidId}
                      setMakeBidId={props?.setMakeBidId}
                      setFilteredAssetDataFunc={
                        props?.setFilteredAssetDataFunc
                      }
                    />
                  </Col>
                );
              }
            })}
          </>
        ) : (
          <>
            {props.assetLoader && (
              <ClipLoader
                color={"#fff"}
                loading={props.assetLoader}
                cssOverride={props.override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
            {!props.assetLoader && (
              <MyBidsEmpty imgUrl={noBids} title={"auction"} />
            )}
          </>
        )}
        <SignInPhoneModal
          handleCloseModal={props.closeModal}
          showSignIn={props.showSignIn}
          handleOpenModal={props.openModal}
          modalType={t("header_signin")}
        />

        {props.show && (
          <CommonBidModal
            filterData={props.data}
            fullscreen={props.fullscreen}
            setShow={props.setShow}
            show={props.show}
            assetItem={props.assetItem}
            setAssetItem={props.setAssetItem}
            loader={props.loader}
            assetId={props.assetId}
            buttonSlider="show"
            assetTitle={props.assetTitle}
            is_auto_bidding={props.is_auto_bidding}
            categoryData={props.categoryData}
            auto_bidding_on={props?.assetItem?.auto_bidding_on}
            setHighestStart={props?.setHighestStart}
            setHighestFalseShow={props?.setHighestFalseShow}
            highestStart={props?.highestStart}
            highestFalseShow={props?.highestFalseShow}
            makeBidId={props?.makeBidId}
            setMakeBidId={props?.setMakeBidId}
          />
        )}
      </Row>
      <Row>
        {props?.pageLoader ? (
          <ClipLoader
            color={"#fff"}
            loading={props?.pageLoader}
            cssOverride={props?.override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          ""
        )}
      </Row>
    </>
  );
};

export default CategoryPropertyListNew;
