import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import SignInPhoneModal from "../../components/modals/signinphonemodal";
import CommonBidModal from "../auctionbidding/commonbidmodal";
import MyBidsEmpty from "../mybids/mybidsempty";
import { ClipLoader } from "react-spinners";
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { apiWithToken } from "../../feature/envs/api";
import {
  useNavigate
} from "react-router-dom";
import { CartCount } from "../../contexts/signup";
import "../auctionbidding/auctionbidding.scss";
import "../../components/mybidscards/mybidscards.scss";
import DirectSaleBottom from "./directsalebottom";
import DirectSaledGridCardTop from "../../components/mybidscards/directsaledgridcardtop";
import watchlistImage from "../../assets/Icons/EmptyStateImages/Bookmark.png";
import { toast } from "react-toastify";
import { connectSocket, disconnectSocket } from "../../utils/socket";
import { EventLogger, EventNames } from "../../utils/EventLogger";

let socket: any = null;
let assetsArr: any = [];

type Props = {
  filteredAssetData?: any;
  setFilteredAssetData?: any;
  assetLoader?: any;
};

const MyWatchListDirectSales = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const fullscreen = true;
  const [show, setShow] = useState(false);
  const [assetItem, setAssetItem] = useState<any>();
  const loader = false;
  const is_auto_bidding = false;
  const [showSignIn, setShowSignIn] = useState(false);
  const { setCartCount } = useContext<any>(CartCount);
  const { filteredAssetData, setFilteredAssetData, assetLoader } = props;
  assetsArr = filteredAssetData;

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    assetsArr = !assetsArr && [];
    socket = connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    addSocketListeners();
    return () => {
      socket.off("add-to-watchlist", addWatchlist);
      socket.off("remove-from-watchlist", removeWatchlist);
      socket.off("add-to-cart", addRemoveCartSocketCall);
      socket.off("remove-from-cart", addRemoveCartSocketCall);
      socket.off("watchlist-details", watchListDetails);
      socket.off("add-to-cart", addRemoveCartSocketCall);
      socket.off("remove-from-cart", addRemoveCartSocketCall);
      socket.off("my-cart", addRemoveCartSocketCall);
      socket.off("my-cart-details", getMyCartDetails);
    };
  }, [socket]);

  const addSocketListeners = () => {
    if (socket !== null) {
      socket.on("add-to-watchlist", addWatchlist);
      socket.on("remove-from-watchlist", removeWatchlist);
      socket.on("add-to-cart", addRemoveCartSocketCall);
      socket.on("remove-from-cart", addRemoveCartSocketCall);
      socket.on("watchlist-details", watchListDetails);
      socket.on("add-to-cart", addRemoveCartSocketCall);
      socket.on("remove-from-cart", addRemoveCartSocketCall);
      socket.on("my-cart", addRemoveCartSocketCall);
      socket.on("my-cart-details", getMyCartDetails);
    }
  };

  const addWatchlist = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("my-watchlist", {
        authorization: authToken,
      });
    }
  }, []);

  const removeWatchlist = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("my-watchlist", {
        authorization: authToken,
      });
    }
  }, []);

  const addRemoveCartSocketCall = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("my-watchlist", {
        asset_id: res.data.asset_id,
        authorization: authToken,
      });
      socket.emit("my-cart", {
        authorization: authToken,
      });
    }
  }, []);

  const getMyCartDetails = useCallback((res: any) => {
    let count: any = null;
    if (res?.data?.cart && res?.data?.cart?.items?.length) {
      count = res?.data?.cart?.items?.length;
    } else {
      count = null;
    }
    setCartCount(count);
  }, []);

  const watchListDetails = useCallback((res: any) => {
    setFilteredAssetData(res?.data?.direct_sale);
  }, []);

  const openModal = (modaltype: any) => {
    setShowSignIn(true);
  };
  const closeModal = (modaltype: any) => {
   setShowSignIn(false);
  };

  const handleWatchlist = (event: any, asset: any) => {
    event.stopPropagation();

    apiWithToken
      .put(`/api/v1/watchlist/remove-from-watchlist`, {
        asset_id: asset.asset_id,
      })
      .then((res: any) => {
        if (res.data.status === 200) {
          EventLogger.logEvent(EventNames.REMOVE_FROM_WATCHLIST, {asset_id: asset.asset_id});
          toast(
            <p className="CartErrMsgWatch">{t("removed_from_watchlist")}</p>,
            {
              autoClose: 2500,
              hideProgressBar: true,
              toastId: "cartToast",
            }
          );
        }
      });
  };

  const override: CSSProperties = {
    display: "block",
    margin: "20% auto",
    borderColor: "black",
    overflow: "-moz-hidden-unscrollable",
    opacity: 0.1,
  };
  const handleDetail = (data: any) => {
    let id  = data?.asset_id;
    navigate(`/d/${id}`, {
      state: {
        assetItem: data,
        type: "DirectSaleDetail",
      },
    });
  };
  return (
    <>
      <Row>
        {filteredAssetData?.length > 0 ? (
          <>
            {filteredAssetData?.map((asset: any, index: number) => (
              <Col lg={2} xs={6} sm={4} md={3} key={asset.asset_id}>
                <div
                  className="bidcardsection DirectSalesBidding"
                  onClick={() => handleDetail(asset)}
                >
                  <DirectSaledGridCardTop
                    type="watchlist"
                    index={index}
                    data={asset}
                    handleWatchlist={handleWatchlist}
                  />
                  <DirectSaleBottom value={t("bhd")} data={asset} />
                </div>
              </Col>
            ))}
          </>
        ) : (
          <>
            {assetLoader && (
              <ClipLoader
                color={"#fff"}
                loading={assetLoader}
                cssOverride={override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
            {!assetLoader && (
              <MyBidsEmpty
                imgUrl={watchlistImage}
                title={"direct sales"}
                className={"auction-icon"}
              />
            )}
          </>
        )}
      </Row>
      <SignInPhoneModal
        handleCloseModal={closeModal}
        showSignIn={showSignIn}
        handleOpenModal={openModal}
        modalType={t("header_signin")}
      />

      {show && (
        <CommonBidModal
          fullscreen={fullscreen}
          setShow={setShow}
          show={show}
          assetItem={assetItem}
          setAssetItem={setAssetItem}
          loader={loader}
          buttonSlider="show"
          is_auto_bidding={is_auto_bidding}
        />
      )}
    </>
  );
};

export default MyWatchListDirectSales;
