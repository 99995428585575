import {
  useEffect,
  useState,
  CSSProperties,
  Fragment,
  useContext,
  useCallback,
} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isLessTime } from "../../utils/functions";
import Stopwatch from "../stopwatch/stopwatch";
import { CartCount, AllDataContext } from "../../contexts/signup";
import { connectSocket, disconnectSocket } from "../../utils/socket";
import { fetchAuctions, fetchDirectSale, fetchFeaturedCards } from "../../api";
import { EventLogger, EventNames } from "../../utils/EventLogger";

let socket: any = null;
let assetsArr: any = [];

type Props = {
  direction: string;
};

const HomeAuctions = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [auctionOption, setAuctionOption] = useState<any>([]);
  const [directSaleOption, setDirectSaleOption] = useState<any>([]);
  const { count, setCartCount } = useContext<any>(CartCount);
  const { allData, setAllData } = useContext<any>(AllDataContext);

  let lessTimeClassAuction = "";
  let lessTimeClassDirect = "";

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    assetsArr = [];
    socket = connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    addSocketListeners();
    return () => {
      socket.off("add-to-cart", addRemoveCartSocketCall);
      socket.off("remove-from-cart", addRemoveCartSocketCall);
      socket.off("my-cart", addRemoveCartSocketCall);
      socket.off("my-cart-details", getMyCartDetails);

      socket.off("home-auction", getMyHomeAuction);
      socket.off("home-direct-sale", getMyHomeDirectSale);
      socket.off("home-featured", getMyHomeFeatured);

      socket.off("auction-home-details", getMyHomeOptions);
      socket.off("direct-sale-home-details", getMyHomeOptions);
      socket.off("featured-home-details", getMyHomeOptions);
    };
  }, [socket]);

  const addSocketListeners = () => {
    if (socket !== null) {
      socket.on("add-to-cart", addRemoveCartSocketCall);
      socket.on("remove-from-cart", addRemoveCartSocketCall);
      socket.on("my-cart", addRemoveCartSocketCall);
      socket.on("my-cart-details", getMyCartDetails);

      socket.on("home-auction", getMyHomeAuction);
      socket.on("home-direct-sale", getMyHomeDirectSale);
      socket.on("home-featured", getMyHomeFeatured);

      socket.on("auction-home-details", getMyHomeOptions);
      socket.on("direct-sale-home-details", getMyHomeOptions);
      socket.on("featured-home-details", getMyHomeOptions);
    }
  };

  const getMyHomeAuction = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (authToken !== null && authToken !== undefined) {
      socket.emit("auction-home", {
        authorization: authToken,
      });
    }
  }, []);

  const getMyHomeDirectSale = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (authToken !== null && authToken !== undefined) {
      socket.emit("direct-sale-home", {
        authorization: authToken,
      });
    }
  }, []);

  const getMyHomeFeatured = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (authToken !== null && authToken !== undefined) {
      socket.emit("featured-home", {
        authorization: authToken,
      });
    }
  }, []);

  const getMyHomeOptions = useCallback((res: any) => {
    if (res?.direct_sale?.length > 0) {
      setDirectSaleOption(res?.direct_sale);
      setAllData((prevData: any) => ({
        ...prevData,
        directsale: res?.direct_sale,
      }));
    }
    if (res?.auctions?.length > 0) {
      setAuctionOption(res?.auctions);
      setAllData((prevData: any) => ({
        ...prevData,
        auctions: res?.auctions,
      }));
    }
  }, []);

  const getMyCartDetails = useCallback((res: any) => {
    let count: any = null;
    if (res?.data?.cart && res?.data?.cart?.items?.length) {
      count = res?.data?.cart?.items?.length;
    } else {
      count = null;
    }
    setCartCount(count);
  }, []);

  const addRemoveCartSocketCall = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res?.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("get-bid-details", {
        asset_id: res?.data?.asset_id,
        authorization: authToken,
      });
      socket.emit("my-cart", {
        authorization: authToken,
      });
    }
  }, []);

  const fetchOptions = async () => {
    setLoader(true);
    Promise.all([
      fetchAuctions(setAllData, setAuctionOption),
      fetchDirectSale(setAllData, setDirectSaleOption),
      fetchFeaturedCards(setAllData),
    ])
      .then(() => {
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    localStorage.setItem("GridViewOn", JSON.stringify(false));
    if (
      localStorage.getItem("id-token") === "undefined" ||
      localStorage.getItem("refresh-token") === "undefined"
    ) {
      localStorage.removeItem("id-token");
      localStorage.removeItem("refresh-token");
      localStorage.removeItem("user-details");
      localStorage.removeItem("auction_category");
      localStorage.removeItem("direct_category");
      fetchOptions();
    } else {
      fetchOptions();
    }
  }, []);

  const openAuctionPage = (category: any) => {
    EventLogger.logEvent(EventNames.CATEGORY_SELECTION_CHANGE, {category_id: category?.category_id, auction_type: "auction"});
    navigate(`/c/${category?.category_id}`, {
      state: {
        id: category?.category_id,
        category_title: category?.category_title,
        category: category,
        auctionData: auctionOption,
        direction: props?.direction,
      },
    });
  };
  const openDirectSalePage = (category: any) => {
    EventLogger.logEvent(EventNames.CATEGORY_SELECTION_CHANGE, {category_id: category?.category_id, auction_type: "direct-sale"});
    navigate(`/s/${category?.category_id}`, {
      state: {
        id: category?.category_id,
        category_title: category?.category_title,
        category: category,
        direction: props?.direction,
      },
    });
  };
  const override: CSSProperties = {
    display: "block",
    margin: "20% auto",
    borderColor: "black",
    overflow: "-moz-hidden-unscrollable",
    opacity: 0.1,
  };

  const auction = auctionOption?.length > 0;
  const directSale = directSaleOption?.length > 0;
  const conditionMainClass = directSale ? 12 : 12;
  const conditionSubClass = directSale ? [4, 3, 2] : [4, 3, 2];

  return (
    <Row className="platessection">
      {directSale && !loader && (
        <Col xl={12}>
          <div className="container">
            <div className="row">
              <div className="col">
                {" "}
                <h2 className="plateheadings">{t("direct_sale")}</h2>
              </div>
              
              {/*  Below code will need in future versions
              <div className="col">
                <div className="cartIcon d-block d-sm-block d-lg-none d-md-none">
                  <Link
                    to="/cart"
                    className="headericons nav-link"
                  >
                    <Figure.Image alt="storage" src={Storage} className="mt-2"  />
                    {count > 0 && <span className="cartCount">{count}</span>}
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
          <div></div>
          <Row className="gx-4 gy-4 cardsdirection">
            {loader ? (
              <ClipLoader
                color={"#fff"}
                loading={loader}
                cssOverride={override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <>
                {directSaleOption?.map((category: any) => {
                  lessTimeClassDirect = isLessTime(category?.closest_end_time)
                    ? "overtime"
                    : "time";

                  return (
                    <Col
                      key={category.category_id}
                      xs={conditionSubClass[0]}
                      sm={conditionSubClass[1]}
                      xl={conditionSubClass[2]}
                      className="text-center ArabicCardsDirection"
                    >
                      <div className="CarPlatesBox">
                        <div
                          className="carplates"
                          onClick={() => openDirectSalePage(category)}
                        >
                          <img
                            src={category?.category_icon}
                            alt={category?.category_title}
                          />
                          {category?.count > 0 ? (
                            <span className="platenumbers">
                              {category?.count}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="text-center">
                          <p className="plates">
                            {" "}
                            {t("en") === "en"
                              ? category?.category_title
                              : category?.category_title_ar}
                          </p>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </>
            )}
          </Row>
        </Col>
      )}
      <Col xl={conditionMainClass} className="mt-5">
        {auction && !loader && (
          <h2 className="plateheadings">{t("auction")}</h2>
        )}
        <Row className="gx-4 gy-4 cardsdirection">
          {loader ? (
            <ClipLoader
              color={"#fff"}
              loading={loader}
              cssOverride={override}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <>
              {auctionOption?.map((category: any, index: number) => {
                lessTimeClassAuction = isLessTime(category?.closest_end_time)
                  ? "overtime"
                  : "time";
                return (
                  <Fragment key={category?.category_id}>
                    <Col
                      xs={conditionSubClass[0]}
                      sm={conditionSubClass[1]}
                      xl={conditionSubClass[2]}
                      className="text-center ArabicCardsDirection"
                    >
                      <div className="CarPlatesBox">
                        <div
                          className="carplates"
                          onClick={() => openAuctionPage(category)}
                        >
                          <img
                            src={category?.category_icon}
                            alt={category?.category_title}
                          />
                          {category?.count > 0 ? (
                            <span className="platenumbers">
                              {category?.count}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="text-center">
                          <>
                            <p className="plates">
                              {t("en") === "en"
                                ? category?.category_title
                                : category?.category_title_ar}
                            </p>
                            <Stopwatch
                              compName={"home"}
                              endTime={category?.closest_end_time}
                            />
                          </>
                        </div>
                      </div>
                    </Col>
                  </Fragment>
                );
              })}
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default HomeAuctions;
