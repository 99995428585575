import { useState, useRef, useEffect } from "react";
import "./modals.scss";

let currentOtpIndex: number = 0;
type Props = {
  handleOtp: any;
  setOtp: any;
  otp: any;
  hasError: boolean;
  inputDisabled?: any;
};

const UserOtp = (props: Props) => {
  const [activeOtpIndex, setActiveOtpIndex] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleOnchange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    const newOtp: string[] = [...props.otp];
    newOtp[currentOtpIndex] = value.substring(value.length - 1);

    if (!value) {
      setActiveOtpIndex(currentOtpIndex - 1);
    } else {
      setActiveOtpIndex(currentOtpIndex + 1);
    }
    props.setOtp(newOtp);
    props.handleOtp(newOtp);
  };
  const handleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    currentOtpIndex = index;
    if (key === "Backspace") {
      setActiveOtpIndex(currentOtpIndex - 1);
    }
  };
  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtpIndex]);

  useEffect(()=>{
    setTimeout(()=>{
      inputRef.current?.focus();
    },1000)
  })

  return (
    <div className="d-flex justify-content-between">
      {props.otp.map((_: any, index: any) => {
        return (
          <input
            disabled={props.inputDisabled}
            key={index}
            ref={index === activeOtpIndex ? inputRef : null}
            type="number"
            className={`otp-fields spin-button-none ${props.hasError ? "failure" : ""
              }`}
            onKeyDown={(e) => handleOnKeyDown(e, index)}
            onChange={handleOnchange}
            value={props.otp[index]}
            data-hj-allow
          />
        );
      })}
    </div>
  );
};
export default UserOtp;
