import "./footer.scss";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FooterHeading from "../headings/footerheading";
import Figure from "react-bootstrap/Figure";
import IosButton from "../buttons/iosbutton";
import AndroidButton from "../buttons/androidbutton";
import { useTranslation } from "react-i18next";
import FooterLinks from "../anchorlinks/footerlinks";
//import MazadLogo from "../../assets/Icons/mazad-logo.svg";
import MazadFooterLogo from "../../assets/Icons/mazadfooter.png";
import Twitter from "../../assets/Icons/twitter.svg";
import Instagram from "../../assets/Icons/instagram.svg";
import Facebook from "../../assets/Icons/facebook.svg";
import Youtube from "../../assets/Icons/youtube.svg";
import LinkedIn from "../../assets/Icons/linkedin.svg";
import IphoneWhite from "../../assets/Icons/iphone-white.svg";
import AndroidWhite from "../../assets/Icons/android-white.svg";
import MazadLogo from "../../assets/Icons/Splash-Logo.png";
import { useContext } from "react";
import { UserContext } from "../../contexts/signup";


const Footer = () => {
  const { t } = useTranslation();
  const { user } = useContext<any>(UserContext);
  const { contactDetails } = user;

  return (
    <Container fluid className="footerline" style={{marginTop:'5rem'}}>
      <Container>
        <Row className="footersection">
          <Col xs={6} sm={3}>
            <FooterHeading value={t("help")} />
            <ul className="footerlist">
              <li>
                {/* <FooterLinks value={t("contact_support")} url={t("")} /> */}
                <FooterLinks
                  value={t("contact_support")}
                  url={"/contact-us"}
                />
              </li>
              <li>
                <FooterLinks
                  value={t("privacy_policy")}
                  url={"/privacy-policy"}
                />
              </li>
              <li>
                {" "}
                {/* <FooterLinks value={t("terms_of_services")}  url={t('')} />{" "} */}
                <FooterLinks
                  value={t("terms_of_services")}
                  url={"/terms-of-service"}
                />{" "}
              </li>
              <li>
                {" "}
                <FooterLinks value={t("cookie_policy")} url={"/cookie-policy"} />
              </li>
              {/* <li>
                <FooterLinks value={t("terms_of_use")} url={"/terms-of-use"} />
              </li> */}
            </ul>
          </Col>

          <Col xs={6} sm={3}>
            <FooterHeading value={t("resources")} />
            <ul className="footerlist">
             
              <li>
                <a className="footerlinks" target="_blank" href={contactDetails?.faq_link?.value ? contactDetails?.faq_link?.value : '#'} >{t("faq")} </a>
              </li>
              <li>
                <a className="footerlinks" target="_blank" href={contactDetails?.guides?.value ? contactDetails?.guides?.value : '#'}> {t("guides")} </a>
              </li>
              <li>       
                <a  className="footerlinks" target="_blank" href={contactDetails?.status?.value ? contactDetails?.status?.value : '#'} > {t("status")} </a>
              </li>
            </ul>
          </Col>
          <Col xs={6} sm={3}>
           
          </Col>
          <Col
            xs={6}
            sm={3}
            className="d-flex justify-content-lg-end footerlogosection align-items-start"
          >
            <div className="d-flex align-items-center cardsdirection">
              <img src={MazadLogo} alt="mazadimg" className="footerlogo" />
              {/* <div className="d-flex flex-column">
                <img
                  src={MazadFooterLogo}
                  alt="mazadfooter"
                  className="mazadfooter"
                />
                <h2 className="brand-name">mazad</h2>
              </div> */}
            </div>
          </Col>
        </Row>
        <Row className="mb-5 bottomlinks">
          <div className="d-flex justify-content-between footerbottomsection">
            <ul className="footericons d-flex align-items-center justify-content-center">
              {contactDetails?.twitter?.value && (
                <li>
                  <a href={contactDetails?.twitter?.value} target="_blank">
                    <Figure.Image alt="twitter" src={Twitter} />
                  </a>
                </li>
              )}
              {contactDetails?.instagram?.value && (
                <li>
                  <a href={contactDetails?.instagram?.value} target="_blank">
                    <Figure.Image alt="instagram" src={Instagram} />
                  </a>
                </li>
              )}
              {contactDetails?.facebook?.value && (
                <li>
                  <a href={contactDetails?.facebook?.value} target="_blank">
                    <Figure.Image alt="facebook" src={Facebook} />
                  </a>
                </li>
              )}
              {contactDetails?.youtube?.value && (
                <li>
                  <a href={contactDetails?.youtube?.value} target="_blank">
                    <Figure.Image alt="youtube" src={Youtube} />
                  </a>
                </li>
              )}
              {contactDetails?.linkedIn?.value && (
                <li>
                  <a href={contactDetails?.linkedIn?.value} target="_blank">
                    <Figure.Image alt="LinkedIn" src={LinkedIn} />
                  </a>
                </li>
              )}
            </ul>
            <div className="d-flex align-items-center downloadsection">
              <h3 className="downloadheading">{t("download_the_app")}</h3>

              <IosButton
                className="footerbuttons home-iosbutton"
                imgUrl={IphoneWhite}
                apple_store_link={contactDetails?.apple_store_link}
              />
              <AndroidButton className="footerbuttons" imgUrl={AndroidWhite} google_store_link={contactDetails?.google_store_link} />
            </div>
          </div>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
