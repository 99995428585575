import { Container } from "react-bootstrap";
import "./ordercompleteregistration.scss";
import OrderCompleteRegistrationPayment from "./ordercompleteregistrationpayment";

type Props = {};

const DirectCompleteRegistration = (props: Props) => {
  return (
    <Container>
      <OrderCompleteRegistrationPayment />
    </Container>
  );
};

export default DirectCompleteRegistration;
