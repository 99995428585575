import { Col } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import Deposits from "./deposits";
import FirstRouting from "./firstrouting";
import SecondRouting from "./secondrouting";
import ProfilePurchase from "./profilepurchase";
import TransactionHistory from "./transactionhistory";
import ThirdRouting from "./thirdrouting";
import Refund from "./refund";

const RightSideSection = () => {
  return (
    <Col lg={9}>
      <Routes>
        <Route path="/" element={<FirstRouting />} index />
        <Route path="notification" element={<SecondRouting />} />
        <Route path="setting" element={<ThirdRouting />} />
        <Route path="deposits" element={<Deposits />} />
        <Route path="refund" element={<Refund />} />
        <Route path="transactions" element={<TransactionHistory />} />
        <Route path="purchases" element={<ProfilePurchase />} />
      </Routes>
    </Col>
  );
};

export default RightSideSection;
