import { useTranslation } from "react-i18next";
import Stopwatch from "../../pages/stopwatch/stopwatch";
type Props = {

  featuredata: any;
};

const AuctionEndingButton = (props: Props) => {
   const { t } = useTranslation();

  const classnameValues :any = {
    'ending_soon' : 'auctionbutton',
    "featured": "featuredbutton",
    "pending_purchase": "claimbutton"

  }

  const titleValues :any = {
    'ending_soon' : 'ends in ',
    "featured": "featured",
    "pending_purchase": "complete purchase"

  }
  const ImageURL :any = {
    'ending_soon' : '../../assets/Icons/timer.svg',
    "featured": '../../assets/Icons/gavel.svg',
    "pending_purchase": "../../assets/Icons/cliamdoc.svg"

  }
  return (
    <button className={classnameValues[props.featuredata.card_type]}>
      <img src={ImageURL[props.featuredata.card_type]} className="auctionimage" alt="auctionbtn" />
      <span className="auctiontext">{titleValues[props.featuredata.card_type]} {props?.featuredata?.card_type === 'ending_soon' ? <Stopwatch compName="home_banner" endTime={props?.featuredata?.end_time}/> : ''} </span>
    </button>
  );
};

export default AuctionEndingButton;
