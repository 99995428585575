import React, { useState } from "react";
import { Container, Figure, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UseMyIdToggle from "../../components/directsales/usemyidtoggle";
import { apiWithToken } from "../../feature/envs/api";
import Success from "../../assets/Icons/file-upload-success.svg";
import { useNavigate } from "react-router-dom";

type Props = {
  selectedAssetId?: any;
  toggle?: any;
  setToggle?: any;
  cart_id?: any;
};

const OrderCompleteRegistrationDocument = (props: Props) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState(false);

  const handleChange = (e: any) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    updateData({ documents: e.target.files[0] });
  };

  const userDetails: any = {
    transaction_date: "",
    note: "",
  };

  const [paymentOptions, setPaymentOptions] = useState<any>(userDetails);

  const updateData = (obj: any) => {
    setPaymentOptions((prev: any) => ({ ...prev, ...obj }));
  };
  const navigate = useNavigate();
  const createUserID = () => {
    setLoading(true);
    const bankPayment = new FormData();
    paymentOptions?.documents &&
      bankPayment.append("document", paymentOptions?.documents || null);
    bankPayment.append("customer_note", "");
    bankPayment.append("type", "TRANSFER_OF_OWNERSHIP");
    bankPayment.append("asset_id", `${props?.selectedAssetId}`);
    bankPayment.append("use_my_details", props?.toggle);

    const getApi = () => {
      apiWithToken.get(`/api/v1/bids/my-bid`).then((res: any) => {
        if (res.data.status === 200) {
          const id = props?.selectedAssetId;
          const bidsWon = res.data.data.bids_won;
          const result = bidsWon.find(
            (obj: any) => obj.asset_id === +id && id !== 0
          );
          const { transaction } = result;
          setLoading(false);
          navigate(
            `/checkout?paymentid=${transaction?.transaction_id}&status=${result?.status}`
          );
        }
      });
    };

    const cartgetApi = () => {
      props?.cart_id > 0 &&
        apiWithToken
          .get(`/api/v1/order-details/${props?.cart_id}`)
          .then((res: any) => {
            if (res.data.status === 200) {
              const id = props?.selectedAssetId;
              const bidsWon = res.data.data.direct_sale;
              const result = bidsWon.find(
                (obj: any) => obj.asset_id === +id && id !== 0
              );
              const { transaction } = result;
              setLoading(false);
              navigate(
                `/checkout?paymentid=${transaction?.transaction_id}&status=${result?.status}`
              );
            }
          });
    };

    apiWithToken
      .post("api/v1/user-request", bankPayment, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        if (res.data.status === 200) {
          props?.cart_id > 0 ? cartgetApi() : getApi();
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Container>
      <Row>
        <div className="RegisterPaymentLeftSection">
          <div className="purchase_container">
            <UseMyIdToggle toggle={props.toggle} setToggle={props.setToggle} />
          </div>
          <div className="d-flex align-items-center TransforItem"></div>

          <div className={props.toggle === false ? "" : "docInput"}>
            <div className="updatepassport">
              <div className="d-flex align-items-center TransforItem">
                <label htmlFor="upload-file" className="upload-deposit-file">
                  <img src="./assets/Icons/passport-id.svg" alt="apple" />

                  <span>{t("personal_id_passport")}</span>
                </label>
                <input
                  id="upload-file"
                  type="file"
                  onChange={handleChange}
                  accept=".png, .jpg, .jpeg, .pdf"
                  hidden
                />
                <div className="file-success">
                  {file ? (
                    <Figure.Image
                      alt="file-upload-success"
                      style={{ transition: "0.9s" }}
                      src={Success}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <label htmlFor="upload-file" className="otherDoc">
        
          </label>
          <input
            id="upload-file"
            type="file"
            onChange={handleChange}
            accept=".png, .jpg, .jpeg, .pdf"
            hidden
          />

          <button
            type="button"
            className={
              props.toggle || file ? "confirm-otp-number" : "disable_btn"
            }
            onClick={createUserID}
            disabled={loading}
          >
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <>
                <>{t("submit")}</>{" "}
              </>
            )}
          </button>
        </div>
      </Row>
    </Container>
  );
};

export default React.memo(OrderCompleteRegistrationDocument);
