import "./tabs.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import BidsWon from "../../pages/bidswon/bidswon";
import MyWatchListDirectSales from "../../pages/mywatchlist/mywatchlistdirectsales";
import MyPuruchasesAction from "../../pages/internalrounting/mypuruchasesaction";
import OngoingBidsNew from "../../pages/mybids/ongoingbidsnew";
import CommonWatchlist from "../../pages/mywatchlist/commonwatchlist";
import { useSearchParams } from "react-router-dom";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { apiWithToken } from "../../feature/envs/api";
import { trackPageView } from "../../utils/analytics";

type Props = {
  className: string;
  value1: string;
  value2: string;
  tabValue1: string;
  tabValue2: string;
};

const NavTabs = (props: Props) => {
  const [searchParams] = useSearchParams();
  const [filteredAssetWonData, setFilteredAssetWonData] = useState([]);
  const [filteredAssetOngoingData, setFilteredAssetOngoingData] = useState([]);
  const [filteredAssetAuctionWatchData, setFilteredAssetAuctionWatchData] =
    useState([]);
  const [filteredAssetDirectWatchData, setFilteredAssetDirectWatchData] =
    useState([]);

  const [filteredAssetAuctionPurchase, setFilteredAssetAuctionPurchase] =
    useState([]);
  const [filteredAssetDirectPurchase, setFilteredAssetDirectPurchase] =
    useState([]);
  const [assetLoader, setAssetLoader] = useState(false);

  const tabVal =
    searchParams.get("val") === t("bids_won")
      ? t("bids_won")
      : searchParams.get("val") === t("direct_sale")
      ? t("direct_sale")
      : props.value2;

  const getApiMyBids = () => {
    apiWithToken
      .get(`/api/v1/bids/my-bid`)
      .then((res: any) => {
        if (res.data.status === 200) {
          if (res.data.status === 200) {
            setFilteredAssetWonData(res?.data?.data?.bids_won);
            setFilteredAssetOngoingData(res?.data?.data?.ongoing_bids);
          }
        }
      })
      .finally(() => {
        setAssetLoader(false);
      });
  };

  const getApiMyWatchlist = () => {
    apiWithToken.get(`/api/v1/watchlist/my-watchlist`).then((res: any) => {
      if (res.data.status === 200) {
        setFilteredAssetAuctionWatchData(res?.data?.data?.auctions);
        setFilteredAssetDirectWatchData(res?.data?.data?.direct_sale);
        setAssetLoader(false);
      }
    });
  };

  const getApiMyPurchases = () => {
    apiWithToken.get(`/api/v1/my-purchases`).then((res: any) => {
      if (res.data.status === 200) {
        setFilteredAssetDirectPurchase(res.data.data.direct_sale);
        setFilteredAssetAuctionPurchase(res.data.data.auction);
        setAssetLoader(false);
      }
    });
  };

  useEffect(() => {
    let pageViewTitle: string;
    switch (props?.tabValue2) {
      case "bidsWon":
        pageViewTitle = "My Bids";
        break;
      case "watchListDirectSale":
        pageViewTitle = "Watch List";
        break;
      case "myPurchaseDirect":
        pageViewTitle = "My Purchases";
        break;
      default:
        pageViewTitle = "Mazad";
    }
    trackPageView(
      window.location.pathname + window.location.search,
      pageViewTitle
    );

    setAssetLoader(true);
    props?.tabValue2 == "bidsWon" && getApiMyBids();
    props?.tabValue2 == "watchListDirectSale" && getApiMyWatchlist();
    props?.tabValue2 == "myPurchaseDirect" && getApiMyPurchases();
  }, []);

  return (
    <Tabs
      defaultActiveKey={tabVal}
      id="uncontrolled-tab-example"
      className={`${props.className}`}
    >
      <Tab eventKey={`${props.value2}`} title={`${props?.value2}`}>
        {props.tabValue2 === "bidsWon" ? (
          <BidsWon
            filteredAssetData={filteredAssetWonData ?? filteredAssetWonData}
            setFilteredAssetData={setFilteredAssetWonData}
            assetLoader={assetLoader}
          />
        ) : (
          ""
        )}
        {props.tabValue2 === "watchListDirectSale" ? (
          <MyWatchListDirectSales
            filteredAssetData={
              filteredAssetDirectWatchData ?? filteredAssetDirectWatchData
            }
            setFilteredAssetData={setFilteredAssetDirectWatchData}
            assetLoader={assetLoader}
          />
        ) : (
          ""
        )}
        {props.tabValue2 === "myPurchaseDirect" ? (
          <MyPuruchasesAction
            type={props?.value2}
            filteredAssetData={
              filteredAssetDirectPurchase ?? filteredAssetDirectPurchase
            }
            setFilteredAssetData={setFilteredAssetDirectPurchase}
            assetLoader={assetLoader}
          />
        ) : (
          ""
        )}
      </Tab>
      <Tab eventKey={`${props.value1}`} title={`${props.value1}`}>
        {props.tabValue1 === "bidsOngoing" ? (
          <OngoingBidsNew
            bidsUrl="./assets/Icons/details-red.svg"
            filteredAssetData={
              filteredAssetOngoingData ?? filteredAssetOngoingData
            }
            setFilteredAssetData={setFilteredAssetOngoingData}
            assetLoader={assetLoader}
          />
        ) : (
          ""
        )}
        {props.tabValue1 === "watchlistAuction" ? (
          <CommonWatchlist
            filteredAssetData={
              filteredAssetAuctionWatchData ?? filteredAssetAuctionWatchData
            }
            setFilteredAssetData={setFilteredAssetAuctionWatchData}
            assetLoader={assetLoader}
          />
        ) : (
          ""
        )}
        {props.tabValue1 === "myPurchaseAuction" ? (
          <MyPuruchasesAction
            type={props.value1}
            filteredAssetData={
              filteredAssetAuctionPurchase ?? filteredAssetAuctionPurchase
            }
            setFilteredAssetData={setFilteredAssetAuctionPurchase}
            assetLoader={assetLoader}
          />
        ) : (
          ""
        )}
      </Tab>
    </Tabs>
  );
};

export default NavTabs;
