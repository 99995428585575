import NavTabs from "../../components/tabs/navtabs";
import { useTranslation } from "react-i18next";
type Props = {};

const ProfilePurchase = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className="MyPurchasesKeys">
      <NavTabs
        className="keyscontent"
        value1={t("auction")}
        value2={t("direct_sale")}
        tabValue1="myPurchaseAuction"
        tabValue2="myPurchaseDirect"
      />
    </div>
  );
};

export default ProfilePurchase;
