import RightArrow from "../../assets/Icons/rightarrow.svg";
import RequestIcon from "../../assets/Icons/request-viewing.svg";
import InquiryIcon from "../../assets/Icons/send-enquiry.svg";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import CrossMark from "../../assets/Icons/crossmark.svg";
import Countryfeild from "../inputfeilds/countryfeild";
import DatePicker from "react-datepicker";
import CalenderIcon from "../../assets/Icons/calender.svg";
import { apiWithToken } from "../../feature/envs/api";
import { toast } from "react-toastify";
import SignInPhoneModal from "../modals/signinphonemodal";
import { UserContext } from "../../contexts/signup";
import { dateWithTimeUnix } from "../../utils/functions";

type Props = {
  heading?: any;
  assetItem?: any;
  customer_inquiry?: any;
  request_for_view?: any;
};

const ActionSection = (props: Props) => {
  const { heading, assetItem, customer_inquiry, request_for_view } = props;
  const { t } = useTranslation();
  const [fullscreenview, setFullscreenView] = useState<any>(true);
  const [showView, setShowView] = useState(false);
  const [phoneInvalid, setPhoneInvalid] = useState(true);
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>();
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const { user } = useContext<any>(UserContext);
  const [number, setNumber] = useState();

  // Calculate tomorrow's date
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  function handleShowView(breakpoint: any) {
    if (!user?.userLoggedIn) {
      setShowSignIn(true);
    } else {
      setFullscreenView(breakpoint);
      setShowView(true);
    }
  }
  const placeHolder = t("select_viewing_date_time");

  const { asset_id } = assetItem;
  function CustomInput(props: any) {
    return (
      <div className=" d-flex justify-content-start PaymentMethods PaymentHighlight dateCust">
        <div className="datepicker-input1" onClick={props.onClick}>
          <span>
            <img src={CalenderIcon} alt="Calender" />
          </span>
        </div>

        <input
          type="text"
          className="datepicker-input1 w-100 "
          onClick={props.onClick}
          value={props.value}
          onChange={props.onChange}
          placeholder={placeHolder}
          data-hj-allow
        />
      </div>
    );
  }

  const formatWeekday = (dayName: any) => {
    return dayName.substr(0, 3);
  };

  const view_date_time = dateWithTimeUnix(startDate);

  const handleViewRequest = (() => {
    setLoading(true);
    apiWithToken
      .post(`api/v1/user-request`, { type: 'REQUEST_FOR_VIEWING', asset_id: asset_id, phone_number: number, date_and_time: view_date_time })
      .then((res: any) => {
        if (res.data.status === 200) {
          toast(<p className="CartSuccessMsg">{t('thank_you_for_your_request')}</p>, {
            autoClose: 3000,
            hideProgressBar: true,
            toastId: "cartToast",
          });
          setStartDate(null);
        } else {
          if (res.data.status === 400) {
            toast(<p className="CartErrMsgWatch">{t("request_already_existed")}</p>, {
              autoClose: 2500,
              hideProgressBar: true,
              toastId: "cartToast",
            });
          }else{
            toast(<p className="CartErrMsgWatch">{res.data.message}</p>, {
              autoClose: 2500,
              hideProgressBar: true,
              toastId: "cartToast",
            });
          }
         
          setStartDate(null);
        }
      }).finally(() => {
        setLoading(false);
        setShowView(false);
      });
  })

  const openModal = (modaltype: any) => {
    modaltype === "signin" ? setShowSignIn(true) : setShowSignUp(true);
  };
  const closeModal = (modaltype: any) => {
    modaltype === "signin" ? setShowSignIn(false) : setShowSignUp(false);
  };

  const minTime = new Date();
  minTime.setHours(8, 0); // Set minimum time to 8 AM

  const maxTime = new Date();
  maxTime.setHours(15, 0); // Set maximum time to 3 PM

  // Function to check if a date is between Sunday to Thursday
  const isWeekday = (date: any) => {
    const day = date.getDay();
    return day >= 0 && day <= 4; // Sunday is 0 and Thursday is 4
  };

// Handles the show inquiry action for a given asset
const handleShowInquiry = (asset_id: any, asset_title: string, asset_title_ar: string) => {
  let message = "";

  message = t("en") === "en" ? `I would like to inquire about this asset "${asset_title}", with the lot number ${asset_id}` : `اود الاستفسار عن هذا العقار ${asset_title_ar}, برقم ${asset_id}`;

  (window as any).Intercom('showNewMessage', message);
}

  return (
    <>
      {(request_for_view || customer_inquiry) &&

        <div className="DocumentsTermsPrivacy mt-5">
          <h2 className="profileheading">{heading}</h2>

          {request_for_view &&
            <div
              className="DocumentsData d-flex justify-content-between"
              key={'request'}
              onClick={() => handleShowView("sm-down")}
            >
              <p className="DocumentsDataText PlatePurchase">
                <img src={RequestIcon} alt="folder" />
                {t('request_for_viewing')}
              </p>
              <img src={RightArrow} alt="rightarrow" />
            </div>
          }
          {customer_inquiry &&
            <div
              className="DocumentsData d-flex justify-content-between"
              key={'send_inquiry'}
              onClick={() => handleShowInquiry(assetItem.asset_id, assetItem.asset_title, assetItem.asset_title_ar)}
            >
              <p className="DocumentsDataText PlatePurchase">
                <img src={InquiryIcon} alt="folder" />
                {t('send_inquiry')}
              </p>
              <img src={RightArrow} alt="rightarrow" />
            </div>
          }
        </div>
      }

      <Modal
        show={showView}
        fullscreen={fullscreenview}
        onHide={() => setShowView(false)}
        className="NotificationModal"
      >
        <Modal.Header className="d-flex align-items-center">
          <div className="CloseButtons">
            <button
              className="bahrainarrow"
              onClick={() => setShowView(false)}
            >
              <img src={CrossMark} alt="crossmark" />
            </button>
          </div>

          <div className="HeadingContainer">
            <h2 className="confirmnumber">
              {t("request_for_viewing")}
            </h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form className="number-form" onSubmit={(e) => e.preventDefault()}>
            <div className="mb-5 w-100">
              <DatePicker
                selected={startDate}
                minDate={tomorrow}
                customInput={<CustomInput />}
                formatWeekDay={formatWeekday}
                onChange={(date: any) => {
                  setStartDate(date);
                }}
                dateFormat="dd/MM/yyyy hh:mm aa"
                placeholderText={placeHolder}
                showTimeSelect
                timeFormat="hh:mm aa"
                timeIntervals={30} // Interval of time selection
                minTime={minTime} // Minimum time 8 AM
                maxTime={maxTime} // Maximum time 3 PM
                filterDate={isWeekday} // Allow selection only on Sunday to Thursday
              />
            </div>
            <div className="mb-5">

              <Countryfeild
                state={state}
                setState={setState}
                setPhoneInvalid={setPhoneInvalid}
                setLoading={setLoading}
                setNumber={setNumber}
              />
            </div>
          </form>
          
          <button
            // Changed the disabled state from phoneInvalid to loading
            disabled={loading}
            className={`signin-phone sign-top ${phoneInvalid || !startDate ? "disable_btn" : ""
              }`}
            onClick={() => {
              handleViewRequest();
            }}
          >
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <>
                <>{t("submit_request")}</>{" "}
              </>
            )}
          </button>
        </Modal.Body>
        <div className="d-flex">
          <p className="locktext-request">
            {t("terms_privacy_policy")}{" "}
            <a href="http://www.mazad.app/terms-of-service">
              {t("terms_conditions")}{" "}
            </a>
            {t("and")}{" "}
            <a href="http://www.mazad.app/privacy-policy">
              {t("lock_privacy_policy")}
            </a>
          </p>
        </div>
      </Modal>

      <SignInPhoneModal
        handleCloseModal={closeModal}
        showSignIn={showSignIn}
        handleOpenModal={openModal}
        modalType={t("header_signin")}
      />

    </>
  );
};

export default ActionSection;
