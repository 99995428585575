import { CSSProperties, useCallback, useContext, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BidsWonCardBahrain from "../../components/bidswoncards/bidswoncardbahrain";
import BidsWonCardBottom from "../../components/bidswoncards/bidswoncardbottom";
import { useTranslation } from "react-i18next";
import MyBidsEmpty from "../mybids/mybidsempty";
import { ClipLoader } from "react-spinners";
import "./bidswon.scss";
import { useNavigate } from "react-router-dom";
import Page from "../../assets/Icons/pages.svg";
import Arrow from "../../assets/Icons/bahrain-arrow.svg";
import noBids from "../../assets/Icons/noBids.svg";
import { connectSocket, disconnectSocket } from "../../utils/socket";
import { CartCount } from "../../contexts/signup";

let socket: any = null;
let assetsArr: any = [];
let asset_id: any = null;
type Props = {
  filteredAssetData?:any,
  setFilteredAssetData?:any,
  assetLoader?:any;
}
const BidsWon = (props:Props) => {
  const {filteredAssetData, setFilteredAssetData, assetLoader} = props;
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setCartCount } = useContext<any>(CartCount);

  const pendingStatuses = [
    "pending_for_payment",
    "pending_for_payment_approval",
    "pending_registration",
    "pending_purchase",
  ];

  const override: CSSProperties = {
    display: "block",
    margin: "20% auto",
    borderColor: "black",
    overflow: "-moz-hidden-unscrollable",
    opacity: 0.1,
  };


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    assetsArr = [];
    socket = connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    addSocketListeners();
    return () => {
      socket.off("make-bid", emitMakeBid);
      socket.off("turn-off-auto-bid", turnOffAutoBid);
      socket.off("add-to-watchlist", addWatchlist);
      socket.off("remove-from-watchlist", removeWatchlist);
      socket.off("add-to-cart", addRemoveCartSocketCall);
      socket.off("remove-from-cart", addRemoveCartSocketCall);
      socket.off("my-cart", addRemoveCartSocketCall);
      socket.off("my-cart-details", getMyCartDetails);
      socket.on("my-bids", getMyBids);
      socket.on("my-bid-details", getMyBidsDetails);
    };
  }, [socket]);

  const addSocketListeners = () => {
    if (socket !== null) {
      socket.on("make-bid", emitMakeBid);
      socket.on("turn-off-auto-bid", turnOffAutoBid);
      socket.on("add-to-watchlist", addWatchlist);
      socket.on("remove-from-watchlist", removeWatchlist);
      socket.on("add-to-cart", addRemoveCartSocketCall);
      socket.on("remove-from-cart", addRemoveCartSocketCall);
      socket.on("my-cart", addRemoveCartSocketCall);
      socket.on("my-cart-details", getMyCartDetails);
      socket.on("my-bids", getMyBids);
      socket.on("my-bid-details", getMyBidsDetails)
    }
  };

  const getMyCartDetails = useCallback((res: any) => {
    let count: any = null;
    if (res?.data?.cart && res?.data?.cart?.items?.length) {
      count = res?.data?.cart?.items?.length;
    } else {
      count = null;
    }
    setCartCount(count);
  }, []);

  const getMyBids = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
     if (authToken !== null && authToken !== undefined) {
      socket.emit("get-my-bid", {
        authorization: authToken,
      });
    }
  }, []);


  const emitMakeBid = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("get-bid-details", {
        asset_id: res.data.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const addWatchlist = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("get-bid-details", {
        asset_id: res.data.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const removeWatchlist = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("get-bid-details", {
        asset_id: res.data.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const getMyBidsDetails = useCallback((res: any) => {
    setFilteredAssetData(res.data.bids_won);
  }, []);

  

  const turnOffAutoBid = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (
      +res?.data?.asset_id === +asset_id &&
      authToken !== null &&
      authToken !== undefined
    ) {
      socket.emit("get-bid-details", {
        asset_id: res?.data?.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const addRemoveCartSocketCall = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("get-bid-details", {
        asset_id: res.data.asset_id,
        authorization: authToken,
      });
      socket.emit("my-cart", {
        authorization: authToken,
      });
    }
  }, []);

  return (
    <Row>
      {filteredAssetData?.length > 0 ? (
        <>
          {filteredAssetData.map((asset: any, index: number) => {
            return (
              <Col lg={4} md={6}>
                <div
                  className="biddigwonsection"
                >
                  <BidsWonCardBahrain
                    asset={asset}
                    imgUrl={
                      pendingStatuses.includes(asset.status) ? Arrow : Page
                    }
                  />

                  <BidsWonCardBottom
                    highest_price={asset.highest_price}
                    end_time={asset?.purchase_end_time}
                    className={asset.status}
                    name={asset?.status}
                  />
                </div>
              </Col>
            );
          })}
        </>
      ) : (
        <>
          {assetLoader && (
            <ClipLoader
              color={"#fff"}
              loading={assetLoader}
              cssOverride={override}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
          {!assetLoader && (
            <MyBidsEmpty imgUrl={noBids} title={"ongoingbidswon"} />
          )}
        </>
      )}
    </Row>
  );
};

export default BidsWon;
