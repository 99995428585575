import "./buttons.scss";
type Props = {
  imgUrl: string;
  className: string;
  apple_store_link?: string;
};

const handleClick = (apple_store_link:any)=> {
  if(apple_store_link?.value) window.open(apple_store_link?.value, '_blank');
}

const IosButton = (props: Props) => {
  return (
    <button className={props.className} onClick={()=>handleClick(props?.apple_store_link)}>
      <img src={props.imgUrl} alt="" />
      <span className="iostext">iOS</span>
    </button>
  );
};

export default IosButton;
