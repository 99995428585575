import { apiWithToken } from "../feature/envs/api";

export const base_url = "http://43.241.63.71:3146/";

let headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Accept", "GET");

var requestOptions = {
  method: "GET",
  headers: headers,
};

let baseURL: any = "";
const hostname = window.location.hostname;

if (hostname.includes("staging")) {
  baseURL = process.env.REACT_APP_API_BASE_URL_STAGE;
} else if (hostname.includes("dev") || hostname.includes("localhost")) {
  baseURL = process.env.REACT_APP_API_BASE_URL_DEV;
} else {
  baseURL = process.env.REACT_APP_API_BASE_URL;
}

export const countriesApi = () => {
  fetch(`${baseURL}/countries?lang=en`, requestOptions);
};

export const fetchAuctions = (setAllData: any, setAuctionOption?: any) => {
  return apiWithToken.get("api/v1/category/home-auction").then((res: any) => {
    if (res?.status === 200) {
      if (setAuctionOption) {
        setAuctionOption(res?.data?.data?.auctions);
      }

      const category = res?.data?.data?.auctions?.map((data: any) => {
        return {
          category_icon: data?.category_icon,
          category_id: data?.category_id,
          category_title: data?.category_title,
          category_title_ar: data?.category_title_ar,
        };
      });
      setAllData((prevData: any) => ({
        ...prevData,
        auctions: res?.data?.data?.auctions,
        auction_category: category,
      }));
      localStorage.setItem("auction_category", JSON.stringify(category));
    } else {
      setAuctionOption([]);
    }
  });
};

export const fetchDirectSale = (setAllData: any, setDirectSaleOption?: any) => {
  return apiWithToken
    .get("api/v2/category/home-directsale")
    .then((res: any) => {
      if (res?.status === 200) {
        const category = res?.data?.data?.direct_sale?.map((data: any) => {
          return {
            category_icon: data?.category_icon,
            category_id: data?.category_id,
            category_title: data?.category_title,
            category_title_ar: data?.category_title_ar,
          };
        });
        localStorage.setItem("direct_category", JSON.stringify(category));
        if (setDirectSaleOption) {
          setDirectSaleOption(res?.data?.data?.direct_sale);
        }
        setAllData((prevData: any) => ({
          ...prevData,
          directsale: res?.data?.data?.direct_sale,
          direct_category: category,
        }));
      } else {
        setDirectSaleOption([]);
      }
    });
};

export const fetchFeaturedCards = (setAllData: any) => {
  return apiWithToken
    .get("api/v2/category/home-featured-cards")
    .then((res: any) => {
      if (res?.status === 200) {
        setAllData((prevData: any) => ({
          ...prevData,
          featured_cards: res?.data?.data?.featured_cards,
        }));
      }
    });
};
