// analytics.js
import ReactGA from "react-ga4";
import { flattenObject } from "./EventLogger";

let trackingId = null;
const hostname = window.location.hostname;

if (hostname.includes("staging")) {
  trackingId = "G-P47LF6NXRF";
} else if (hostname.includes("dev") || hostname.includes("localhost")) {
  trackingId = "G-YR9GNTWDK7";
} else {
  trackingId = "G-81BGL8VY98";
}

// Initialize Google Analytics
ReactGA.initialize(trackingId);

// Set user ID if available
const setUserId = (userId) => {
  ReactGA.initialize(trackingId, { userId });
  ReactGA.set({ userId: userId });
};

// Track page view
const trackPageView = (pathname, title) => {
  ReactGA.send({ hitType: "pageview", page: pathname, title });
};


/**
 * Tracks a custom event using ReactGA.
 *
 * @param {string} eventName - The name of the event to track. Defaults to an empty string.
 * @param {object} eventParams - Additional parameters for the event. Defaults to an empty object.
 * @return {void} This function does not return a value.
 */
const trackGA4Event = (eventName = "", eventParams = {}) => {
  ReactGA.event(eventName, flattenObject(eventParams, "__"));
  // ReactGA.event({
  //   category: "Mazad",
  //   action: eventName,
  //   // custom: flattenObject(eventParams),
  // });
};

export { setUserId, trackPageView, trackGA4Event };
