import { useTranslation } from "react-i18next";
import Stopwatch from "../../pages/stopwatch/stopwatch";
import dummyImg_2 from "../../assets/Images/bmw-m5.png";
import carplateImg from "../../assets/Icons/Car Plate (Long).svg";
import Taxiplatelong from "../../assets/Icons/taxi_long.svg";

type Props = {
  selectedAsset?: any;
};

const DirectsaleBahrainBottomCenter = (props: Props) => {
  const { purchase_end_time } =
    props.selectedAsset !== null ? props?.selectedAsset : [];
  const { t } = useTranslation();
  const longPlate = props?.selectedAsset?.category_title?.split(" ")[0] === "Car" ? carplateImg : Taxiplatelong;
  const classVal = props?.selectedAsset?.category_title?.split(" ")[0] === "Car" ? 'box_img33' : 'box_img44';
  const dummyImg_1_new = (
    <div className="plate_container_bidswon">
      <img src={longPlate} alt={props?.selectedAsset?.asset_title} />
      <div className={classVal}>
        <span className="carFont">{props?.selectedAsset?.asset_title}</span>
      </div>
    </div>
  );
  const dummyImg_2_new = (
    // <img src={dummyImg_2} alt="bahrain" className="bidswonbahrain" />
    <div className="bidswonbahrain customBg"></div>
  );

  const boxImg =
    props?.selectedAsset?.category_title.split(" ")[0] === "Taxi" ||
    props?.selectedAsset?.category_title.split(" ")[0] === "Car"
      ? dummyImg_1_new
      : dummyImg_2_new;

  return (
    <div className="biddigwonsection">
      <div className="biddingswoncard d-flex justify-content-center">
        <div className="container_font">{boxImg}</div>
      </div>
      <div className="bidregister">
        <p className="pending_registration text-center">
          {t("time_remaining_to_complete_purchase")}{" "}
          <Stopwatch compName={"bidsWon"} endTime={purchase_end_time} />
        </p>
      </div>
    </div>
  );
};

export default DirectsaleBahrainBottomCenter;
