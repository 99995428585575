import { t } from "i18next";
import { Modal } from "react-bootstrap";
import "../internalrounting/internalrouting.scss";
import CrossMark from "../../assets/Icons/crossmark.svg";
type Props = {
  showViewMore?: any;
  fullscreenview?: any;
  setViewMore?: any;
  assetDetails?: any;
  viewName?: any;
};
const ViewMoreModal = (props: Props) => {
  const { showViewMore, fullscreenview, setViewMore, assetDetails, viewName } =
    props;
  type AnyRecord = Record<string, any>;
  const objDetails: AnyRecord = assetDetails && t("en")==="en" ? assetDetails?.asset_details : assetDetails?.asset_details_ar ? assetDetails?.asset_details_ar : [];
  let myElements,
    title = "";

  if (viewName === "DescriptionButton") {
    myElements = assetDetails && assetDetails?.description && (
      <div className="d-flex justify-content-between align-items-center DetailsListItems">
        <p className="DetailsModalText">
          {assetDetails && t("en")==="en" ? assetDetails?.description :assetDetails?.description_ar}
        </p>
      </div>
    );
    title = t("description");
  } else {
    myElements =
      assetDetails &&
      assetDetails?.asset_details &&
      Object.entries(objDetails).map(([key, value], index) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center DetailsListItems"
            key={index}
          >
            <p className="DetailsItems">{key}</p>
            <p className="DetailsPrice">{value}</p>
          </div>
        );
      });
    title = t("details");
  }

  return (
    <>
      <Modal
        show={showViewMore}
        fullscreen={fullscreenview}
        onHide={() => setViewMore(false)}
        className="NotificationModal modal-dialog_custom"
      >
        <Modal.Header className="d-flex align-items-center">
          <div className="CloseButtons">
            <button className="bahrainarrow" onClick={() => setViewMore(false)}>
              <img src={CrossMark} alt="cancelbutton" />
            </button>
          </div>
          <div className="HeadingContainer me-4">
            <h2 className="confirmnumber">{title}</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="DetailsModalSection">{myElements} </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewMoreModal;
