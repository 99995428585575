import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import CrossMark from "../../assets/Icons/crossmark.svg";

const SecondRouting = () => {
  const { t } = useTranslation();
  const [fullscreen, setFullscreen] = useState<any>(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint: any) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const notificationArr = [
    {
      id: "c1",
      imgUrl: "../assets/Images/notification-img1.png",
      title: t("outbid"),
      text: t("outbid_text"),
      time: t("outbid_time"),
    },
    {
      id: "c2",
      imgUrl: "../assets/Images/notification-img2.png",
      title: t("bid_won"),
      text: t("bid_won_text"),
      time: t("bid_won_time"),
    },
    {
      id: "c3",
      imgUrl: "../assets/Images/bmw-m5.png",
      title: t("complete_registration"),
      text: t("complete_registration_text"),
      time: t("complete_registration_time"),
    },
    {
      id: "c4",
      imgUrl: "../assets/Images/notification-img4.png",
      title: t("featured"),
      text: t("featured_text"),
      time: t("featured_time"),
    },
    {
      id: "c5",
      imgUrl: "../assets/Images/office-furniture.png",
      title: t("live_event"),
      text: t("live_event_text"),
      time: t("live_event_time"),
    },
    {
      id: "c5",
      imgUrl: "../assets/Images/direct-sale.png",
      title: t("direct_sale"),
      text: t("direct_sale_text"),
      time: t("direct_sale_time"),
    },
  ];
  return (
    <>
      <div className="UserProfileLeft">
        <div className="d-flex justify-content-end ClearAllNotifications">
          <button
            className="NotificationButton"
            onClick={() => handleShow("sm-down")}
          >
            {t("clear_all_notifications")}
          </button>
        </div>
        {notificationArr?.map((data) => (
          <div className="d-flex userprofilepicture " key={data.id}>
            <img
              src={data.imgUrl}
              alt="notifiaction"
              className="notificationimages"
            />
            <div className="notificationdetails">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="profileheading">{data.title}</h2>
                <p className="notificationtime">{data.time}</p>
              </div>

              <p className="notificationtext"> {data.text}</p>
            </div>
          </div>
        ))}
      </div>

      <Modal
        show={show}
        fullscreen={fullscreen}
        onHide={() => setShow(false)}
        className="NotificationModal"
      >
        <Modal.Header>
          {/* <button className="crossbutton" onClick={() => setShow(false)}>
            <img src="../assets/Icons/crossmark.svg" alt="cancelbutton" />
          </button> */}
          <div className="CloseButtons">
            <button className="bahrainarrow" onClick={() => setShow(false)}>
              <img src={CrossMark} alt="crossmark" />
            </button>
            </div>
          <h2 className="confirmnumber">{t("notifications")}</h2>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center flex-column">
          <img
            src="../assets/Icons/notificationbell.svg"
            alt="notficationbell"
            className="NotificationImage"
          />
          <h2 className="profileheading">{t("are_you_sure")}</h2>
          <p className="ModalClearText">
            {t("are_you_sure_text")} <br /> {t("are_you_sure_text_done")}
          </p>
          <button className="NotificationButton">
            {t("clear_all_notifications")}
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SecondRouting;
