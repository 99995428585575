import * as React from "react";

import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "./contexts/signup";
import VerifyUserId from "./pages/completeregistration/verifyuserid";
import CompleteRegistration from "./pages/completeregistration/completeregistration";
import { decryptData } from "./utils/functions";

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const { user, setUser } = React.useContext<any>(UserContext);
  let location = useLocation();
  const navigate = useNavigate();
  const urlString = window.location.href;
  const url = new URL(urlString);
  const path = url.pathname;

  if (!user.userLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    const userDetails = decryptData("user-details");
    
    if (Object.keys(userDetails).length !== 0) {
      if (
        userDetails?.identity_verification_status !== true &&
        userDetails?.doc_review_status !== "completed" &&
        userDetails?.full_name === null
      ) {

        if (path !== "/registration") {
          const newUrl = "/registration";
          window.history.pushState(null, "", newUrl);
          return <CompleteRegistration />;
        }
      } else if (
        userDetails?.identity_verification_status !== true &&
        userDetails?.doc_review_status !== "completed" &&
        userDetails?.full_name !== null
      ) {

        if (path !== "/verify-user") {
          const newUrl = "/verify-user";
          window.history.pushState(null, "", newUrl);
          return <VerifyUserId />;
        }
      }

    }else{
      //redirect to login page
      setUser({
        userLoggedIn: false,
      });
      localStorage.removeItem("user-details")
      localStorage.removeItem("id-token")
      localStorage.removeItem("refresh-token")
      navigate("/");

    }

  }

  return children;
}
