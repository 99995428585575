import AddCart from "../../assets/Icons/directsales-cart.svg";
import DirectSaleFullPurchase from "../../components/directsales/directsalefullpurchase";
import { useTranslation } from "react-i18next";
import Stopwatch from "../stopwatch/stopwatch";
import { numberFormat } from "../../utils/functions";
import CarPlateBidButton from "./carplatebidbutton";
import carplateImg from "../../assets/Icons/car details.svg";
import "../auctionbidding/auctionbidding.scss";
import DocumentsSection from "../../components/directsales/documentssection";
import Taxiplatelong from "../../assets/Icons/taxi_long.svg";

type Props = {
  assetItem?: any;
  handleShow?: any;
  category_title?:any;
  setExpiredAsset?:any;
  highestStart?:any;
  makeBidId?:any;
};

const CarPlatesAssetDetails = (props: Props) => {
  const { assetItem, category_title } = props;
  const { t } = useTranslation();
  const longPlate = category_title?.split(" ")[0] === "Car" ? carplateImg : Taxiplatelong;
  const classVal = category_title?.split(" ")[0] === "Car" ? 'box_img66' : 'box_img77';

  return (
    <>
      <div className="DirectSaleProductdetails">
        <div className="container_plate_new">
            <div className="detail_img">
              <img src={longPlate} alt="bahrain" className="w-100" />
              <div className={classVal}>
                <span className="detailfont">{assetItem.asset_title}</span>
              </div>
            </div>
        </div>

        <div className="AssetTiming">
          {assetItem?.end_time && (
            <Stopwatch
              compName={"assetDetails"}
              endTime={assetItem?.end_time}
              setExpiredAsset={props?.setExpiredAsset}
            />
          )}
          <div className="AssetTimingBottom d-flex justify-content-between ArabicBottomColors">
            <div className="text-center">
              <h2 className="AssetBidCount">
                {t("bhd") + numberFormat(assetItem?.highest_price)}
              </h2>
              <p className="AssetBidText">{t("highest_bid")}</p>
            </div>
            <div className="VerticleLine"></div>
            <div className="text-center">
              <h2 className="AssetBidCount">
                {numberFormat(assetItem?.total_bids)}
              </h2>
              <p className="AssetBidText">{t("bids_made")}</p>
            </div>
          </div>
        </div>

        <CarPlateBidButton
          btnImg={AddCart}
          btnValue={t("bid_now")}
          handleShow={props.handleShow}
          end_time={assetItem?.end_time}
          asset_id={assetItem?.asset_id}
          is_highest_bidder={assetItem?.is_highest_bidder}
          consumed_limit_percentage={assetItem?.consumed_limit_percentage}
          total_limit={assetItem?.total_limit}
          highest_price={assetItem?.highest_price}
          highestStart={props?.highestStart}
          makeBidId={props?.makeBidId}
        />
        {assetItem?.description && (
          <DirectSaleFullPurchase description={assetItem?.description} />
        )}
        {assetItem?.asset_doc ? (
          <DocumentsSection
            heading={"Documents"}
            docList={assetItem?.asset_doc}
          />
        ) : (
          <h1 style={{ marginTop: "10px" }}></h1>
        )}
      </div>
    </>
  );
};

export default CarPlatesAssetDetails;
