import { memo, useContext, useEffect, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./inputfields.scss";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/signup";
import { api } from "../../feature/envs/api";
import Searchfield from "./searchfield";
import { PhoneNumberUtil } from "google-libphonenumber";
import CrossMark from "../../assets/Icons/crossmark.svg";
import Country from "../../assets/Icons/country.svg";
import CheckMark from "../../assets/Icons/radiocheckmark.svg"

type Props = {
  className?: string;
  setPhoneInvalid?: any;
  setLoading?: any;
  state?: any;
  setState?: any;
  alreadloggedIn?:boolean;
  setNumber?: any;
};

const Countryfeild = (props: Props) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [data, setData] = useState<any>("");
  const [fullscreen, setFullscreen] = useState<any>(true);
  const [countriesShow, setCountriesShow] = useState(false);
  const [searchText, updateSearchText] = useState("");
  const {user, setUser } = useContext<any>(UserContext);
  const [code, setCode] = useState("+973");
  const [flag, setFlag] = useState();
  const [countryName, setCountryName] = useState("");
  const { setNumber} = props;
  const handleApi = () => {    
    api
      .get("countries?lang=en")
      .then((result: any) => setCountries(result.data))
      .catch(() => {});
  };

  useEffect(() => {
    handleApi();
  }, []);

  const handleChange = (country: any) => {
    setCode(country.dial_code);
    setFlag(country.flag);
    setCountryName(country.name);
    setCountriesShow(false);
    let fullnumber = country.dial_code + data;
    setNumber(fullnumber);
    validatePhoneNumber(fullnumber, country.dial_code, data);
      setUser({
        ...user,
        phoneNumber: `${country.dial_code}${data}`,
        type: "PHONE_NUMBER",
        countyName: country.name,
      });
    localStorage.setItem("countryName", country.name);
  };

  function closeCountries(e: any) {
    e.stopPropagation();
    setCountriesShow(false);
  }

  function handleShow(breakpoint: any) {
    setFullscreen(breakpoint);
    setCountriesShow(true);
  }
  const validatePhoneNumber = (phoneNumber: any, code: any, data: any) => {
    if (!data) {
      props.setState("");
      props.setPhoneInvalid(true);
      return;
    }
    /*
    Phone number validation using google-libphonenumber
    */
    let valid = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
    } catch (e) {
      valid = false;
    }
    if (valid) {
      props.setState("");
      props.setPhoneInvalid(false);
    } else {
      props.setState(t("please_enter_valid_mobile_number"));
      props.setPhoneInvalid(true);
      props?.setLoading(false);
    }
  };
  const handleSignUp = (e: any) => {
    const { value } = e.target;
    if (!isNaN(value)) {
      setData(value);
    }
    let fullnumber = code + value;
    validatePhoneNumber(fullnumber, code, value);
    setUser({...user, phoneNumber: `${code}${value}`, type: "PHONE_NUMBER" });
  };

  const lowerSearch = searchText.trim().toLowerCase();
  let updatedCountries = [...countries];
  if (lowerSearch !== "") {
    updatedCountries = countries.filter(
      (country: any) =>
        country.name.toLowerCase().includes(lowerSearch) ||
        country.dial_code.toLowerCase().includes(lowerSearch)
    );
  }

  return (
    <>
      <InputGroup size="lg" className={props.className}>
        <InputGroup.Text
          id="inputGroup-sizing-lg"
          className="flagInput"
          onClick={() => handleShow("sm-down")}
        >
          {flag ? (
            <span>{flag}</span>
          ) : (
            <img src={Country} alt="country" />
          )}
          <span>{code}</span>
        </InputGroup.Text>
        <Modal
          show={countriesShow}
          fullscreen={fullscreen}
          onHide={() => setCountriesShow(false)}
        >
          <div className="modal-header confirm-bottom">
            <div className="CloseButtons">
            <button className="bahrainarrow" onClick={closeCountries}>
              <img src={CrossMark} alt="crossmark" />
            </button>
            </div>
            <h2 className="confirmnumber me-4">{t("select_country")}</h2>
          </div>
          <Searchfield
            searchText={searchText}
            updateSearchText={updateSearchText}
          />
          <ul className="countrylist">
            {updatedCountries?.map((country: any, index: any) => {
              return (
                <li
                  key={index}
                  className="d-flex align-items-center countryitem d-flex justify-content-between"
                >
                  <label htmlFor={country.name} className="d-flex">
                    <h1>{country.flag}</h1>
                    <div className="countryname">{`${country.name} (${country.dial_code})`}</div>
                  </label>
                  <input
                    type="radio"
                    id={`${country.number}`}
                    name="selectcountry"
                    className="radiocustom"
                    value={country.number}
                    onChange={() => handleChange(country)}
                  />
                  <div className="countryradio">
                    <img
                      src={CheckMark}
                      alt="checkamrk"
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </Modal>
        <Form.Control
          id="signup"
          aria-label="Large"
          aria-describedby="inputGroup-sizing-sm"
          placeholder="0000000000"
          onChange={handleSignUp}
          value={data}
          data-hj-allow
        />
      </InputGroup>
      <div className="text-muted">
        <div className="text-danger"> {props.state}</div>
      </div>
    </>
  );
};

export default memo(Countryfeild);
