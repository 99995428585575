import { useTranslation } from "react-i18next";
import Stopwatch from "../../pages/stopwatch/stopwatch";
import { numberFormat } from "../../utils/functions";
import "./bidswoncard.scss";

type Props = {
  className: string;
  name: string;
  end_time?: any;
  highest_price?: any;
  type?: string;
  setFilteredAssetDataFunc?: any;
  handleDeleteManual?: any;
  asset_id?: any;
  classState?: any;
  setClassState?: any;
};

const BidsWonCardBottom = (props: Props) => {

  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-between bidregister">
      {t("en") == "en" ? (
        <p className="bhdwon">
          {" "}
          {t("bhd")}
          {numberFormat(props?.highest_price)}
        </p>
      ) : (
        <p className="bhdwon">
          {numberFormat(props?.highest_price)} {t("bhd")}
        </p>
      )}

      {props?.type === "cartcheckout" ? (
        <Stopwatch
          compName={"cartcheckout"}
          endTime={props?.end_time}
          setFilteredAssetDataFunc={props?.setFilteredAssetDataFunc}
          handleDeleteManual={props?.handleDeleteManual}
          asset_id={props?.asset_id}
          setClassState={props?.setClassState}
        />
      ) : props?.name === "pending_approval" ||
        props?.name === "successfully_approved" ? (
        <p className={`${props?.className}`}>{t(props?.name)}</p>
      ) : (
        <p className={`${props?.className}`}>
          {t(props?.name)}{" "}
          {<Stopwatch compName={"bidsWon"} endTime={props?.end_time} />}
        </p>
      )}
    </div>
  );
};

export default BidsWonCardBottom;
