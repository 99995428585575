import io from "socket.io-client";

// const connectionConfig = {
//   jsonp: false,
//   reconnection: true,
//   autoConnect: true,
//   reconnectionDelay: 1000,
//   reconnectionDelayMax: 5000,
//   reconnectionAttempts: Infinity,
//   transports: ['websocket'],
//   upgrade: false,
// };



let baseURL = '';
const url = window.location.href;
const hostname = window.location.hostname;
//console.log(baseURL, url, hostname, !hostname.includes("staging"));

if(hostname.includes("staging")){
  baseURL = process.env.REACT_APP_API_BASE_URL_STAGE
}else if (hostname.includes("dev") || hostname.includes("localhost")){
  baseURL = process.env.REACT_APP_API_BASE_URL_DEV
}else{
  baseURL = process.env.REACT_APP_API_BASE_URL
}
//const statVal = !hostname.includes("staging") && !hostname.includes("localhost")

const statVal = hostname.includes("localhost") ? false : true;
let socket = io(baseURL, { 
  withCredentials: statVal,
  transports: ['websocket'],
 });

export const connectSocket = () => {
  socket.disconnect().connect();
  socket.on("connection", () => {
    //console.log("connected--------", socket.connected);
  });
  return socket;
};

export const disconnectSocket = () => {
  //console.log("disconnected--------");
  socket.disconnect(true);
};
