import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import CrossMark from "../../assets/Icons/crossmark.svg";
import SingOut from "../../assets/Icons/sign-out.svg";
type Props = {
  closelogoutModal: () => void;
  show: boolean;
  openlogoutModal: () => void;
  logout: () => void;
};
const LogoutModal = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.closelogoutModal}
        className="NotificationModal"
        centered
      >
        <Modal.Header>
          <div className="CloseButtons">
            <button className="bahrainarrow" onClick={props.closelogoutModal}>
              <img src={CrossMark} alt="crossmark" />
            </button>
          </div>
          <h2 className="confirmnumber">{t("logout")}</h2>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center flex-column">
          <img src={SingOut} alt="notficationbell" className="SignoutImage" />
          <h2 className="profileheading">{t("are_you_sure")}</h2>
          <p className="ModalClearText">
            {t("logout_warning")} <br /> {t("logout-waring-remaining")}
          </p>
          <button
            className="NotificationButton"
            onClick={() => {
              props.logout();
              props.closelogoutModal();
            }}
          >
            {t("logout")}
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LogoutModal;
