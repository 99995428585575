import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDcJiX70aLBLqUneIuo5-QwLehs6PlbxvI",
  authDomain: "mazad-b6255.firebaseapp.com",
  projectId: "mazad-b6255",
  storageBucket: "mazad-b6255.appspot.com",
  messagingSenderId: "958269822704",
  appId: "1:958269822704:web:aefde8367b62e4f2d35435",
  measurementId: "G-81BGL8VY98",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
