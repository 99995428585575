/* eslint-disable react-hooks/exhaustive-deps */
import "./completereg.scss";
import Container from "react-bootstrap/Container";
import BreadCrums from "../../components/breadcrumbs/breadcrums";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ValidateDocuments = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 9000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container className="useridsection">
      <div className="verifyidentification">
        <BreadCrums
          previousPage={t("complete_registration")}
          currentPage={t("identity_verification")}
        />
      </div>
      <div className="d-flex justify-content-center">
        <div className="d-flex flex-column align-items-center depositesection">
          <h2 className="profileheading">{t("verify_your_id")}</h2>
          <img
            src="./assets/Images/deposit.png"
            alt="deposit"
            className="depositeimg"
          />
          <h2 className="profileheading">{t("instant_deposits")}</h2>
          <p className="depositetext text-center">
            {t("skip_the_bank_transfer_and_make_deposits")}
          </p>
          <span className="loader"></span>
          <span className="loader2"></span>
          <p className="depositebottomtext text-center">
            {t(
              "please_wait_while_we_validate"
            )}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default ValidateDocuments;
