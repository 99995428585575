import { useTranslation } from "react-i18next";
import HighestBidder from "../../assets/Icons/highest-bidder.svg";
import OutBidder from "../../assets/Icons/outbidder.svg";
import CommonBidModal from "./commonbidmodal";
import { isWithin5Seconds, numberFormat } from "../../utils/functions";
import { ClipLoader } from "react-spinners";
import MyBidsEmpty from "../mybids/mybidsempty";
import Stopwatch from "../stopwatch/stopwatch";
import redWatchlistIcon from "../../assets/Icons/details-red.svg";
import watchlistIcon from "../../assets/Icons/details.svg";
import "../auctionbidding/auctionbidding.scss";
import carplateImg from "../../assets/Icons/Car Plate (Long).svg";
import Taxiplatelong from "../../assets/Icons/taxi_long.svg";
import noBids from "../../assets/Icons/noBids.svg";
import Hammer from "../../assets/Icons/hammer.svg";
import { useState } from "react";

type Props = {
  index?: any;
  direction?: any;
  data?: any;
  showSignIn?: any;
  show?: any;
  assetItem?: any;
  setShow?: any;
  is_auto_bidding?: any;
  loader?: any;
  fullscreen?: any;
  setAssetItem?: any;
  assetTitle?: any;
  assetId?: any;
  assetLoader?: any;
  handleDetailPage?: any;
  handleWatchlist?: any;
  handleShow?: any;
  closeModal?: any;
  openModal?: any;
  override?: any;
  categoryData?: any;
  asset?: any;
  highestStart?: any;
  makeBidId?: any;
  setMakeBidId?: any;
  setFilteredAssetDataFunc?: any;
};

const CarPlateListView = (props: Props) => {
  const { t } = useTranslation();
  const longPlate =
    props?.asset?.category_title?.split(" ")[0] === "Car"
      ? carplateImg
      : Taxiplatelong;
  const classVal =
    props?.asset?.category_title?.split(" ")[0] === "Car"
      ? "box_img33"
      : "box_img44";

  const [shouldBlink, setShouldBlink] = useState(false);
  const callAnimationFunc = () => {
    setTimeout(() => {
      setShouldBlink(false);
    }, 1500);
    setShouldBlink(true);
  };
  let shouldBlinkClass = " ";

  if (isWithin5Seconds(props?.asset?.latest_bid_time)) {
    shouldBlinkClass = "biddigwonsection blink-animation";
    if (!shouldBlink) {
      callAnimationFunc();
    }
  } else {
    shouldBlinkClass = "biddigwonsection";
  }

  return (
    <>
      {Object.keys(props?.asset).length > 0 ? (
        <>
          <div className={shouldBlinkClass} 
            onClick={() => props.handleDetailPage(props?.asset, props.index)} //to make the whole card clickable
            style={{cursor:"pointer"}} //temporary fix until phase 2
          >
            <div className="biddingswoncard d-flex justify-content-between"> {/*removed onClick from here*/}
              <div className="d-flex align-items-center">
                <div className="container_font">
                  <div className="container_font">
                    <div className={classVal}>
                      <span className="carFont">
                        {props.asset?.asset_title}
                      </span>
                    </div>
                    <img src={longPlate} alt="bahrain" />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center me-2">
                <button
                  className="bahrainarrow"
                  onClick={(event: any) =>
                    props.handleWatchlist(event, props?.asset)
                  }
                >
                  <img
                    src={
                      props?.asset?.is_added_to_watchlist
                        ? redWatchlistIcon
                        : watchlistIcon
                    }
                    alt="watchlist"
                  />
                </button>
              </div>
              <div className="d-flex align-items-center">
                <button
                  className="bahrainarrow"
                  onClick={(event: any) =>
                    props.handleShow(
                      "sm-down",
                      props?.asset,
                      event,
                      props.index
                    )
                  }
                >
                  <img src={Hammer} alt="rightarrow" />
                </button>
              </div>
            </div>

            <div
              className={
                props?.makeBidId === props?.asset?.asset_id &&
                props?.highestStart
                  ? "d-flex justify-content-between align-items-center CategoryCardBottomBlue"
                  : props?.asset?.is_highest_bidder === true
                  ? "d-flex justify-content-between align-items-center CategoryCardBottomBlue"
                  : props?.asset?.is_highest_bidder === false
                  ? "d-flex justify-content-between align-items-center CategoryCardBottomPink"
                  : "d-flex justify-content-between align-items-center CategoryCardBottomGrey"
              }
            >
              <p className="HighestBidder d-none">
                <img src={HighestBidder} alt="highbidder" />
                You’re the Highest Bidder
              </p>
              <p className="OutBidder d-none">
                <img src={OutBidder} alt="highbidder" />
                You’ve been outbid
              </p>
              {t("en") === "en" ? (
                <p className="bhdwon ">
                  {t("bhd")} {numberFormat(props?.asset.highest_price)}
                </p>
              ) : (
                <p className="bhdwon ">
                  {numberFormat(props?.asset.highest_price)} {t("bhd")}{" "}
                </p>
              )}

              <div className="d-flex align-items-center ArabicBottomColors">
                <div className="BottomHammer">
                  <img src={Hammer} alt="hammer" />
                </div>
                <span className="bidcount">{props?.asset.total_bids}</span>
              </div>
              <Stopwatch
                compName={"assetListView"}
                endTime={props?.asset?.end_time}
                setFilteredAssetDataFunc={
                  props?.setFilteredAssetDataFunc
                }
                asset_id={props?.asset?.asset_id}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {props.assetLoader && (
            <ClipLoader
              color={"#fff"}
              loading={props.assetLoader}
              cssOverride={props.override}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
          {!props.assetLoader && (
            <MyBidsEmpty imgUrl={noBids} title={"auction"} />
          )}
        </>
      )}

      {props.show && (
        <CommonBidModal
          filterData={props.data}
          fullscreen={props.fullscreen}
          setShow={props.setShow}
          show={props.show}
          assetItem={props.assetItem}
          setAssetItem={props.setAssetItem}
          loader={props.loader}
          assetId={props.assetId}
          buttonSlider="show"
          assetTitle={props.assetTitle}
          is_auto_bidding={props.is_auto_bidding}
          categoryData={props.categoryData}
        />
      )}
    </>
  );
};

export default CarPlateListView;
