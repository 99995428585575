import Button from "react-bootstrap/Button";

type Props = {
  className?: string;
  value: string;
  handleSignUpClick: (modaltype: any) => void;
};

const SignUpButton = (props: Props) => {
  return (
    <Button
      variant="outline-primary"
      className="signUp"
      onClick={() => {
        props.handleSignUpClick("signup");
      }}
    >
      {props.value}
    </Button>
  );
};

export default SignUpButton;
