/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  GoogleMap,
  InfoWindow,
  OverlayView,
  MarkerClusterer,
  Marker,
} from "@react-google-maps/api";
import { useState } from "react";
import { Col } from "react-bootstrap";
import "./auctionbidding.scss";
import { numberFormat } from "../../utils/functions";
import { t } from "i18next";
import DirectSaledGridCardTop from "../../components/mybidscards/directsaledgridcardtop";
import DirectSaleBottom from "../mywatchlist/directsalebottom";

type Props = {
  data?: any;
  showSignIn?: any;
  setShowSignIn?: any;
  show?: any;
  assetItem?: any;
  setShow?: any;
  filteredAssetData?: any;
  is_auto_bidding?: any;
  loader?: any;
  setLoader?: any;
  fullscreen?: any;
  setAssetItem?: any;
  assetTitle?: any;
  assetId?: any;
  assetLoader?: any;
  handleDetailPage?: any;
  handleWatchlist?: any;
  handleShow?: any;
  closeModal?: any;
  openModal?: any;
  override?: any;
  categoryData?: any;
  setHighestStart?: any;
  setHighestFalseShow?: any;
  highestStart?: any;
  highestFalseShow?: any;
  makeBidId?: any;
  setMakeBidId?: any;
  setFilteredAssetDataFunc?: any;
};
export const MapviewDirectsale = (props: Props): JSX.Element => {
  const assetData = props?.filteredAssetData;
  const [mapRef, setMapRef] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [clickedMarkerIndex, setClickedMarkerIndex] = useState(-1);

  const mapOptions = {
    disableDefaultUI: true,
    clickable: false,
  };

  const designData = assetData.map((asset: any, index: number) => {
    // eslint-disable-next-line no-lone-blocks
    {
      return (
        <Col key={asset.asset_id} lg={12} md={12} sm={12} xs={12}>
          <div
            className="bidcardsection DirectSalesBidding map"
            onClick={() => props.handleDetailPage(asset, index)}
            key={asset.asset_id}
          >
            <DirectSaledGridCardTop
              data={asset}
              handleWatchlist={props?.handleWatchlist}
              setShowSignIn={props?.setShowSignIn}
            />
            <DirectSaleBottom value={t("bhd")} data={asset} />
          </div>
        </Col>
      );
    }
  });

  const handleInfoWindowOpen = (markerIndex: number) => {
    if (clickedMarkerIndex === markerIndex) {
      // If the same marker is clicked again, close the InfoWindow
      setClickedMarkerIndex(-1);
      setInfoWindowOpen(false);
    } else {
      // If a different marker is clicked, open the InfoWindow
      setClickedMarkerIndex(markerIndex);
      setInfoWindowOpen(true);
    }
  };

  const onMapLoad = (map: any) => {
    setMapRef(map);
    // Add event listener to track when the zoom is changed
    map.addListener("zoom_changed", () => {
      const updatedZoomLevel = map.getZoom();
      setZoom(updatedZoomLevel);
    });
    // assigned the bounds as the approximate bounds of bahrain
    const bounds = new window.google.maps.LatLngBounds(
      new google.maps.LatLng(25.803694, 50.363175)
    );
    bounds.extend(new google.maps.LatLng(26.333516, 50.755249));
    // checks if location exists and is not included in bounds of bahrain then extends the bounds accordingly
    assetData?.forEach(({ latitude: lat, longitude: lng }: any) => {
      return (
        lat !== null &&
        lng !== null &&
        !bounds.contains({ lat, lng }) &&
        bounds.extend({ lat, lng })
      );
    });
    map.fitBounds(bounds);
  };
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const handleInfoWindowClose = () => {
    setInfoWindowOpen(false);
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    // Prevent click event propagation to default markers
    setIsOpen(false);
    event.stop();
  };
  const divStyle = {
    background: "white",
    border: "1px solid #ccc",
    padding: "13px 26px 13px 26px",
    top: "812.01px",
    left: "611px",
    width: "191.7px",
    height: "48.25px",
    borderRadius: "13039.24px",
    fontWeight: 700,
    fontSize: "18.26px",
    display: "flex",
    justifyContent: "center",
  };
  const divStyleSelected = {
    background: "#403E3A",
    border: "1px solid #ccc",
    padding: "13px 26px 13px 26px",
    top: "812.01px",
    left: "611px",
    width: "191.7px",
    height: "48.25px",
    borderRadius: "13039.24px",
    fontWeight: 700,
    fontSize: "18.26px",
    display: "flex",
    justifyContent: "center",
    color: "#FFFFFF",
  };
  const mapstyle = {
    height: "952px",
  };
  // adding zoom state
  const [zoom, setZoom] = useState(0);

  return (
    <>
      <GoogleMap
        zoom={12}
        mapContainerClassName="map-container"
        onLoad={onMapLoad}
        onClick={handleMapClick}
        mapContainerStyle={mapstyle}
        options={mapOptions}
      >
        {/* Render clusters when zoomed out */}
        {zoom < 16 ? (
          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={60}
            maxZoom={16}
            minimumClusterSize={1}
            clusterClass="custom-cluster-icon"
            styles={[
              {
                url: "https://cluster-map.s3.me-south-1.amazonaws.com/blue-circle.png",
                height: 50, // Icon height
                width: 50, // Icon width
                textColor: "#ffffff", // Text color
              },
              // Add more style objects for different cluster sizes
            ]}
          >
            {(clusterer) =>
              assetData.map(
                (
                  { asset_price, latitude: lat, longitude: lng }: any,
                  ind: any
                ) =>
                  lat !== null &&
                  lng !== null && (
                    <Marker
                      key={ind}
                      position={{ lat, lng }}
                      clusterer={clusterer}
                      onClick={() => handleInfoWindowOpen(ind)}
                    />
                  )
              )
            }
          </MarkerClusterer>
        ) : (
          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={60}
            maxZoom={16}
            minimumClusterSize={1}
            styles={[
              {
                url: "https://cluster-map.s3.me-south-1.amazonaws.com/blue-circle.png",
                height: 50, // Icon height
                width: 50, // Icon width
                textColor: "#ffffff", // Text color
              },
              // Add more style objects for different cluster sizes
            ]}
          >
            {(clusterer) => (
              <div>
                {assetData.map(
                  (
                    { asset_price, latitude: lat, longitude: lng }: any,
                    ind: any
                  ) =>
                    lat !== null &&
                    lng !== null && (
                      <OverlayView
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        position={{ lat, lng }}
                      >
                        <>
                          <div
                            style={
                              infoWindowOpen && clickedMarkerIndex === ind
                                ? divStyleSelected
                                : divStyle
                            }
                            onClick={() => handleInfoWindowOpen(ind)}
                          >
                            <span>
                              {t("en") === "en"
                                ? t("bhd") + " " + numberFormat(asset_price)
                                : numberFormat(asset_price) + t("bhd")}
                            </span>
                          </div>

                          {infoWindowOpen && clickedMarkerIndex === ind && (
                            <InfoWindow
                              position={{ lat, lng }}
                              onCloseClick={handleInfoWindowClose}
                              options={{
                                pixelOffset: new window.google.maps.Size(
                                  0,
                                  -30
                                ),
                              }}
                            >
                              {designData[ind]}
                            </InfoWindow>
                          )}
                        </>
                      </OverlayView>
                    )
                )}
              </div>
            )}
          </MarkerClusterer>
        )}
      </GoogleMap>
    </>
  );
};
