import { Row } from "react-bootstrap";
import CrossButton from "../../components/buttons/crossbutton";
import LeftArrow from "../../assets/Icons/left-arrow.svg";
import BreadCrums from "../../components/breadcrumbs/breadcrums";

type Props = {
  end_title?: any;
  current_page?: any;
  previous_page?: any;
};

const RegistrationPageDirection = (props: Props) => {
  return (
    <Row>
      <div className="d-flex justify-content-between profilereg">
        <div className="d-flex align-items-center biddingswoncard">
          <CrossButton className="prevarrow" imgUrl={LeftArrow} />
          <BreadCrums
            previousPage={props?.previous_page || ""}
            currentPage={props?.current_page || ""}
            end_title={props?.end_title}
          />
        </div>
      </div>
    </Row>
  );
};

export default RegistrationPageDirection;
