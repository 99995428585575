import Container from "react-bootstrap/Container";
import BreadCrums from "../../components/breadcrumbs/breadcrums";
import CrossButton from "../../components/buttons/crossbutton";
import NavTabs from "../../components/tabs/navtabs";
import "./ongoing.scss";
import LeftArrow from "../../assets/Icons/left-arrow.svg";
import { useTranslation } from "react-i18next";
const Mybids = () => {
  const { t } = useTranslation();
  return (
    <Container>
      
      <div className="d-flex align-items-center profilereg biddingswoncard">
        <CrossButton
          className="prevarrow"
          imgUrl={LeftArrow}
        />
        <BreadCrums previousPage={t("my_bids")} 
         />
      </div>
      <NavTabs
        className="keyscontent"
        value1={t("ongoing_bids")}
        value2={t("bids_won")}
        tabValue1 = "bidsOngoing"
        tabValue2 = "bidsWon"
      />
    </Container>
  );
};

export default Mybids;
