import Breadcrumb from "react-bootstrap/Breadcrumb";
import "./breadcrumb.scss";

type Props = {
  previousPage: string;
  currentPage?: any;
  end_title?: any;
  subtitle?: any;
};

const BreadCrums = (props: Props) => {
  return (
    <Breadcrumb>
      {props.previousPage && (
        <Breadcrumb.Item href="#" className="breadcrumbtitle">
          {props.previousPage}
        </Breadcrumb.Item>
      )}
      {/* {props?.subtitle &&
        <Breadcrumb.Item href="#" className="breadcrumbtitle">
          {props?.subtitle}
        </Breadcrumb.Item>
      } */}
      {props?.currentPage && (
        <Breadcrumb.Item href="#" className="breadcrumbtitle">
          {props?.currentPage}
        </Breadcrumb.Item>
      )}
      {props.end_title && (
        <Breadcrumb.Item href="#" className="breadcrumbtitle">
          {props.end_title}
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};

export default BreadCrums;
