import { Link } from "react-router-dom";
import ProfileButton from "../../components/buttons/profilebutton";
import { useTranslation } from "react-i18next";
type Props = {};

const ThirdRouting = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className="UserProfileLeft">
      <div className="d-flex justify-content-between align-items-center PushNotifications">
        <div>
          <h2 className="profileheading">{t("push_notifications")}</h2>
          <p className="notificationtext">{t("push_notifications_text")}</p>
        </div>
        <div className="ProfileCheckBox">
          <label className="switch">
            <input type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center DeleteUserAccount">
        <div className="settingsbottom">
          <h2 className="profileheading">{t("delete_account_capitalize")}</h2>
          <p className="notificationtext">{t("delete_account_text")}</p>
        </div>
        <Link to="/deleteaccount">
          <ProfileButton value={t("delete_account_capitalize")} />
        </Link>
      </div>
    </div>
  );
};

export default ThirdRouting;
