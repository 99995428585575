import { t } from "i18next";

type Props = {
  imgUrl: string;
  title: any;
  className?: any;
};

const titleMap: { [key: string]: [string, string]; } = {
  "Cart": ["nothing_here_cart", "empty_cart_message"],
  "direct sales": ["nothing_here_watch", "empty_direct_sale"],
  "bids": ["nothing_here_watch", "empty_bids_message"],
  "ongoingbidswon": ["nothing_here_bids", "empty_watch_bids_message"],
  "auction": ["nothing_here", "empty_auction_message"],
  "direct": ["nothing_here_bids", ""],
  "bidpurchases": ["nothing_here_purchases", "empty_purchase_message"],
  "transaction_history": ["nothing_here_transactions", "blank_transaction"]
};


/**
 * Renders an empty bids section with an image or SVG, title, and text.
 *
 * @param {Props} props - The properties for the component.
 * @param {string} props.imgUrl - The URL of the image to display.
 * @param {any} props.title - The title of the empty section.
 * @param {string} [props.className] - Optional class name for the image.
 * @return {JSX.Element} The rendered empty bids section.
 */
const MyBidsEmpty = (props: Props) => {

  const [titleKey, textKey] = titleMap[props.title] || ["", ""];
  const isSvg = props.imgUrl.endsWith('.svg');
  const ImageTag = isSvg ? 'object' : 'img';
  const imageProps = isSvg ? { data: props.imgUrl, type: "image/svg+xml" } : { src: props.imgUrl, width: "144px" };

  return (
    <div className="text-center emptybidssection">
      <ImageTag alt="nobids" className={props.className || "emtpybidsimage"} {...imageProps} />
      <h2 className="emptybids">{t(titleKey)}</h2>
      <p className="emptybidstext">{t(textKey)}</p>
    </div>
  );
};

export default MyBidsEmpty;
