import { useState } from "react";
import Calendar from "react-calendar";
import { BsChevronRight } from "react-icons/bs";
import { BsChevronLeft } from "react-icons/bs";
import { useTranslation } from "react-i18next";

type Props = {};

const ModalCalander = (props: Props) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());

  return (
    <>
      <div className="calendar-container">
        <Calendar
          calendarType={t("en") === "en" ? "ISO 8601" : "Arabic"}
          onChange={()=>setDate}
          value={date}
          nextLabel={<BsChevronRight size={15} />}
          prev2Label={<BsChevronLeft size={15} />}
          next2Label={<BsChevronRight size={15} />}
        />
        <div className="timeModule d-flex justify-content-between align-items-center">
          <span className="CalanderTimeText">{t("time")}</span>
          <span className="CalanderTime">9:41 {t("am")}</span>
        </div>
      </div>
    </>
  );
};

export default ModalCalander;
