import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiWithToken } from "../../feature/envs/api";
import { toast } from "react-toastify";
import locationIcon from "../../assets/Icons/location.svg";
import Cart from "../../assets/Icons/storage.svg";
import CartDisabled from "../../assets/Icons/CartDisabled.svg";
import { decryptData, numberFormat } from "../../utils/functions";
import { useTranslation } from "react-i18next";
import redWatchlistIcon from "../../assets/Icons/details-red.svg";
import watchlistIcon from "../../assets/Icons/details.svg";
import Carplatelong from "../../assets/Icons/Car Plate (Long).svg";
import Taxiplatelong from "../../assets/Icons/taxi_long.svg";
import { UserContext } from "../../contexts/signup";
import years from "../../assets/Icons/years.svg";

import GreenCart from "../../assets/Icons/greenCart.svg";
import { AssetTag } from "../../components/tags/AssetTag";
import { EventLogger, EventNames } from "../../utils/EventLogger";

type Props = {
  data?: any;
  handleWatchlist?: any;
  type?: any;
  index?: any;
  fetchOptions?: any;
  value?: any;
  setShowSignIn?: any;
};

const DirectSaleListViewCard = (props: Props) => {
  const { t } = useTranslation();
  const { user } = useContext<any>(UserContext);
  const navigate = useNavigate();
  const [addToCartInProgress, setAddToCartInProgress] = useState(false);
  const [removeFromCartInProgress, setRemoveFromCartInProgress] =
    useState(false);

  const addToCart = () => {
    // Check if addToCart is already in progress
    if (addToCartInProgress) {
      return;
    }
    // Set the flag to indicate that addToCart is in progress
    setAddToCartInProgress(true);
    apiWithToken
      .post(`/api/v2/cart/add-to-cart`, { asset_id: props?.data?.asset_id })
      .then((res: any) => {
        if (res.data.status === 200) {
          EventLogger.logEvent(EventNames.ADD_TO_CART, {asset_id: props?.data?.asset_id});
          toast(<p className="CartSuccessMsg">{t("item_added_to_cart")}</p>, {
            autoClose: 3000,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        } else {
          toast(<p className="CartErrMsgLarge">{res.data.message}</p>, {
            autoClose: 2500,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        }
      })
      .finally(() => {
        setTimeout(() => {
          setAddToCartInProgress(false);
        }, 1000);
      });
  };

  const removeFromCart = () => {
    if (removeFromCartInProgress) {
      return;
    }
    // Set the flag to indicate that addToCart is in progress
    setRemoveFromCartInProgress(true);
    apiWithToken
      .put(`/api/v2/cart/remove-from-cart`, { asset_id: props?.data?.asset_id })
      .then((res: any) => {
        if (res.data.status === 200) {
          EventLogger.logEvent(EventNames.REMOVE_FROM_CART, {asset_id: props?.data?.asset_id});
          toast(<p className="CartErrMsg">{res.data.message}</p>, {
            autoClose: 2500,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        } else {
          toast(<p className="CartErrMsg">{res.data.message}</p>, {
            autoClose: 2500,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        }
      })
      .finally(() => {
        setTimeout(() => {
          setRemoveFromCartInProgress(false);
        }, 1000);
      });
  };

  const handleCart = (event: any) => {
    event?.stopPropagation();
    if (!user.userLoggedIn) {
      props.setShowSignIn(true);
    } else {
      const userDetails = decryptData("user-details");

      if (
        userDetails?.identity_verification_status !== true &&
        userDetails?.doc_review_status !== "completed" &&
        userDetails?.full_name === null
      ) {
        navigate("/registration");
      } else if (
        userDetails?.identity_verification_status !== true &&
        userDetails?.doc_review_status !== "completed" &&
        userDetails?.full_name !== null
      ) {
        navigate("/verify-user");
      } else {
        if (props?.data?.is_available_for_cart) {
          addToCart();
        } else {
          removeFromCart();
        }
      }
    }
  };

  const size = "thumbnail"; // Dynamic property name
  const divRef: any = useRef(null);

  const handleImageError = (e: any) => {
    const img = e.target;
    img.style.display = "none"; // Hide the failed image
    divRef.current.style.display = "block"; // Show the replacement div
  };

  const classVal =
    props?.data?.category_title?.split(" ")[0] === "Car"
      ? "box_img33"
      : "box_img44";

  let content = null;

  const carTaxiContent = (
    <div className="d-flex align-items-center">
      <div className="container_font">
        <div className="container_font">
          <div className={classVal}>
            <span className="carFont">{props?.data?.asset_title}</span>
          </div>
          <img
            src={
              props?.data?.category_title?.split(" ")[0] === "Taxi"
                ? Taxiplatelong
                : Carplatelong
            }
            alt="bahrain"
          />
        </div>
      </div>
    </div>
  );

  const otherCategoryContent = (
    <div className="d-flex align-items-center">
      <div className="container-general-list">
        {props.data?.tag && (
          <AssetTag
            tag={props.data?.tag}
            discountPercentage={props.data?.discount_percentage}
          />
        )}
        {props?.data?.asset_images && props?.data?.asset_images.length > 0 ? (
          <>
            <img
              src={props?.data?.asset_images[0]?.url[size]}
              alt={props?.data?.asset_title}
              onError={handleImageError}
            />
            <div
              className="img customBg"
              ref={divRef}
              style={{ display: "none" }}
            ></div>
          </>
        ) : (
          <div className="img customBg"></div>
        )}
      </div>

      <div className="px-3">
        <h2 className="AuctionCardHeading">
          {t("en") === "en"
            ? props?.data?.asset_title
            : props?.data?.asset_title_ar}
        </h2>

        {props?.data?.location_title && (
          <p className="calander">
            <img src={locationIcon} alt="calander" />
            <span>
              {t("en") === "en"
                ? props?.data?.location_title
                : props?.data?.location_title_ar}
            </span>
          </p>
        )}

        {props?.data?.asset_id && (
          <p className="calander">
            <img src={years} alt="calander" />
            <span>{props?.data?.asset_id}</span>
          </p>
        )}
      </div>
    </div>
  );

  if (["Taxi", "Car"].includes(props?.data?.category_title?.split(" ")[0])) {
    content = carTaxiContent;
  } else {
    content = otherCategoryContent;
  }

  return (
    <>
      <div>
        <>
          {props?.data && (
            <div className="biddingswoncard d-flex justify-content-between align-items-center">
              {content}
              <div className="flex-grow-1"></div>
              <div className="d-flex align-items-center me-2">
                <button
                  className="bahrainarrow"
                  onClick={(event: any) =>
                    props.handleWatchlist(event, props?.data)
                  }
                >
                  <img
                    src={
                      props?.data?.is_added_to_watchlist ||
                      props?.type === "watchlist"
                        ? redWatchlistIcon
                        : watchlistIcon
                    }
                    alt="watchlist"
                  />
                </button>
              </div>

              <div className="d-flex align-items-center">
                <button
                  className="bahrainarrow"
                  onClick={
                    props.data?.is_available_for_cart
                      ? handleCart
                      : props?.data?.in_my_cart
                      ? handleCart
                      : (e) => e.stopPropagation()
                  }
                >
                  <img
                    src={
                      props?.data?.is_available_for_cart
                        ? Cart
                        : props?.data?.in_my_cart
                        ? GreenCart
                        : CartDisabled
                    }
                    alt={numberFormat(props?.data?.is_available_for_cart)}
                  />
                </button>
              </div>
            </div>
          )}
        </>
      </div>
      <div className="bidcardbottomsale">
        {t("bhd") === "BHD " ? (
          <h2 className="cardbidsecondtitleLeft">
            {" "}
            BHD {props.value} {numberFormat(props?.data?.asset_price)}
          </h2>
        ) : (
          <h2 className="cardbidsecondtitleLeft">
            {" "}
            BHD {numberFormat(props?.data?.asset_price)} {props.value}{" "}
          </h2>
        )}
      </div>
    </>
  );
};

export default DirectSaleListViewCard;
