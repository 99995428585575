import { t } from "i18next";
import { useState } from "react";
import Info from "../../assets/Icons/info.svg";
import CompletePurchaseInfoModal from "../modals/purchaseinfomodal";
type Props = {
  setToggle?: any;
  toggle?: any;
  title?: any;
};

const DirectSaleToggle = (props: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [fullscreen, setFullscreen] = useState<any>(true);

  const onChange = () => {
    if (props.toggle) {
      props.setToggle(false);
    } else {
      props.setToggle(true);
    }
  };
  const handleInfo = (breakpoint: any) => {
    setShowModal(true);
    setFullscreen(breakpoint);
  };
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex align-items-center">
        <h2 className="UseMyId">{t("use_my_deposit")}</h2>
        <img
          src={Info}
          alt="info"
          className="InfoIcon"
          onClick={() => handleInfo("sm-down")}
        />
      </div>
      <label className="NotificationModal switch">
        <input type="checkbox" onChange={onChange} />
        <span className="slider round"></span>
      </label>
      <CompletePurchaseInfoModal
        showModal={showModal}
        setShowModal={setShowModal}
        fullscreen={fullscreen}
        title={props.title}
      />
    </div>
  );
};

export default DirectSaleToggle;
