import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Clock from "../../assets/Icons/directsales-clock.svg";
import Cube from "../../assets/Icons/directsales-cube.svg";
import BidNow from "../../assets/Icons/bid-now.svg";
import Location from "../../assets/Icons/location.svg";
import { useTranslation } from "react-i18next";
import "./auctionbidding.scss";
import { apiWithToken } from "../../feature/envs/api";
import { useNavigate, useParams } from "react-router";
import { ClipLoader } from "react-spinners";
import CommonBidModal from "./commonbidmodal";
import ModalCalander from "./modalcalander";
import DirectsalesTopIcons from "../directsales/directsalestopicons";
import CategoryAssetDetailsTopImages from "./categoryassetdetailstopimages";
import SignInPhoneModal from "../../components/modals/signinphonemodal";
import { CartCount, UserContext } from "../../contexts/signup";
import Stopwatch from "../stopwatch/stopwatch";
import {
  decryptData,
  numberFormat,
  timeAgo,
  timeAtWithDay,
} from "../../utils/functions";
import CarPlatesAssetDetails from "../categorycarplates/carplatesassetdetails";
import CalendarImg from "../../assets/Icons/calander-timing.svg";
import DocumentsSection from "../../components/directsales/documentssection";
import ViewMoreModal from "./viewmoremodal";
import CrossMark from "../../assets/Icons/crossmark.svg";
import { connectSocket, disconnectSocket } from "../../utils/socket";
import ProfileSocialContact from "../../components/profile/profilesocialcontact";
import HelpContact from "../../assets/Icons/help-contact.svg";
import LeftArrow from "../../assets/Icons/left-arrow.svg";
import CommonInfoModal from "./commoninfomodal";
import HighestBidderWhite from "../../assets/Icons/highestbidder-white.svg";
import BidTimeUp from "../../assets/Icons/bid-timeup.svg";
import { AllDataContext } from "../../contexts/signup";
import { trackPageView } from "../../utils/analytics";
import ActionSection from "../../components/directsales/actionsection";
import DirectSaleAddToCart from "../../components/directsales/directsaleaddtocart";
import GreenCart from "../../assets/Icons/greenCart.svg";
import AddCart from "../../assets/Icons/directsales-cart.svg";
import BlurCart from "../../assets/Icons/blur-cart.svg";
import { EventLogger, EventNames } from "../../utils/EventLogger";
let socket: any = null;
let assetsArr: any = [];

type Props = {
  data?: any;
  time?: any;
  assestData?: any;
  setHighestStart?: any;
  setHighestFalseShow?: any;
  highestStart?: any;
  highestFalseShow?: any;
  type?: string;
};

const CategoryAssetDetailsOneData = (props: Props) => {
  const { allData, setAllData } = useContext<any>(AllDataContext);
  const { type } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fullscreen, setFullscreen] = useState<any>(true);
  const [fullscreenview, setFullscreenView] = useState<any>(true);
  const [showSupport, setShowSupport] = useState<any>(false);
  const [show, setShow] = useState(false);
  const [assetItem, setAssetItem] = useState<any>({});
  const [docCount, setDocCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const { user } = useContext<any>(UserContext);
  const [is_auto_bidding, setis_auto_bidding] = useState(false);
  const [showCalender, setShowCalender] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [assetImages, setAssetImages] = useState<any>([]);
  const [category_title, setCategoryTitle] = useState(allData?.category_title);
  const [expiredAsset, setExpiredAsset] = useState(false);
  const [assetDetails, setAssetDetails] = useState<Record<string, any>>({});
  const [showViewMore, setViewMore] = useState(false);
  const { setCartCount } = useContext<any>(CartCount);
  const [showAuctionEnded, setShowAuctionEnded] = useState(false);
  const [highestFalseShow, setHighestFalseShow] = useState(false);
  const [highestStart, setHighestStart] = useState(false);
  const [makeBidId, setMakeBidId] = useState(null);
  const [customer_inquiry, setCustomer_inquiry] = useState(false);
  const [request_for_view, setRequest_for_view] = useState(false);
  const model_year = "Model year";
  // let asset_id: any = searchParams.get("id") || 0;
  let { id }: any = useParams();
  const assetId = id || 0;
  let asset_id: any = id || 0;
  const stateVal =
    assetDetails && assetDetails?.description && assetDetails?.asset_details
      ? false
      : assetDetails?.description && !assetDetails?.asset_details
      ? true
      : false;
  const [description, setDescription] = useState(stateVal);

  useEffect(() => {
    trackPageView(
      window.location.pathname + window.location.search,
      "Asset Details"
    );
    window.scrollTo(0, 0); // Scroll to the top of the page
    assetsArr = [];
    socket = connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    addSocketListeners();
    return () => {
      socket.off("make-bid", emitMakeBid);
      socket.off("asset-bid-details", getAssetBidDetail);
      socket.off("turn-off-auto-bid", turnOffAutoBid);
      socket.off("add-to-watchlist", addWatchlist);
      socket.off("remove-from-watchlist", removeWatchlist);
      socket.off("category-asset", getCategoryAssetSocketCall);
      socket.off("add-to-cart", handleOnAssetAddedToCart);
      socket.off("add-to-cart", addRemoveCartSocketCall);
      socket.off("remove-from-cart", handleOnAssetRemovedFromCart);
      socket.off("remove-from-cart", addRemoveCartSocketCall);
      socket.off("my-cart", addRemoveCartSocketCall);
      socket.off("my-cart-details", getMyCartDetails);
    };
  }, [socket]);

  const addSocketListeners = () => {
    if (socket !== null) {
      socket.on("make-bid", emitMakeBid);
      socket.on("asset-bid-details", getAssetBidDetail);
      socket.on("turn-off-auto-bid", turnOffAutoBid);
      socket.on("add-to-watchlist", addWatchlist);
      socket.on("remove-from-watchlist", removeWatchlist);
      socket.on("category-asset", getCategoryAssetSocketCall);
      socket.on("add-to-cart", handleOnAssetAddedToCart);
      socket.on("add-to-cart", addRemoveCartSocketCall);
      socket.on("remove-from-cart", handleOnAssetRemovedFromCart);
      socket.on("remove-from-cart", addRemoveCartSocketCall);
      socket.on("my-cart", addRemoveCartSocketCall);
      socket.on("my-cart-details", getMyCartDetails);
    }
  };

  const addRemoveCartSocketCall = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res?.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("get-bid-details", {
        asset_id: res?.data?.asset_id,
        authorization: authToken,
      });
      socket.emit("my-cart", {
        authorization: authToken,
      });
    }
    // getMyCartApi();
    // }
  }, []);

  const getMyCartDetails = useCallback((res: any) => {
    let count: any = null;
    if (res?.data?.cart && res?.data?.cart?.items?.length) {
      count = res?.data?.cart?.items?.length;
    } else {
      count = null;
    }
    setCartCount(count);
  }, []);

  const emitMakeBid = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res?.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("get-bid-details", {
        asset_id: res?.data?.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const addWatchlist = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res?.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("get-bid-details", {
        asset_id: res?.data?.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const removeWatchlist = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res?.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("get-bid-details", {
        asset_id: res?.data?.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const getAssetBidDetail = useCallback((res: any) => {
    const data = res?.data;
    const arr = assetsArr.map((item: any) => {
      if (item?.asset_id === data?.asset_id) {
        return {
          ...item,
          end_time: data?.end_time,
          highest_price: data?.highest_price,
          is_added_to_watchlist: data?.is_added_to_watchlist,
          is_auto_bidding: data?.is_auto_bidding,
          is_highest_bidder: data?.is_highest_bidder,
          total_bids: data?.total_bids,
        };
      } else {
        return item;
      }
    });

    assetsArr = arr;

    if (+res?.data?.asset_id === +asset_id) {
      setAssetItem([...assetsArr]);
      bidDetailApiResponse(res);
    }
  }, []);

  const turnOffAutoBid = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (
      +res?.data?.asset_id === +asset_id &&
      authToken !== null &&
      authToken !== undefined
    ) {
      socket.emit("get-bid-details", {
        asset_id: res?.data?.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const override: CSSProperties = {
    display: "block",
    margin: "20% auto",
    borderColor: "black",
    overflow: "-moz-hidden-unscrollable",
    opacity: 0.1,
  };
  const getAssetDetails = () => {
    apiWithToken.get(`/api/v1/asset/${id}`).then((res: any) => {
      if (res.data.status === 200) {
        EventLogger.logEvent(EventNames.VIEW_ASSET, {asset_id: id});
        setAssetDetails(res?.data?.data[0]);
        setCategoryTitle(res?.data?.data[0]?.category_title);
        setRequest_for_view(res?.data?.data[0]?.request_for_view);
        setCustomer_inquiry(res?.data?.data[0]?.customer_inquiry);
        setAssetItem(res?.data?.data[0]);
        // console.log("asset Images", res.data.data[0].asset_doc.length);
        const docLen = res?.data?.data[0]?.asset_doc?.length;
        setDocCount(docLen);
        if (timeAgo(res?.data?.data[0]?.end_time) >= 0) {
          setExpiredAsset(true);
        }
        setLongitude(res?.data?.data[0].longitude);
        setLatitude(res?.data?.data[0].latitude);
        setAssetImages(res?.data?.data[0]?.asset_images);
      } else if (res?.data?.status !== 200) {
        navigate("/");
      } else {
        setLoader(false);
      }
    });
  };
  useEffect(() => {
    setLoader(true);
    getAssetDetails();
  }, [id]);

  const getCategoryAssetSocketCall = useCallback((res: any) => {
    const data = res.data[0];
    assetsArr =
      assetsArr &&
      assetsArr.map((item: any) => {
        if (item.asset_id == data.asset_id) {
          return {
            ...item,
            is_added_to_watchlist: data.is_added_to_watchlist,
          };
        } else {
          return item;
        }
      });
    setAssetItem(data);
  }, []);

  const handleOnAssetAddedToCart = useCallback((res: any) => {
    const data = res.data;

    const userDetails = decryptData("user-details");
    const userId = userDetails?.id;
    
    assetsArr = assetsArr && assetsArr.map((item: any) => {
      if (item.asset_id == data.asset_id && userId == data.user_id) {
        return {
          ...item,
          is_available_for_cart: false,
          in_my_cart: true,
        };
      } else if (item.asset_id == data.asset_id ) {
        return {
          ...item,
          is_available_for_cart: false,
          in_my_cart: false,
        };
      }else {
        return item;
      }
    })
    setAssetItem(assetsArr[0]);
  }, []);

  const handleOnAssetRemovedFromCart = useCallback((res: any) => {
    const data = res.data;

    assetsArr = assetsArr && assetsArr.map((item: any) => {
      if (item.asset_id == data.asset_id ) {
        return {
          ...item,
          is_available_for_cart: true,
          in_my_cart: false,
        };
      }else {
        return item;
      }
    })

    setAssetItem(assetsArr[0]);
  }, []);

  function handleShow(breakpoint: any) {
    if (!user?.userLoggedIn) {
      setShowSignIn(true);
    } else {
      const userDetails = decryptData("user-details");

      if (
        userDetails?.identity_verification_status !== true &&
        userDetails?.doc_review_status !== "completed" &&
        userDetails?.full_name === null
      ) {
        navigate("/registration");
      } else if (
        userDetails?.identity_verification_status !== true &&
        userDetails?.doc_review_status !== "completed" &&
        userDetails?.full_name !== null
      ) {
        navigate("/verify-user");
      } else {
        setFullscreen(breakpoint);
        setLoading(true);
        setShow(true);
      }
    }
  }
  function handleShowView(breakpoint: any) {
    setFullscreenView(breakpoint);
    setViewMore(true);
  }

  const showDescription = () => {
    setDescription(!description);
  };

  const openModal = (modaltype: any) => {
    modaltype === "signin" ? setShowSignIn(true) : setShowSignUp(true);
  };
  const closeModal = (modaltype: any) => {
    modaltype === "signin" ? setShowSignIn(false) : setShowSignUp(false);
  };

  useEffect(() => {
    if (!show) {
      getBidDetails();
    }
  }, [show]);

  const bidDetailApiResponse = (res: any) => {
    setAssetItem(res.data);
    setis_auto_bidding(res.data.is_auto_bidding);
  };

  function getBidDetails() {
    if (timeAgo(assetItem?.end_time && assetItem?.end_time) > 0) {
      setExpiredAsset(true);
    }

    apiWithToken
      .get(`api/v1/bids/assets/${id}/get-bid-details`)
      .then((res: any) => {
        if (res.data.status === 200) {
          setLoading(false);
          const data = res.data.data;
          bidDetailApiResponse(res.data);
          setAssetItem(res?.data?.data);
          setis_auto_bidding(res.data.data.is_auto_bidding);
          setLoader(false);
        } else {
          setLoader(false);
        }
      });
  }
  useEffect(() => {
    if (show) {
      setLoader(true);
      getBidDetails();
    }
  }, [show]);
  const isNUmberPlate =
    (assetDetails && assetDetails?.category_title?.split(" ")[0] === "Taxi") ||
    assetDetails?.category_title?.split(" ")[0] === "Car"
      ? true
      : false;

  type AnyRecord = Record<string, any>;
  const objDetails: AnyRecord =
    assetDetails && t("en") === "en"
      ? assetDetails?.asset_details
      : assetDetails?.asset_details_ar
      ? assetDetails?.asset_details_ar
      : [];

  const myElements =
    assetDetails &&
    assetDetails?.asset_details &&
    Object.entries(objDetails).map(([key, value], index) => {
      return (
        index < 5 && (
          <div
            className={`${
              !description ? "" : "d-none"
            } d-flex justify-content-between align-items-center PropertyAddress`}
            key={index}
          >
            <p className="AssetType">{key}</p>
            <p className="AssetAddress">{value.toString()}</p>
          </div>
        )
      );
    });

  const imgIcon =
    assetDetails?.category_title === "Vehicles" ? CalendarImg : Location;
  const words =
    assetDetails?.description && assetDetails?.description.trim().split(" ");
  const wordLimit = 140;

  let descriptions =
    words && words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : assetDetails?.description;

  let descriptions_ar =
    words && words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : assetDetails?.description_ar;

  const DetailsDescription =
    assetDetails && assetDetails?.asset_details && assetDetails?.description;

  useEffect(() => {
    expiredAsset === true && setShowAuctionEnded(true);
  }, [expiredAsset]);

  var currentPage =
    assetDetails && assetDetails?.category_title
      ? t("en") === "en"
        ? assetDetails?.category_title
        : assetDetails?.category_title_ar
      : "";
  const outBidderUI = (
    <div className="overlayOut">
      <div className="overlay-colorOut">
        <img src={HighestBidderWhite} alt="You've been outbid" />
        {t("you_been_outbid")}
      </div>
    </div>
  );

  const docList = assetDetails?.asset_doc;

  const displayCond =
    t("en") === "en"
      ? (assetDetails && assetDetails?.asset_details) ||
        assetDetails?.description ||
        docCount > 0
      : (assetDetails && assetDetails?.asset_details_ar) ||
        assetDetails?.description_ar ||
        docCount > 0;

  const topUpNowCheck =
    assetDetails?.consumed_limit_percentage === 100 ||
    assetDetails?.total_limit === 0 ||
    assetDetails?.total_limit <= assetDetails?.highest_price;

  const handleTopUpClick = () => {
    navigate("/profile/deposits");
  };

  const topUPUI = topUpNowCheck && (
    <button className="TopUpNowButton" onClick={handleTopUpClick}>
      <img src={BidTimeUp} alt="Top Up Now" />
      {t("top_up_now")}
      {assetDetails?.is_highest_bidder === false && outBidderUI}
    </button>
  );

  const btnText = assetItem?.in_my_cart
    ? t("remove_from_cart")
    : assetItem?.is_available_for_cart
    ? t("add_to_cart")
    : t("unavailable");
  const cartImg = assetItem?.in_my_cart
    ? GreenCart
    : assetItem?.is_available_for_cart
    ? AddCart
    : BlurCart;
  const classNameVal = assetItem?.in_my_cart
    ? "remove_from_cart"
    : assetItem?.is_available_for_cart
    ? "signin-phone"
    : "disable_btn";
  const Customstyle: any = type === "directsale" ? { marginTop: "0px" } : {};
  return (
    <Container>
      {isNUmberPlate ? (
        assetItem && (
          <>
            <DirectsalesTopIcons
              end_title={assetItem?.asset_title}
              assetItem={assetItem}
              currentPage={currentPage}
              subtitle={currentPage}
              previousPage={t("auction")}
              asset_id={id}
              setShowSupport={setShowSupport}
            />
            <CarPlatesAssetDetails
              assetItem={assetItem}
              handleShow={handleShow}
              category_title={category_title}
              setExpiredAsset={setExpiredAsset}
              highestStart={highestStart}
              makeBidId={makeBidId}
            />
          </>
        )
      ) : (
        <>
          <DirectsalesTopIcons
            assetItem={assetItem}
            currentPage={currentPage}
            subtitle={currentPage}
            previousPage={t("auction")}
            asset_id={id}
            setShowSupport={setShowSupport}
          />
          {assetImages && (
            <CategoryAssetDetailsTopImages assetImages={assetImages} />
          )}

          {loader ? (
            <ClipLoader
              color={"#fff"}
              loading={loader}
              cssOverride={override}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <>
              <Row className="mt-4">
                <Col lg={6}>
                  {displayCond && (
                    <div className="AssetDetailsLeft">
                      {((assetDetails && assetDetails?.asset_details) ||
                        assetDetails?.description) && (
                        <div className="AssetDetailsDescription">
                          <div className="AssetDetailsAddress">
                            <div className="d-flex flex-md-row flex-column flex-lg-column flex-xl-row justify-content-between align-items-center DetailsDescriptionButtons">
                              {t("en") === "en"
                                ? assetDetails &&
                                  assetDetails?.asset_details && (
                                    <button
                                      className={`${
                                        !description
                                          ? "DetailsButton w-100"
                                          : "DescriptionButton"
                                      }`}
                                      onClick={
                                        DetailsDescription && showDescription
                                      }
                                    >
                                      {t("details")}
                                    </button>
                                  )
                                : assetDetails &&
                                  assetDetails?.asset_details_ar && (
                                    <button
                                      className={`${
                                        !description
                                          ? "DetailsButton w-100"
                                          : "DescriptionButton"
                                      }`}
                                      onClick={
                                        DetailsDescription && showDescription
                                      }
                                    >
                                      {t("details")}
                                    </button>
                                  )}

                              {t("en") === "en"
                                ? assetDetails &&
                                  assetDetails?.description &&
                                  assetDetails?.asset_details && (
                                    <button
                                      className={`${
                                        description
                                          ? "DetailsButton w-100"
                                          : "DescriptionButton"
                                      }`}
                                      onClick={showDescription}
                                    >
                                      {t("description")}
                                    </button>
                                  )
                                : assetDetails &&
                                  assetDetails?.description_ar &&
                                  assetDetails?.asset_details_ar && (
                                    <button
                                      className={`${
                                        description
                                          ? "DetailsButton w-100"
                                          : "DescriptionButton"
                                      }`}
                                      onClick={showDescription}
                                    >
                                      {t("description")}
                                    </button>
                                  )}

                              {t("en") === "en"
                                ? assetDetails &&
                                  assetDetails?.description &&
                                  !assetDetails?.asset_details && (
                                    <button
                                      className={`${
                                        !description
                                          ? "DetailsButton w-100"
                                          : "DescriptionButton"
                                      }`}
                                      // onClick={showDescription}
                                    >
                                      {t("description")}
                                    </button>
                                  )
                                : assetDetails &&
                                  assetDetails?.description_ar &&
                                  !assetDetails?.asset_details_ar && (
                                    <button
                                      className={`${
                                        !description
                                          ? "DetailsButton w-100"
                                          : "DescriptionButton"
                                      }`}
                                    >
                                      {t("description")}
                                    </button>
                                  )}
                            </div>

                            {assetDetails &&
                              assetDetails?.asset_details &&
                              myElements}
                            {assetDetails &&
                              assetDetails?.description &&
                              assetDetails?.asset_details && (
                                <div
                                  className={`${description ? "" : "d-none"}`}
                                >
                                  <p className="AssetDescriptionText">
                                    {t("en") === "en"
                                      ? assetDetails?.description &&
                                        descriptions
                                      : assetDetails?.description &&
                                        descriptions_ar}
                                  </p>
                                </div>
                              )}
                            {assetDetails &&
                              assetDetails?.description &&
                              !assetDetails?.asset_details && (
                                <div
                                  className={`${!description ? "" : "d-none"}`}
                                >
                                  <p className="AssetDescriptionText">
                                    {t("en") === "en"
                                      ? assetDetails?.description &&
                                        descriptions
                                      : assetDetails?.description &&
                                        descriptions_ar}
                                  </p>
                                </div>
                              )}
                          </div>

                          {t("en") === "en"
                            ? assetDetails &&
                              (assetDetails?.asset_details ||
                                assetDetails?.description) && (
                                <div
                                  className="ViewMoreButton"
                                  onClick={() => handleShowView("sm-down")}
                                >
                                  <a href="#">{t("view_more")}</a>
                                </div>
                              )
                            : assetDetails &&
                              (assetDetails?.asset_details_ar ||
                                assetDetails?.description_ar) && (
                                <div
                                  className="ViewMoreButton"
                                  onClick={() => handleShowView("sm-down")}
                                >
                                  <a href="#">{t("view_more")}</a>
                                </div>
                              )}
                        </div>
                      )}

                      {docCount > 0 && (
                        <DocumentsSection
                          heading={t("documents")}
                          docList={docList}
                        />
                      )}
                    </div>
                  )}

                  <ActionSection
                    heading={t("actions")}
                    assetItem={assetItem}
                    request_for_view={request_for_view}
                    customer_inquiry={customer_inquiry}
                  />

                  <Row className="ArabicBottomColors">
                    {latitude === null && longitude === null ? (
                      ""
                    ) : (
                      <Col className="MapLocation">
                        <div className="d-flex justify-content-between align-items-center AssetMap">
                          <h2 className="profileheading">{t("map")}</h2>
                          <a
                            className="OpenMaps"
                            href={`https://www.google.com/maps?q=${latitude}, ${longitude}&z=15`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t("open_on_maps")}
                          </a>
                        </div>
                        <div className="mapheight">
                          <iframe
                            className="iframe-map"
                            title="location"
                            src={`https://maps.google.com/maps?q=${latitude}, ${longitude}&z=15&output=embed`}
                            loading="lazy"
                          ></iframe>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Col>

                <Col
                  lg={6}
                  style={{ position: "sticky", top: "15px", height: "79vh" }}
                >
                  {type !== "directsale" && (
                    <div className="AssetTiming">
                      {assetItem?.end_time && (
                        <Stopwatch
                          compName={"assetDetails"}
                          endTime={assetItem?.end_time}
                          setExpiredAsset={setExpiredAsset}
                        />
                      )}
                      <div className="AssetTimingBottom d-flex justify-content-between ArabicBottomColors">
                        <div className="text-center">
                          <h2 className="AssetBidCount">
                            {numberFormat(assetItem?.highest_price)}
                          </h2>
                          <p className="AssetBidText">{t("highest_bid")}</p>
                        </div>
                        <div className="VerticleLine"></div>
                        <div className="text-center">
                          <h2 className="AssetBidCount">
                            {numberFormat(assetItem?.total_bids)}
                          </h2>
                          <p className="AssetBidText">{t("bids_made")}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="AssetTradeCenter mb-4" style={Customstyle}>
                    <h2 className="AssetTradeHeading">
                      {assetItem?.asset_title && t("en") === "en"
                        ? assetItem?.asset_title
                        : assetItem?.asset_title_ar}
                    </h2>
                    {id && (
                      <>
                        {" "}
                        {assetDetails && assetDetails?.location_title && (
                          <p className="AssetCartText">
                            <img
                              src={imgIcon}
                              alt="cube"
                              className="AssetCartIcons"
                            />
                            {assetDetails &&
                            assetDetails?.category_title === "Vehicles"
                              ? assetDetails?.asset_details[model_year]
                              : t("en") === "en"
                              ? assetDetails?.location_title
                              : assetDetails?.location_title_ar}
                          </p>
                        )}
                        {assetDetails?.asset_id && (
                          <p className="AssetCartText">
                            <img
                              src={Cube}
                              alt="cube"
                              className="AssetCartIcons"
                            />
                            {assetDetails?.asset_id}
                          </p>
                        )}
                      </>
                    )}
                    {assetItem?.end_time && type !== "directsale" ? (
                      <div className="d-sm-flex justify-content-between">
                        <p className="AssetCartText">
                          <img
                            src={Clock}
                            alt="clock"
                            className="AssetCartIcons"
                          />
                          <span>{timeAtWithDay(assetItem?.end_time)}</span>
                        </p>
                      </div>
                    ) : null}

                    {type !== "directsale" ? (
                      highestStart && assetItem?.is_highest_bidder === false ? (
                        <button
                          className={`HigestBidderButton mt-5
                              }`}
                          onClick={() => handleShow("sm-down")}
                        >
                          <img src={HighestBidderWhite} alt="Highest Bidder" />
                          {" " + t("you_are_the_highest_bidder")}
                        </button>
                      ) : !highestStart &&
                        assetItem?.is_highest_bidder === true ? (
                        <button
                          className={`HigestBidderButton mt-4
                              }`}
                          onClick={() => handleShow("sm-down")}
                        >
                          <img src={HighestBidderWhite} alt="Highest Bidder" />
                          {" " + t("you_are_the_highest_bidder")}
                        </button>
                      ) : (
                        <>
                          {topUpNowCheck ? (
                            topUPUI
                          ) : (
                            <button
                              disabled={expiredAsset}
                              className={
                                expiredAsset
                                  ? "signin-phone disable_btn"
                                  : "signin-phone"
                              }
                              onClick={() => handleShow("sm-down")}
                            >
                              {loading ? (
                                <div className="spinner-border" role="status">
                                  <span className="visually-hidden">
                                    {t("loading")}
                                  </span>
                                </div>
                              ) : (
                                <>
                                  <img src={BidNow} alt="lock" />
                                  {t("bid_now")}{" "}
                                  {assetItem?.is_highest_bidder === false &&
                                    outBidderUI}
                                </>
                              )}
                            </button>
                          )}
                        </>
                      )
                    ) : (
                      <DirectSaleAddToCart
                        endTime={assetItem?.end_time}
                        asset_id={assetItem?.asset_id}
                        btnImg={cartImg}
                        btnValue={btnText}
                        classNameVal={classNameVal}
                        type={type}
                      />
                    )}
                  </div>
                </Col>
              </Row>

              <SignInPhoneModal
                handleCloseModal={closeModal}
                showSignIn={showSignIn}
                handleOpenModal={openModal}
                modalType={t("header_signin")}
              />
              <Modal
                show={showCalender}
                fullscreen={fullscreenview}
                onHide={() => setShowCalender(false)}
                className="NotificationModal"
              >
                <Modal.Header className="d-flex align-items-center">
                  <div className="CloseButtons">
                    <button
                      className="bahrainarrow"
                      onClick={() => setShow(false)}
                    >
                      <img src={CrossMark} alt="crossmark" />
                    </button>
                  </div>
                  <div className="HeadingContainer">
                    <h2 className="confirmnumber">
                      {t("request_for_viewing")}
                    </h2>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <ModalCalander />
                </Modal.Body>
                <div className="AuctionTermsConditions">
                  <p className="mb-0">
                    {t("by_continuing_agree_our")}
                    <span> {t("terms_and_conditions")}</span>
                    {t("and_symbol")}
                    <span> {t("privacy_policy")}</span>{" "}
                    {t("after_privacy_policy")}
                  </p>
                </div>
              </Modal>
            </>
          )}
        </>
      )}

      {showAuctionEnded && type !== "directsale" && (
        <CommonInfoModal
          fullscreen={false}
          modalType={
            assetItem?.is_highest_bidder
              ? "Auction Ended Won"
              : "Auction Ended Loss"
          }
          showModal={showAuctionEnded}
          showInfoIconModal={showAuctionEnded}
          setInfoIconModal={setShowAuctionEnded}
          asset_id={assetItem?.asset_id}
        />
      )}

      {show && type !== "directsale" && (
        <CommonBidModal
          categoryData={assetDetails}
          fullscreen={fullscreen}
          setShow={setShow}
          show={show}
          assetItem={assetItem}
          setAssetItem={setAssetItem}
          loader={loader}
          assetId={+assetId}
          assetTitle={assetItem?.asset_title}
          is_auto_bidding={is_auto_bidding}
          buttonSlider=""
          auto_bidding_on={assetItem?.auto_bidding_on}
          setHighestStart={setHighestStart}
          setHighestFalseShow={setHighestFalseShow}
          highestStart={highestStart}
          highestFalseShow={highestFalseShow}
          makeBidId={makeBidId}
          setMakeBidId={setMakeBidId}
        />
      )}

      {assetDetails && (
        <ViewMoreModal
          showViewMore={showViewMore}
          setViewMore={setViewMore}
          fullscreenview={fullscreenview}
          assetDetails={assetDetails}
          viewName={
            assetDetails.description && assetDetails.asset_details
              ? !description
                ? "DetailsButton"
                : "DescriptionButton"
              : assetDetails.description
              ? "DescriptionButton"
              : assetDetails.asset_details
              ? "DetailsButton"
              : null
          }
        />
      )}

      <Modal
        show={showSupport}
        onHide={() => setShowSupport(false)}
        className="NotificationModal"
      >
        <Modal.Header className="d-flex align-items-center">
          {/* <button className="crossbutton" onClick={() => setShowSupport(false)}>
            <img src={LeftArrow} alt="cancelbutton" />
          </button> */}

          <div className="CloseButtons">
            <button
              className="bahrainarrow"
              onClick={() => setShowSupport(false)}
            >
              <img src={LeftArrow} alt="LeftArrow" />
            </button>
          </div>

          <div className="HeadingContainer">
            <h2 className="confirmnumber">{t("help_contact")}</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center">
            <img src={HelpContact} alt="help" className="HelpAndContactImg" />
            <h2 className="AutoBidHeading">{t("need_help")}</h2>
          </div>
          <ProfileSocialContact />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default CategoryAssetDetailsOneData;
