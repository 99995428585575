import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

type Props = {
  name: string;
  value?: string;
  onChange?: (e: any) => void;
  inputProps?: any;
  errorProps?: any;
};

const FloatLabels = (props: Props) => {
  const { inputProps, errorProps } = props;
  return (
    <FloatingLabel controlId={props.name} label={props.name}>
      <Form.Control
        onChange={props.onChange}
        value={props.value}
        placeholder="placeholder-text"
        {...inputProps}
        data-hj-allow
      />
      {errorProps?.hasError && (
        <div className="text-danger" style={{ marginTop: 4 }}>
          {errorProps?.errorText}
        </div>
      )}
    </FloatingLabel>
  );
};

export default FloatLabels;
