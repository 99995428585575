import { useContext, useState } from "react";
import { Figure } from "react-bootstrap";
import Upload from "../../../assets/Icons/upload-file.svg";
import Success from "../../../assets/Icons/file-upload-success.svg";
import "../internalrouting.scss";
import { apiWithToken } from "../../../feature/envs/api";
import { useNavigate } from "react-router";
import { dateWithTime } from "../../../utils/functions";
import DatePicker from "react-datepicker";
import CalenderIcon from "../../../assets/Icons/calender.svg";
import "react-datepicker/dist/react-datepicker.css";
import { t } from "i18next";
import { UserContext } from "../../../contexts/signup";
import { EventLogger, EventNames } from "../../../utils/EventLogger";

type Props = {
  totalAmount: number;
  category_id: number;
  method: string;
  useDepositAmount?: any;
  transactionType?: any;
  typePurchase?: any;
  asset_id?: any;
  cart_id?: any;
  selectedType?: any;
};
function CustomInput(props: any) {
  return (
    <div className=" d-flex justify-content-start PaymentMethods PaymentHighlight  ">
      <div className="datepicker-input1" onClick={props.onClick}>
        <span>
          <img src={CalenderIcon} />
        </span>
      </div>

      <input
        type="text"
        className="datepicker-input1 w-100"
        onClick={props.onClick}
        value={props.value}
        onChange={props.onChange}
        placeholder="dd/mm/yyyy"
        data-hj-allow
      />
    </div>
  );
}
const formatWeekday = (dayName: any) => {
  return dayName.substr(0, 3);
};

const Chequetransfer = (props: Props) => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState();
  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState(false);
  const MinDateofTheYear = `${new Date().getMonth() + 1}/${
    new Date().getDate() - 2
  }/${new Date().getFullYear()}`;
  const handleChange = (e: any) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    updateData({ documents: e.target.files[0] });
  };

  const userDetails: any = {
    transaction_date: "",
    note: "",
  };

  const [paymentOptions, setPaymentOptions] = useState<any>(userDetails);

  const updateData = (obj: any) => {
    setPaymentOptions((prev: any) => ({ ...prev, ...obj }));
  };

  const { user } = useContext<any>(UserContext);
  const { contactDetails } = user;
const {company_name, timings, location, map_location, latitude, longitude} = contactDetails;


  const createTransaction = () => {
    const transaction_date = dateWithTime(paymentOptions.transaction_date);
    const bankPayment = new FormData();
    const bankPaymentCart = new FormData();
    bankPayment.append("documents", paymentOptions.documents);
    bankPayment.append("deposit_amount", `${props.totalAmount}`);
    bankPayment.append("transaction_date", `${transaction_date}`);
    bankPayment.append("payment_method", props.method);
    bankPayment.append("note", paymentOptions.note.trim());
    bankPayment.append("category_id", `${props.category_id}`);
    bankPayment.append("deposit_date", `${transaction_date}`);

    if (props?.transactionType === "INVOICE" && props?.cart_id > 0) {
      bankPaymentCart.append("payment_method", props.method);
      bankPaymentCart.append("documents", paymentOptions.documents);
      bankPaymentCart.append("deposit_date", `${transaction_date}`);
      bankPaymentCart.append("cart_id", `${props?.cart_id}`);
      bankPaymentCart.append("transaction_type", `${props?.transactionType}`);
    } else if (props?.transactionType === "INVOICE") {
      bankPayment.append("use_deposit_amount", `${props?.useDepositAmount}`);
      bankPayment.append("asset_id", `${props?.asset_id}`);
      bankPayment.append("transaction_type", `${props?.transactionType}`);
    } else {
      bankPayment.append("transaction_type", "DEPOSIT");
    }
    const data =
      props?.transactionType === "INVOICE" && props?.cart_id > 0
        ? bankPaymentCart
        : bankPayment;

    apiWithToken
      .post("api/v1/transaction", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        if (res.data.status === 200) {
          EventLogger.logEvent(EventNames.DEPOSIT, {category_id: props.category_id, payment_type: props.method});
          navigate(`/checkout?paymentid=${res.data.data.transaction_id}`, {
            state: res.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const directionClass = t("en") == "en" ? "textRight" : "";

  const mapUrl = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3578.466481348634!2d${longitude?.value}!3d${latitude?.value}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49a5ef88933a91%3A0xe243933f6e4774e0!2sMazad%20Bahrain!5e0!3m2!1sen!2sus!4v1697179886847!5m2!1sen!2sus`;


  const continueDisabled =
    paymentOptions.transaction_date === "" ||
    props.totalAmount === 0 ||
    !file;
  return (
    <div>
      <div className="UploadReciept">
        <p className="CurrentDepositeText">{t("cheque_message")}</p>
      </div>
      <div className="DepositeSection">
        <h2 className="PaymentMethodHeading">{t("deposit")}</h2>
        <div className="d-flex align-items-center PaymentMethods PaymentHighlight">
          <label htmlFor="upload-file" className="upload-deposit-file">
            <Figure.Image alt="youtube" src={Upload} />
            {t("check_transfer_slip")}
          </label>
          <input
            id="upload-file"
            type="file"
            onChange={handleChange}
            accept=".png, .jpg, .jpeg, .pdf"
            hidden
          />
          <div className="file-success">
            {file ? (
              <Figure.Image
                alt="file-upload-success"
                style={{ transition: "0.9s" }}
                src={Success}
              />
            ) : null}
          </div>
        </div>
        <div className="calender">
          <DatePicker
            selected={startDate}
            minDate={new Date(MinDateofTheYear)}
            maxDate={new Date()}
            customInput={<CustomInput />}
            formatWeekDay={formatWeekday}
            wrapperClassName="datepicker"
            onChange={(date: any) => {
              setStartDate(date);
              updateData({ transaction_date: date });
            }}
            dateFormat={"dd/MM/yyyy"}
            placeholderText="dd/mm/yyyy"
          />
        </div>
      </div>
      <div className="AccountDetails">
      <div className="d-flex justify-content-between align-items-center DetailsItem">
          <p className="CurrentAvailable">{t("company_name")}</p>
          <p className="CurrentAvailable ps-4">{t("en") === "en" ? company_name.value :  company_name.value_ar || company_name.value}</p>
        </div>
        <div className="d-flex justify-content-between align-items-center DetailsItem">
          <p className="CurrentAvailable">{t("location")}</p>
          <p className={`CurrentAvailable ps-4 ${directionClass}`}>{t("en") === "en" ? location.value :  location.value_ar || location.value }</p>
        </div>
        <div className="d-flex justify-content-between align-items-center DetailsItem">
          <p className="CurrentAvailable">{t("timing")}</p>
          <p className="CurrentAvailable">{t("en") === "en" ? timings.value :  timings.value_ar || timings.value}</p>
        </div>
      </div>
      <div className="cheque-map">
        <h3>{t("map")}</h3>
        <a
          href={map_location?.value}
          target="_blank"
          rel="noreferrer"
        >
          <p>{t("open_on_maps")}</p>
        </a>
      </div>
      <div className="loaction-url">
      <iframe  className="iframe-map" src={mapUrl} width="400" height="300" loading="lazy" ></iframe>
       
      </div>
      <button
        className={`confirm-otp-number ${
          (continueDisabled || loading) ? "disable_btn" : ""
        }`}
        onClick={() => {
          setLoading(true);
          createTransaction();
        }}
        disabled={continueDisabled || loading}
      >
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : props?.selectedType === "directsalecheckout" ? (
          t("continue_to_payment")
        ) : (
          t("continue")
        )}
      </button>
    </div>
  );
};

export default Chequetransfer;
