import { Container } from "react-bootstrap";
import BreadCrums from "../../components/breadcrumbs/breadcrums";
import CrossButton from "../../components/buttons/crossbutton";
import NavTabs from "../../components/tabs/navtabs";
import "./mywatchlist.scss";
import LeftArrow from "../../assets/Icons/left-arrow.svg";
import { useTranslation } from "react-i18next";
type Props = {};

const MyWatchList = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="d-flex align-items-center profilereg biddingswoncard">
        <CrossButton className="prevarrow" imgUrl={LeftArrow} />
        <BreadCrums
          previousPage={t("my_watchlist")}
        />
      </div>
      <NavTabs
        className="keyscontent"
        value1={t("auction")}
        value2={t("direct_sale")}
        tabValue1="watchlistAuction"
        tabValue2="watchListDirectSale"
      />
    </Container>
  );
};

export default MyWatchList;
