import AuctionEndingButton from "../../components/buttons/auctionendingbutton";
import CarPlate from "../../assets/Icons/car details.svg";
import { useNavigate } from "react-router-dom";
import Taxiplatelong from "../../assets/Icons/taxi_long.svg";
import SignInPhoneModal from "../../components/modals/signinphonemodal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  featuredata: any;
};

const SliderImages = (props: Props) => {
  const { featuredata } = props;
  const navigate = useNavigate();
  const [showSignIn, setShowSignIn] = useState(false);
  const { t } = useTranslation();

  const openModal = (modaltype: any) => {
    setShowSignIn(true);
  };

  const handleCloseShow = () => {
    setShowSignIn(false);
  };
  const longPlate =
    featuredata?.category_title?.split(" ")[0] === "Car"
      ? CarPlate
      : Taxiplatelong;
  const classVal =
    featuredata?.category_title?.split(" ")[0] === "Car"
      ? "box_img66"
      : "box_img77";

  const dummyImg_1_new = (
    <div className="container-plate">
      <div className="detail_img">
        <img src={longPlate} alt="bahrain" />
        <div className={classVal}>
          <span className="detailfont">{featuredata?.asset_title}</span>
        </div>
      </div>
    </div>
  );
  const dummyImg_2_new =
    featuredata?.asset_images && featuredata?.asset_images.length > 0 ? (
      <img
        src={featuredata?.asset_images && featuredata?.asset_images[0]}
        alt="bahrain"
        className="completed-image"
      />
    ) : (
      <div className="container-plate"></div>
    );

  var boxImg =
    featuredata?.category_title?.split(" ")[0] === "Taxi" ||
    featuredata?.category_title?.split(" ")[0] === "Car"
      ? dummyImg_1_new
      : dummyImg_2_new;

  if (
    featuredata?.card_type === "login" ||
    featuredata?.card_type === "featured" ||
    featuredata?.card_type === "make_deposit" ||
    featuredata?.card_type === "pending_registration" ||
    featuredata?.card_type === "pending_purchase"
  ) {
    var dummyImg_3_new = featuredata?.image ? (
      <img
        style={{ height: "auto" }}
        src={featuredata?.image}
        alt="bahrain"
        className="completed-image"
      />
    ) : (
      <div className="container-plate"></div>
    );

    var dummyImg_4_new = featuredata?.image_ar ? (
      <img
        style={{ height: "auto" }}
        src={featuredata?.image_ar}
        alt="bahrain"
        className="completed-image"
      />
    ) : (
      <div className="container-plate"></div>
    );

    boxImg = t("en") === "en" ? dummyImg_3_new : dummyImg_4_new;
  }

  const handleNavigate = () => {
    if (
      featuredata?.card_type === "login" ||
      featuredata?.card_type === "featured" ||
      featuredata?.card_type === "make_deposit" ||
      featuredata?.card_type === "pending_registration" ||
      featuredata?.card_type === "pending_purchase"
    ) {
      if (featuredata?.card_type === "login") {
        openModal("header_signin");
      }
      if (featuredata?.card_type === "make_deposit") {
        navigate("profile/deposits");
      }
      if (
        featuredata?.card_type === "pending_purchase"
      ) {
        navigate("complete-purchase");
      }
      if (
        featuredata?.card_type === "pending_registration"
      ) {
        navigate("profile/purchases");
      }
    } else {
      featuredata?.card_type === "pending_purchase"
        ? navigate(`complete-purchase?id=${featuredata?.asset_id}`)
        : navigate(
            `/a/${featuredata?.asset_id}`,
            {
              state: {
                data: featuredata,
                time: featuredata?.end_time,
                assestData: featuredata,
                title: featuredata?.asset_title,
                category_title: featuredata?.category_title,
                categoryId: featuredata?.category_id,
              },
            }
          );
    }
  };
  return (
    <>
      <div className="sliderimage">
        <div className=" boximage ">
          <div
            className="container_font pointer_class"
            onClick={handleNavigate}
          >
            {boxImg}
          </div>
        </div>

        {featuredata?.card_type !== "login" &&
        featuredata?.card_type !== "featured" &&
        featuredata?.card_type !== "make_deposit" &&
        featuredata?.card_type !== "pending_purchase" &&
        featuredata?.card_type !== "pending_registration" ? (
          <div className="sliderlivebutton">
            <AuctionEndingButton featuredata={props.featuredata} />
          </div>
        ) : (
          ""
        )}
      </div>
      <SignInPhoneModal
        showSignIn={showSignIn}
        handleCloseModal={handleCloseShow}
        handleOpenModal={openModal}
        modalType={t("header_signin")}
      />
    </>
  );
};

export default SliderImages;
