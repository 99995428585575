import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CrossButton from "../../components/buttons/crossbutton";
import LeftArrow from "../../assets/Icons/left-arrow.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "../../contexts/signup";
import { trackPageView } from "../../utils/analytics";

type Props = {};

const MazadTermsAndServices = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext<any>(UserContext);
  const { contactDetails } = user;
  const location = useLocation();
  const pathname = location?.pathname;


  const pageDetails: any = {
    "/terms-of-service": "Terms of Service",
    "/cookie-policy": "Cookie Policy",
    "/privacy-policy": "Privacy Policy",
    "/terms-of-use": "Terms of Use",

  };
  const cookiepolicy = t("en") === "en" ? contactDetails?.cookies_policy?.value : contactDetails?.cookies_policy?.value_ar;
  const privacypolicy = t("en") === "en" ? contactDetails?.privacy_policy?.value : contactDetails?.privacy_policy?.value_ar;
  const termsofservice = t("en") === "en" ? contactDetails?.terms_and_condition?.value : contactDetails?.terms_and_condition?.value_ar;
  const termsofuse = t("en") === "en" ? contactDetails?.terms_of_use?.value : contactDetails?.terms_of_use?.value_ar;
useEffect(()=>{
  trackPageView((window.location.pathname + window.location.search), pageDetails[pathname]);
},[])

  return (
    <Container>
      <Row>
        <div className="d-flex align-items-center profilereg biddingswoncard">
          <CrossButton className="prevarrow" imgUrl={LeftArrow} />
          <Breadcrumb>
            <Breadcrumb.Item href="#" className="breadcrumbtitle">
              {t("home")}
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#" className="breadcrumbtitle">
              {t("all_topics")}
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#" className="breadcrumbtitle">
              {t("legal_terms")}
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#" className="breadcrumbtitle">
              {contactDetails &&
                t(pageDetails[pathname].replaceAll(" ", "_").toLowerCase())}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </Row>
      <Row>
        <Col lg={8}>
          {contactDetails && pageDetails[pathname] === "Terms of Service" ? (
            <>
              <h2 className="TermsHeading">{t("terms_of_service")}</h2>
              <p dangerouslySetInnerHTML={{ __html: termsofservice }} />
            </>
          ) : contactDetails && pageDetails[pathname] === "Cookie Policy" ? (
            <>
              <h2 className="TermsHeading">{t("cookie_policy")}</h2>
              <p dangerouslySetInnerHTML={{ __html: cookiepolicy }} />
            </>
          ) : contactDetails && pageDetails[pathname] === "Terms of Use" ? (
            <>
              <h2 className="TermsHeading">{t("terms_of_use")}</h2>
              <p dangerouslySetInnerHTML={{ __html: termsofuse }} />
            </>
          ) : (
            contactDetails && (
              <>
                <h2 className="TermsHeading">{t("privacy_policy")}</h2>
                <p dangerouslySetInnerHTML={{ __html: privacypolicy }} />

              </>
            )
          )}
        </Col>
        <Col lg={1}></Col>
        <Col lg={3} className="FeedbackSection">
          <h2 className="TermsHeading">{t("need_to_get_in_touch")}</h2>
          <p className="WrightAnswers">{t("need_to_get_in_touch_text")}</p>
          <Button
            className="contactus w-100"
            variant="transparent"
            onClick={() => navigate("/contact-us", { replace: true })}
          >
            {" "}
            {t("contact_us")}
          </Button>
          <p className="FeedbackGiven">
            {t("you_can_also")} <a href="#">{t("give_us_feedback")}</a>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default MazadTermsAndServices;
