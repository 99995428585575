import { t } from "i18next";
import { Figure } from "react-bootstrap";
import { toast } from "react-toastify";
import Copy from "../../assets/Icons/copy.svg";

type Props = {
  userDetails: { name: string; cost: string }[];
};

const handleCopyText = (cost: any) => {
  navigator.clipboard.writeText(cost);
  toast(
    <p className="CartSuccessMsg">
      {t("copy_clipboard")}
    </p>,
    {
      autoClose: 3000,
      hideProgressBar: true,
      toastId: 'cartToast'
    }
  );
};

const AccountDetails = (props: Props) => {
  const { userDetails } = props;
  return (
    <>
      {userDetails.map(({ name, cost }, ind) => (
        <div
          className="d-flex justify-content-between DetailsItem"
          key={`${name}-${ind}`}
        >
          <p className="CurrentAvailable">{t(name)}</p>
          <div className="d-flex">
            <p className="CurrentAvailableCost">{cost}</p>
            <Figure.Image
              onClick={() => handleCopyText(cost)}
              alt="copy"
              src={Copy}
              className="CopiedIcon"
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default AccountDetails;
