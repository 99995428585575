import { Col, Container, Row } from "react-bootstrap";
import BreadCrums from "../../components/breadcrumbs/breadcrums";
import CrossButton from "../../components/buttons/crossbutton";
import ReachUsOptions from "./reachusoptions";
import LeftArrow from "../../assets/Icons/left-arrow.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import AllAccountDetails from "./allaccountdetails";
import { numberFormat, unixToDateTime } from "../../utils/functions";
import PaymentPending from "../../assets/Icons/payment-pending.svg";
import PaymentSucces from "../../assets/Icons/payment-success.svg";
import PaymentFailed from "../../assets/Icons/payment-failed.svg";
import { useTranslation } from "react-i18next";
import { CSSProperties, useEffect, useState } from "react";
import { apiWithToken } from "../../feature/envs/api";
import ClipLoader from "react-spinners/ClipLoader";
import { trackPageView } from "../../utils/analytics";

type Props = {};

const ContinueCheckout = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loader, setLoader] = useState(true);
  const [transactionData, setTransactionData] = useState<any>();
  const registerStatus = searchParams?.get("status") || "";
  const classRegister =
    registerStatus === "pending_approval"
      ? "CurrentAvailableCost StatusPending"
      : "CurrentAvailableCost StatusDone";

  function getTransactionData() {
    apiWithToken
      .put(
        `/api/v1/transaction/verify-payment?transaction_id=${searchParams.get(
          "paymentid"
        )}`
      )
      .then((res: any) => {
        if (res.data.status === 200) {
          setTransactionData(res.data.data);
          setLoader(false);
          const { id, asset_id } = res.data.data?.asset || res.data.data;
          setTimeout(() => {
            if (
              res.data.data.transaction_type === "INVOICE" &&
              registerStatus === "" &&
              res?.data?.data?.cart_id <= 0
            ) {
              navigate(`/complete-purchase?id=${asset_id || id}`);
            }
          }, 5000);
        }
      });
  }
  useEffect(() => {
    trackPageView((window.location.pathname + window.location.search), "Continue Checkout");
    getTransactionData();
  }, []);

  const override: CSSProperties = {
    display: "block",
    margin: "20% auto",
    borderColor: "black",
    overflow: "-moz-hidden-unscrollable",
    opacity: 0.1,
  };

  const title = t("payment_details");
  const paymentDate =
    transactionData?.payment_method === "benefit" ||
      transactionData?.payment_method === "credit_card" ||
      transactionData?.payment_method === "wallet" ||
      transactionData?.transaction_type === "REFUND"
      ? unixToDateTime(transactionData?.transaction_date || transactionData?.created_at)
      : unixToDateTime(transactionData?.deposit_date || transactionData?.created_at);

  const statusArray: any = {
    PENDING: {
      title:
        transactionData?.transaction_type === "REFUND"
          ? t("thank_you_for_your_request")
          : t("thank_you_for_your_payment"),
      subtitle:
        transactionData?.transaction_type === "REFUND"
          ? t("request_subtitle")
          : t("pending_subtitle"),
      status: t("processing"),
      img: PaymentPending,
      classVal: "CurrentAvailableCost StatusPending",
    },
    DONE: {
      title:
        transactionData?.transaction_type === "REFUND"
          ? t("thank_you_for_your_request")
          : t("thank_you_for_your_payment"),
      subtitle:
        transactionData?.transaction_type === "REFUND"
          ? t("your_request_was_successful")
          : t("your_payment_was_successful"),
      status:
        transactionData?.transaction_type === "REFUND"
          ? t("request_successful")
          : t("payment_successful"),
      img: PaymentSucces,
      classVal: "CurrentAvailableCost StatusDone",
    },
    CANCELLED: {
      title: t("cancel_payment"),
      subtitle: t("payment_cancel_subtitle"),
      status: t("payment_failed"),
      img: PaymentFailed,
      classVal: "CurrentAvailableCost StatusCanceled",
    },
    FAILED: {
      title: t("cancel_payment"),
      subtitle: t("payment_cancel_subtitle"),
      status: t("payment_failed"),
      img: PaymentFailed,
      classVal: "CurrentAvailableCost StatusCanceled",
    },
    REFUNDED: {
      title:
        transactionData?.status === "REFUNDED"
          ? t("thank_you_for_your_request")
          : t("thank_you_for_your_payment"),
      subtitle:
        transactionData?.status === "REFUNDED"
          ? t("your_request_was_successful")
          : t("your_payment_was_successful"),
      status:
        transactionData?.status === "REFUNDED"
          ? t("request_successful")
          : t("payment_successful"),
      img: PaymentSucces,
      classVal: "CurrentAvailableCost StatusDone",
    }
  };

  const paymentDetails = [
    {
      userDetails: [
        {
          name: t("payment_id"),
          cost: `${transactionData?.transaction_id}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("deposited_amount"),
          cost:
            t("en") === "en"
              ? t("bhd") +
              `${" " + numberFormat(transactionData?.deposit_amount)}`
              : `${" " +
              numberFormat(transactionData?.deposit_amount) +
              " " +
              t("bhd")
              }`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("selected_category"),
          cost: t("en") === "en"
            ? `${transactionData?.category_title}` : `${transactionData?.category_title_ar}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("limit_amount_added"),
          cost:
            t("en") === "en"
              ? t("bhd") +
              `${" " + numberFormat(transactionData?.deposit_amount)}`
              : `${" " +
              numberFormat(transactionData?.deposit_amount) +
              " " +
              t("bhd")
              }`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("new_limit_amount"),
          cost:
            t("en") === "en"
              ? t("bhd") +
              `${" " + numberFormat(transactionData?.new_current_limit || 0)}`
              : `${" " +
              numberFormat(transactionData?.new_current_limit || 0) +
              " " +
              t("bhd")
              }`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("payment_method"),
          cost: t(transactionData?.payment_method),
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("payment_date"),
          cost: `${unixToDateTime(
            transactionData?.transaction_date ||
            transactionData?.deposit_date ||
            transactionData?.created_at
          )}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("payment_status"),
          cost: `${statusArray[transactionData?.status]?.status}`,
          classVal: `${statusArray[transactionData?.status]?.classVal}`,
        },
      ],
    },
  ];

  const yesVal =
    t("en") === "en"
      ? `${t("yes")} ${t("bhd")} (${numberFormat(transactionData?.used_deposited_amount)})`
      : ` (${numberFormat(transactionData?.used_deposited_amount)}) ${t("bhd")} ${t("yes")}`;

  const useDepositAmount = transactionData?.use_deposit_amount
    ? yesVal
    : t("no");

  const total_amount_paid = transactionData?.deposit_amount;
  const asset_amount = transactionData?.deposit_amount + transactionData?.used_deposited_amount;

  const purchaseDetails = [
    {
      userDetails: [
        {
          name: t("payment_id"),
          cost: `${transactionData?.transaction_id}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("asset_id"),
          cost: transactionData?.id
            ? transactionData?.asset_id || transactionData?.asset?.id
            : "-",
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("asset_amount"),
          cost:
            t("en") === "en"
              ? t("bhd") + `${" " + numberFormat(asset_amount)}`
              : `${" " + numberFormat(asset_amount) + " " + t("bhd")}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("use_deposit_amount"),
          cost: useDepositAmount,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("total_amount_paid"),
          cost:
            t("en") === "en"
              ? t("bhd") + `${" " + numberFormat(total_amount_paid)}`
              : `${" " + numberFormat(total_amount_paid) + " " + t("bhd")}`,
          classVal: `CurrentAvailableCost`,
        },

        {
          name: t("payment_method"),
          cost: t(transactionData?.payment_method),
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("payment_date"),
          cost: `${paymentDate}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("payment_status"),
          cost: `${statusArray[transactionData?.status]?.status}`,
          classVal: `${statusArray[transactionData?.status]?.classVal}`,
        },
        {
          name: t("request_status"),
          cost: `${t(registerStatus)}`,
          classVal: `${classRegister}`,
        },
      ],
    },
  ];

  const checkoutDetails = [
    {
      userDetails: [
        {
          name: t("payment_id"),
          cost: `${transactionData?.transaction_id}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("order_id"),
          cost: `${transactionData?.cart_id || 0}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("total_amount_paid"),
          cost:
            t("en") === "en"
              ? t("bhd") +
              `${" " +
              numberFormat(
                transactionData?.deposit_amount -
                transactionData?.used_deposited_amount
              )
              }`
              : `${" " +
              numberFormat(
                transactionData?.deposit_amount -
                transactionData?.used_deposited_amount
              ) +
              " " +
              t("bhd")
              }`,
          classVal: `CurrentAvailableCost`,
        },

        {
          name: t("payment_method"),
          cost: t(transactionData?.payment_method),
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("payment_date"),
          cost: `${paymentDate}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("payment_status"),
          cost: `${statusArray[transactionData?.status]?.status}`,
          classVal: `${statusArray[transactionData?.status]?.classVal ||
            "successfully_approved"
            }`,
        },
        {
          name: t("request_status"),
          cost: `${t(registerStatus)}`,
          classVal: `${classRegister}`,
        },
      ],
    },
  ];
  const refundDetails = [
    {
      userDetails: [
        {
          name: t("request_id"),
          cost: `${transactionData?.transaction_id}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("request_amount"),
          cost:
            t("en") === "en"
              ? t("bhd") +
              `${" " + numberFormat(transactionData?.deposit_amount || 0)}`
              : `${" " +
              numberFormat(transactionData?.deposit_amount || 0) +
              " " +
              t("bhd")
              }`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("request_method"),
          cost: t(transactionData?.payment_method),
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("refund_request_date"),
          cost: `${paymentDate}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("request_status"),
          cost: `${statusArray[transactionData?.status]?.status}`,
          classVal: `${statusArray[transactionData?.status]?.classVal ||
            "successfully_approved"
            }`,
        },
      ],
    },
  ];

  const currentPage =
    transactionData?.transaction_type === "INVOICE"
      ? transactionData && t("my_purchases")
      : transactionData && t("my_deposit_bidding_limit");
  const end_title =
    transactionData?.transaction_type === "REFUND" || transactionData?.status === "REFUNDED"
      ? transactionData && t("refund_deposit")
      : "";

  return (
    <Container>
      <Row>
        <Col>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center profilereg biddingswoncard">
              <CrossButton className="prevarrow" imgUrl={LeftArrow} />
              {!loader && (
                <BreadCrums
                  previousPage={t("profile")}
                  currentPage={currentPage}
                  end_title={end_title}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        {loader ? (
          <ClipLoader
            color={"#fff"}
            loading={loader}
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <Col className="CheckoutSection">
            <div className="text-center CheckoutTopSection">
              <div className="text-center UserPaymentStatus">
                <img
                  src={statusArray[transactionData?.status]?.img}
                  alt={transactionData?.status}
                />
                <h2 className="profileheading">
                  {statusArray[transactionData?.status]?.title}
                </h2>
                <div>
                  <span className="profileheading"> {t("bhd")}</span>
                  <span className="UserPaidAmount">
                    {" "}
                    {" " +
                      numberFormat(
                        transactionData?.payment_method === "wallet"
                          ? transactionData?.used_deposited_amount > 0 ? transactionData?.used_deposited_amount
                            : transactionData?.deposit_amount : transactionData?.deposit_amount
                      )}
                  </span>
                </div>
              </div>
              <p className="CurrentAvailable">
                {statusArray[transactionData?.status]?.subtitle}
              </p>
            </div>
            {transactionData?.transaction_type === "DEPOSIT" && transactionData?.status === "REFUNDED" &&
              refundDetails?.map(({ userDetails }) => (
                <div className="AccountDetails">
                  <h2 className="PaymentMethodHeading">{title}</h2>
                  <AllAccountDetails userDetails={userDetails} />
                </div>
              ))}

            {transactionData?.transaction_type === "DEPOSIT" &&  transactionData?.status !== "REFUNDED" &&
              paymentDetails.map(({ userDetails }) => (
                <div className="AccountDetails">
                  <h2 className="PaymentMethodHeading">{title}</h2>
                  <AllAccountDetails userDetails={userDetails} />
                </div>
              ))}
            {transactionData?.transaction_type === "INVOICE" &&
              transactionData?.cart_id &&
              checkoutDetails.map(({ userDetails }) => (
                <div className="AccountDetails">
                  <h2 className="PaymentMethodHeading">{title}</h2>
                  <AllAccountDetails userDetails={userDetails} />
                </div>
              ))}
            {transactionData?.transaction_type === "INVOICE" &&
              !transactionData?.cart_id &&
              purchaseDetails.map(({ userDetails }) => (
                <div className="AccountDetails">
                  <h2 className="PaymentMethodHeading">{title}</h2>
                  <AllAccountDetails userDetails={userDetails} />
                </div>
              ))}
            {transactionData?.transaction_type === "REFUND" &&
              refundDetails?.map(({ userDetails }) => (
                <div className="AccountDetails">
                  <h2 className="PaymentMethodHeading">{title}</h2>
                  <AllAccountDetails userDetails={userDetails} />
                </div>
              ))}
            <ReachUsOptions />
            <button
              className="confirm-otp-number"
              onClick={() => {
                transactionData?.transaction_type === "DEPOSIT"
                  ? navigate("/profile/deposits")
                  : transactionData?.transaction_type === "INVOICE" &&
                    transactionData?.cart_id
                    ? navigate(`/profile/purchases?val=${t("direct_sale")}`)
                    : transactionData?.transaction_type === "REFUND"
                      ? navigate(`/profile/transactions`)
                      : navigate(`/bids?val=${t("bids_won")}`);
              }}
            >
              {t("continue")}
            </button>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default ContinueCheckout;
