import { Modal } from "react-bootstrap";
import "./modals.scss";

type Props = {
  showModal: boolean;
  validationCompleted: boolean;
  onActionClick: () => void;
  warningText: string;
};

const Warningmodal = (props: Props) => {
  return (
    <Modal show={props.showModal} onHide={() => {}}>
      <div
        className={`warning-heading ${
          props.validationCompleted ? "success" : "failure"
        }`}
      >
        {props.validationCompleted ? "Success" : "Error"}!!!!
      </div>
      <div className="warning-text">{props.warningText}</div>
      <div>
        <button onClick={props.onActionClick} className="confirmsubmit">
          {props.validationCompleted ? "Ok" : "Retry"}
        </button>
      </div>
    </Modal>
  );
};

export default Warningmodal;
