import { useContext, useState } from "react";
import { Figure } from "react-bootstrap";
import AccountDetails from "../accountdetails";
import Upload from "../../../assets/Icons/upload-file.svg";
import Success from "../../../assets/Icons/file-upload-success.svg";
import { apiWithToken } from "../../../feature/envs/api";
import { useNavigate } from "react-router";
import moment from "moment";
import { dateWithTime } from "../../../utils/functions";
import DatePicker from "react-datepicker";
import CalenderIcon from "../../../assets/Icons/calender.svg";
import "react-datepicker/dist/react-datepicker.css";
import { t } from "i18next";
import { UserContext } from "../../../contexts/signup";
import { EventLogger, EventNames } from "../../../utils/EventLogger";

type Props = {
  totalAmount: number;
  category_id: number;
  method: string;
  useDepositAmount?: any;
  transactionType?: any;
  typePurchase?: any;
  asset_id?: any;
  cart_id?: any;
  selectedType?: any;
};

function CustomInput(props: any) {
  return (
    <div className=" d-flex justify-content-start PaymentMethods PaymentHighlight  ">
      <div className="datepicker-input1" onClick={props.onClick}>
        <span>
          <img src={CalenderIcon} />
        </span>
      </div>

      <input
        type="text"
        className="datepicker-input1 w-100"
        onClick={props.onClick}
        value={props.value}
        onChange={props.onChange}
        placeholder="dd/mm/yyyy"
        data-hj-allow
      />
    </div>
  );
}
const formatWeekday = (dayName: any) => {
  return dayName.substr(0, 3);
};

const Banktransfer = (props: Props) => {
  const navigate = useNavigate();
  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const MinDateofTheYear = `${new Date().getMonth() + 1}/${new Date().getDate() - 2
    }/${new Date().getFullYear()}`;
  const handleChange = (e: any) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    updateData({ documents: e.target.files[0] });
  };

  const userDetails: any = {
    transaction_date: "",
    note: "",
  };


  const { user } = useContext<any>(UserContext);
  const { contactDetails } = user;
  const { account_name, account_number, bank_name, company_name, contact_number, swift_code, timings, location, iban_number, routing_number
  } = contactDetails;

  const accountDetails = [
    {
      title: t("account_details"),
      userDetails: [
        {
          name: "account_name",
          cost: t("en") === "en" ? account_name?.value : account_name?.value_ar || account_name?.value,
        },
        {
          name: "bank_name",
          cost: t("en") === "en" ? bank_name?.value : bank_name?.value_ar || bank_name?.value,
        },
        {
          name: "iban",
          cost: t("en") === "en" ? iban_number?.value : iban_number?.value_ar || iban_number?.value,
        },
        {
          name: "account_number",
          cost: t("en") === "en" ? account_number?.value : account_number?.value_ar || account_number?.value,
        },
        {
          name: "swift_code",
          cost: t("en") === "en" ? swift_code?.value : swift_code?.value_ar || swift_code?.value,
        },
        {
          name: "routing_number",
          cost: t("en") === "en" ? routing_number?.value : routing_number?.value_ar || routing_number?.value,
        },
      ],
    },
  ];

  const [paymentOptions, setPaymentOptions] = useState<any>(userDetails);

  const updateData = (obj: any) => {
    setPaymentOptions((prev: any) => ({ ...prev, ...obj }));
  };

  const createTransaction = () => {
    const transaction_date = dateWithTime(paymentOptions.transaction_date);
    const bankPayment = new FormData();
    const bankPaymentCart = new FormData();
    bankPayment.append("documents", paymentOptions.documents);
    bankPayment.append("deposit_amount", `${props.totalAmount}`);
    bankPayment.append("transaction_date", `${transaction_date}`);
    bankPayment.append("payment_method", props.method);
    bankPayment.append("category_id", `${props.category_id}`);
    bankPayment.append("deposit_date", `${transaction_date}`);

    if (props?.transactionType === "INVOICE" && props?.cart_id > 0) {
      bankPaymentCart.append("payment_method", props.method);
      bankPaymentCart.append("documents", paymentOptions.documents);
      bankPaymentCart.append("deposit_date", `${transaction_date}`);
      bankPaymentCart.append("cart_id", `${props?.cart_id}`);
      bankPaymentCart.append("transaction_type", `${props?.transactionType}`);
    } else if (props?.transactionType === "INVOICE") {
      bankPayment.append("use_deposit_amount", `${props?.useDepositAmount}`);
      bankPayment.append("asset_id", `${props?.asset_id}`);
      bankPayment.append("transaction_type", `${props?.transactionType}`);
    } else {
      bankPayment.append("transaction_type", "DEPOSIT");
    }

    bankPayment.append("note", paymentOptions.note.trim());

    const data =
      props?.transactionType === "INVOICE" && props?.cart_id > 0
        ? bankPaymentCart
        : bankPayment;

    apiWithToken
      .post("api/v1/transaction", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        if (res.data.status === 200) {
          EventLogger.logEvent(EventNames.DEPOSIT, {category_id: props.category_id, payment_type: props.method});
          navigate(`/checkout?paymentid=${res.data.data.transaction_id}`, {
            state: res.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
  };

  const continueDisabled =
    paymentOptions.transaction_date === "" || props.totalAmount === 0 || !file;

  return (
    <div>
      <div className="UploadReciept">
        <p className="CurrentDepositeText">{t("upload_bank_reciet")}</p>
      </div>
      <div className="DepositeSection">
        <h2 className="PaymentMethodHeading">{t("deposit")}</h2>
        <div className="PaymentMethods d-flex align-items-center PaymentHighlight">
          <div className="d-flex align-items-center TransforItem">
            <label htmlFor="upload-file" className="upload-deposit-file">
              <Figure.Image alt="youtube" src={Upload} />
              {t("deposit_transfer_slip")}
            </label>
            <input
              id="upload-file"
              type="file"
              onChange={handleChange}
              accept=".png, .jpg, .jpeg, .pdf"
              hidden
            />
            <div className="file-success">
              {file ? (
                <Figure.Image
                  alt="file-upload-success"
                  style={{ transition: "0.9s" }}
                  src={Success}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div>
          <DatePicker
            selected={startDate}
            minDate={new Date(MinDateofTheYear)}
            maxDate={new Date()}
            customInput={<CustomInput />}
            formatWeekDay={formatWeekday}
            onChange={(date: any) => {
              setStartDate(date);
              updateData({ transaction_date: date });
            }}
            dateFormat={"dd/MM/yyyy"}
            placeholderText="dd/mm/yyyy"
          />
        </div>
      </div>
      {accountDetails.map(({ title, userDetails }) => (
        <div key={title} className="AccountDetails">
          <h2 className="PaymentMethodHeading">{title}</h2>
          <AccountDetails userDetails={userDetails} />
        </div>
      ))}

      <button
        className={`confirm-otp-number ${(continueDisabled || loading) ? "disable_btn" : ""
          }`}
        onClick={() => {
          setLoading(true);
          createTransaction();
        }}
        disabled={continueDisabled || loading}
      >
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : props?.selectedType === "directsalecheckout" ? (
          t("continue_to_payment")
        ) : (
          t("continue")
        )}
      </button>
    </div>
  );
};

export default Banktransfer;
