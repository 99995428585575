import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Container, Row } from "react-bootstrap";
import CategoryPropertiesGrid from "./categorypropertiesgrid";
import "./auctionbidding.scss";
import { useTranslation } from "react-i18next";
import {
  useNavigate,
  useParams,
} from "react-router-dom";
import HeaderCarousel from "../../components/navbar/headercarousel";
import CrossButton from "../../components/buttons/crossbutton";
import BreadCrums from "../../components/breadcrumbs/breadcrums";
import LeftArrow from "../../assets/Icons/left-arrow.svg";
import CategoryPropertyListNew from "./categorypropertylistnew";
import { apiWithToken } from "../../feature/envs/api";
import { CartCount, UserContext, AllDataContext } from "../../contexts/signup";
import { toast } from "react-toastify";
import { connectSocket, disconnectSocket } from "../../utils/socket";
import { MapView } from "./mapview";
import { Libraries, useLoadScript } from "@react-google-maps/api";
import { decryptData } from "../../utils/functions";
import ClipLoader from "react-spinners/ClipLoader";
import { trackPageView } from "../../utils/analytics";
import { EventLogger, EventNames } from "../../utils/EventLogger";

let socket: any = null;
let assetsArr: any = [];
let asset_id: any = null;
type Props = {
  direction?: any;
};
let isRequestInProgress = false;
const libraries: Libraries = ["drawing", "places"]; //Added for performance warning

const AuctionBidding = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [viewGrid, setViewGrid] = useState(true);
  const [fullscreen, setFullscreen] = useState<any>(true);
  const [show, setShow] = useState(false);
  const [assetItem, setAssetItem] = useState<any>();
  const [assetId, setAssetId] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [assetLoader, setAssetLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);

  const [is_auto_bidding, setis_auto_bidding] = useState(false);
  const [assetTitle, setAssetTitle] = useState();
  const [showSignIn, setShowSignIn] = useState(false);
  const { user } = useContext<any>(UserContext);
  const [filteredAssetData, setFilteredAssetData] = useState<any>([]);
  const { setCartCount } = useContext<any>(CartCount);
  const [categoryData, setCategoryData] = useState<any>();
  const [sortObj, setSortObj] = useState<any>();
  const { allData, setAllData } = useContext<any>(AllDataContext);
  const [highestFalseShow, setHighestFalseShow] = useState(false);
  const [highestStart, setHighestStart] = useState(false);
  const [makeBidId, setMakeBidId] = useState(null);
  const { id }: any = useParams();
  const [totalAssetCount, setTotalAssetCount] = useState<number>(); //To get the number of total assets per category

  //MAP Code
  const [viewMap, setViewMap] = useState(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCNndAcjTzbIK0Q6Z3v2jWcBf3tOf-GiPA",
    libraries: libraries,
  });

  // calls fetch auctions function when the map view is in focus
  useEffect(() => {
    if (viewMap === true) {
      fetchAuctions();
    }
  }, [viewMap])

  useEffect(() => {
    trackPageView((window.location.pathname + window.location.search), "Auctions");
    window.scrollTo(0, 0); // Scroll to the top of the page
    assetsArr = [];
    socket = connectSocket();
    return () => {
      disconnectSocket();
    };

  }, []);

  useEffect(() => {
    addSocketListeners();
    return () => {
      socket.off("make-bid", emitMakeBid);
      socket.off("asset-bid-details", getAssetBidDetail);
      socket.off("turn-off-auto-bid", turnOffAutoBid);
      socket.off("add-to-watchlist", addWatchlist);
      socket.off("remove-from-watchlist", removeWatchlist);
      socket.off("add-to-cart", addRemoveCartSocketCall);
      socket.off("remove-from-cart", addRemoveCartSocketCall);
      socket.off("my-cart", addRemoveCartSocketCall);
      socket.off("my-cart-details", getMyCartDetails);
    };
  }, [socket]);

  const addSocketListeners = () => {
    if (socket !== null) {
      socket.on("make-bid", emitMakeBid);
      socket.on("asset-bid-details", getAssetBidDetail);
      socket.on("turn-off-auto-bid", turnOffAutoBid);
      socket.on("add-to-watchlist", addWatchlist);
      socket.on("remove-from-watchlist", removeWatchlist);
      socket.on("add-to-cart", addRemoveCartSocketCall);
      socket.on("remove-from-cart", addRemoveCartSocketCall);
      socket.on("my-cart", addRemoveCartSocketCall);
      socket.on("my-cart-details", getMyCartDetails);
    }
  };

  const getMyCartDetails = useCallback((res: any) => {
    let count: any = null;
    if (res?.data?.cart && res?.data?.cart?.items?.length) {
      count = res?.data?.cart?.items?.length;
    } else {
      count = null;
    }
    setCartCount(count);
  }, []);

  const emitMakeBid = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    socket.emit("get-bid-details", {
      asset_id: res.data.asset_id,
      authorization: authToken,
    });
  }, []);

  const addWatchlist = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("get-bid-details", {
        asset_id: res.data.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const removeWatchlist = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("get-bid-details", {
        asset_id: res.data.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const getAssetBidDetail = useCallback((res: any) => {
    const data = res?.data;
        const arr = assetsArr.map((item: any) => {
      if (item?.asset_id === data?.asset_id) {
        return {
          ...item,
          end_time: data?.end_time,
          highest_price: data?.highest_price,
          is_added_to_watchlist: data?.is_added_to_watchlist,
          is_auto_bidding: data?.is_auto_bidding,
          is_highest_bidder: data?.is_highest_bidder,
          total_bids: data?.total_bids,
          latest_bid_time: data?.latest_bid_time,
        };
      } else {
        return item;
      }
    });

    assetsArr = arr;

    if (+res?.data?.asset_id === +data?.asset_id || asset_id === null) {
      setAllData({ ...allData, auctions: assetsArr });
      setFilteredAssetData([...assetsArr]);
      if (res?.data?.asset_id === asset_id) {
        bidDetailApiResponse(res);
      }
    }
  }, []);

  const turnOffAutoBid = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (
      +res?.data?.asset_id === +asset_id &&
      authToken !== null &&
      authToken !== undefined
    ) {
      socket.emit("get-bid-details", {
        asset_id: res?.data?.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const addRemoveCartSocketCall = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("get-bid-details", {
        asset_id: res.data.asset_id,
        authorization: authToken,
      });
      socket.emit("my-cart", {
        authorization: authToken,
      });
    }
  }, []);

  const bidDetailApiResponse = (res: any) => {
    setAssetItem(res?.data);
    setis_auto_bidding(res?.data?.is_auto_bidding);
  };

  // When user not logged in open Signin Modal
  const openModal = useCallback((modaltype: any) => {
    setShowSignIn(true);
  }, [setShowSignIn]);
  const closeModal = useCallback((modaltype: any) => {
    setShowSignIn(false);
  }, [setShowSignIn]);

  // When user logged in and bid popup is open
  function getBidDetails() {
    apiWithToken
      .get(`api/v1/bids/assets/${assetId}/get-bid-details`)
      .then((res: any) => {
        if (res.data.status === 200) {
          bidDetailApiResponse(res.data);
          setAssetId(res?.data?.data?.asset_id);
          asset_id = res?.data?.data?.asset_id;
          setLoader(false);
        }
      });
  }

  // When Bid popup is Open
  useEffect(() => {
    if (show) {
      setLoader(true);
      getBidDetails();
    } else {
      setAssetItem("");
    }
  }, [show]);

  function handleShow(breakpoint: any, asset: any, event: any, index: number) {
    event.stopPropagation();
    if (!user.userLoggedIn) {
      setShowSignIn(true);
    } else {
      const userDetails = decryptData("user-details");

      if (
        userDetails?.identity_verification_status !== true &&
        userDetails?.doc_review_status !== "completed" &&
        userDetails?.full_name === null
      ) {
        navigate("/registration");
      } else if (
        userDetails?.identity_verification_status !== true &&
        userDetails?.doc_review_status !== "completed" &&
        userDetails?.full_name !== null
      ) {
        navigate("/verify-user");
      } else {
        setAssetId(asset.id ? asset.id : asset.asset_id);
        asset_id = asset.id ? asset.id : +asset.asset_id;
        setAssetItem(filteredAssetData[index]);
        setFullscreen(breakpoint);
        setShow(true);
        setCategoryData(asset);
        setAssetTitle(asset?.category_title);
      }
    }
  }

  const handleDetailPage = (asset: any, index: number) => {
    const id = asset.id || asset.asset_id;
    navigate(`/a/${id}`, {
      state: {
        data: asset,
        time: asset.end_time,
        assestData: filteredAssetData,
        category: categoryData,
        index: index,
        title: asset?.asset_title,
        category_title: asset?.category_title,
        categoryId: id,
      },
    });
  };

  const handleWatchlist = (event: any, asset: any) => {
    event.stopPropagation();
    if (!user.userLoggedIn) {
      setShowSignIn(true);
    } else {
      const userDetails = decryptData("user-details");

      if (
        userDetails?.identity_verification_status !== true &&
        userDetails?.doc_review_status !== "completed" &&
        userDetails?.full_name === null
      ) {
        navigate("/registration");
      } else if (
        userDetails?.identity_verification_status !== true &&
        userDetails?.doc_review_status !== "completed" &&
        userDetails?.full_name !== null
      ) {
        navigate("/verify-user");
      } else {
        if (asset?.is_added_to_watchlist) {
          apiWithToken
            .put(`/api/v1/watchlist/remove-from-watchlist`, {
              asset_id: asset?.asset_id,
            })
            .then((res: any) => {
              if (res.data.status === 200) {
                EventLogger.logEvent(EventNames.REMOVE_FROM_WATCHLIST, {asset_id: asset?.asset_id});
                toast(
                  <p className="CartErrMsgWatch">
                    {t("removed_from_watchlist")}
                  </p>,
                  {
                    autoClose: 2500,
                    hideProgressBar: true,
                    toastId: "cartToast",
                  }
                );
              }
            });
        } else {
          apiWithToken
            .post(`/api/v1/watchlist/add-to-watchlist`, {
              asset_id: asset?.asset_id,
            })
            .then((res: any) => {
              if (res.data.status === 200) {
                EventLogger.logEvent(EventNames.ADD_TO_WATCHLIST, {asset_id: asset?.asset_id});
                toast(
                  <p className="CartSuccessMsg">{t("added_to_watchlist")}</p>,
                  {
                    autoClose: 3000,
                    hideProgressBar: true,
                    toastId: "cartToast",
                  }
                );
              }
            });
        }
      }
    }
  };

  const prevIdRef = useRef<string | undefined>(undefined);
  const [highestPrices, setHighestPrices]: any = useState({ min: 0, max: 0 });
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState<any>(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 700 <
      document.documentElement.offsetHeight ||
      isLoading
    ) {
      return;
    }
    totalPage > page && fetchAuctions();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  useEffect(() => {
    if (prevIdRef.current === undefined || prevIdRef.current !== id) {
      prevIdRef.current = id;
      setFilteredAssetData([]);
      setAllData({ ...allData, auction_asset: [] });
      fetchAuctions();
    } else if (sortObj && sortObj?.priceRange) {
      fetchAuctions();
    } else {
      // Update prevIdRef.current with the current id value
      prevIdRef.current = id;
      const timeout = setTimeout(() => {
        fetchAuctions();
      }, 350);
      // Clear the previous timeout on every input change
      return () => clearTimeout(timeout);
    }
  }, [id, searchValue, sortObj]);

  function fetchAuctions() {
    if (page === 0) setAssetLoader(true);
    if (page >= 1) setPageLoader(true);

    setIsLoading(true);
    if (isRequestInProgress === true && !searchValue) {
      return; // Exit the function if a request is already in progress
    }
    isRequestInProgress = true;
    const apiUrl = `/api/v1/category/${id}/auction/assets`;

    if (searchValue || (sortObj && sortObj?.priceRange)) {
      let min = 0,
        max = null;
      if (sortObj?.priceRange) {
        const { min_price, max_price } = sortObj?.priceRange;
        if (highestPrices.min !== min_price || highestPrices.max !== max_price) {
          min = min_price;
          max = max_price;
        }

      }
      let apiEndpoint = sortObj
        ? `${apiUrl}?sort_by=${sortObj.sort_by || "highest_price"}&sort_order=${sortObj.sort_order || "DESC"
        }&search_value=${searchValue}`
        : `${apiUrl}?search_value=${searchValue}`;
      if (max !== null && min !== null) {
        apiEndpoint += `&min_price=${min}&max_price=${max}`;
      }

      // checks if map view is in focus & the total asset count is not null
      const fullUrl = viewMap && totalAssetCount ? `${apiEndpoint}&take=${totalAssetCount}` : `${apiEndpoint}&page=${page + 1}` ;
      apiWithToken
        .get(fullUrl)
        .then((res: any) => {
          if (res.data.status === 200) {
            const auction_asset = res?.data?.data?.data;
            const total = res?.data?.data?.total; // stores the total in a constant to be used in the same render instead of the previous render in useState
            setTotalPage(total);
            setPage(page + 1);

            const mergeData =
              allData?.auction_asset && page + 1 !== 1
                ? [...allData?.auction_asset, ...auction_asset]
                : auction_asset;
            setFilteredAssetData(mergeData);
            setAllData({ ...allData, auction_asset: mergeData });
            assetsArr = mergeData;
            !viewMap && setTotalAssetCount(30 * total) // Only change the total asset count if it is normal view so only so it doesnt multiply by the total of non-paginated map view api response 
          } else {
            setFilteredAssetData([]);
            assetsArr = [];
          }
        })
        .catch((error) => {
          // Handle any errors
          console.error(error);
        })
        .finally(() => {
          isRequestInProgress = false; // Reset the flag after the request is completed
          setIsLoading(false);
          setAssetLoader(false);
          setPageLoader(false);
        });
    } else {
      // checks if map view is in focus & the total asset count is not null
      const fullUrl = viewMap && totalAssetCount ? `${apiUrl}?&take=${totalAssetCount}` : `${apiUrl}?page=${page + 1}` ;

      apiWithToken
        .get(fullUrl)
        .then((res: any) => {
          if (res.data.status === 200) {
            const auction_asset = res?.data?.data?.data;
            const total = res?.data?.data?.total; // stores the total in a constant to be used in the same render instead of the previous render in useState
            setTotalPage(total);
            setPage(page + 1);
            // const mergeData =
            //   allData?.auction_asset && page + 1 !== 1
            //     ? [...allData?.auction_asset, ...auction_asset]
            //     : auction_asset;
            const mergeData =
              allData?.auction_asset && page + 1 !== 1
                ? [...assetsArr, ...auction_asset]
                : auction_asset;
           // const mergeData = [...assetsArr, ...auction_asset];
           !viewMap && setTotalAssetCount(30 * total); // Only change the total asset count if it is normal view so only so it doesnt multiply by the total of non-paginated map view api response 
           //console.log("Total Asset Count",totalAssetCount)

            setFilteredAssetData(mergeData);
            setAllData({
              ...allData,
              auction_asset: mergeData,
              category_title: mergeData[0]?.category_title,
              category_title_ar: mergeData[0]?.category_title_ar,

            });


            setHighestPrices({
              min: res?.data?.data?.prices?.min_price,
              max: res?.data?.data?.prices?.max_price,
            });
            assetsArr = mergeData;
          } else {
            setFilteredAssetData([]);
            setAllData({ ...allData, auction_asset: [] });
            setHighestPrices({
              min: 0,
              max: 0,
            });
            assetsArr = [];
          }
        })
        .catch((error) => {
          // Handle any errors
          console.error(error);
        })
        .finally(() => {
          isRequestInProgress = false; // Reset the flag after the request is completed
          setIsLoading(false);
          setAssetLoader(false);
          setPageLoader(false);
        });
    }
  }

  const handleFilter = (search: any) => {
    setPage(0);
    setSortObj(search);
  };

  const override: CSSProperties = {
    display: "block",
    margin: "20% auto",
    borderColor: "black",
    overflow: "-moz-hidden-unscrollable",
    opacity: 0.1,
  };

  const handleSearch = (e: any) => {
    const { value } = e.target;
    setSearchValue(value);
    setPage(0);
  };
  //Delay in API call ( debouncing)

  useEffect(() => {
    const savedView: any = localStorage.getItem("GridViewOn");
    setViewGrid(JSON.parse(savedView));
  }, []);

  function setFilteredAssetDataFunc(asset_id?: any) {
    if (asset_id) {
      const arra = filteredAssetData.filter((asset: any) => asset.asset_id !== asset_id)
      assetsArr = arra;
      setFilteredAssetData(arra)
          }
  }
  var currentPage = allData?.category_title
    ? allData?.category_title
    : filteredAssetData?.length > 0
      ? t("en") === "en"
        ? filteredAssetData[0]?.category_title
        : filteredAssetData[0]?.category_title_ar
      : "";

  var category_title = allData?.category_title
    ? allData?.category_title
    : filteredAssetData?.length > 0
      ? filteredAssetData[0]?.category_title
      : "";

  return (
    <>
      <Container>
        <HeaderCarousel
          direction={props.direction}
          handleSearch={handleSearch}
          setViewGrid={setViewGrid}
          viewGrid={viewGrid}
          assetTitle={currentPage}
          handleFilter={handleFilter}
          type={"auction"}
          filterValue={sortObj}
          setAssetLoader={setAssetLoader}
          setViewMap={setViewMap}
          viewMap={viewMap}
          highestPrices={highestPrices}
          setPage={setPage}
        />
      </Container>
      <Container fluid={viewMap}>
        {!viewMap && (
          <Row>
            <div className="d-flex justify-content-between profilereg biddingswoncard">
              <div className="d-flex align-items-center ">
                <CrossButton className="prevarrow" imgUrl={LeftArrow} setPage={setPage}/>
                <BreadCrums
                  previousPage={t("auction")}
                  currentPage={t("en") === "en"
                    ? currentPage : allData?.category_title_ar}
                />
              </div>
            </div>
          </Row>
        )}

        {(viewGrid &&
          filteredAssetData &&
          filteredAssetData.length > 0 &&
          filteredAssetData[0].hasOwnProperty("asset_images")) ||
          (viewGrid && Array.isArray(filteredAssetData)) ? (
          <CategoryPropertiesGrid
            showSignIn={showSignIn}
            setShowSignIn={setShowSignIn}
            show={show}
            assetItem={assetItem}
            setShow={setShow}
            filteredAssetData={filteredAssetData}
            is_auto_bidding={is_auto_bidding}
            loader={loader}
            setLoader={setLoader}
            fullscreen={fullscreen}
            setAssetItem={setAssetItem}
            assetTitle={assetTitle}
            assetId={assetId}
            assetLoader={assetLoader}
            handleDetailPage={handleDetailPage}
            handleWatchlist={handleWatchlist}
            handleShow={handleShow}
            closeModal={closeModal}
            openModal={openModal}
            categoryData={categoryData}
            setFilteredAssetDataFunc={setFilteredAssetDataFunc}
            setHighestStart={setHighestStart}
            setHighestFalseShow={setHighestFalseShow}
            highestStart={highestStart}
            highestFalseShow={highestFalseShow}
            makeBidId={makeBidId}
            setMakeBidId={setMakeBidId}
            category_title={category_title}
            viewMap={viewMap}
            pageLoader={pageLoader}
          />
        ) : (
          !viewGrid &&
          viewMap === false && (
            <CategoryPropertyListNew
              showSignIn={showSignIn}
              viewGrid={viewGrid}
              show={show}
              assetItem={assetItem}
              setShow={setShow}
              filteredAssetData={filteredAssetData}
              is_auto_bidding={is_auto_bidding}
              loader={loader}
              setLoader={setLoader}
              fullscreen={fullscreen}
              setAssetItem={setAssetItem}
              assetTitle={assetTitle}
              assetId={assetId}
              assetLoader={assetLoader}
              handleDetailPage={handleDetailPage}
              handleWatchlist={handleWatchlist}
              handleShow={handleShow}
              closeModal={closeModal}
              openModal={openModal}
              override={override}
              categoryData={categoryData}
              key={assetId}
              setHighestStart={setHighestStart}
              setHighestFalseShow={setHighestFalseShow}
              highestStart={highestStart}
              highestFalseShow={highestFalseShow}
              makeBidId={makeBidId}
              setMakeBidId={setMakeBidId}
              category_title={category_title}
              pageLoader={pageLoader}
              setFilteredAssetDataFunc={setFilteredAssetDataFunc}
            />
          )
        )}

            {/* checks if the api response is done otherwise shows clip loader*/}
        {viewMap ? isLoaded && !isLoading ? (
          <MapView
            showSignIn={showSignIn}
            setShowSignIn={setShowSignIn}
            show={show}
            assetItem={assetItem}
            setShow={setShow}
            filteredAssetData={filteredAssetData}
            is_auto_bidding={is_auto_bidding}
            loader={loader}
            setLoader={setLoader}
            fullscreen={fullscreen}
            setAssetItem={setAssetItem}
            assetTitle={assetTitle}
            assetId={assetId}
            assetLoader={assetLoader}
            handleDetailPage={handleDetailPage}
            handleWatchlist={handleWatchlist}
            handleShow={handleShow}
            closeModal={closeModal}
            openModal={openModal}
            categoryData={categoryData}
            setHighestStart={setHighestStart}
            setHighestFalseShow={setHighestFalseShow}
            highestStart={highestStart}
            highestFalseShow={highestFalseShow}
            makeBidId={makeBidId}
            setMakeBidId={setMakeBidId}
            setFilteredAssetDataFunc={setFilteredAssetDataFunc}
          />
        ) : (
          (
            <ClipLoader
              loading={isLoading}
              cssOverride={override}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )
        ): ""}
      </Container>
    </>
  );
};

export default AuctionBidding;
