import { useTranslation } from "react-i18next";
type Props = {
  description?:any;
};

const DirectSaleFullPurchase = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className="BhdData">
      <p className="CartText" style={{marginBottom:0}}> {props?.description}</p>
    </div>
  );
};

export default DirectSaleFullPurchase;
