type Props = {
  userDetails: { name: string; cost: string; classVal?: string }[];
};

const AllAccountDetails = (props: Props) => {
  const { userDetails } = props;
  return (
    <>
      {userDetails.map(({ name, cost, classVal }, ind) => {
        return cost != "wallet" ? (
          <div
            className="d-flex justify-content-between DetailsItem"
            key={`${name}-${ind}`}
          >
            {cost !== "" && <p className="CurrentAvailable">{name}</p>}

            <div className="d-flex">
              <p className={classVal}>{cost}</p>
            </div>
          </div>
        ) : (
          ""
        );
      })}
    </>
  );
};

export default AllAccountDetails;
