import { useContext, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modals.scss";
import DeviceSignInButtons from "../buttons/devicesigninbuttons";
import ConfirmOtpModals from "./confirmotpmodals";
import Countryfeild from "../inputfeilds/countryfeild";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/signup";
import { api } from "../../feature/envs/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LockImage from "../../assets/Icons/lock.svg";
import SignScreen from "../../assets/Icons/lock-signscreen.svg";
import AppleLogo from "../../assets/Icons/apple-logo.svg";
import FBLogo from "../../assets/Icons/facebook-logo.svg";
import ReCAPTCHA from "react-google-recaptcha";

import {
  getAuth,
  signInWithPopup,
  FacebookAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { auth } from "../../firebase.js";
import { encryptData } from "../../utils/functions";

type Props = {
  handleCloseModal: (modaltype: any) => void;
  showSignIn: boolean;
  handleOpenModal: (modaltype: any) => void;
  modalType?: any;
};

const SignInPhoneModal = (props: Props) => {
  const { t } = useTranslation();
  const [showOtp, setShowOtp] = useState(false);
  const { user, setUser } = useContext<any>(UserContext);
  const [loading, setLoading] = useState(false);
  const [phoneInvalid, setPhoneInvalid] = useState(true);
  const [state, setState] = useState("");
  const navigate = useNavigate();

  const openSignUpModal = () => {
    props.handleCloseModal("signin");
    props.handleOpenModal("signup");
  };

  const openSignInModal = () => {
    props.handleCloseModal("signup");
    props.handleOpenModal("signin");
  };
  const openOtpModal = (modaltype: any) => {
    props.handleCloseModal("signin");
    modaltype = "otpstop" ? setShowOtp(true) : setShowOtp(true);
  };
  const otpModalStop = (modaltype: any) => {
    modaltype = "otpstop" ? setShowOtp(false) : setShowOtp(false);
  };

  const facebookLogin = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then((result: any) => {
        handleSocialSignUpApi("FACEBOOK", result?.user?.email, null, null);
      })
      .catch((error: any) => {
        console.log("Facebook Signin error", error);
      });
  };

  const appleSignUp = () => {
    const provider = new OAuthProvider("apple.com");
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result: any) => {
        const phoneNumber = result?.user?.providerData[0]?.phoneNumber;
        const email_id = result?.user?.email;
        const uid = result?.user?.uid;
        handleSocialSignUpApi("APPLE", email_id, phoneNumber, uid);
      })
      .catch((error: any) => {
        console.log("Apple Signin error", error);
      });
  };

  const handleSocialSignUpApi = (
    type: any,
    email_id: any,
    phone_number: any,
    id: any
  ) => {
    setLoading(true);
    const obj: any = {
      type: type,
    };

    if (type === "APPLE") {
      obj.apple_uid = id;
      obj.phone_number = phone_number && phone_number;
      obj.email_id = email_id && email_id;
    } else {
      obj.email_id = email_id;
    }

    api
      .post(`api/v1/customer/login`, obj)
      .then((result) => {
        if (result?.data?.status !== 400) {
          const token = result?.data?.data?.token?.response?.token?.idToken;
          const refreshToken =
            result?.data?.data?.token?.response?.token?.refreshToken;
          localStorage.setItem("id-token", token);
          localStorage.setItem("refresh-token", refreshToken);
          encryptData("user-details", result?.data?.data?.user);

          setUser({
            ...user,
            userLoggedIn: true,
            userDetails: result?.data?.data?.user,
          });
          props.handleCloseModal("signup");

          if (
            result?.data?.data?.user?.identity_verification_status !== true &&
            result?.data?.data?.user?.doc_review_status !== "completed" &&
            result?.data?.data?.user?.full_name !== null
          ) {
            navigate("/verify-user");
          } else if (
            result?.data?.data?.user?.identity_verification_status !== true &&
            result?.data?.data?.user?.doc_review_status !== "completed" &&
            result?.data?.data?.user?.full_name === null
          ) {
            navigate("/registration");
          } else {
            navigate("/");
          }
          setLoading(false);
        } else {
          setLoading(false);
          navigate("/");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast(<p className="CartErrMsg">{t("cancel_payment")}</p>, {
          autoClose: 2500,
          hideProgressBar: true,
          toastId: "cartToast",
        });
      });
  };

  const handleSignUpApi = async (type: any) => {
    const token = await reCaptchaRef.current.executeAsync();
    if (
      (type === "PHONE_NUMBER" && user?.phoneNumber !== "") ||
      (type === "EMAIL" && user?.email !== "")
    ) {
      setLoading(true);
      const newObjMobile = {
        type: "PHONE_NUMBER",
        phone_number: user.phoneNumber,
        captcha_token: token,
      };
      const newObjEmail = {
        type: "EMAIL",
        email_id: user.email,
        captcha_token: token,
      };

      const obj = type === "EMAIL" ? newObjEmail : newObjMobile;
      api
        .post(`api/v1/customer/generate-otp`, obj)
        .then((result) => {
          setUser({ ...user, pinId: result.data.data.pinId });
          props.handleCloseModal("signin");
          openOtpModal("otpstop");
          setLoading(false);
          reCaptchaRef.current.reset();
        })
        .catch((error) => {
          setLoading(false);
          toast(<p className="CartErrMsg">{t("cancel_payment")}</p>, {
            autoClose: 2500,
            hideProgressBar: true,
            toastId: "cartToast",
          });
          reCaptchaRef.current.reset();
        });
    } else {
      setLoading(false);
      setState("Please enter valid mobile number!");
    }
  };
  const onHandleUser = () => {
    setUser({ type: "PHONE_NUMBER", email: "", phoneNumber: "" });
    props.handleCloseModal("signin");
  };

  const reCaptchaRef: any = useRef();
  const key: any = process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY;
  return (
    <>
      {props.showSignIn ? (
        <ReCAPTCHA
          sitekey={key}
          size="invisible"
          ref={reCaptchaRef}
          onExpired={() => console.log("reCAPTCHA expired")}
          onErrored={() => console.error("reCAPTCHA error")}
        />
      ) : null}

      <Modal show={props.showSignIn} onHide={onHandleUser}>
        <div className="modal-header">
          <h2 className="modal-title">{t(`${props?.modalType}`)}</h2>
          <button
            className="skip-btn"
            onClick={() => {
              props.handleCloseModal("signin");
            }}
          >
            {t("skip")}
          </button>
        </div>
        <div className="phn-email-buttons"></div>
        <form className="number-form" onSubmit={(e) => e.preventDefault()}>
          <Countryfeild
            state={state}
            setState={setState}
            setPhoneInvalid={setPhoneInvalid}
            setLoading={setLoading}
          />
          <button
            // Changed the disabled state from phoneInvalid to loading
            disabled={loading}
            className={`signin-phone sign-top ${
              phoneInvalid ? "disable_btn" : ""
            }`}
            onClick={() => {
              handleSignUpApi("PHONE_NUMBER");
            }}
          >
            {loading ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <>
                <img src={LockImage} alt="lock" />
                <>{t("signin_modal_button")}</>{" "}
              </>
            )}
          </button>
        </form>
        <div className="d-flex">
          <span className="signscreenlock">
            <img src={SignScreen} alt="sign-in" />
          </span>
          <p className="locktext">
            {t("terms_privacy_policy")}{" "}
            <a href="http://www.mazad.app/terms-of-service">
              {t("terms_conditions")}{" "}
            </a>
            {t("and")}{" "}
            <a href="http://www.mazad.app/privacy-policy">
              {t("lock_privacy_policy")}
            </a>
          </p>
        </div>
        <h2 className="hr-lines">{t("or")}</h2>
        <div className="device-btn">
          <DeviceSignInButtons
            imgUrl={AppleLogo}
            name={
              props?.modalType === "signin"
                ? t("sign_in_with_apple")
                : t("sign_up_with_apple")
            }
            onClick={appleSignUp}
          />
          <DeviceSignInButtons
            imgUrl={FBLogo}
            name={
              props?.modalType === "signin"
                ? t("sign_in_with_facebook")
                : t("sign_up_with_facebook")
            }
            onClick={facebookLogin}
          />
          {props?.modalType === "signin" ? (
            <p className="no-acount">
              {t("dont_ac_signup")}{" "}
              <span onClick={openSignUpModal}>{t("user_signup")}</span>
            </p>
          ) : (
            <p className="have-acount">
              {t("already_ac")}{" "}
              <span onClick={openSignInModal}>{t("user_signin")}</span>
            </p>
          )}
        </div>
      </Modal>

      {showOtp && (
        <ConfirmOtpModals
          setPhoneInvalid={setPhoneInvalid}
          setConfirmation={() => {
            otpModalStop("otpstop");
          }}
          showOtp={showOtp}
          handCloseOtpModal={otpModalStop}
        />
      )}
    </>
  );
};

export default SignInPhoneModal;
