import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import SignInPhoneModal from "../../components/modals/signinphonemodal";
import CommonBidModal from "../auctionbidding/commonbidmodal";
import MyBidsEmpty from "../mybids/mybidsempty";
import { ClipLoader } from "react-spinners";
import Stopwatch from "../stopwatch/stopwatch";
import {
  decryptData,
  isWithin5Seconds,
  numberFormat,
} from "../../utils/functions";
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { apiWithToken } from "../../feature/envs/api";
import { useNavigate } from "react-router-dom";
import { CartCount, UserContext } from "../../contexts/signup";
import "../auctionbidding/auctionbidding.scss";
import redWatchlistIcon from "../../assets/Icons/details-red.svg";
import "../../components/mybidscards/mybidscards.scss";
import CarPlate from "../../assets/Icons/Car Plate.svg";
import TaxiPlate from "../../assets/Icons/taxi_plate.svg";
import Hammer from "../../assets/Icons/hammer.svg";
import watchlistImage from "../../assets/Icons/EmptyStateImages/Bookmark.png";
import { toast } from "react-toastify";
import { connectSocket, disconnectSocket } from "../../utils/socket";
import { AssetTag } from "../../components/tags/AssetTag";
import { EventLogger, EventNames } from "../../utils/EventLogger";
let socket: any = null;
let assetsArr: any = [];
let asset_id: any = null;
type Props = {
  tab?: string;
  filteredAssetData?: any;
  setFilteredAssetData?: any;
  assetLoader?: any;
};

const CommonWatchlist = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fullscreen, setFullscreen] = useState<any>(true);
  const [show, setShow] = useState(false);
  const [assetItem, setAssetItem] = useState<any>();
  const [assetId, setAssetId] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [is_auto_bidding, setis_auto_bidding] = useState(false);
  const [assetTitle, setAssetTitle] = useState();
  const [showSignIn, setShowSignIn] = useState(false);
  const { user } = useContext<any>(UserContext);
  const addedToWatchlist: any = [];
  const [categoryData, setCategoryData] = useState<any>();
  const { setCartCount } = useContext<any>(CartCount);
  const [highestFalseShow, setHighestFalseShow] = useState(false);
  const [highestStart, setHighestStart] = useState(false);
  const [makeBidId, setMakeBidId] = useState(null);
  const size = "small"; // Dynamic property name
  const { filteredAssetData, setFilteredAssetData, assetLoader } = props;
  assetsArr = filteredAssetData;

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    assetsArr = !assetsArr && [];
    socket = connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    addSocketListeners();
    return () => {
      socket.off("make-bid", emitMakeBid);
      socket.off("asset-bid-details", getAssetBidDetail);
      socket.off("turn-off-auto-bid", turnOffAutoBid);
      socket.off("add-to-watchlist", addWatchlist);
      socket.off("remove-from-watchlist", removeWatchlist);
      socket.off("watchlist-details", watchListDetails);
      socket.off("add-to-cart", addRemoveCartSocketCall);
      socket.off("remove-from-cart", addRemoveCartSocketCall);
      socket.off("my-cart", addRemoveCartSocketCall);
      socket.off("my-cart-details", getMyCartDetails);
    };
  }, [socket]);

  const addSocketListeners = () => {
    if (socket !== null) {
      socket.on("make-bid", emitMakeBid);
      socket.on("asset-bid-details", getAssetBidDetail);
      socket.on("turn-off-auto-bid", turnOffAutoBid);
      socket.on("add-to-watchlist", addWatchlist);
      socket.on("remove-from-watchlist", removeWatchlist);
      socket.on("watchlist-details", watchListDetails);
      socket.on("add-to-cart", addRemoveCartSocketCall);
      socket.on("remove-from-cart", addRemoveCartSocketCall);
      socket.on("my-cart", addRemoveCartSocketCall);
      socket.on("my-cart-details", getMyCartDetails);
    }
  };

  const getMyCartDetails = useCallback((res: any) => {
    let count: any = null;
    if (res?.data?.cart && res?.data?.cart?.items?.length) {
      count = res?.data?.cart?.items?.length;
    } else {
      count = null;
    }
    setCartCount(count);
  }, []);

  const addRemoveCartSocketCall = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("my-cart", {
        authorization: authToken,
      });
    }
  }, []);

  const emitMakeBid = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("get-bid-details", {
        asset_id: res.data.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const addWatchlist = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("my-watchlist", {
        authorization: authToken,
      });
    }
  }, []);

  const removeWatchlist = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("my-watchlist", {
        authorization: authToken,
      });
    }
  }, []);

  const watchListDetails = useCallback((res: any) => {
    setFilteredAssetData(res?.data?.auctions);
  }, []);

  const getAssetBidDetail = useCallback((res: any) => {
    const data = res.data;
    const arr = assetsArr.map((item: any) => {
      if (item.asset_id === data.asset_id) {
        return {
          ...item,
          end_time: data?.end_time,
          highest_price: data?.highest_price,
          is_added_to_watchlist: data?.is_added_to_watchlist,
          is_auto_bidding: data?.is_auto_bidding,
          is_highest_bidder: data?.is_highest_bidder,
          total_bids: data?.total_bids,
          latest_bid_time: data?.latest_bid_time,
        };
      } else {
        return item;
      }
    });

    assetsArr = arr;
    if (+res?.data?.asset_id === +asset_id || asset_id === null) {
      setFilteredAssetData([...assetsArr]);
      bidDetailApiResponse(res);
    }
  }, []);

  const turnOffAutoBid = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (
      +res?.data?.asset_id === +asset_id &&
      authToken !== null &&
      authToken !== undefined
    ) {
      socket.emit("get-bid-details", {
        asset_id: res?.data?.asset_id,
        authorization: authToken,
      });
    }
  }, []);

  const bidDetailApiResponse = (res: any) => {
    setAssetItem(res.data);
    setis_auto_bidding(res.data.is_auto_bidding);
  };

  const openModal = (modaltype: any) => {
    setShowSignIn(true);
  };
  const closeModal = (modaltype: any) => {
    setShowSignIn(false);
  };

  function getBidDetails() {
    apiWithToken
      .get(`api/v1/bids/assets/${assetId}/get-bid-details`)
      .then((res: any) => {
        if (res.data.status === 200) {
          bidDetailApiResponse(res.data);
          setLoader(false);
        }
      });
  }

  useEffect(() => {
    if (show) {
      setLoader(true);
      getBidDetails();
    } else {
      setAssetItem("");
    }
  }, [show]);

  useEffect(() => {}, [assetItem]);

  function handleShow(breakpoint: any, asset: any, event: any, index: number) {
    event.stopPropagation();
    if (!user.userLoggedIn) {
      setShowSignIn(true);
    } else {
      const userDetails = decryptData("user-details");

      if (
        userDetails?.identity_verification_status !== true &&
        userDetails?.doc_review_status !== "completed" &&
        userDetails?.full_name === null
      ) {
        navigate("/registration");
      } else if (
        userDetails?.identity_verification_status !== true &&
        userDetails?.doc_review_status !== "completed" &&
        userDetails?.full_name !== null
      ) {
        navigate("/verify-user");
      } else {
        setAssetId(asset.id ? asset.id : asset.asset_id);
        asset_id = asset.id ? asset.id : +asset.asset_id;
        setAssetItem(filteredAssetData[index]);
        setFullscreen(breakpoint);
        setShow(true);
        setCategoryData(asset);
        setAssetTitle(asset.asset_title);
      }
    }
  }

  const handleDetailPage = (asset: any, index: number) => {
    const id = asset.asset_id || asset.asset_id;
    navigate(`/a/${id}`, {
      state: {
        data: asset,
        time: asset.end_time,
        assestData: filteredAssetData,
        category: assetItem,
        index: index,
        title: asset.asset_title,
        categoryId: id,
      },
    });
  };
  const handleWatchlist = (event: any, index: any, asset: any) => {
    event.stopPropagation();
    apiWithToken
      .put(`/api/v1/watchlist/remove-from-watchlist`, {
        asset_id: asset.asset_id,
      })
      .then((res: any) => {
        if (res.data.status === 200) {
          EventLogger.logEvent(EventNames.REMOVE_FROM_WATCHLIST, { asset_id: asset.asset_id });
          toast(
            <p className="CartErrMsgWatch">{t("removed_from_watchlist")}</p>,
            {
              autoClose: 2500,
              hideProgressBar: true,
              toastId: "cartToast",
            }
          );
        }
      });
  };

  const override: CSSProperties = {
    display: "block",
    margin: "20% auto",
    borderColor: "black",
    overflow: "-moz-hidden-unscrollable",
    opacity: 0.1,
  };

  function setFilteredAssetDataFunc(asset_id?: any) {
    if (asset_id) {
      const arra = filteredAssetData.filter(
        (asset: any) => asset.asset_id !== asset_id
      );
      setFilteredAssetData(arra);
    }
  }
  const [shouldBlink, setShouldBlink] = useState(false);
  const callAnimationFunc = () => {
    setTimeout(() => {
      setShouldBlink(false);
    }, 1500);
    setShouldBlink(true);
  };
  return (
    <>
      <Row>
        {filteredAssetData?.length > 0 ? (
          <>
            {filteredAssetData?.map((asset: any, index: number) => {
              const ImagePlate =
                asset?.category_title.split(" ")[0] === "Taxi"
                  ? TaxiPlate
                  : CarPlate;

              const dummyImg_1_new = (
                <div className="taxi_parent_container">
                  <div className="parent_taxi">
                    <div className="box_img_new1">
                      <h1 className="font_text">{asset?.asset_title}</h1>
                    </div>
                    <img src={ImagePlate} alt={asset?.asset_title} />
                  </div>
                </div>
              );
              const dummyImg_2_new =
                asset?.asset_images && asset?.asset_images.length > 0 ? (
                  <img
                    src={asset?.asset_images[0]?.url[size]}
                    alt={asset?.asset_title}
                    className="w-100 cartImage"
                  />
                ) : (
                  <div className="w-100 cartImage customBg"></div>
                );

              const boxImg =
                asset?.category_title.split(" ")[0] === "Taxi" ||
                asset?.category_title.split(" ")[0] === "Car"
                  ? dummyImg_1_new
                  : dummyImg_2_new;
              const assetTitle =
                asset?.category_title.split(" ")[0] === "Taxi" ||
                asset?.category_title.split(" ")[0] === "Car"
                  ? ""
                  : t("en") === "en"
                  ? asset?.asset_title
                  : asset?.asset_title_ar;

              let shouldBlinkClass = " ";

              if (isWithin5Seconds(asset?.latest_bid_time)) {
                shouldBlinkClass = "bidcardsection blink-animation";
                if (!shouldBlink) {
                  callAnimationFunc();
                }
              } else {
                shouldBlinkClass = "bidcardsection";
              }

              return (
                <Col key={asset.asset_id} lg={2} md={3} sm={4} xs={6}>
                  <div className={shouldBlinkClass}>
                    <div className="ongoingcard">
                      <div
                        className="imgessectionicons"
                        onClick={() => handleDetailPage(asset, index)}
                      >
                        {asset.tag && (
                          <AssetTag
                            tag={asset.tag}
                            discountPercentage={asset.discount_percentage}
                          />
                        )}
                        <div className="container_font custom_image">
                          {boxImg}
                        </div>
                        <div className="d-flex justify-content-between iconssection ArabicBottomColors">
                          <div
                            className="bidicons"
                            onClick={(event: any) =>
                              handleWatchlist(event, index, asset)
                            }
                          >
                            <img
                              src={
                                !addedToWatchlist.includes(index)
                                  ? redWatchlistIcon
                                  : "./assets/Icons/details.svg"
                              }
                              alt="bids"
                            />
                          </div>
                          <div
                            className="bidicons"
                            onClick={(event: any) =>
                              handleShow("sm-down", asset, event, index)
                            }
                          >
                            <img src={Hammer} alt="bids" />
                          </div>
                        </div>
                      </div>
                      <h2 className="cardbidtitle">
                        <span className="threedots"> {assetTitle} </span>
                      </h2>
                      <h2 className="cardbidsecondtitle">
                        {t("en") === "en"
                          ? t("bhd") + numberFormat(asset?.highest_price)
                          : numberFormat(asset?.highest_price) + t("bhd")}
                      </h2>
                    </div>

                    <div
                      className={
                        makeBidId === asset?.asset_id && highestStart
                          ? "d-flex justify-content-between align-items-center CategoryCardBottomBlue"
                          : asset?.is_highest_bidder === true
                          ? "d-flex justify-content-between bidcardbottomblue"
                          : asset?.is_highest_bidder === false
                          ? "d-flex justify-content-between bidcardbottompink"
                          : "d-flex justify-content-between bidcardbottomsale"
                      }
                    >
                      <div className="d-flex align-items-center ArabicBottomColors">
                        <img src={Hammer} alt="bidcount" />
                        <span className="bidcount">
                          {asset.number_of_bids || asset.total_bids}{" "}
                        </span>
                      </div>
                      <Stopwatch
                        compName={"assetList"}
                        endTime={asset?.end_time}
                        setFilteredAssetDataFunc={setFilteredAssetDataFunc}
                        asset_id={asset?.asset_id}
                      />
                    </div>
                  </div>
                </Col>
              );
            })}
          </>
        ) : (
          <>
            {assetLoader && (
              <ClipLoader
                color={"#fff"}
                loading={assetLoader}
                cssOverride={override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
            {!assetLoader && (
              <MyBidsEmpty
                imgUrl={watchlistImage}
                title={"bids"}
                className={"auction-icon"}
              />
            )}
          </>
        )}
      </Row>
      <SignInPhoneModal
        handleCloseModal={closeModal}
        showSignIn={showSignIn}
        handleOpenModal={openModal}
        modalType={t("header_signin")}
      />

      {show && (
        <CommonBidModal
          filterData={filteredAssetData}
          fullscreen={fullscreen}
          setShow={setShow}
          show={show}
          assetItem={assetItem}
          setAssetItem={setAssetItem}
          loader={loader}
          assetId={assetId}
          buttonSlider="show"
          assetTitle={assetTitle}
          is_auto_bidding={is_auto_bidding}
          categoryData={categoryData}
          setHighestStart={setHighestStart}
          setHighestFalseShow={setHighestFalseShow}
          highestStart={highestStart}
          highestFalseShow={highestFalseShow}
          makeBidId={makeBidId}
          setMakeBidId={setMakeBidId}
        />
      )}
    </>
  );
};

export default CommonWatchlist;
