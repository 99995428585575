import GreenCheckmark from "../../assets/Icons/green-checkmark.svg";
import GreyCheckmark from "../../assets/Icons/EllipseIcon.svg";

// popup-checkmark
import { useTranslation } from "react-i18next";
type Props = {
  selectedAsset?: any;
  toggle?: any;
};

const RegistrationDocuments = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className="RegistrationDocuments">
      <h2 className="RegistrationDocumentHeading">{t("required_document")}</h2>
      <p className="RegistrationDocumentText">
        {t("submit_the_following_documents")}
      </p>
      <div className="d-flex justify-content-between">
        <div>
          <div className="d-flex UserDocuments">
            <img
              src={
                props?.selectedAsset?.transaction
                  ? GreenCheckmark
                  : GreyCheckmark
              }
              alt="greencheckmark"
              className="me-2"
            />
            <p className="AllDocuments">{t("remaining_payment_on_asset")}</p>
          </div>
          <div className="d-flex UserDocuments">
            <img
              src={
                props?.toggle || props?.selectedAsset?.user_request
                  ? GreenCheckmark
                  : GreyCheckmark
              }
              alt="greencheckmark"
              className="me-2"
            />
            <p className="AllDocuments">{t("personal_id_passport")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationDocuments;
