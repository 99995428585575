import "./AssetTag.scss";
import { useTranslation } from "react-i18next";

interface Props {
  className?: any;
  divClassName?: any;
  tag: string;
  discountPercentage?: string;
}

export const AssetTag = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className={`shape-half-pill ${props?.className ?? ""}`}>
      <div className={`element-OFF ${props?.divClassName ?? ""}`}>
        {props?.tag === "DISCOUNT"
          ? props?.discountPercentage + `% ${t(props.tag)}`
          : t(props?.tag)}
      </div>
    </div>
  );
};
