import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import BreadCrums from "../../components/breadcrumbs/breadcrums";
import CrossButton from "../../components/buttons/crossbutton";
import RightSideSection from "./rightsidesection";
import "./internalrouting.scss";
import LeftArrow from "../../assets/Icons/left-arrow.svg";
import { useTranslation } from "react-i18next";
const activeStates: any = {
  "/profile": 1,
  "/profile/notification": 2,
  "/profile/setting": 3,
  "/profile/deposits": 4,
  "/profile/refund": 5,
  "/profile/transactions": 6,
  "/profile/purchases": 7,
};

type Props = {};

const InternalRouting = (props: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [showPageDiection, setPageDirection] = useState(
    activeStates[pathname] <= 3
  );
  const hideBrudCrums = () => {
    setPageDirection(false);
  };
  const showBrudCrums = () => {
    setPageDirection(true);
  };

  return (
    <Container>
      <Row className={`${showPageDiection ? "d-none" : ""}`}>
        <Col>
          <div className="d-flex align-items-center profilereg biddingswoncard">
            <CrossButton className="prevarrow" imgUrl={LeftArrow} />
            <BreadCrums
              previousPage={t("profile")}
              currentPage={
                (activeStates[pathname] === 1 ? t("my_profile") : null) ||
                (activeStates[pathname] === 2 ? t("my_notification") : null) ||
                (activeStates[pathname] === 3 ? t("my_settings") : null) ||
                (activeStates[pathname] === 4 ? t("my_deposit") : null) ||
                (activeStates[pathname] === 5 ? t("refund") : null) ||
                (activeStates[pathname] === 6 ? t("my_transaction") : null) ||
                (activeStates[pathname] === 7 ? t("my_purchases") : null)
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <div className={`${showPageDiection ? "" : "d-none"}`}>
            <div className="d-flex align-items-center profilereg biddingswoncard">
              <CrossButton className="prevarrow" imgUrl={LeftArrow} />
              <BreadCrums
                previousPage={t("profile")}
                currentPage={
                  (activeStates[pathname] === 1 ? t("my_profile") : null) ||
                  (activeStates[pathname] === 2 ? t("my_notification"): null) ||
                  (activeStates[pathname] === 3 ? t("my_settings") : null) ||
                  (activeStates[pathname] === 4 ? t("my_deposit") : null) ||
                  (activeStates[pathname] === 5 ? t("refund") : null) ||
                  (activeStates[pathname] === 6 ? t("my_transaction") : null) ||
                  (activeStates[pathname] === 7 ? t("my_purchases") : null)
                }
              />
            </div>
          </div>
          <ul className="ProfileDashboard">
            <Link to="/profile">
              <li
                onClick={showBrudCrums}
                className={activeStates[pathname] === 1 ? "BgColor" : ""}
              >
                {t("my_details")}
              </li>
            </Link>
            {/* <Link to="/profiledetails/notification">
              <li
                onClick={showBrudCrums}
                className={activeStates[pathname] === 2 ? "BgColor" : ""}
              >
                {t("notifications")}
              </li>
            </Link> */}
            {/* <Link to="/profiledetails/setting">
              <li
                onClick={showBrudCrums}
                className={activeStates[pathname] === 3 ? "BgColor" : ""}
              >
                {t("settings_and_privacy")}
              </li>
            </Link> */}
            <Link to="/profile/deposits">
              <li onClick={hideBrudCrums} className="SubMenu">
                {t("deposit_and_limit")}
              </li>
            </Link>
            <ul
              className={`SubProfileDashboard ${
                showPageDiection ? "d-none" : ""
              }`}
            >
              <Link to="/profile/deposits">
                <li
                  onClick={hideBrudCrums}
                  className={activeStates[pathname] === 4 ? "BgColor" : ""}
                >
                  {t("my_deposit_bidding_limit")}
                </li>
              </Link>

              <Link to="/profile/refund">
                <li
                  onClick={hideBrudCrums}
                  className={activeStates[pathname] === 5 ? "BgColor" : ""}
                >
                  {t("refund_deposit")}
                </li>
              </Link>
              <Link to="/profile/transactions">
                <li
                  onClick={hideBrudCrums}
                  className={activeStates[pathname] === 6 ? "BgColor" : ""}
                >
                  {t("transaction_history")}
                </li>
              </Link>
            </ul>

            <Link to="/profile/purchases">
              <li
                onClick={hideBrudCrums}
                className={activeStates[pathname] === 7 ? "BgColor" : ""}
              >
                {t("my_purchases")}
              </li>
            </Link>
          </ul>
        </Col>
        <RightSideSection />
      </Row>
    </Container>
  );
};

export default InternalRouting;
