import { useEffect, useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import UploadPhoto from "../../components/buttons/uploadphoto";
import VerifyNumberButton from "../../components/buttons/verifynumberbutton";
import FloatLabelNumberEdit from "../../components/inputfeilds/floatlabelnumberedit";
import FloatLabels from "../../components/inputfeilds/floatlabels";
import { useTranslation } from "react-i18next";
import { api, apiWithToken } from "../../feature/envs/api";
import { UserContext } from "../../contexts/signup";
import { toast } from "react-toastify";
import ProfileImage from "../../assets/Icons/livestream-avtar.svg";
import ConfirmUserUpdateOtpModal from "../../components/modals/updateuserotpmodal";
import UpdateNumberModal from "../../components/modals/updatenumbermodal";
import UpdateEmailModal from "../../components/modals/updateemailmodal";
import { decryptData } from "../../utils/functions";
type Props = {};

const errorCodes = [
  {
    field: "full_name",
    pattern: /^[a-z ,.'-]+$/i,
    errorText: "Please enter valid name",
  },
  {
    field: "phone_number",
    pattern: /\d$/,
    errorText: "Please enter valid mobile number",
  },
  {
    field: "email_id",
    pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    errorText: "Please enter valid email",
  },
];
const FirstRouting = (props: Props) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<any>({});
  const [country, setCountry] = useState<any>("");
  const [userdata, setUserdata] = useState<any>({});
  const { user, setUser } = useContext<any>(UserContext);
  const [loading, showLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [flag, setFlag] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  const defaultFormData: any = {
    full_name: userdata.full_name,
    company_name: userdata.company_name || "",
    company_regNo: userdata.company_regNo || "",
    profile_pic: userdata.profile_pic,
  };
  useEffect(() => {
    updateData(userdata);
  }, [userdata]);
  const [userFormData, updateFormData] = useState({
    ...defaultFormData,
    errorFields: {},
  });
  const [file, setFile] = useState<any>();
  const { errorFields } = userFormData;

  const updateData = (obj: any) => {
    updateFormData((prev: any) => ({ ...prev, ...obj }));
  };

  const openOtpModal = (modaltype: any) => {
    modaltype = "otpstop" ? setShowOtp(true) : setShowOtp(true);
  };
  const otpModalStop = (modaltype: any) => {
    modaltype = "otpstop" ? setShowOtp(false) : setShowOtp(false);
  };
  const handleChange = (e: any) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    updateData({ profile_pic: e.target.files[0] });
    setShow(true);
  };

  const UpdateUserDetails = () => {
    showLoading(true);
    let havingErrors = false;
    let codes: any = {};
    errorCodes.forEach(({ pattern, field, errorText }) => {
      if (!pattern.test(userFormData[field])) {
        codes[field] = errorText;
        havingErrors = true;
      }
    });
    if (havingErrors) {
      updateData({ errorFields: codes });
      showLoading(false);
    } else {
      const data: any = new FormData();

      // Find the key-value pairs with different values
      const differentPairs: any = {};

      for (const key in userFormData) {
        if (
          userFormData.hasOwnProperty(key) &&
          userFormData[key] !== userdata[key]
        ) {
          if (key != "errorFields") {
            data.append(key, userFormData[key]);
          }
        }
      }
      apiWithToken
        .put(`api/v1/customer/my-details`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res: any) => {
          if (res.data.status === 200) {
            getUserDetail();
            setFlag(true);
            toast(
              <p className="CartSuccessMsg">{t("profile_updated")}</p>,
              {
                autoClose: 2500,
                hideProgressBar: true,
                toastId: "cartToast",
              }
            );
            showLoading(false);
            otpModalStop("otpstop");
            setShow(false);
          } else {
            showLoading(false);
            toast(<p className="CartErrMsg">{res.data.message}</p>, {
              autoClose: 2500,
              hideProgressBar: true,
              toastId: "cartToast",
            });
          }
        });
    }
  };

  function getUserDetail() {
    apiWithToken.get(`api/v1/customer/my-details`).then((res: any) => {
      if (res.data.status === 200) {
        let userdata = res.data.data;
        setUserdata(userdata);
      }
    });
  }

  useEffect(() => {
    getUserDetail();
    const data = decryptData("user-details");
    setItems(data);
    const country1 = localStorage.getItem("countryName");
    setCountry(country1);
  }, [flag]);
  const hadleDetails = () => {
    UpdateUserDetails();
  };
  return (
    <div className="UserProfileLeft">
      <div className="savebtn">
        <button
          disabled={!show}
          className={`${!show ? "verifynumber" : "updateuserbutton"}`}
          onClick={hadleDetails}
        >
          {loading ? (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">{t("loading")}</span>
            </div>
          ) : (
            t("save")
          )}
        </button>
      </div>

      <div className="userprofilepicture">
        <h2 className="profileheading">{t("profile_picture")}</h2>
        <div className="d-flex justify-content-between">
          <div className="profileimage">
            {!file ? (
              userdata?.profile_pic ? (
                <img src={userdata?.profile_pic} alt="profile" />
              ) : (
                <img src={ProfileImage} alt="profile" />
              )
            ) : (
              <img className="profile_img" src={file} alt="profile" />
            )}{" "}
          </div>
          <div className="ali gn-self-end">
            <button className="uploadphotobutton">
              <label>
                <input
                  type="file"
                  onChange={handleChange}
                  accept="image/png, image/jpg, image/jpeg"
                  hidden
                  id="photo"
                />
                <span>
                  <img src="./assets/Icons/upload-photo.svg" alt="upload" />
                </span>
                {t("upload_photo")}
              </label>
            </button>
          </div>
        </div>
      </div>
      <div className="userprofilepicture">
        <h2 className="profileheading">{t("personal_information")}</h2>
        <form>
          <Row>
            <Col sm={6}>
              <FloatLabels
                name={t("name_no_star")}
                inputProps={{ required: true }}
                errorProps={{
                  hasError: errorFields["full_name"],
                  errorText: errorFields["full_name"],
                }}
                value={userFormData.full_name}
                onChange={(e: any) => {
                  updateData({ full_name: e.target.value });
                  setShow(true);
                }}
              />
              <FloatLabelNumberEdit
                floatLabel={t("email_id")}
                value={userFormData.email_id}
                onChange={(e: any) => {
                  updateData({ email_id: e.target.value });
                  setShow(true);
                }}
                disable={true}
                numberAvailable
                edit=""
                editbutton
                handleModal={() => setShowEmailModal(true)}
              />
              <FloatLabelNumberEdit
                floatLabel={t("mobile_number_no_star")}
                value={userFormData.phone_number}
                onChange={(e: any) => {
                  updateData({ phone_number: e.target.value });
                  setShow(true);
                }}
                numberAvailable
                handleModal={() => setShowModal(true)}
                disable={true}
                edit=""
                editbutton
              />
            </Col>
            <Col sm={6}>
              <FloatLabels
                name={t("company_name")}
                value={userFormData.company_name}
                onChange={(e: any) => {
                  updateData({ company_name: e.target.value });
                  setShow(true);
                }}
              />
              <FloatLabels
                name={t("company_reg")}
                value={userFormData.company_regNo}
                onChange={(e: any) => {
                  updateData({ company_regNo: e.target.value });
                  setShow(true);
                }}
              />
            </Col>
          </Row>
        </form>
      </div>

      <div className="userprofilepicture">
        <h2 className="profileheading">{t("personal_identification")}</h2>
        <form>
          <Row>
            <Col sm={6}>
              <FloatLabelNumberEdit
                floatLabel={t("name_no_star")}
                value={userdata?.identified_name}
                disable={true}
                edit=""
              />
              <FloatLabelNumberEdit
                floatLabel={t("nationality")}
                value={userdata?.country}
                edit=""
                disable={true}
              />
              <FloatLabelNumberEdit
                floatLabel={t("date_of_birth")}
                value={userdata?.date_of_birth}
                edit=""
                disable={true}
              />
            </Col>
            <Col sm={6}>
              <FloatLabelNumberEdit
                floatLabel={t("personal_id_number")}
                value={userdata?.identity_card_number}
                edit=""
                disable={true}
              />
              <FloatLabelNumberEdit
                floatLabel={t("gender")}
                value={userdata?.gender}
                edit=""
                disable={true}
              />
              {/* <FloatLabelNumberEdit
                floatLabel={t("id_expiration")}
                value={userdata?.id_expiration_time}
                edit=""
                disable={true}
              /> */}
              {showOtp && (
                <ConfirmUserUpdateOtpModal
                  setConfirmation={() => {
                    otpModalStop("otpstop");
                  }}
                  userData={userFormData}
                  showOtp={showOtp}
                  handCloseOtpModal={otpModalStop}
                  UpdateUserDetails={UpdateUserDetails}
                  getUserDetail={getUserDetail}
                />
              )}
              <UpdateNumberModal
                userdata={userFormData}
                showSignUp={showModal}
                handleCloseModal={() => setShowModal(false)}
                handleOpenModal={() => setShowModal(true)}
                getUserDetail={getUserDetail}
              />

              <UpdateEmailModal
                userdata={userFormData}
                showSignUp={showEmailModal}
                handleCloseModal={() => setShowEmailModal(false)}
                handleOpenModal={() => setShowEmailModal(true)}
                getUserDetail={getUserDetail}
              />
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
};

export default FirstRouting;
