import { Col, Container, Row } from "react-bootstrap";
import BidsWonCardBahrain from "../../components/bidswoncards/bidswoncardbahrain";
import BidsWonCardBottom from "../../components/bidswoncards/bidswoncardbottom";
import Delete from "../../assets/Icons/directsales-delete.svg";
import CrossButton from "../../components/buttons/crossbutton";
import LeftArrow from "../../assets/Icons/left-arrow.svg";
import BreadCrums from "../../components/breadcrumbs/breadcrums";
import { useTranslation } from "react-i18next";
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { apiWithToken } from "../../feature/envs/api";
import { ClipLoader } from "react-spinners";
import MyBidsEmpty from "../mybids/mybidsempty";
import RegistrationPaymentMethods from "../ordercompleteregistration/registrationpaymentmethods";
import { toast } from "react-toastify";
import { CartCount } from "../../contexts/signup";
import { addTimeToCart, timeAgo } from "../../utils/functions";
import { connectSocket, disconnectSocket } from "../../utils/socket";
import { trackPageView } from "../../utils/analytics";
import { EventLogger, EventNames } from "../../utils/EventLogger";
import CartImage from "../../assets/Icons/EmptyStateImages/Cart.png";

let socket: any = null;
let assetsArr: any = [];
type Props = {};
const SingleItem = ({
  asset,
  handleDetail,
  handleDelete,
  setFilteredAssetDataFunc,
  handleDeleteManual,
}: any) => {
  let pauset_at = asset?.paused_at && asset?.paused_at;
  let newTimer = addTimeToCart(pauset_at);
  const [classState, setClassState] = useState("");
  const { t } = useTranslation();
  const timerVal = timeAgo(newTimer || asset?.reserved_at);

  useEffect(() => {
    if (timerVal >= -1) {
      handleDeleteManual(asset?.asset_id);
    }
  }, [timerVal, asset?.asset_id]);
  return (
    <div className={classState} onClick={(event) => handleDetail(event, asset)}>
      <BidsWonCardBahrain
        asset={asset}
        imgUrl={Delete}
        type="delete"
        handleDelete={handleDelete}
      />

      <BidsWonCardBottom
        highest_price={asset?.asset_price}
        end_time={newTimer || asset?.reserved_at}
        className={asset?.status}
        name={t(asset?.status)}
        type={"cartcheckout"}
        setFilteredAssetDataFunc={setFilteredAssetDataFunc}
        handleDeleteManual={handleDeleteManual}
        asset_id={asset?.asset_id}
        classState={classState}
        setClassState={setClassState}
      />
    </div>
  );
};

const DirectSaleCheckout = (props: Props) => {
  const { t } = useTranslation();
  const [assetLoader, setAssetLoader] = useState(false);
  const [filteredAssetData, setFilteredAssetData] = useState([]);
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<any>([]);
  const { setCartCount } = useContext<any>(CartCount);

  useEffect(() => {
    trackPageView((window.location.pathname + window.location.search), "Checkout");
    window.scrollTo(0, 0); // Scroll to the top of the page
    assetsArr = [];
    socket = connectSocket();
    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    addSocketListeners();
    return () => {
      socket.off("remove-from-cart", addRemoveCartSocketCall);
      socket.off("add-to-cart", addRemoveCartSocketCall);
      socket.off("my-cart", addRemoveCartSocketCall);
      socket.off("my-cart-details", getMyCartDetails);
    };
  }, [socket]);

  const addSocketListeners = () => {
    if (socket !== null) {
      socket.on("remove-from-cart", addRemoveCartSocketCall);
      socket.on("add-to-cart", addRemoveCartSocketCall);
      socket.on("my-cart", addRemoveCartSocketCall);
      socket.on("my-cart-details", getMyCartDetails);
    }
  };

  const addRemoveCartSocketCall = useCallback((res: any) => {
    const authToken = localStorage.getItem("id-token");
    if (res.data?.asset_id && authToken !== null && authToken !== undefined) {
      socket.emit("my-cart", {
        authorization: authToken,
      });
    }
    // getApi();
  }, []);

  const getMyCartDetails = useCallback((res: any) => {
    setFilteredAssetData(res?.data?.cart?.items);
    assetsArr = res?.data?.cart?.items;
    setSelectedAsset(res?.data?.cart);
    let count: any = null;
    if (res?.data?.cart && res?.data?.cart?.items?.length) {
      count = res?.data?.cart?.items?.length;
    } else {
      count = null;
    }
    setCartCount(count);
  }, []);

  const getApi = () => {
    apiWithToken.get(`/api/v2/cart/my-cart`).then((res: any) => {
      if (res.data.status === 200) {
        setFilteredAssetData(res?.data?.data?.cart?.items);
        assetsArr = res?.data?.data?.cart?.items;

        setSelectedAsset(res?.data?.data?.cart);
        if (res.data.message === "Cart is empty") {
          setCartCount(null);
        } else {
          let count: any = null;
          if (res?.data?.data?.cart && res?.data?.data?.cart?.items?.length) {
            count = res?.data?.data?.cart?.items?.length;
          } else {
            count = null;
          }
          setCartCount(count);
        }
        setAssetLoader(false);
      } else {
        setAssetLoader(false);
      }
    });
  };
  useEffect(() => {
    setAssetLoader(true);
    getApi();
  }, []);

  const handleDelete = (asset: any) => {
    apiWithToken
      .put(`/api/v2/cart/remove-from-cart`, { asset_id: asset?.asset_id })
      .then((res: any) => {
        if (res.data.status === 200) {
          EventLogger.logEvent(EventNames.REMOVE_FROM_CART, {asset_id: asset?.asset_id});
          toast(<p className="CartErrMsg">{res.data.message}</p>, {
            autoClose: 2500,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        } else {
          toast(<p className="CartErrMsg">{res.data.message}</p>, {
            autoClose: 2500,
            hideProgressBar: true,
            toastId: "cartToast",
          });
        }
      });
  };

  const handleDeleteManual = (asset_id: any) => {
    apiWithToken
      .put(`/api/v2/cart/remove-from-cart`, { asset_id: asset_id })
      .then((res: any) => { 
        if (res?.data?.status === 200) {
          EventLogger.logEvent(EventNames.REMOVE_FROM_CART, {asset_id: asset_id});
        }
      });
  };

  const override: CSSProperties = {
    display: "block",
    margin: "20% auto",
    borderColor: "black",
    overflow: "-moz-hidden-unscrollable",
    opacity: 0.1,
  };

  const handleDetail = (event: any, data: any) => {
    navigate(
      `/d/${data.asset_id}`,
      {
        state: {
          assetItem: data,
          type: "DirectSaleDetail",
        },
      }
    );
  };
  function setFilteredAssetDataFunc() {
    getApi();
  }

  return (
    <>
      <Container>
        {assetLoader && (
          <ClipLoader
            color={"#fff"}
            loading={assetLoader}
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
        <Row>
          <div className="d-flex justify-content-between profilereg biddingswoncard">
            <div className="d-flex align-items-center">
              <CrossButton className="prevarrow" imgUrl={LeftArrow} />
              {!assetLoader && <BreadCrums previousPage={t("cart")} />}
            </div>
          </div>
        </Row>
      </Container>
      <Container>
        <Row>
          {filteredAssetData?.length > 0 ? (
            <>
              <Col lg={4} md={6}>
                {filteredAssetData.map((asset: any, index: number) => {
                  const itemProps = {
                    asset,
                    handleDetail,
                    handleDelete,
                    setFilteredAssetDataFunc,
                    handleDeleteManual,
                  };
                  return <SingleItem key={index} {...itemProps} />;
                })}
              </Col>
            </>
          ) : (
            <>
              {assetLoader && (
                <ClipLoader
                  color={"#fff"}
                  loading={assetLoader}
                  cssOverride={override}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
              {!assetLoader && (
                <MyBidsEmpty
                  imgUrl={CartImage}
                  title={"Cart"}
                />
              )}
            </>
          )}

          <Col lg={2} className="DirectSalesLeft"></Col>
          <Col lg={5} className="DirectSalesLeft">
            {filteredAssetData?.length > 0 && (
              <RegistrationPaymentMethods
                selectedType={"directsalecheckout"}
                selectedAsset={selectedAsset}
                toggle={toggle}
                setToggle={setToggle}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DirectSaleCheckout;