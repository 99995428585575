import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import HeaderFilter from "../../assets/Icons/header-inactive-filter.svg";
import HeaderGrid from "../../assets/Icons/header-grid-view.svg";
import HeaderLocation from "../../assets/Icons/header-inactive-map.svg";
import ActiveLocation from "../../assets/Icons/header-active-location.svg";
import HeaderSearch from "../../assets/Icons/header-search.svg";
import ActiveFilter from "../../assets/Icons/header-active-filter.svg"
import { apiWithToken } from "../../feature/envs/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import HeaderList from "../../assets/Icons/header-list-view.svg"
import FilterModal from "../../pages/auctionbidding/filtermodal";
import { useTranslation } from "react-i18next";
import { AllDataContext, UserContext } from "../../contexts/signup";
import { encryptData } from "../../utils/functions";
import { fetchAuctions, fetchDirectSale, fetchFeaturedCards } from "../../api";
import { EventLogger, EventNames } from "../../utils/EventLogger";

type Props = {
  direction?: string;
  handleSearch: any;
  setViewGrid?: any;
  viewGrid?: any;
  assetTitle?: any;
  handleFilter?: any;
  type?: any;
  filterValue?: any;
  setAssetLoader?: any;
  setViewMap?: any;
  viewMap?: any;
  highestPrices?: any;
  setPage?: any;
};

const HeaderCarousel = (props: Props) => {
  //  console.log("Test prices", props?.highestPrices);
  const min = props?.highestPrices?.min || 0;
  const max = props?.highestPrices?.max || 0;

  const navigate = useNavigate();
  const [fullscreen, setFullscreen] = useState<any>(true);
  const [auctionOption, setAuctionOption] = useState<any>();
  const [directSaleOption, setDirectSaleOption] = useState<any>();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [activeTab, setActiveTab] = useState<any>();
  const { t } = useTranslation();
  const [range, setRange] = useState({ min: min, max: max });
  const { allData, setAllData } = useContext<any>(AllDataContext);
  const { user, setUser } = useContext<any>(UserContext);
  const { id }: any = useParams();
  const location = useLocation();

  const fetchOptions = async () => {
    await Promise.all([
      fetchAuctions(setAllData, setAuctionOption),
      fetchDirectSale(setAllData, setDirectSaleOption),
      fetchFeaturedCards(setAllData),
    ])
      .then(() => {
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const showCurrentStatus = () => {
    return new Promise((resolve, reject) => {
      apiWithToken
        .get(`api/v1/customer/kyc-status`, {})
        .then((result) => {
          if (result.status === 200) {
            resolve(result);
          } else {
            reject(new Error("Status code is not 200"));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  useEffect(() => {

    if(!localStorage.getItem("auction_category") || !localStorage.getItem("direct_category")){
      fetchOptions();
    }

    if (!user?.userDetails && user?.userLoggedIn) {
      const res = showCurrentStatus();
      res
        .then((response: any) => {
          // console.log("res1", response);
          if (response.data.status === 498) {
            setUser({
              userLoggedIn: false,
            });
            localStorage.removeItem("user-details");
            localStorage.removeItem("id-token");
            localStorage.removeItem("refresh-token");
            localStorage.removeItem("auction_category");
            localStorage.removeItem("direct_category");
            navigate("/");
            return;
          }
          setUser({
            ...user,
            email: response?.data?.data?.email_id,
            phoneNumber: response?.data?.data?.phone_number,
            full_name: response?.data?.data?.full_name,
            userDetails: response.data.data,
          });
          // localStorage.setItem(
          //   "user-details",
          //   JSON.stringify(response.data.data)
          // );
          encryptData("user-details", response?.data?.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, []);

  
  const auc_category: any = localStorage.getItem("auction_category");
  const dir_category: any = localStorage.getItem("direct_category");

  const auction_category =
    JSON.parse(auc_category) || auctionOption?.auction_category;
  const direct_category =
    JSON.parse(dir_category) || auctionOption?.direct_category;

  useEffect(() => {
    const categoryId: number = +(location.pathname.split("/").pop() || 0);
    let index;

    if (props.type === "directSale") {
      index = direct_category?.findIndex((category: any) => category?.category_id === +categoryId);
    } else {
      index = auction_category?.findIndex((category: any) => category?.category_id === +categoryId);
    }

    setActiveTab(index);

  }, [id, props.type]);



  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 991, min: 576 },
      items: 7,
    },
    mobile: {
      breakpoint: { max: 575, min: 0 },
      items: 5,
    },
  };
  const handleCategoryClick = (category: any, index: any) => {
    props?.setPage(0);
    setActiveTab(index);
    // Passing an empty obj to the handleFilter function when a new category is clicked to reset the sortObj/filterValue obj
    props?.handleFilter({});
    //props.handleFilter({ ...props.filterValue, priceRange: undefined });
    setAllData((prevData: any) => ({
      ...prevData,
      category_title: category?.category_title,
      category_title_ar: category?.category_title_ar,

    }));
    if (props.type === "auction") {
      EventLogger.logEvent(EventNames.CATEGORY_SELECTION_CHANGE, {category_id: id, auction_type: "auction"});
      navigate(`/c/${category?.category_id}`);
    } else if (props.type === "directSale") {
      EventLogger.logEvent(EventNames.CATEGORY_SELECTION_CHANGE, {category_id: id, auction_type: "direct-sale"});
      navigate(`/s/${category?.category_id}`);
    }
  };
  const handleViewAssets = () => {
    // Handles when the change view button is pressed while the map view is on to close the map view
    if (props.setViewMap) {
      props.setViewMap(false);
    }
    if (props.viewGrid) {
      props.setViewGrid(false);
      localStorage.setItem("GridViewOn", JSON.stringify(false));
    } else {
      props.setViewGrid(true);
      localStorage.setItem("GridViewOn", JSON.stringify(true));
    }
  };
  const handleFilter = (breakpoint: any) => {
    // props.setAssetLoader(true);
    if (showFilterModal === true) {
      setShowFilterModal(false);
    } else {
      setShowFilterModal(true);
      setFullscreen(breakpoint);
    }
  };

  const handleViewMap = () => {
    props?.setViewMap(!props.viewMap)
  };

  const searchPlacehoder: any = t("search");

  return (
    <Container>
      {(auction_category || direct_category) && (
        <Row className="d-flex align-items-center HeaderCorouselRow">
          <Col xl={10}>
            <div className="d-flex flex-column flex-lg-row justify-content-lg-between  ">
              {props.type === "auction"
                ? auction_category && (
                  <Carousel
                    containerClass="container"
                    responsive={responsive}
                    slidesToSlide={1}
                    className="HeaderCorouselsTWo"
                    rtl={props.direction === "rtl"}
                  >
                    {auction_category?.map((item: any, index: any) => (
                      <div
                        className={
                          activeTab === index
                            ? "HeaderCorouselsfocus"
                            : "HeaderCorousels"
                        }
                        key={item.category_id}
                        onClick={() => handleCategoryClick(item, index)}
                      >
                        <img
                          src={item.category_icon}
                          alt={item?.category_title}
                        />
                      </div>
                    ))}
                  </Carousel>
                )
                : direct_category && (
                  <Carousel
                    containerClass="container"
                    responsive={responsive}
                    slidesToSlide={1}
                    className="HeaderCorouselsTWo"
                    rtl={props.direction === "rtl"}
                  >
                    {direct_category?.map((item: any, index: any) => (
                      <div
                        className={
                          activeTab === index
                            ? "HeaderCorouselsfocus"
                            : "HeaderCorousels"
                        }
                        key={item.category_id}
                        onClick={() => handleCategoryClick(item, index)}
                      >
                        <img
                          src={item.category_icon}
                          alt={item?.category_title}
                        />
                      </div>
                    ))}
                  </Carousel>
                )}

              <div className="form-group has-search d-flex align-items-center">
                <span className="form-control-feedback">
                  <img src={HeaderSearch} alt="search" />
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder={searchPlacehoder}
                  onChange={props.handleSearch}
                  data-hj-allow
                />
              </div>
            </div>
          </Col>
          <Col xl={2}>
            <div className="d-flex align-items-center justify-content-between">
              <img
                className="nav_img"
                // Checks if filterValue variable has filter values like sortObj to show active or inactive filter button
                src={props?.filterValue?.sort_by ? ActiveFilter : HeaderFilter}
                alt="header-filter"
                onClick={() => handleFilter("sm-down")}
              />
              {props.viewGrid ? (
                <img
                  className="nav_img"
                  src={HeaderGrid}
                  alt="header-filter"
                  onClick={handleViewAssets}
                />
              ) : (
                <img
                  className="nav_img"
                  src={HeaderList}
                  alt="list"
                  onClick={handleViewAssets}
                ></img>
              )}
              <img
                className="nav_img"
                src={props?.viewMap ? ActiveLocation : HeaderLocation}
                alt="header-filter"
                onClick={handleViewMap}
              />
            </div>
          </Col>
        </Row>
      )}
      {showFilterModal && (
        <FilterModal
          filterValue={props.filterValue}
          title={props.assetTitle}
          show={showFilterModal}
          setShow={setShowFilterModal}
          fullscreen={fullscreen}
          handleFilter={props.handleFilter}
          type={props.type}
          range={{
            min: min,
            max: max,
          }}
        />
      )}
    </Container>
  );
};

export default HeaderCarousel;
