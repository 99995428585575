
type Props = {
  className: string;
  imgUrl: string;
  onClick?: () => void;
  setPage?:any;
};

const CrossButton = (props: Props) => {
  props?.setPage && props?.setPage(0)
  const handleOnClick = () => {
    if (props.onClick) {
      props.onClick();
    } else {
      props?.setPage &&  props?.setPage(0)
     window.history.back();
    }
  };

  return (
    <button
      onClick={handleOnClick}
      className={`bahrainarrow ${props.className}`}
    >
      <img src={props.imgUrl} alt="button" />
    </button>
  );
};

export default CrossButton;
