import Button from "react-bootstrap/Button";
import "./buttons.scss";
type Props = {
  className?: string;
  value: string;
  handleClick: (modaltype: any) => void;
};

const SignInButton = (props: Props) => {
  return (
    <Button
      variant="outline-primary"
      className="signIn"
      onClick={() => {
        props.handleClick("signin");
      }}
    >
      {props.value}
    </Button>
  );
};

export default SignInButton;
