import "../pages/internalrounting/internalrouting.scss";
import Chequetransfer from "../pages/internalrounting/paymentoptions/chequetransfer";
import Banktransfer from "../pages/internalrounting/paymentoptions/banktransfer";
import WhiteCheckamark from "../assets/Icons/radiocheckmark.svg";
import Creditcardtransfer from "../pages/internalrounting/paymentoptions/creditcardtransfer";
import Benefittransfer from "../pages/internalrounting/paymentoptions/benefittransfer";
import { useTranslation } from "react-i18next";
import Applepay from "../pages/internalrounting/paymentoptions/applepay";
import { numberFormat } from "../utils/functions";
import Walletpayment from "../pages/internalrounting/paymentoptions/walletpayment";

type paymentType = {
  id: number;
  payment_title: string;
  payment_title_ar: string;
  payment_method: string;
  icon: string;
};
type Props = {
  sortedMethods?: any;
  totalAmount?: any;
  category_id?: any;
  method?: any;
  selectedOption?: any;
  updateOption?: any;
  toggle?: any;
  transactionType?: any;
  typePurchase?: any;
  asset_id?: any;
  vat_percentage?: any;
  item_total?: any;
  vat?: any;
  cart_id?: any;
  selectedType?: any;
  totalWalletAmount?: any;
  selectedAsset?: any;
};
export default function PaymentMethods(props: Props) {
  const { t } = useTranslation();

  const totalAmount = props?.toggle
    ? props?.selectedAsset?.total_amount - props?.selectedAsset?.deposit_amount
    : props?.selectedAsset?.total_amount;


  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="">
          <h2 className="PaymenySummaryHeading">{t("payment_summary")}</h2>

          {/* PRODUCT PURCHASE */}
          {props?.selectedAsset?.item_total_base_price > 1 && (
            <>
              <div className="d-flex justify-content-between TotalAmount">
                <p className="Vat">{t("item_amount")} </p>
                {t("en") === "en" ? (
                  <p className="Amount">
                    {t("bhd")}{" "}
                    {numberFormat(props?.selectedAsset?.item_total_base_price)}
                  </p>
                ) : (
                  <p className="Amount">
                    {numberFormat(props?.selectedAsset?.item_total_base_price)}{" "}
                    {t("bhd")}
                  </p>
                )}
              </div>

              {props?.cart_id < 1 ? (
                <div className="d-flex justify-content-between TotalAmountDeposit">
                  <p className={props?.toggle ? "Vat" : "VatDeposit"}>
                    {props?.toggle
                      ? t("remaining_deposit")
                      : t("deposit_amount")}{" "}
                  </p>
                  {t("en") === "en" ? (
                    <p className={props?.toggle ? "Amount" : "AmountDeposit"}>
                      {t("bhd")}{" "}
                      {numberFormat(
                        props?.toggle
                          ? props?.selectedAsset?.deposit_amount -
                              props?.selectedAsset?.item_total_base_price >
                            0
                            ? props?.selectedAsset?.deposit_amount -
                              props?.selectedAsset?.total_amount
                            : 0
                          : props?.selectedAsset?.deposit_amount
                      )}
                    </p>
                  ) : (
                    <p className={props?.toggle ? "Amount" : "AmountDeposit"}>
                      {numberFormat(props?.selectedAsset?.deposit_amount)}{" "}
                      {t("bhd")}
                    </p>
                  )}
                </div>
              ) : (
                ""
              )}

             

              {props?.selectedAsset?.admin_fees &&
              props?.selectedAsset?.admin_fees > 0 ? (
                <div className="d-flex justify-content-between TotalAmount">
                  <p className="Vat">{t("admin_fees")}</p>
                  {t("en") === "en" ? (
                    <p className="Amount">
                      {t("bhd")}{" "}
                      {numberFormat(props?.selectedAsset?.admin_fees)}
                    </p>
                  ) : (
                    <p className="Amount">
                      {numberFormat(props?.selectedAsset?.admin_fees)}{" "}
                      {t("bhd")}
                    </p>
                  )}
                </div>
              ) : (
                ""
              )}

              {props?.selectedAsset?.auction_fees &&
              props?.selectedAsset?.auction_fees > 0 ? (
                <div className="d-flex justify-content-between TotalAmount">
                  <p className="Vat">{t("auction_fees")}</p>
                  {t("en") === "en" ? (
                    <p className="Amount">
                      {t("bhd")}{" "}
                      {numberFormat(props?.selectedAsset?.auction_fees)}
                    </p>
                  ) : (
                    <p className="Amount">
                      {numberFormat(props?.selectedAsset?.auction_fees)}{" "}
                      {t("bhd")}
                    </p>
                  )}
                </div>
              ) : (
                ""
              )}

              {props?.selectedAsset?.delivery_fees &&
              props?.selectedAsset?.delivery_fees > 0 ? (
                <div className="d-flex justify-content-between TotalAmount">
                  <p className="Vat">{t("delivery_fees")}</p>
                  {t("en") === "en" ? (
                    <p className="Amount">
                      {t("bhd")}{" "}
                      {numberFormat(props?.selectedAsset?.delivery_fees)}
                    </p>
                  ) : (
                    <p className="Amount">
                      {numberFormat(props?.selectedAsset?.delivery_fees)}{" "}
                      {t("bhd")}
                    </p>
                  )}
                </div>
              ) : (
                ""
              )}

{props?.selectedAsset?.item_total_vat ? (
                <div className="d-flex justify-content-between TotalAmount">
                  <p className="Vat">
                    {t("vat")}{" "}
                    {props?.vat_percentage > 0 ?  props?.vat_percentage + "%" : ''}
                  </p>
                  {t("en") === "en" ? (
                    <p className="Amount">
                      {t("bhd")}{" "}
                      {numberFormat(props?.selectedAsset?.item_total_vat)
                        ? numberFormat(props?.selectedAsset?.item_total_vat)
                        : 0}
                    </p>
                  ) : (
                    <p className="Amount">
                      {numberFormat(props?.selectedAsset?.item_total_vat)
                        ? numberFormat(props?.selectedAsset?.item_total_vat)
                        : 0 }
                      {t("bhd")}
                    </p>
                  )}
                </div>
              ) : (
                ""
              )}

              <div className="d-flex justify-content-between TotalAmount">
                <p className="Amount">{t("total_amount")}</p>
                {t("en") === "en" ? (
                  <p className="Amount">
                    {t("bhd")} {numberFormat(totalAmount > 0 ? totalAmount : 0)}
                  </p>
                ) : (
                  <p className="Amount">
                    {numberFormat(totalAmount > 0 ? totalAmount : 0)} {t("bhd")}
                  </p>
                )}
              </div>
            </>
          )}

          {/* CART PURCHASE */}
          {props?.item_total < 1 && (
            <>
              <div className="d-flex justify-content-between TotalAmount">
                <p className="Amount">{t("total_amount")}</p>
                {t("en") === "en" ? (
                  <p className="Amount">
                    {t("bhd")}{" "}
                    {props.totalAmount > 0
                      ? numberFormat(props.totalAmount)
                      : 0}
                  </p>
                ) : (
                  <p className="Amount">
                    {numberFormat(props.totalAmount)} {t("bhd")}
                  </p>
                )}
              </div>

              {props.vat_percentage && (
                <div className="d-flex justify-content-between TotalAmount">
                  <p className="Vat">
                    {t("vat")}{" "}
                    {isNaN(numberFormat(props?.vat_percentage))
                      ? ""
                      : props?.vat_percentage}
                  </p>
                  {t("en") === "en" ? (
                    <p className="Amount">
                      {t("bhd")}{" "}
                      {isNaN(numberFormat(props?.vat))
                        ? 0
                        : numberFormat(props.vat)}
                    </p>
                  ) : (
                    <p className="Amount">
                      {numberFormat(props?.vat_percentage)} {t("bhd")}
                    </p>
                  )}
                </div>
              )}
            </>
          )}

          {/* Total value starts here */}
          {totalAmount > 0 ||
          props?.toggle === false ||
          props?.totalAmount > 0 ||
          props?.toggle === undefined ? (
            <>
              {props?.toggle === undefined && (
                <div className="d-flex justify-content-between TotalAmount">
                  <p className="Amount">{t("total_amount")}</p>
                  {t("en") === "en" ? (
                    <p className="Amount">
                      {t("bhd")}{" "}
                      {numberFormat(
                        props?.selectedAsset?.total_amount || props?.totalAmount
                      )}
                    </p>
                  ) : (
                    <p className="Amount">
                      {numberFormat(
                        props?.selectedAsset?.total_amount || props?.totalAmount
                      )}{" "}
                      {t("bhd")}
                    </p>
                  )}
                </div>
              )}

              <div className="d-flex justify-content-between">
                <h2 className="PaymentMethodHeading">{t("payment_method")}</h2>
                <img src="../assets/Icons/top-arrow.svg" alt="top-arrow" />
              </div>
              {props.sortedMethods &&
                props.sortedMethods?.map((data: paymentType) => (
                  <div
                    key={data.id}
                    onClick={() =>
                      props.updateOption(
                        props.selectedOption === data.id ? -1 : data.id
                      )
                    }
                    className={
                      props.selectedOption === -1 ||
                      props.selectedOption === data.id
                        ? ""
                        : "d-none"
                    }
                  >
                    <div
                      className={`d-flex align-items-center PaymentMethods ${
                        props.selectedOption === data.id
                          ? "PaymentHighlightRed"
                          : "PaymentHighlight"
                      }`}
                    >
                      {props.selectedOption !== data.id && (
                        <div className="SelectPayment"></div>
                      )}
                      <label
                        htmlFor="PaymentOne"
                        className="d-flex align-items-center order-1"
                      >
                        <img
                          src={
                            data.icon || "../assets/Images/bank-transfer.png"
                          }
                          alt="bank-transfer"
                        />
                        <p className="CurrentAvailable">
                          {t("en") === "en"
                            ? data?.payment_title
                            : data?.payment_title_ar}
                        </p>
                      </label>
                      <input
                        type="radio"
                        id="PaymentOne"
                        name="selectcountry"
                      />
                      {props.selectedOption === data.id && (
                        <div className="SelectPayment d-flex justify-content-center align-items-center order-0">
                          <img src={WhiteCheckamark} alt="checkmark" />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              {props.selectedOption === 1 && (
                <Banktransfer
                  selectedType={props?.selectedType}
                  totalAmount={
                    props?.selectedAsset?.total_amount || props?.totalAmount
                  }
                  category_id={props?.category_id}
                  method={props?.method}
                  useDepositAmount={props?.toggle}
                  transactionType={props?.transactionType}
                  typePurchase={props?.typePurchase}
                  asset_id={props?.asset_id}
                  cart_id={props?.cart_id}
                />
              )}
              {props.selectedOption === 2 && (
                <Chequetransfer
                  selectedType={props?.selectedType}
                  totalAmount={
                    props?.selectedAsset?.total_amount || props?.totalAmount
                  }
                  category_id={props?.category_id}
                  method={props?.method}
                  useDepositAmount={props?.toggle}
                  transactionType={props?.transactionType}
                  typePurchase={props?.typePurchase}
                  asset_id={props?.asset_id}
                  cart_id={props?.cart_id}
                />
              )}
              {props.selectedOption === 3 && (
                <Benefittransfer
                  selectedType={props?.selectedType}
                  totalAmount={
                    props?.selectedAsset?.total_amount || props?.totalAmount
                  }
                  payment_method={props?.selectedOption}
                  category_id={props?.category_id}
                  method={props?.method}
                  useDepositAmount={props?.toggle}
                  transactionType={props?.transactionType}
                  typePurchase={props?.typePurchase}
                  asset_id={props?.asset_id}
                  cart_id={props?.cart_id}
                />
              )}
              {props.selectedOption === 4 && (
                <Creditcardtransfer
                  selectedType={props?.selectedType}
                  totalAmount={
                    props?.selectedAsset?.total_amount || props?.totalAmount
                  }
                  payment_method={props?.selectedOption}
                  category_id={props?.category_id}
                  method={props?.method}
                  useDepositAmount={props?.toggle}
                  transactionType={props?.transactionType}
                  typePurchase={props?.typePurchase}
                  asset_id={props?.asset_id}
                  cart_id={props?.cart_id}
                />
              )}
              {props.selectedOption === 5 && (
                <Applepay
                  totalAmount={
                    props?.selectedAsset?.total_amount || props?.totalAmount
                  }
                  payment_method={props.selectedOption}
                  category_id={props?.category_id}
                  method={props?.method}
                  asset_id={props?.asset_id}
                />
              )}
            </>
          ) : (
            <>
              <Walletpayment
                selectedType={props?.selectedType}
                amount={props?.totalWalletAmount}
                totalAmount={0}
                category_id={props.category_id}
                method={"wallet"}
                useDepositAmount={props?.toggle}
                transactionType={"INVOICE"}
                typePurchase={props?.typePurchase}
                asset_id={props.asset_id}
                cart_id={props?.cart_id}
              />
            </>
          )}
        </div>
      </form>
    </div>
  );
}
