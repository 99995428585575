import RightArrow from "../../assets/Icons/rightarrow.svg";
import CopiedFolder from "../../assets/Icons/directsales-upload-documents.svg";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

type Props = {
  heading?: any;
  docList?: any;
};

const DocumentsSection = (props: Props) => {  
  const { heading, docList } = props;
  const {t} = useTranslation();
  const displayDocument = (url: any) => {
    window.open(url);
  };
  // const myElements =
  //   docList &&
  //   Object.entries(docList).map(([key, value], index) => {
  //     const documentName = Object.keys(value as Record<string, string>)[0];
  //     const documentUrl = (value as { [key: string]: string })[documentName];
  //     return (
  //       <div
  //         className="DocumentsData d-flex justify-content-between"
  //         onClick={() => displayDocument(documentUrl)}
  //       >
  //         <p className="DocumentsDataText PlatePurchase">
  //           <img src={CopiedFolder} />
  //           {documentName}
  //         </p>
  //         <img src={RightArrow} alt="rightarrow" />
  //       </div>
  //     );
  //   });

    const myElements =
    docList &&
    docList.map((item:any, index:any) => {
      const documentName = t("en") === "en"
      ? item.title : item?.title_ar;
      const documentUrl = item.doc;
      return (
        <div
          className="DocumentsData d-flex justify-content-between"
          onClick={() => displayDocument(documentUrl)}
          key={index}
        >
          <p className="DocumentsDataText PlatePurchase">
            <img src={CopiedFolder} alt="folder" />
            {documentName}
          </p>
          <img src={RightArrow} alt="rightarrow" />
        </div>
      );
    });
    

  return (
    <>
      {Object.keys(docList).length !== 0 && (
        <div className="DocumentsTermsPrivacy">
          <h2 className="profileheading">{heading}</h2>
          {myElements}
        </div>
      )}
    </>
  );
};

export default DocumentsSection;
