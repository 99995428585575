import "./completereg.scss";
import Container from "react-bootstrap/Container";
import CrossButton from "../../components/buttons/crossbutton";
import BreadCrums from "../../components/breadcrumbs/breadcrums";
import FloatLabels from "../../components/inputfeilds/floatlabels";
import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/signup";
import { apiWithToken } from "../../feature/envs/api";
import { toast } from "react-toastify";
import CrossMark from "../../assets/Icons/crossmark.svg";
import profileUpload from "../../assets/Icons/profile-upload.png";
import uploadPhoto from "../../assets/Icons/upload-photo.svg";
import { decryptData, encryptData } from "../../utils/functions";

let errorCodes: any = null;

const defaultFormData: any = {
  full_name: "",
  company_name: "",
  company_regNo: "",
  profile_pic: "",
};

const CompleteRegistration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, showLoading] = useState(false);
  const { user, setUser } = useContext<any>(UserContext);
  const [, setConfirmation] = useState(false);
  const userDetails = decryptData("user-details");

  if (user?.email || user?.userDetails?.email_id) {
    errorCodes = [
      {
        field: "full_name",
        pattern: /^[a-z ,.'-]+$/i,
        errorText: "Please enter valid name",
      },
      {
        field: "email_id",
        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        errorText: "Please enter valid email",
      },
    ];
  } else {
    errorCodes = [
      {
        field: "full_name",
        pattern: /^[a-z ,.'-]+$/i,
        errorText: "Please enter valid name",
      },
      {
        field: "phone_number",
        pattern: /\d$/,
        errorText: "Please enter valid mobile number",
      },
    ];
  }

  const [userFormData, updateFormData] = useState({
    ...defaultFormData,
    phone_number: user?.phoneNumber || user?.userDetails?.phone_number || userDetails?.phone_number || "",
    email_id: user?.email || user?.userDetails?.email_id || userDetails?.email_id || "",
    full_name: user?.full_name || user?.userDetails?.full_name || "",
    type: user.type || "PHONE_NUMBER",
    errorFields: {},
  });
  const [file, setFile] = useState<any>();

  const updateData = (obj: any) =>
    updateFormData((prev: any) => ({ ...prev, ...obj }));

  const handleChange = (e: any) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    updateData({ profile_pic: e.target.files[0] });
  };

  const handleRegistration = () => {
    let havingErrors = false;
    let codes: any = {};

    if (havingErrors) {
      updateData({ errorFields: codes });
    } else {
      const data = new FormData();
      data.append("full_name", userFormData.full_name.trim());
      user?.phoneNumber ||
        (user?.userDetails?.phone_number &&
          data.append("phone_number", userFormData.phone_number.trim()));
      user?.email ||
        (user?.userDetails?.email_id &&
          data.append("email_id", userFormData.email_id.trim()));
      data.append("company_name", userFormData.company_name.trim());
      data.append("company_regNo", userFormData.company_regNo.trim());
      data.append("profile_pic", userFormData.profile_pic);
      apiWithToken
        .post(`api/v1/customer/register`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res: any) => {
          if (res.data.status === 200) {
            setConfirmation(true);
            setUser({
              ...user,
              userLoggedIn: true,
              email: res.data.data.email,
              phoneNumber: res.data.data.phoneNumber,
              full_name: res.data.data.full_name,
              userDetails: res.data.data,
            });
            encryptData('user-details', res?.data?.data);
            if (
              res?.data?.data?.full_name &&
              res?.data?.data?.doc_review_status == null &&
              res?.data?.data?.identity_verification_status == null
            ) {
              navigate("/verify-user");
            } else {
              navigate("/");
            }
          } else {
            toast(<p className="CartErrMsg">{res.data.message}</p>, {
              autoClose: 2500,
              hideProgressBar: true,
              toastId: "cartToast",
            });
          }
        })
        .catch(() =>
          toast(<p className="CartErrMsg">{t("cancel_payment")}</p>, {
            autoClose: 2500,
            hideProgressBar: true,
            toastId: "cartToast",
          })
        );
    }
  };

  const { errorFields, email_id, phone_number, full_name } = userFormData;
  const registerDisabled =
    (email_id && email_id.trim() === "") ||
    (phone_number && phone_number.trim() === "") ||
    full_name.trim() === "";

  const [email, setEmail] = useState(null);
  const [phnNumber, setPhnNumber] = useState(null);

  useEffect(() => {
    setEmail(user?.userDetails?.email_id || userDetails?.email_id || null);
    setPhnNumber(user?.userDetails?.phone_number || userDetails?.phone_number || null);
  }, [user]);

  return (
    <Container className="registrationsection">
      <div className="d-flex align-items-center profilereg biddingswoncard">
        <CrossButton className="crossright" imgUrl={CrossMark} />
        <BreadCrums
          previousPage={t("header_signup")}
          currentPage={t("complete_registration")}
        />
      </div>
      <div className="d-flex justify-content-center">
        <div className="d-flex flex-column align-items-center">
          <h2 className="profileheading">{t("profile_picture")}</h2>
          <div className="profileimage">
            {!file ? (
              <img src={profileUpload} alt="profile" />
            ) : (
              <img
                className="profile_img"
                src={file || profileUpload}
                alt="profile pic"
              />
            )}
          </div>
          <button className="uploadphotobutton">
            <label>
              <input
                type="file"
                onChange={handleChange}
                accept="image/png, image/jpg, image/jpeg"
                hidden
              />
              <span>
                <img src={uploadPhoto} alt="upload" />
              </span>
              {t("upload_photo")}
            </label>
          </button>
          <h2 className="profileheading">{t("personal_info")}</h2>
          <form className="profileform" onSubmit={(e) => e.preventDefault()}>
            <FloatLabels
              name={t("name")}
              value={userFormData.full_name}
              onChange={(e: any) => updateData({ full_name: e.target.value })}
              inputProps={{ required: true }}
              errorProps={{
                hasError: errorFields["full_name"],
                errorText: errorFields["full_name"],
              }}
            />
            {(userDetails?.email_id) || user?.userDetails?.email_id ? (
              <FloatLabels
                name={t("email_id")}
                value={userFormData.email_id || userDetails?.email_id}
                onChange={(e) => updateData({ email_id: e.target.value })}
                inputProps={{
                  disabled: userDetails?.email_id,
                }}
                errorProps={{
                  hasError: errorFields["email_id"],
                  errorText: errorFields["email_id"],
                }}
              />
            ) : (
              ""
            )}
            {(userDetails?.phone_number) ||
              user?.userDetails?.phone_number ? (
              <FloatLabels
                name={t("mobile_number")}
                value={userFormData.phone_number || userDetails?.phone_number}
                onChange={(e) => updateData({ phone_number: e.target.value })}
                inputProps={{ disabled: userDetails?.phone_number }}
                errorProps={{
                  hasError: errorFields["phone_number"],
                  errorText: errorFields["phone_number"],
                }}
              />
            ) : (
              ""
            )}
            <FloatLabels
              name={t("company_name")}
              value={userFormData.company_name}
              onChange={(e) => updateData({ company_name: e.target.value })}
            />
            <FloatLabels
              name={t("company_reg")}
              value={userFormData.company_regNo}
              onChange={(e) => updateData({ company_regNo: e.target.value })}
            />
            <div title={registerDisabled ? "Please fill required fields" : ""}>
              <button
                disabled={registerDisabled}
                className={`confirm-otp-number ${registerDisabled ? "disable_btn" : ""
                  }`}
                onClick={() => {
                  showLoading(true);
                  handleRegistration();
                }}
              >
                {loading ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">{t("Loading")}</span>
                  </div>
                ) : (
                  t("continue_with_reg")
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default CompleteRegistration;
