import { t } from "i18next";
import { Form, InputGroup } from "react-bootstrap";
import SearchIcon from "../../assets/Icons/search-icon.svg";

type Props = {
  searchText: string;
  updateSearchText: (e: any) => void;
};

const Searchfield = (props: Props) => {
  const placeHolder = t("search");
  return (
    <InputGroup style={{ marginBottom: 22 }}>
      <InputGroup.Text>
        <img src={SearchIcon} alt="search-icon" />
      </InputGroup.Text>
      <Form.Control
        type="text"
        autoFocus={true}
        placeholder={placeHolder}
        value={props.searchText}
        onChange={(e) => props.updateSearchText(e.target.value)}
        data-hj-allow
      />
    </InputGroup>
  );
};

export default Searchfield;
