import { Container } from "react-bootstrap";
import BreadCrums from "../../components/breadcrumbs/breadcrums";
import CrossButton from "../../components/buttons/crossbutton";
import DirectSalesContacts from "../../components/directsales/directsalescontacts";
import Dots from "../../assets/Icons/directsales-dots.svg";
import LeftArrow from "../../assets/Icons/left-arrow.svg";
import { useTranslation } from "react-i18next";
import redWatchlistIcon from "../../assets/Icons/details-red.svg";
import watchlistIcon from "../../assets/Icons/details.svg";
type Props = {
  currentPage?: any;
  subtitle?: any;
  assetItem?: any;
  previousPage?: any;
  end_title?: any;
  asset_id?: any;
  setShowSupport?:any;
};

const DirectsalesTopIcons = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="d-sm-flex justify-content-between profilereg biddingswoncard">
        <div className="d-flex align-items-center">
          <CrossButton className="prevarrow" imgUrl={LeftArrow} />
          <BreadCrums
            previousPage={props?.previousPage || t("direct_sale")}
            currentPage={props.currentPage}
            end_title={props?.end_title}
          />
        </div>
        <div className="d-flex DirectSalesIcons">
          <DirectSalesContacts
            imgUrl={
              props?.assetItem?.is_added_to_watchlist
                ? redWatchlistIcon
                : watchlistIcon
            }
            type="watchlist"
            assetItem={props?.assetItem}
            asset_id={props?.asset_id}
          />
          <DirectSalesContacts imgUrl={Dots} setShowSupport={props?.setShowSupport}  type="contact" />
        </div>
      </div>
    </Container>
  );
};

export default DirectsalesTopIcons;
