import { t } from "i18next";

type Props = {
  title: string;
  subtitle?: string;
  text: string;
  className?: string;
  status?: any;
  img?: string;
  alt?: string;
  amount?: string;
};

const PaymentRequest = (props: Props) => {
  return (
    <div className="text-center CheckoutTopSection">
      <div className="text-center UserPaymentStatus">
        <img src={props?.img} alt={props?.alt} />
        <h2 className="profileheading">{props?.title}</h2>
        <div>
          <span className="profileheading"> {t("bhd")}</span>
          <span className="UserPaidAmount">{props?.amount}</span>
        </div>
      </div>
      <p className="CurrentAvailable">{props?.subtitle}</p>
    </div>
  );
};

export default PaymentRequest;
