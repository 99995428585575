import { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../contexts/signup";

type Props = {
  className?: string;
  setEmailErr?: any;
};

const SignUpMailInput = (props: Props) => {
  const [email, setEmail] = useState<any>();
  const [emailErr, setEmailErr] = useState<any>(false);
  const { user, setUser } = useContext<any>(UserContext);
  const { t } = useTranslation();

  const handleEmailChange = (event: any) => {
    event.preventDefault(event);
    const { value } = event.target;
    // const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const pattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    setEmailErr(!pattern.test(value));
    props.setEmailErr(!pattern.test(value));
    setEmail(value);
    setUser({
      ...user,
      email: value,
      type: "EMAIL",
    });
    //setUser({ type: "EMAIL", email: value });
  };
  const placeHolder = t("example_email");
  return (
    <>
      <InputGroup
        size="lg"
        className={props.className}
        style={{ flexDirection: "column" }}
      >
        <Form.Control
          aria-label="Large"
          aria-describedby="inputGroup-sizing-sm"
          placeholder={placeHolder}
          onChange={handleEmailChange}
          value={email}
          style={{ width: "100%" }}
          data-hj-allow
        />
        {emailErr && (
          <div className="text-muted">
            <div className="text-danger">Enter Valid Email Id</div>
          </div>
        )}
      </InputGroup>
    </>
  );
};

export default SignUpMailInput;
