import { apiWithToken } from "../../../feature/envs/api";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState, CSSProperties } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CrossButton from "../../../components/buttons/crossbutton";
import BreadCrums from "../../../components/breadcrumbs/breadcrums";
import ReachUsOptions from "./../reachusoptions";
import "../internalrouting.scss";
import ClipLoader from "react-spinners/ClipLoader";
import { numberFormat, unixToDateTime } from "../../../utils/functions";
import { t } from "i18next";
import PaymentPending from "../../../assets/Icons/payment-pending.svg";
import PaymentSucces from "../../../assets/Icons/payment-success.svg";
import PaymentFailed from "../../../assets/Icons/payment-failed.svg";
import AllAccountDetails from "../allaccountdetails";
import LeftArrow from "../../../assets/Icons/left-arrow.svg";

const Creditpaymentstatus = () => {
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState<any>();
  const [loader, setLoader] = useState(true);
  const { status, transaction_id } = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const queryParams = new URLSearchParams(location.search);
  const error = queryParams.get("error");
  const isCanceled = error?.includes("canceled");
  const registerStatus = searchParams?.get("status") || "";
  const classRegister =
    registerStatus === "pending_approval"
      ? "CurrentAvailableCost StatusPending"
      : "CurrentAvailableCost StatusDone";

  const handlePaymentStatus = () => {
    const payment_method = localStorage.getItem("payment_method");
    localStorage.removeItem("payment_method");
    let apiLink: string = "";

    if (status === "cancel") {
      apiLink = `api/v1/transaction/payment-cancel?transaction_id=${transaction_id}`;
    } else if (isCanceled) {
      apiLink = `api/v1/transaction/payment-cancel?transaction_id=${transaction_id}`;
    } else if (status === "success" || status === "done" || payment_method === "credit_card" || payment_method === "benefit" || status === "failed") {
      apiLink = `api/v1/transaction/verify-payment?transaction_id=${transaction_id}`;
    }


    apiWithToken
      .put(apiLink)
      .then((res: any) => {
        let result = res.data.data;
        setTransactionData(result);
        setLoader(false);

        const { id, asset_id } = res.data.data?.asset || res.data.data;
        setTimeout(() => {
          if (
            res.data.data.transaction_type === "INVOICE" &&
            registerStatus === "" &&
            res.data.data.cart_id < 1
          ) {
            navigate(`/complete-purchase?id=${asset_id || id}`);
          }
        }, 5000);

        if (res.data.status === 400) {
          navigate("/profile/deposits");
        }
      })
      .catch((error: any) => {
        console.log("Error after payment", error);
        //navigate("/directsalecheckout");
      });
  };

  useEffect(() => {
    handlePaymentStatus();
  }, []);
  const override: CSSProperties = {
    display: "block",
    margin: "20% auto",
    borderColor: "black",
    overflow: "-moz-hidden-unscrollable",
  };

  const title = t("payment_details");
  const paymentDate =
    transactionData?.payment_method === "benefit" ||
      transactionData?.payment_method === "credit_card" ||
      transactionData?.payment_method === "wallet" ||
      transactionData?.transaction_type === "REFUND"
      ? unixToDateTime(transactionData?.transaction_date || transactionData?.created_at)
      : unixToDateTime(transactionData?.deposit_date || transactionData?.created_at);
  const statusArray: any = {
    PENDING: {
      title:
        transactionData?.transaction_type === "REFUND"
          ? t("thank_you_for_your_request")
          : t("thank_you_for_your_payment"),
      subtitle:
        transactionData?.transaction_type === "REFUND"
          ? t("request_subtitle")
          : t("pending_subtitle"),
      status: t("processing"),
      img: PaymentPending,
      classVal: "CurrentAvailableCost StatusPending",
    },
    DONE: {
      title:
        transactionData?.transaction_type === "REFUND"
          ? t("thank_you_for_your_request")
          : t("thank_you_for_your_payment"),
      subtitle:
        transactionData?.transaction_type === "REFUND"
          ? t("your_request_was_successful")
          : t("your_payment_was_successful"),
      status:
        transactionData?.transaction_type === "REFUND"
          ? t("request_successful")
          : t("payment_successful"),
      img: PaymentSucces,
      classVal: "CurrentAvailableCost StatusDone",
    },
    CANCELLED: {
      title: t("cancel_payment"),
      subtitle: t("payment_cancel_subtitle"),
      status: t("payment_failed"),
      img: PaymentFailed,
      classVal: "CurrentAvailableCost StatusCanceled",
    },
    FAILED: {
      title: t("cancel_payment"),
      subtitle: t("payment_cancel_subtitle"),
      status: t("payment_failed"),
      img: PaymentFailed,
      classVal: "CurrentAvailableCost StatusCanceled",
    },
  };

  const paymentDetails = [
    {
      userDetails: [
        {
          name: t("payment_id"),
          cost: `${transactionData?.transaction_id}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("deposited_amount"),
          cost:
            t("en") === "en"
              ? t("bhd") +
              `${" " + numberFormat(transactionData?.deposit_amount)}`
              : `${" " +
              numberFormat(transactionData?.deposit_amount) +
              " " +
              t("bhd")
              }`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("selected_category"),
          cost: t("en") === "en"
            ? `${transactionData?.category_title}` : `${transactionData?.category_title_ar}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("limit_amount_added"),
          cost:
            t("en") === "en"
              ? t("bhd") +
              `${" " + numberFormat(transactionData?.deposit_amount)}`
              : `${" " +
              numberFormat(transactionData?.deposit_amount) +
              " " +
              t("bhd")
              }`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("new_limit_amount"),
          cost:
            t("en") === "en"
              ? t("bhd") +
              `${" " + numberFormat(transactionData?.new_current_limit || 0)}`
              : `${" " +
              numberFormat(transactionData?.new_current_limit || 0) +
              " " +
              t("bhd")
              }`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("payment_method"),
          cost: t(transactionData?.payment_method),
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("payment_date"),
          cost: `${unixToDateTime(
            transactionData?.transaction_date ||
            transactionData?.deposit_date ||
            transactionData?.created_at
          )}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("payment_status"),
          cost: `${statusArray[transactionData?.status]?.status}`,
          classVal: `${statusArray[transactionData?.status]?.classVal}`,
        },
      ],
    },
  ];

  const yesVal =
    t("en") === "en"
      ? `${t("yes")} ${t("bhd")} (${transactionData?.used_deposited_amount})`
      : ` (${transactionData?.used_deposited_amount}) ${t("bhd")} ${t("yes")}`;

  const useDepositAmount = transactionData?.use_deposit_amount
    ? yesVal
    : t("no");

  const total_amount_paid =
    transactionData?.deposit_amount - transactionData?.used_deposited_amount > 0
      ? transactionData?.deposit_amount - transactionData?.used_deposited_amount
      : 0;


  const asset_amount =
    transactionData?.deposit_amount === 0
      ? transactionData?.used_deposited_amount
      : transactionData?.deposit_amount;

  const purchaseDetails = [
    {
      userDetails: [
        {
          name: t("payment_id"),
          cost: `${transactionData?.transaction_id}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("asset_id"),
          cost: transactionData?.id
            ? transactionData?.asset_id || transactionData?.asset?.id
            : "-",
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("asset_amount"),
          cost:
            t("en") === "en"
              ? t("bhd") + `${" " + numberFormat(asset_amount)}`
              : `${" " + numberFormat(asset_amount) + " " + t("bhd")}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("use_deposit_amount"),
          cost: useDepositAmount,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("total_amount_paid"),
          cost:
            t("en") === "en"
              ? t("bhd") + `${" " + numberFormat(total_amount_paid)}`
              : `${" " + numberFormat(total_amount_paid) + " " + t("bhd")}`,
          classVal: `CurrentAvailableCost`,
        },

        {
          name: t("payment_method"),
          cost: t(transactionData?.payment_method),
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("payment_date"),
          cost: `${paymentDate}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("payment_status"),
          cost: `${statusArray[transactionData?.status]?.status}`,
          classVal: `${statusArray[transactionData?.status]?.classVal}`,
        },
        {
          name: t("request_status"),
          cost: `${t(registerStatus)}`,
          classVal: `${classRegister}`,
        },
      ],
    },
  ];

  const checkoutDetails = [
    {
      userDetails: [
        {
          name: t("payment_id"),
          cost: `${transactionData?.transaction_id}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("order_id"),
          cost: `${transactionData?.cart_id || 0}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("total_amount_paid"),
          cost:
            t("en") === "en"
              ? t("bhd") +
              `${" " +
              numberFormat(
                transactionData?.deposit_amount -
                transactionData?.used_deposited_amount
              )
              }`
              : `${" " +
              numberFormat(
                transactionData?.deposit_amount -
                transactionData?.used_deposited_amount
              ) +
              " " +
              t("bhd")
              }`,
          classVal: `CurrentAvailableCost`,
        },

        {
          name: t("payment_method"),
          cost: t(transactionData?.payment_method),
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("payment_date"),
          cost: `${paymentDate}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("payment_status"),
          cost: `${statusArray[transactionData?.status]?.status}`,
          classVal: `${statusArray[transactionData?.status]?.classVal ||
            "successfully_approved"
            }`,
        },
        {
          name: t("request_status"),
          cost: `${t(registerStatus)}`,
          classVal: `${classRegister}`,
        },
      ],
    },
  ];
  const refundDetails = [
    {
      userDetails: [
        {
          name: t("request_id"),
          cost: `${transactionData?.transaction_id}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("request_amount"),
          cost:
            t("en") === "en"
              ? t("bhd") +
              `${" " + numberFormat(transactionData?.deposit_amount || 0)}`
              : `${" " +
              numberFormat(transactionData?.deposit_amount || 0) +
              " " +
              t("bhd")
              }`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("request_method"),
          cost: t(transactionData?.payment_method),
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("refund_request_date"),
          cost: `${paymentDate}`,
          classVal: `CurrentAvailableCost`,
        },
        {
          name: t("request_status"),
          cost: `${statusArray[transactionData?.status]?.status}`,
          classVal: `${statusArray[transactionData?.status]?.classVal ||
            "successfully_approved"
            }`,
        },
      ],
    },
  ];


  const currentPage =
    transactionData?.transaction_type === "INVOICE"
      ? t("my_purchases")
      : t("my_deposit_bidding_limit");
  const end_title =
    transactionData?.transaction_type === "REFUND"
      ? transactionData && t("refund_deposit")
      : "";

  return (
    <Container>
      <Row>
        <Col>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center profilereg biddingswoncard">
              <CrossButton className="prevarrow" imgUrl={LeftArrow} />
              <BreadCrums
                previousPage={t("profile")}
                currentPage={currentPage}
                end_title={end_title}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        {loader ? (
          <ClipLoader
            color={"#fff"}
            loading={loader}
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <Col className="CheckoutSection">
            <div className="text-center CheckoutTopSection">
              <div className="text-center UserPaymentStatus">
                <img
                  src={statusArray[transactionData?.status]?.img}
                  alt={transactionData?.status}
                />
                <h2 className="profileheading">
                  {statusArray[transactionData?.status]?.title}
                </h2>
                <div>
                  <span className="profileheading"> {t("bhd")}</span>
                  <span className="UserPaidAmount">
                    {" "}
                    {" " +
                      numberFormat(
                        transactionData?.payment_method === "wallet"
                          ? transactionData?.used_deposited_amount
                          : transactionData?.deposit_amount
                      )}
                  </span>
                </div>
              </div>
              <p className="CurrentAvailable">
                {statusArray[transactionData?.status]?.subtitle}
              </p>
            </div>
            {transactionData?.transaction_type === "DEPOSIT" &&
              paymentDetails.map(({ userDetails }) => (
                <div className="AccountDetails">
                  <h2 className="PaymentMethodHeading">{title}</h2>
                  <AllAccountDetails userDetails={userDetails} />
                </div>
              ))}
            {transactionData?.transaction_type === "INVOICE" &&
              transactionData?.cart_id &&
              checkoutDetails.map(({ userDetails }) => (
                <div className="AccountDetails">
                  <h2 className="PaymentMethodHeading">{title}</h2>
                  <AllAccountDetails userDetails={userDetails} />
                </div>
              ))}
            {transactionData?.transaction_type === "INVOICE" &&
              !transactionData?.cart_id &&
              purchaseDetails.map(({ userDetails }) => (
                <div className="AccountDetails">
                  <h2 className="PaymentMethodHeading">{title}</h2>
                  <AllAccountDetails userDetails={userDetails} />
                </div>
              ))}

            {transactionData?.transaction_type === "REFUND" &&
              refundDetails?.map(({ userDetails }) => (
                <div className="AccountDetails">
                  <h2 className="PaymentMethodHeading">{title}</h2>
                  <AllAccountDetails userDetails={userDetails} />
                </div>
              ))}
            <ReachUsOptions />
            {/* <ProfileButton value="Download PDF" /> */}
            {transactionData?.cart_id > 0 ? (
              <button
                className="confirm-otp-number"
                onClick={() => {
                  transactionData?.transaction_type === "DEPOSIT"
                    ? navigate("/profile/deposits")
                    : transactionData?.status === "CANCELLED"
                      ? navigate(`/cart`)
                      : navigate(
                        `/complete-purchase?cartid=${transactionData?.cart_id}`
                      );
                }}
              >
                {t("continue")}
              </button>
            ) : (
              <button
                className="confirm-otp-number"
                onClick={() => {
                  transactionData?.transaction_type === "DEPOSIT"
                    ? navigate("/profile/deposits")
                    : navigate(
                      `/complete-purchase?id=${transactionData?.asset_id}`
                    );
                }}
              >
                {t("continue")}
              </button>
            )}
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Creditpaymentstatus;
